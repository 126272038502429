import { Table } from 'components-antd';
import styles from './styles.module.scss';
import { teamTableColumns } from './tableColumns';
import { useEffect, useMemo, useState } from 'react';
import { MemberTypes, PermissionTypes } from 'app-constants/enums/permissionSettings';
import { ManageRoleAndAccess } from './ManageRoleAndAccess';
import { useSelector } from 'react-redux';
import { getTeamTransactionPermissionstDataArray } from 'store/selectors/teamList';
import { TEAM_ADMIN, TEAM_BASIC, TEAM_OWNER } from 'settings/constants/roles';
import { getTransactionRolesDataDashboardSelector } from 'store/selectors/adminPanel';

type AgentMembersTableType = {
  memberType: string;
  searchValue: string;
  loadingMembers: boolean;
};

export const AgentMembersTable = (props: AgentMembersTableType) => {
  const { memberType, searchValue, loadingMembers } = props;
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const teamTransactionPermissions = useSelector(getTeamTransactionPermissionstDataArray);
  const transactionRoles = useSelector(getTransactionRolesDataDashboardSelector);

  useEffect(() => {
    const list = getMembersByType(teamTransactionPermissions, memberType);
    const expandedIds = list.filter(({ AddToTransaction }) => AddToTransaction).map(({ Id }) => Id);

    setExpandedRowKeys(expandedIds);
  }, [teamTransactionPermissions, memberType]);

  const getMembersByType = (permissionUsers, type) => {
    const isAdmins = type === MemberTypes.Admins;

    const members = [...permissionUsers];

    return members.filter((item) =>
      isAdmins ? item.Role === TEAM_ADMIN || item.Role === TEAM_OWNER : item.Role === TEAM_BASIC,
    );
  };

  const dataSource = useMemo(() => {
    const list = getMembersByType(teamTransactionPermissions, memberType);

    if (searchValue) {
      return list.filter(({ Name }) => Name.toLowerCase().includes(searchValue.toLowerCase()));
    }

    return list;
  }, [teamTransactionPermissions, memberType, searchValue]);

  const onSelectType = (rowId, type) => {
    if (type === PermissionTypes.AutomaticallyAdd) {
      setExpandedRowKeys((prev) => [...prev, rowId]);
    } else if (expandedRowKeys.includes(rowId)) {
      const keys = expandedRowKeys.filter((value) => value !== rowId);
      setExpandedRowKeys(keys);
    }
  };

  return (
    <Table
      className={`${styles.agentMembersTable}`}
      columns={teamTableColumns({
        memberType,
        setExpandedRowKeys: onSelectType,
        transactionRoles,
      })}
      dataSource={dataSource}
      loading={loadingMembers}
      rowKey={'Id'}
      pagination={{
        total: dataSource?.length,
        defaultPageSize: dataSource?.length,
      }}
      expandable={{
        expandedRowKeys,
        expandedRowRender: (record) => <ManageRoleAndAccess {...record} />,
        showExpandColumn: false,
      }}
    />
  );
};
