import { apiServer } from 'settings/web-services/api';
import { Campaign, CreateCampaignPayload } from 'store/reducers/radarKits/types';
import { GetMemberCampaignInfoPayload } from './types';

export const getPublishedKits = () => {
  return apiServer.get('/campaign/publishedKits');
};

export const postCampaign = (cfg: CreateCampaignPayload) => {
  return apiServer.post<{ result: Campaign }>('/campaign', cfg);
};

export type UpdateCampaignPayload = Partial<
  CreateCampaignPayload & { Id: number; deleted?: number[] }
>;

export const updateCampaign = (cfg: UpdateCampaignPayload) => {
  const { Id, ...rest } = cfg;
  return apiServer.put(`/campaign/${Id}`, rest);
};

export const getCampaigns = () => {
  return apiServer.get('/campaign');
};

export const getCampaignById = (id: number) => {
  return apiServer.get(`/campaign/${id}`);
};

export const getCampaignMembersByCampaignId = (id: number) => {
  return apiServer.get(`/campaign/campaignMembers/${id}`);
};

export const getCampaignMasterSwitch = (id: number) => {
  return apiServer.get(`/campaign/campaignMembersMasterSwitch/${id}`);
};

export const putCampaignMasterSwitch = (cfg: { Active: boolean; CampaignId: number }) => {
  return apiServer.put(`/campaign/campaignMembersMasterSwitch`, cfg);
};

export const getCampaignPublishedKitsWithStats = () => {
  return apiServer.get('/campaign/publishedKits');
};

export const getUpcomingMemberCampaignInfo = ({
  campaignId,
  memberId,
}: GetMemberCampaignInfoPayload) => {
  return apiServer.get(`/campaign/${campaignId}/member/${memberId}/upcoming`);
};

export const getFailedMemberCampaignInfo = ({
  campaignId,
  memberId,
}: GetMemberCampaignInfoPayload) => {
  return apiServer.get(`/campaign/${campaignId}/member/${memberId}/failed`);
};

export const getCanceledMemberCampaignInfo = ({
  campaignId,
  memberId,
}: GetMemberCampaignInfoPayload) => {
  return apiServer.get(`/campaign/${campaignId}/member/${memberId}/canceled`);
};

export const getOverdueMemberCampaignInfo = ({
  campaignId,
  memberId,
}: GetMemberCampaignInfoPayload) => {
  return apiServer.get(`/campaign/${campaignId}/member/${memberId}/overdue`);
};

export const getPastMemberCampaignInfo = ({
  campaignId,
  memberId,
}: GetMemberCampaignInfoPayload) => {
  return apiServer.get(`/campaign/${campaignId}/member/${memberId}/past`);
};

export const getTestClockReq = (data: GetMemberCampaignInfoPayload) => {
  return apiServer.get(
    `/campaign/test-clock/config?campaignId=${data.campaignId}&memberId=${data.memberId}`,
  );
};

export const createTestClockReq = ({ campaignId, memberId }: GetMemberCampaignInfoPayload) => {
  return apiServer.post(`/campaign/test-clock/config`, {
    campaignId: Number(campaignId),
    memberId: Number(memberId),
  });
};

export const resetTestClockReq = ({ campaignId, memberId }: GetMemberCampaignInfoPayload) => {
  return apiServer.post(`/campaign/test-clock/reset`, {
    campaignId: Number(campaignId),
    memberId: Number(memberId),
  });
};

export const fastForwardTestClockReq = ({
  campaignId,
  memberId,
  tillDate,
}: GetMemberCampaignInfoPayload & { tillDate: Date }) => {
  return apiServer.post(`/campaign/test-clock/fast-forward`, {
    campaignId: Number(campaignId),
    memberId: Number(memberId),
    tillDate,
  });
};

export const createCampaignTaskReq = (payload) => {
  const { CampaignId, ...config } = payload;
  return apiServer.post(`/campaign/${CampaignId}/tasks`, config);
};

export const updateCampaignTaskReq = (payload) => {
  const { taskId, CampaignId, ...config } = payload;
  return apiServer.put(`/campaign/${CampaignId}/tasks/${taskId || config.Id}`, config);
};

export const getCampaignTaskReq = ({ campaignId, id }) => {
  return apiServer.get(`/campaign/${campaignId}/tasks/${id}`);
};

export const deleteCampaignTaskReq = ({ campaignId, id }) => {
  return apiServer.delete(`/campaign/${campaignId}/tasks/${id}`);
};

export const uploadCampaignTaskDocumentsReq = (payload) => {
  const { CampaignId, taskId, ...config } = payload;
  return apiServer.post(`/campaign/${CampaignId}/tasks/${taskId}/documents`, config);
};

export const deleteCampaignTaskDocumentReq = (payload) => {
  const { campaignId, taskId, documentId } = payload;
  return apiServer.delete(`/campaign/${campaignId}/tasks/${taskId}/documents/${documentId}`);
};

export const getCampaignTaskMembersReq = () => {
  return apiServer.get(`/campaign/taskMembers`);
};

export const postCampaignTaskTemplateReq = (payload) => {
  return apiServer.post(`/campaign/${payload?.CampaignId}/tasks-templates`, payload);
};

export const putCampaignTaskTemplateReq = (payload, options) => {
  return apiServer.put(`/campaign/task/templates/${options.id}`, payload);
};

export const deleteCampainTaskTemplateReq = (id: number) => {
  return apiServer.delete(`/campaign/task/templates/${id}`);
};

export const cancelCampaignExecEventReq = (payload) => {
  return apiServer.put(
    `/campaign/${payload.CampaignId}/member/${payload.MemberId}/cancel-event/${payload.Id}`,
  );
};

export const retryCampaignExecEventReq = (payload) => {
  return apiServer.put(
    `/campaign/${payload.CampaignId}/member/${payload.MemberId}/retry-event/${payload.Id}`,
  );
};

export const addClientToCampaignsReq = (payload) => {
  return apiServer.post(`/campaign/client/enrollments`, payload);
};
