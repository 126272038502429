import { FormProcessLogAction, SignatoryStatus } from 'app-constants';

export const getSignatoryStatus = (signatory, formDetails) => {
  const { email, status } = signatory;
  const isRequested = status === SignatoryStatus.Requested;
  const isVoided = formDetails?.isVoid;

  const signatoryStatus = isRequested
    ? isVoided
      ? 'Voided'
      : getStatusFromHistory(email, formDetails)
    : isVoided
    ? 'Voided'
    : status;

  return signatoryStatus;
};

export const getStatusFromHistory = (signatoryEmail, formDetails) => {
  const { historyLogs } = formDetails;

  const isViewed = historyLogs.find(
    ({ email, Action }) =>
      email === signatoryEmail && Action === FormProcessLogAction.VIEWED_FOR_SIGNING,
  );

  return isViewed ? 'Viewed' : 'Requested';
};

export const getViewDate = (signatoryEmail, formDetails) => {
  const { historyLogs } = formDetails;
  const viewedHistoryLog = historyLogs.find(
    ({ email, Action }) =>
      email === signatoryEmail && Action === FormProcessLogAction.VIEWED_FOR_SIGNING,
  );

  return viewedHistoryLog.CreatedDate;
};
