export default {
  settingsPartner: '/settings/partner',
  settingsPassword: '/settings/password',
  settingsSubscription: '/settings/plan',
  settingsSubscriptionChange: '/settings/plan/change',
  settingsNotifications: '/settings/notifications',
  settingsCoBuyer: '/settings/co-buyer',
  settingsPreApproval: '/settings/pre-approval',
  settingsBrokerage: '/settings/brokerage',
  settingsDisplay: '/settings/display',
  settingsThirdPartyIntegrations: '/settings/third-party-integrations',
  settingsExport: '/settings/export',
  settingPermissions: '/setting/permissions',
};
