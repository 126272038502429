import { useDispatch, useSelector } from 'react-redux';
import { setCriteriaAction, setFilterAction } from 'store/actions/feedv3';
import { getSavedSearchPropertiesV3Effect } from 'store/effects/feedv3';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getSubFilterTypeSelector } from 'store/selectors/feed';
import { getFeedSortSelector } from 'store/selectors/feed';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import { LocalStorage } from 'services';
import { omitBy } from 'lodash-es';
import { LocationType } from 'types';

export const useSearchInstanceV3Effect = () => {
  const dispatch = useDispatch();
  const sort = useSelector(getFeedSortSelector);
  const searchContext = useSelector(getCurrentContextSelector);
  const subFilterType = useSelector(getSubFilterTypeSelector);

  const DEFAULT_PAGE_SIZE = 350;

  const getSearchInstanceV3 = (item, cb?: any, subFilter?: string, isDraw = false) => {
    const cleanedCriteria = omitBy(cleanSearchQueryObj(item?.criterias), (value) => !value);

    const cfg = {
      queryParams: {
        sortField: sort?.fields?.[0] ?? 'MatchScore',
        sortOrder: sort?.order,
        first: DEFAULT_PAGE_SIZE,
        contextId: searchContext?.ContextKey,
        loadTotalCount: true,
        subFilterType: subFilter || subFilterType,
      },
      body: {
        searchQuery: {
          ...cleanedCriteria,
        },
      },
    };
    LocalStorage.setSearchCriteria(cleanedCriteria);

    dispatch(setCriteriaAction({ criteria: cleanedCriteria }));

    dispatch(
      getSavedSearchPropertiesV3Effect(cfg, { isDraw }, (err, resp) => {
        dispatch(setFilterAction({ filter: {} }));
        if (cb) cb(err, resp);
      }),
    );
  };

  return getSearchInstanceV3;
};
