import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Drawer, Spinner } from 'components';

import { useDispatch, useSelector } from 'react-redux';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getTransactionEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';
import Icons from './../../Icons';
import { Button, Col, Row, Tabs } from 'components-antd';
import Timeline from '../Timeline';
import { DetailsView } from 'pages/Workshop/Transactions/components/DetailsView';
import { NamesContainer } from 'pages/Workshop/Transactions/components/NamesContainer';
import moment from 'moment';
import { Contacts } from './Contacts';
import { TransactionNotes } from './Notes';
import { isAfterToday, isToday } from 'helpers';
import classNames from 'classnames';
import { displayListedStatus, displayTransactionStatuses } from 'settings/constants/transaction';

enum TransactionDrawerPanes {
  Timeline = 'Timeline',
  Details = 'Details',
  Contacts = 'Contacts',
  Notes = 'Notes',
}

export const TransactionDrawer = ({ transactionId, isProject, openDrawer, setOpenDrawer }) => {
  const [editDetailId, setEditDetailId] = useState<any>();
  const [tabKey, setTabKey] = useState<string>(TransactionDrawerPanes.Timeline);
  const dispatch = useDispatch();
  const history = useHistory();
  const { transaction, isError, status, isPending, isIdle } = useSelector(getTransactionSelector);
  const address = transaction?.Property?.Address ?? {};

  useEffect(() => {
    if (transactionId) {
      dispatch(getTransactionEffect({ id: transactionId }));
      setTabKey(TransactionDrawerPanes.Timeline);
    }
  }, [transactionId]);

  const navigateToOverview = () => {
    const routeKey = isProject ? 'projects' : 'transactions';
    history.push(`/workshop/${routeKey}/${transactionId}/overview`);
  };

  const getProjectDate = () => {
    const { EffectiveDate, ClosingDate } = transaction;
    const date =
      isToday(EffectiveDate) || isAfterToday(EffectiveDate) ? EffectiveDate : ClosingDate;
    const label = isToday(EffectiveDate) || isAfterToday(EffectiveDate) ? 'Kickoff' : 'Deadline';

    return (
      <div className={classNames(styles.detailField, styles.projectDate)}>
        <span className={styles.label}>{label}:</span>
        <div className={styles.value}>{date ? moment(date).format('MMM DD, YYYY') : 'N/A'}</div>
      </div>
    );
  };

  const tabItems = [
    {
      key: TransactionDrawerPanes.Timeline,
      label: TransactionDrawerPanes.Timeline,
      children: (
        <div className={styles.drawerTimeline}>
          <Timeline isTransactionDrawer={true} isProject={isProject} />
        </div>
      ),
    },
    {
      key: TransactionDrawerPanes.Details,
      label: TransactionDrawerPanes.Details,
      children: (
        <div className={styles.drawerDetailsView}>
          <DetailsView
            editDetailId={editDetailId}
            setEditDetailId={setEditDetailId}
            transactionId={transactionId}
            isProject={isProject}
          />
        </div>
      ),
    },
    {
      key: TransactionDrawerPanes.Contacts,
      label: TransactionDrawerPanes.Contacts,
      children: (
        <Contacts
          participants={transaction?.Participants}
          transactionId={transactionId}
          isProject={isProject}
        />
      ),
    },
    {
      key: TransactionDrawerPanes.Notes,
      label: TransactionDrawerPanes.Notes,
      children: (
        <div className={styles.drawerNotesView}>
          <TransactionNotes
            participants={transaction?.Participants}
            transactionId={transactionId}
            className={styles.transactionNotes}
          />
        </div>
      ),
    },
  ];

  return (
    <Drawer
      isOpen={openDrawer}
      onClose={() => setOpenDrawer(false)}
      header={
        <div className={styles.drawerHeader} onClick={() => setOpenDrawer(false)}>
          <Icons variant={Icons.PANEL_CLOSE} />
        </div>
      }
      className={styles.filterDrawer}
      position={'right'}
      zIndex={1000}
      contentStyleClassName={styles.transactionContent}
    >
      {!isPending ? (
        <div className={styles.drawerContent}>
          <div className={styles.addressBar}>
            {isProject ? (
              <span>{transaction?.Name}</span>
            ) : (
              <span className={styles.address}>
                {`${address?.Line1} ${address?.Line2 ? address?.Line2 : ''}`}
              </span>
            )}
          </div>
          {isProject ? (
            <div className={styles.subtitle}>{transaction?.TransactionCategory?.Category}</div>
          ) : (
            <div className={styles.subtitle}>
              {`${address?.City}, ${address?.State} ${address?.Zip}`}
            </div>
          )}
          <div className={styles.status}>
            {displayListedStatus[transaction?.Status] ??
              displayTransactionStatuses[transaction?.Status] ??
              transaction?.Status}
          </div>
          {isProject ? (
            <Row className={styles.detailsRow} justify="center">
              <Col>{getProjectDate()}</Col>
              <Col>
                <div className={classNames(styles.detailField, styles.ownersField)}>
                  <span className={styles.label}>Owner(s):</span>
                  {transaction?.AgentsOrOwners?.length > 0 ? (
                    <NamesContainer
                      names={transaction?.AgentsOrOwners}
                      maxDisplayCount={2}
                      isWidthRestrict
                    />
                  ) : (
                    <span>None</span>
                  )}
                </div>
              </Col>
            </Row>
          ) : (
            <Row className={styles.detailsRow}>
              <Col lg={8}>
                <div className={styles.detailField}>
                  <span className={styles.label}>Client(s):</span>
                  {transaction?.TransactionClients?.length > 0 ? (
                    <NamesContainer
                      names={transaction?.TransactionClients}
                      maxDisplayCount={2}
                      isWidthRestrict
                    />
                  ) : (
                    <span>None</span>
                  )}
                </div>
              </Col>
              <Col lg={8}>
                <div className={styles.detailField}>
                  <span className={styles.label}>Agent(s):</span>
                  {transaction?.AgentsOrOwners?.length > 0 ? (
                    <NamesContainer
                      names={transaction?.AgentsOrOwners}
                      maxDisplayCount={2}
                      isWidthRestrict
                    />
                  ) : (
                    <span>None</span>
                  )}
                </div>
              </Col>
              <Col lg={8}>
                <div className={styles.detailField}>
                  <span className={styles.label}>Coordinator(s):</span>
                  {transaction?.Coordinators?.length > 0 ? (
                    <NamesContainer
                      names={transaction?.Coordinators}
                      maxDisplayCount={2}
                      isWidthRestrict
                    />
                  ) : (
                    <span>None</span>
                  )}
                </div>
              </Col>
            </Row>
          )}

          {/* Tabs */}
          <Tabs
            activeKey={tabKey}
            onTabClick={(key) => setTabKey(key)}
            defaultActiveKey={TransactionDrawerPanes.Timeline}
            className={styles.transactionPanes}
            items={tabItems}
          />
        </div>
      ) : (
        <div className={styles.loaderContainer}>
          <Spinner />
        </div>
      )}
      <div className={styles.drawerFooter}>
        <Button variant="secondary" size="large" onClick={navigateToOverview}>
          Go to {isProject ? 'Project' : 'Transaction'}
        </Button>
      </div>
    </Drawer>
  );
};
