import { createAction } from 'redux-actions';

export const getPropertyReferenceDataAction = createAction('REQUEST/GET_PROPERTY_REFERENCE_DATA');
export const getPropertyReferenceStatsAction = createAction('REQUEST/GET_PROPERTY_REFERENCE_STATS');
export const getPropertyReferenceDetailsAction = createAction(
  'REQUEST/GET_PROPERTY_REFERENCE_DETAILS',
);
export const updatePropertyReferenceRadiusAction = createAction(
  'REQUEST/UPDATE_PROPERTY_REFERENCE_RADIUS',
);

export const getPropertyReferenceNeighborhoodAction = createAction(
  'REQUEST/GET_PROPERTY_REFERENCE_NEIGHBORHOOD',
);
export const getPropertyReferenceNeighborhoodActivitiesAction = createAction(
  'REQUEST/GET_PROPERTY_REFERENCE_NEIGHBORHOOD_ACTIVITIES',
);

export const updateClientPropertyAction = createAction('REQUEST/UPTADE_CLIENT_PROPERTY_ACTION');
