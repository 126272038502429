import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { cloneDeep } from 'lodash-es';

import { priceConverter } from 'helpers';
import { DateRangeFilter } from 'pages/ClarityRevamped/Components/Filters/DateRangeFilter';
import { TableViewFilter } from 'pages/ClarityRevamped/Components/Filters/TableViewFilter';
import { Wrapper as PendingWrapper } from 'components';
import { useEffect, useState } from 'react';
import { ClarityBarChart } from 'components/ClarityBarChart';
import { ModalHeader } from '../../Components/ModalHeader/ModalHeader';
import { AggregateResultHeader } from '../../Components/ModalHeader';
import { BreakdownMenu } from '../../../../BreakdownMenu';
import TableView from '../../Components/TableView/TableView';
import {
  getAgentTeamStatsSelector,
  getGraphColorSelector,
  getGraphNameSelector,
  getTimePeriodSelector,
} from 'store/selectors/clarity';
import { GeneralLedgerKeys, hideBreakdownMenu } from '../helper';
import { useFetchFinancialStats } from 'pages/ClarityRevamped/hooks';
import { ClarityLineChart } from 'components/ClarityLineChart';
import { LineChart, BarChart } from 'components/Icons';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

import styles from '../../styles.module.scss';

export const Fees = () => {
  const [isGraphView, setIsGraphView] = useState<boolean>(true);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openCompareModal, setOpenCompareModal] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [toggleGraph, setToggleGraph] = useState(false);
  const [summary, setSummary] = useState<any>({});
  const [data, setData] = useState([]);
  const [breakdownSummary, setBreakdownSummary] = useState<any>([]);
  const [breakdownList, setBreakdownList] = useState<any>([]);
  const [selectedAgent, setSelectedAgent] = useState<any>(null);
  const [teamSummary, setTeamSummary] = useState<any>([]);
  const timePeriod = useSelector(getTimePeriodSelector);
  const graphName = useSelector(getGraphNameSelector);
  const graphColor = useSelector(getGraphColorSelector);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  const getStats = useFetchFinancialStats();

  useEffect(() => {
    getStats({
      setSummary,
      setData,
      setIsPending,
      key: graphName,
      aggregationType: 'Net',
      summaryType: GeneralLedgerKeys.Fees,
      aggregateDataFormat: isGraphView ? 'Series' : 'Tabular',
      memo: graphName,
      year: null,
      quarter: null,
      month: null,
      isoWeek: null,
      date: null,
      transactionAgentUserId: selectedAgent?.TransactionAgentUserId,
      transactionAgentContactId: selectedAgent?.TransactionAgentContactId,
    });
  }, [timePeriod, isGraphView, selectedAgent]);

  useEffect(() => {
    if ('Total' in summary && teamStats.itemValue === 'Team' && (firstRender || !selectedAgent))
      setTeamSummary(summary);
  }, [summary, timePeriod]);

  useEffect(() => {
    if (selectedAgent) {
      getStats({
        setSummary: setTeamSummary,
        setData: () => {},
        setIsPending,
        key: graphName,
        aggregationType: 'Net',
        summaryType: GeneralLedgerKeys.Fees,
        aggregateDataFormat: 'Series',
        memo: graphName,
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
      });
    }

    if (hideBreakdownMenu(agentRole, teamStats)) {
      getStats({
        setSummary: setBreakdownSummary,
        setData: () => {},
        setIsPending,
        key: graphName,
        aggregationType: 'Net',
        summaryType: GeneralLedgerKeys.Fees,
        aggregateDataFormat: 'Series',
        memo: graphName,
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
        agentSummary: true,
      });
    }
  }, [timePeriod]);

  useEffect(() => {
    if (breakdownSummary.length && 'Total' in teamSummary) {
      let selectedAgentIndex = 0;
      if (selectedAgent?.TransactionAgent) {
        const index = breakdownSummary.findIndex(
          (row) => row.TransactionAgent === selectedAgent.TransactionAgent,
        );
        if (index === -1) {
          setSelectedAgent(null);
          selectedAgentIndex = 0;
        } else {
          selectedAgentIndex = index + 1;
        }
        selectedAgentIndex = index === -1 ? 0 : index + 1;
      }
      const newList = cloneDeep([
        {
          TransactionAgent: 'Team',
          Total: teamSummary?.Total || 0,
          NumberTransactions: teamSummary?.NumberTransactions || 0,
          Percentage: 100,
        },
        ...breakdownSummary,
      ]);

      const selected = newList[selectedAgentIndex];
      newList[selectedAgentIndex] = { ...selected, selected: true };
      setBreakdownList(newList);
    } else {
      setBreakdownList([]);
    }
  }, [breakdownSummary, teamSummary]);

  useEffect(() => {
    if (firstRender && breakdownList?.length) {
      setOpenMenu(true);
      setFirstRender(false);
    } else if (!breakdownList?.length) setOpenMenu(false);
  }, [breakdownList]);

  const handleClick = (agent, index) => {
    let newList;
    if (agent?.TransactionAgent === 'Team') {
      setSelectedAgent(null);
      newList = cloneDeep([
        {
          TransactionAgent: 'Team',
          Total: teamSummary?.Total || 0,
          NumberTransactions: teamSummary?.NumberTransactions || 0,
          Percentage: 100,
          selected: true,
        },
        ...breakdownSummary,
      ]);
    } else {
      setSelectedAgent(agent);
      newList = cloneDeep([
        {
          TransactionAgent: 'Team',
          Total: teamSummary?.Total || 0,
          NumberTransactions: teamSummary?.NumberTransactions || 0,
          Percentage: 100,
        },
        ...breakdownSummary,
      ]);
      newList[index] = { ...agent, selected: true };
    }
    setBreakdownList(newList);
  };

  const getGraph = () => {
    return toggleGraph || timePeriod === 'MTD' || timePeriod === 'WEEK' ? (
      <ClarityBarChart
        data={data}
        timePeriod={timePeriod}
        colors={[graphColor]}
        title={graphName}
        aggregationType={'Net'}
        summaryType={'FeesExpanded'}
        summaryKey={graphName}
        columnType={GeneralLedgerKeys.Fees}
        memo={graphName}
        selectedAgent={selectedAgent}
        clarityType={'Financials'}
        open={openMenu}
      />
    ) : (
      <ClarityLineChart
        data={data}
        timePeriod={timePeriod}
        colors={[graphColor]}
        title={graphName}
        aggregationType={'Net'}
        summaryType={'FeesExpanded'}
        summaryKey={graphName}
        columnType={GeneralLedgerKeys.Fees}
        memo={graphName}
        selectedAgent={selectedAgent}
        clarityType={'Financials'}
      />
    );
  };

  return (
    <PendingWrapper isPending={false} className={styles.clarityContentWrapper}>
      <div className={styles.headerRows}>
        <ModalHeader title={graphName} />
        <div className={styles.infoRow}>
          <AggregateResultHeader
            totalAmount={priceConverter(summary?.Total || 0)}
            totalRequests={summary?.NumberTransactions}
            textColor={{ color: graphColor }}
          />
          <div className={styles.filtersContainer}>
            <DateRangeFilter
              defaultOption={timePeriod}
              wrapperClassname={styles.dateRangeFilterWrapper}
            />
            {hideBreakdownMenu(agentRole, teamStats) ? (
              <div
                className={classnames(styles.breakdownBtn, { [styles.openMenu]: openMenu })}
                onClick={() => setOpenMenu(!openMenu)}
              >
                Breakdown
              </div>
            ) : (
              <></>
            )}
            {/* <StatusFilter options={['All', ...dropdownList]} cb={() => {}} /> */}
            <TableViewFilter
              setIsGraphView={setIsGraphView}
              openCompareModal={() => setOpenCompareModal(true)}
              setIsPending={setIsPending}
            />
          </div>
        </div>
      </div>
      <div className={styles.bodyRow}>
        <PendingWrapper
          isPending={isPending}
          className={classnames(
            styles.graphContainer,
            { [styles.openMenu]: isGraphView && openMenu },
            { [styles.tableContainer]: !isGraphView && openMenu },
          )}
        >
          {isGraphView ? (
            <div className={styles.graphContent}>
              {(timePeriod === 'YTD' || timePeriod === 'QTD') && (
                <div className={styles.graphToggleContainer}>
                  <div
                    className={styles.grpahToggleBtn}
                    onClick={() => setToggleGraph(!toggleGraph)}
                  >
                    {toggleGraph ? <LineChart /> : <BarChart />}
                  </div>
                </div>
              )}
              {getGraph()}
            </div>
          ) : (
            <TableView
              open={openMenu}
              data={data}
              title={graphName}
              aggregationType={'Net'}
              summaryType={'FeesExpanded'}
              summaryKey={graphName}
              columnType={GeneralLedgerKeys.Fees}
              memo={graphName}
              selectedAgent={selectedAgent}
              clarityType={'Financials'}
            />
          )}
        </PendingWrapper>
        {hideBreakdownMenu(agentRole, teamStats) ? (
          <div className={classnames(styles.breakdownMenu, { [styles.openMenu]: openMenu })}>
            <BreakdownMenu
              open={openMenu}
              list={breakdownList}
              handleClick={handleClick}
              color={graphColor}
              onClose={() => setOpenMenu(false)}
              title={graphName}
              aggregationType={'Net'}
              summaryType={'FeesExpanded'}
              summaryKey={graphName}
              columnType={GeneralLedgerKeys.Fees}
              memo={graphName}
              selectedAgent={selectedAgent}
              clarityType={'Financials'}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* <CompareModal open={openCompareModal} onClose={() => setOpenCompareModal(false)} /> */}
    </PendingWrapper>
  );
};
