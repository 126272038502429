import { BundleDocumentsType } from 'types';

export const prepareBundleForms = (forms) => {
  return forms.map(({ FormName, Name, Id, PublicId, DocumentLink, FormProcessId, Order }) => ({
    id: Id,
    sequence: Order,

    Id,
    Title: FormName || Name,
    DocumentLink,
    PublicId,
    FormProcessId,
  }));
};

export const getOrderedBundleDetails = (orderedDocuments, bundleDocuments, isTemplate) => {
  const orderedBundleDetails: BundleDocumentsType[] = [];
  const pdfDocuments: any[] = [];

  orderedDocuments.forEach(({ PublicId, Id }) => {
    const bundleItem = bundleDocuments.find((item) =>
      isTemplate ? (item.templateId || item.Id) === Id : item.PublicId === PublicId,
    );

    bundleItem && orderedBundleDetails.push(bundleItem);
  });

  return { bundleDetails: orderedBundleDetails };
};

export const filterLazyLoadedDocuments = (forms, loadedDocuments) => {
  const loadedDocumentIds = loadedDocuments?.map(({ document }) => document.Id) || [];

  if (!loadedDocumentIds.length) return [];

  return forms?.filter(({ Id }) => loadedDocumentIds.includes(Id)) || [];
};
