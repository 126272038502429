import { createPortal } from 'react-dom';
import classNames from 'classnames';

import styles from './styles.module.scss';
import MapModal from './MapModal';
import IconButton from '../../PhotosViewModal/IconButton';
import { Close as CloseIcon } from 'components/Icons';

const ExpandedModal = ({ isOpen, className, setIsOpen, options }) => {
  return createPortal(
    <div
      testid="photos_view_modal"
      className={classNames(styles.modal, { [styles.isOpen]: isOpen }, className)}
    >
      <div className={styles.opacityLayer} />
      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <div className={styles.buttonsContainer}>
            <IconButton
              Icon={CloseIcon}
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>
        </div>
        <MapModal options={options} />
      </div>
    </div>,
    document.body,
  );
};

export default ExpandedModal;
