import React, { useEffect, useState } from 'react';

import { AreaChart } from 'components/AreaChart';

import styles from './styles.module.scss';

type ClarityAreaGraphProps = {
  data: any[];
  colors: string[];
  showAverage: boolean;
};

export const ClarityAreaGraph: React.FC<ClarityAreaGraphProps> = ({
  data,
  colors,
  showAverage,
}) => {
  const [chartData, setChartData] = useState<number[]>([]);

  useEffect(() => {
    if (data?.length) {
      setChartData(
        data?.map((item) => {
          if (showAverage) return item?.TotalAverage;
          else if ('TotalValue' in item) return item?.TotalValue;
          else if ('TotalVolume' in item) return item?.TotalVolume;
        }),
      );
    }
  }, [data]);

  const configProps = {
    chartSeries: [
      {
        name: 'Total Value',
        data: chartData,
      },
    ],
    colors,
    className: styles.smallCardAreaChart,
  };

  return <AreaChart {...configProps} />;
};
