import { useState } from 'react';
import Icons from '../Icons';
import { WishListItemType } from '../../DataTypes';
import styles from './styles.module.scss';

type WishListRowPropsType = {
  sectionName: 'Needs' | 'Wants';
  hideFields: boolean;
  needs: any[];
  wants: any[];
  countStarredFields: number;
  starredFieldsCountHandler: Function;
  setStarredWishListRow: Function;
};

function WishListItem(props: WishListItemType) {
  return (
    <div className={styles.wishListItem}>
      {props.match === 'FULL' ? (
        <Icons iconName={'fullMatch'} />
      ) : props.match === 'PARTIAL' ? (
        <Icons iconName={'partialMatch'} />
      ) : (
        <Icons iconName={'noMatch'} />
      )}
      <p className={styles.wishListItemText}>{props.text.replace(/([A-Z])/g, ' $1').trim()}</p>
    </div>
  );
}

function WishListRow(props: WishListRowPropsType) {
  const [starred, setStarred] = useState<boolean>(false);

  const starClickHandler = () => {
    if (!starred) {
      setStarred(true);
      props?.setStarredWishListRow(true);
      props.starredFieldsCountHandler('inc');
    } else if (starred && props.hideFields && props.countStarredFields === 1) {
      setStarred(true);
      props?.setStarredWishListRow(true);
    } else {
      setStarred(false);
      props?.setStarredWishListRow(false);
      props.starredFieldsCountHandler('dec');
    }
  };

  if (!starred && props.hideFields) return <></>;

  return (
    <tr className={styles.row}>
      <td className={styles.column + ' ' + styles.columnHeading}>
        <div>
          <div onClick={() => starClickHandler()}>
            <Icons iconName={starred ? 'yellowStar' : 'star'} />
          </div>
          <div>{props.sectionName + ':'}</div>
        </div>
      </td>
      {props[props.sectionName === 'Needs' ? 'needs' : 'wants'].map((item) => (
        <td className={styles.column}>
          {item.wishListData && item.wishListData.length ? (
            item.wishListData.map((wItem) => (
              <WishListItem text={wItem.feature} match={wItem.matchLevel} />
            ))
          ) : (
            <div>-</div>
          )}
        </td>
      ))}
    </tr>
  );
}

export default WishListRow;
