import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Row, Col } from 'components-antd';
import { convertToDecimalIfNotWhole } from 'helpers';
import { TransactionBuySideCommissionResponsibility } from 'app-constants';
import NumberFormat from 'react-number-format';
import { PriceHighlighter } from '../../../PriceHighlighter';
import { getTransactionSelector } from 'store/selectors/transaction';

import styles from './styles.module.scss';

enum ResponsibilitySides {
  BUYER = 'Buyer',
  SELLER = 'Seller',
}

const ResponsibilityItem = ({ label, ratePercentage, gciValue, isFeeTypePercentage, variant }) => {
  return (
    <Row gutter={12} className={styles.responsibilityItem}>
      <Col lg={14}>
        <div className={styles.labelContainer}>
          <div className={classNames(styles.color, styles[variant])} />
          <span>{label}:</span>
        </div>
      </Col>
      <Col lg={4}>
        <span className={styles.value}>
          {ratePercentage ? convertToDecimalIfNotWhole(parseFloat(ratePercentage)) : '0'}%
          <PriceHighlighter isShow={isFeeTypePercentage} />
        </span>
      </Col>
      <Col lg={6}>
        <NumberFormat
          thousandSeparator
          displayType="text"
          value={convertToDecimalIfNotWhole(gciValue)}
          prefix="$"
          renderText={(val) => (
            <span className={styles.value}>
              {val}
              <PriceHighlighter isShow={!isFeeTypePercentage} />
            </span>
          )}
        />
      </Col>
    </Row>
  );
};

const initialStateCommissionContribution = {
  Value: null,
  IsFeeTypePercentage: true,
};

export const CommissionResponsibility = ({ buysidePrice }) => {
  const { transaction } = useSelector(getTransactionSelector);
  const [buysideCommissionResponsibility, setBuysideCommissionResponsibility] = useState<any>('');
  const [buyerCommissionContribution, setBuyerCommissionContribution] = useState<any>(
    initialStateCommissionContribution,
  );
  const [sellerCommissionContribution, setSellerCommissionContribution] = useState<any>(
    initialStateCommissionContribution,
  );

  useEffect(() => {
    if (transaction?.TransactionCommission) setupDefaultValues();
  }, [transaction]);

  const setupDefaultValues = () => {
    const {
      BuySideCommissionResponsibility,
      BuyerCommissionContribution,
      SellerCommissionContribution,
    } = transaction?.TransactionCommission || {};
    setBuysideCommissionResponsibility(BuySideCommissionResponsibility || '');
    setSellerCommissionContribution(
      SellerCommissionContribution || initialStateCommissionContribution,
    );
    setBuyerCommissionContribution(
      BuyerCommissionContribution || initialStateCommissionContribution,
    );
  };

  const calculateGCI = (percentValue) => {
    return (percentValue / 100) * buysidePrice;
  };

  const calculateRate = (gciValue) => {
    if (isNaN(gciValue)) return 0;
    return convertToDecimalIfNotWhole(((gciValue || 0) * 100) / buysidePrice);
  };

  const responsibilityItemProps = (side) => {
    const isBuySideResponsibilityBoth =
      buysideCommissionResponsibility !== TransactionBuySideCommissionResponsibility.Both;

    const contribution =
      side === ResponsibilitySides.BUYER
        ? buyerCommissionContribution
        : side === ResponsibilitySides.SELLER
        ? sellerCommissionContribution
        : null;

    if (contribution) {
      const { IsFeeTypePercentage, Value } = contribution;
      return {
        ratePercentage: !isBuySideResponsibilityBoth
          ? IsFeeTypePercentage
            ? Value
            : calculateRate(Value)
          : 100,
        gciValue: !isBuySideResponsibilityBoth
          ? IsFeeTypePercentage
            ? calculateGCI(Value)
            : Value
          : buysidePrice,
      };
    }

    return { ratePercentage: 0, gciValue: 0 };
  };

  return (
    <div className={styles.commissionRespContainer}>
      <p className={styles.heading}>Responsible Party:</p>
      {(buysideCommissionResponsibility === TransactionBuySideCommissionResponsibility.Both ||
        buysideCommissionResponsibility === TransactionBuySideCommissionResponsibility.Buyer) && (
        <ResponsibilityItem
          label={ResponsibilitySides.BUYER}
          {...responsibilityItemProps(ResponsibilitySides.BUYER)}
          isFeeTypePercentage={buyerCommissionContribution?.IsFeeTypePercentage}
          variant="blue"
        />
      )}
      {(buysideCommissionResponsibility === TransactionBuySideCommissionResponsibility.Both ||
        buysideCommissionResponsibility === TransactionBuySideCommissionResponsibility.Seller) && (
        <ResponsibilityItem
          label={ResponsibilitySides.SELLER}
          {...responsibilityItemProps(ResponsibilitySides.SELLER)}
          isFeeTypePercentage={sellerCommissionContribution?.IsFeeTypePercentage}
          variant="lightBlue"
        />
      )}
    </div>
  );
};
