import { Table, TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Modal } from 'components-antd';
import {
  getTimePeriodDueFromSelector,
  getTimePeriodDueToSelector,
  getTimePeriodSelector,
} from 'store/selectors/clarity';
import { Wrapper as PendingWrapper } from 'components';
import { useFetchVolumeStats } from 'pages/ClarityRevamped/hooks';
import { ExportCSV } from 'pages/ClarityRevamped/Components/ExportCSV';
import { generateCSVFileName } from '../../helper';

import styles from '../styles.module.scss';

interface DataType {
  Date: string;
  Transaction: string;
  TotalVolume: number;
  PropertyTransactionId: number;
  RepresentingSide: string;
  Agents: string;
}

export const VolumeTableModal = ({
  title,
  summaryType,
  year,
  month,
  isoWeek,
  date,
  open,
  onCancel,
  selectedAgent,
}) => {
  const dueFrom = useSelector(getTimePeriodDueFromSelector);
  const dueTo = useSelector(getTimePeriodDueToSelector);
  let timePeriod = useSelector(getTimePeriodSelector);
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(false);

  if (timePeriod === moment().subtract(1, 'year').year()) timePeriod = 'YTD';

  const getStats = useFetchVolumeStats();

  const getDateTitle = (type) => {
    if (type === 'Listings' || type === 'Purchases') return 'Date';
    else if (type === 'Canceled') return 'Canceled Date';
    return 'Closing Date';
  };

  const volumeColumns: TableProps<DataType>['columns'] = [
    {
      title: getDateTitle(summaryType),
      dataIndex: 'Date',
      key: 'Date',
      sorter: {
        compare: (record1, record2) => {
          const a = moment(record1.Date);
          const b = moment(record2.Date);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value) {
        return <span className={styles.dateColumn}>{value}</span>;
      },
    },
    {
      title: 'Transaction',
      dataIndex: 'Transaction',
      key: 'Transaction',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Transaction;
          const b = record2.Transaction;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value, record) {
        return (
          <a
            target="_blank"
            className={styles.linkToTransaction}
            href={`/workshop/transactions/${record.PropertyTransactionId}/overview`}
            rel="noreferrer"
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'TotalVolume',
      key: 'TotalVolume',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.TotalVolume;
          const b = record2.TotalVolume;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (TotalVolume) => {
        return TotalVolume >= 0
          ? `$${TotalVolume.toLocaleString()}`
          : `-$${Math.abs(TotalVolume).toLocaleString()}`;
      },
    },
    {
      title: 'Representing',
      dataIndex: 'RepresentingRole',
      key: 'RepresentingRole',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.RepresentingSide;
          const b = record2.RepresentingSide;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Agent',
      dataIndex: 'Agents',
      key: 'Agents',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Agents;
          const b = record2.Agents;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (Agents) => {
        return Agents.join(', ');
      },
    },
  ];

  const formatCSVData = (data) => {
    const formattedData = data?.map((row) => {
      return {
        Date: row.Date,
        Transaction: `"${row.Transaction}"`,
        Amount:
          row.TotalVolume >= 0
            ? `$${row.TotalVolume.toLocaleString()}`
            : `-$${Math.abs(row.TotalVolume).toLocaleString()}`,
        RepresentingRole: row.RepresentingRole,
        Agent: row.Agents.join(', '),
      };
    });
    return formattedData.map(({ Date, Transaction, Amount, RepresentingRole }) => ({
      Date,
      Transaction,
      Amount,
      RepresentingRole,
    }));
  };

  useEffect(() => {
    getStats({
      setSummary: () => {},
      setData,
      setIsPending,
      summaryType: summaryType,
      aggregateDataFormat: 'Tabular',
      year: year,
      quarter: null,
      month: timePeriod === 'YTD' ? month : null,
      isoWeek: timePeriod === 'QTD' ? isoWeek : null,
      date: timePeriod === 'MTD' || timePeriod === 'WEEK' || dueFrom || dueTo ? date : null,
      transactionAgentUserId: selectedAgent?.TransactionAgentUserId,
      transactionAgentContactId: selectedAgent?.TransactionAgentContactId,
      expand: true,
    });
  }, [timePeriod, year, month, isoWeek, dueFrom, dueTo]);

  return (
    <Modal
      width={1000}
      className={styles.clarityTableModal}
      open={open}
      footer={null}
      onCancel={onCancel}
    >
      <header className={styles.tableHeader}>
        <h2 className={styles.modalHeader}>{title}</h2>
        <ExportCSV data={formatCSVData(data)} fileName={generateCSVFileName(title)} />
      </header>
      <PendingWrapper isPending={isPending} className={styles.tableContainer}>
        <Table
          className={styles.statsTable}
          columns={volumeColumns}
          dataSource={data}
          pagination={false}
        />
      </PendingWrapper>
    </Modal>
  );
};
