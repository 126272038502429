import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  getComparesSelector,
  getMultipleModSelector,
  getSubFilterTypeSelector,
} from 'store/selectors/feed';

import NumberFormat from 'react-number-format';
import { openNewTab } from 'services/newTab';
import { link } from 'settings/navigation/link';

import { PropertyImage } from 'components/Icons';
import Match from 'pages/Properties/Feed/Properties/Property/Match';
import styles from './styles.module.scss';
import { subFilterTypes } from 'settings/constants/properties';
import Favorite from 'pages/Properties/SearchResults/Properties/Property/Favorite';
import { RecommendedTo } from 'pages/Properties/AgentFeed/components/RecommendedTo';
import Applicants from 'pages/Properties/SearchResults/Properties/Property/Applicants';
import Status from 'pages/Properties/Feed/Properties/Property/Status';
import { routes } from 'settings/navigation/routes';
import { Checkbox } from 'components';
import { setComparesEffect } from 'store/effects';
import { SubFilterStatus } from '../SubFilterStatus';
import Comment from 'pages/Properties/Feed/Properties/Property/Comment';
import { getSavedSearchesFilteredPropertiesList } from 'store/selectors/feedv3';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { isEmpty } from 'lodash-es';
import {
  formatNumber,
  getBathsTotalNum,
  filterImagesOnlyFromPhotoUrls,
  formatArea,
  processMediaToOrderedImages,
} from 'helpers';
import MatchFav from 'pages/Properties/components/MatchFav';
import moment from 'moment';

const threshold = 15;

export const PropertyCard = ({
  data,
  getPageNumber,
  searchInstanceId = null,
  shouldScrollIntoView,
  isAgentType = false,
  index = 0,
  countOnlyUnread = false,
  isFavProperty,
  className = '',
  photoClassName = '',
  containerClassName = '',
}) => {
  const {
    PhotoUrls,
    Media,
    ListPrice,
    PreviousListPrice,
    SellingPrice,
    OpenHouses,
    NumBeds,
    NumBathsTotal,
    SquareFeet,
    Address,
    ActivityStatus,
    PreviousStatus,
    Suggestions,
    HomeType,
    LotSizeSquareFeet,
    LotSizeCalculatedFromRange,
    ExteriorAndLotDetails,
    PropertyListingStatusChangeTimeStamp,
    PriceChangeTimeStamp,
    Market,
  } = data ?? {};

  const { isAgent } = useSelector(getUserRolesMapSelector);
  const { properties } = useSelector(getSavedSearchesFilteredPropertiesList);
  let selectedProperty = properties.find((property) => property.Id === data.Id);
  const photos = !isEmpty(Media)
    ? processMediaToOrderedImages(Media, Market)
    : filterImagesOnlyFromPhotoUrls(PhotoUrls, Market);
  const imageToSend = photos[0];
  const type = useSelector(getSubFilterTypeSelector);
  const multiple = useSelector(getMultipleModSelector);
  const propertyRef = useRef<any>(null);
  const compares = useSelector(getComparesSelector);
  const dispatch = useDispatch();
  const [shouldShowBorder, setShouldShowBorder] = useState(false);
  const isChecked = compares?.data?.findIndex((property) => property?.Id === data.Id) !== -1;
  //LotSizeAcres should only be displayed when it is not calculated from a range
  const showAcreage = HomeType === 'Land';
  const acerageDisplay =
    LotSizeCalculatedFromRange != true && LotSizeSquareFeet
      ? formatArea(Math.round(LotSizeSquareFeet.toFixed(1))) + ' sqft'
      : ExteriorAndLotDetails?.LotSizeInfo
      ? ExteriorAndLotDetails.LotSizeInfo
      : '';

  useEffect(() => {
    if (shouldScrollIntoView && shouldScrollIntoView()) {
      propertyRef?.current?.scrollIntoView({ behavior: 'smooth' });
      setShouldShowBorder(true);
    }
  }, []);

  const onCheckHandler = () => {
    const propertyId = data.Id;
    if (!isChecked && compares?.data?.length < 5) {
      dispatch(setComparesEffect([...compares.data, data]));
    } else {
      const newCompares = compares.data.filter((property) => property.Id !== propertyId);
      dispatch(setComparesEffect(newCompares));
    }
  };

  function camelCaseToNormalText(camelCaseStr) {
    // Insert a space before each uppercase letter
    let result = camelCaseStr.replace(/([A-Z])/g, ' $1');

    // Capitalize the first letter of every word
    result = result
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

    return result;
  }

  const [nearestOpenHouse] = OpenHouses?.length ? OpenHouses : [undefined];
  const navigateToDetails = (e) => {
    let searchPath = link.toSearchListingDetails(
      routes.searchListingDetail,
      data?.Id,
      searchInstanceId,
    );
    let path = link.toSearchListingDetails(routes.feedListingDetail, data?.Id, searchInstanceId);

    if (getPageNumber && getPageNumber()) {
      path += path.includes('?')
        ? `&pageNumber=${getPageNumber()}`
        : `?pageNumber=${getPageNumber()}`;
    }
    e.preventDefault();
    if (multiple) {
      onCheckHandler();
    } else {
      // open the property details with the search path so that all the preferences
      // are sent in the search query to evaluate the matched criteria.
      if (isFavProperty) {
        searchPath = searchPath.includes('?')
          ? `${searchPath}&showscore=false`
          : `${searchPath}?showscore=false`;
      }
      openNewTab(searchPath);
    }
  };

  const isWithinPastThreshold = (date) => {
    const now = moment();
    const givenDate = moment(date);
    return now.diff(givenDate, 'days') <= threshold && givenDate.isBefore(now);
  };

  const showPricePill =
    PreviousListPrice &&
    PreviousListPrice !== ListPrice &&
    PreviousListPrice > ListPrice &&
    isWithinPastThreshold(PriceChangeTimeStamp);
  const showStatusPill =
    PreviousStatus &&
    PreviousStatus !== ActivityStatus &&
    isWithinPastThreshold(PropertyListingStatusChangeTimeStamp);

  return (
    <div
      className={classNames(styles.propertyCard, className, {
        [styles.focusedProperty]: shouldShowBorder,
        [styles.selected]: isChecked,
      })}
      onClick={navigateToDetails}
      ref={propertyRef}
    >
      <Status className={styles.status} status={ActivityStatus} />
      {imageToSend ? (
        <img
          src={imageToSend}
          alt="ggx"
          className={classNames(styles.photo, photoClassName)}
          key={imageToSend}
        />
      ) : (
        <div className={styles.placeholderImg}>
          <PropertyImage />
        </div>
      )}
      <div className={containerClassName}>
        <div className={styles.infoContainer}>
          <div className={styles.topInfo}>
            <div>
              {SellingPrice ? (
                <NumberFormat
                  displayType="text"
                  thousandSeparator
                  value={SellingPrice}
                  prefix="$"
                  renderText={(val) => <span className={styles.price}>{val}</span>}
                />
              ) : (
                <span className={styles.price}>Price Unavailable</span>
              )}
              {showAcreage ? (
                <div className={styles.propertyStats}>
                  <span>{acerageDisplay}</span>
                </div>
              ) : (
                <div className={styles.propertyStats}>
                  {NumBeds > 0 && (
                    <>
                      <span>
                        {NumBeds} {NumBeds > 1 ? 'beds' : 'bed'}
                      </span>
                      {(NumBathsTotal > 0 || SquareFeet > 0) && (
                        <div className={styles.seperator} />
                      )}
                    </>
                  )}
                  {NumBathsTotal > 0 && (
                    <>
                      <span>
                        {getBathsTotalNum(NumBathsTotal)} {NumBathsTotal > 1 ? 'baths' : 'bath'}
                      </span>
                      {SquareFeet > 0 && <div className={styles.seperator} />}
                    </>
                  )}
                  {SquareFeet > 0 && <span>{formatNumber(Math.round(SquareFeet))} sqft</span>}
                </div>
              )}

              <div className={styles.address}>
                <p className={styles.line}>
                  {Address?.Line1} {Address?.Line2 ? `# ${Address?.Line2}` : ''}
                </p>
                <p className={styles.city}>
                  {Address?.City}, {Address?.State} {Address?.Zip}
                </p>
              </div>
            </div>
            {!isAgentType ? (
              <div className={styles.clientMatch}>
                <Match
                  propertyInfo={selectedProperty}
                  isPriceReduction={type === subFilterTypes.PRICE_REDUCTIONS}
                />
              </div>
            ) : data?.MatchedCriterias?.length ? (
              <MatchFav propertyInfo={data} />
            ) : (
              <div>
                <Applicants propertyInfo={data} />
                <Favorite propertyInfo={data} className={styles.agentFavorite} />
              </div>
            )}
          </div>
        </div>

        {/* Status Pills */}

        <div className={styles.lastRow}>
          <div className={styles.statusPills}>
            {Suggestions?.length && (
              <SubFilterStatus
                content={
                  <span className={styles.text}>
                    Highlighted by {Suggestions?.[0]?.Agent?.FirstName}{' '}
                    {Suggestions?.[0]?.Agent?.LastName}
                  </span>
                }
                className={styles.highlighted}
              />
            )}

            {showPricePill ? (
              <SubFilterStatus
                content={
                  <>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      value={PreviousListPrice}
                      prefix="$"
                      renderText={(val) => <p className={styles.grayText}>{val}</p>}
                    />
                    <span className={styles.grayText}>-&gt;</span>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      value={ListPrice}
                      prefix="$"
                      renderText={(val) => <p className={styles.text}>{val}</p>}
                    />
                  </>
                }
              />
            ) : null}
            {showStatusPill ? (
              <SubFilterStatus
                content={
                  <>
                    <p className={styles.grayText}>{camelCaseToNormalText(PreviousStatus)}</p>
                    <span className={styles.grayText}>-&gt;</span>
                    <p className={styles.text}>{camelCaseToNormalText(ActivityStatus)}</p>
                  </>
                }
              />
            ) : null}
            {nearestOpenHouse &&
              (type === subFilterTypes.OPEN_HOUSES || type === subFilterTypes.ALL) && (
                <Status
                  className={styles.openHouseStatus}
                  status={`Open ${
                    nearestOpenHouse?.date !== 'Today'
                      ? nearestOpenHouse?.date?.slice(0, 3)
                      : nearestOpenHouse?.date
                  }, ${nearestOpenHouse?.time?.replaceAll(':00', '')}`}
                />
              )}
          </div>
          <div className={styles.actions}>
            {multiple ? (
              <div>
                <Checkbox
                  value={data.Id}
                  checked={isChecked}
                  className={
                    compares.data.length === 5 && !isChecked ? styles.pointerEventsNone : ''
                  }
                  hasOuterClick={true}
                />
              </div>
            ) : (
              <>
                <Comment propertyInfo={data} onlyReadMsg={countOnlyUnread} />
                {!isAgent ? (
                  <Favorite propertyInfo={data} />
                ) : (
                  <RecommendedTo idx={index} propertyInfo={data} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
