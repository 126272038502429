import { useSelector } from 'react-redux';
import { selectedSequenceSelector } from 'store/selectors/radarKits';
import { getAgentId } from 'store/selectors/user';

export const useSequenceMeta = () => {
  const sequenceData = useSelector(selectedSequenceSelector);
  const agentId = useSelector(getAgentId);

  return {
    isSequenceOwner: sequenceData?.AgentId === agentId,
  };
};
