export const isValidAgentName = (agent) => agent === ' ' || agent === '' || agent === null;

export const formatAgentUserContact = (data) =>
  data.map((agent) => {
    return { ...agent, Agent: agent.AgentUser || agent.AgentContact || agent.TransactionAgentUser };
  });

export const leadSourceColors = [
  '#928CDA',
  '#5A69C2',
  '#2246AA',
  '#4673D1',
  '#51BFE1',
  '#04A451',
  '#00B152',
  '#FBC13A',
  '#FB913A',
  '#FF576D',
  '#EC455E',
];
const colorAssignor = (index) => leadSourceColors[index % leadSourceColors.length];

const randomColorAssignor = () => '#' + Math.floor(Math.random() * 16777215).toString(16);

export const assignRandomColor = (data) =>
  data.map((source, index) => {
    return { ...source, color: colorAssignor(index) };
  });
