/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import PropTypes from 'prop-types';

import Marker from './Marker';
import MultiplePropertiesMarkerComponent from './MultiplePropertiesMarker';

const Markers = (props) => {
  const {
    markers,
    popupClassName,
    markerClassName,
    onClickMarker,
    clusterer,
    getPropertyLink,
    setInfoWindowData,
  } = props;

  return (
    <div>
      {markers.map((properties, index) => {
        const {
          id,
          image,
          price,
          info,
          title,
          geometry: { coordinates },
          highlight,
          address,
          matchedClientsCount,
          addedToFavoritesBy,
          priceToDisplay,
          ...rest
          // eslint-disable-next-line no-unsafe-optional-chaining
        } = properties?.[0];
        const { lng, lat } = coordinates;
        return (
          <Marker
            id={id}
            key={id || index}
            longitude={lng}
            latitude={lat}
            image={image}
            price={price}
            priceToDisplay={properties.length > 1 ? `${properties.length} Units` : priceToDisplay}
            info={info}
            title={title}
            address={address}
            popupClassName={popupClassName}
            markerClassName={markerClassName}
            onClickMarker={(id, e) => {
              onClickMarker(id);
              const lat = e.latLng.lat();
              const lng = e.latLng.lng();
              const propertiesFiltered = markers
                ?.filter((arr) => {
                  const coordinates = arr?.[0]?.geometry?.coordinates;
                  if (!coordinates) return false;

                  const centerLat = parseFloat(lat);
                  const centerLng = parseFloat(lng);
                  const markerLat = parseFloat(coordinates.lat);
                  const markerLng = parseFloat(coordinates.lng);

                  return markerLat === centerLat && markerLng === centerLng;
                })
                ?.flat()
                ?.filter(({ property }) => property)
                ?.map(({ property }) => property);
              if (propertiesFiltered?.length) {
                // Set the InfoWindow data
                setInfoWindowData({
                  position: { lat, lng },
                  properties: propertiesFiltered,
                });
              }
            }}
            clusterer={clusterer}
            getPropertyLink={getPropertyLink}
            highlight={highlight}
            neighborhoodExploration={props.neighborhoodExploration}
            propertyProps={properties?.[0]}
            {...rest}
          />
        );
      })}
    </div>
  );
};

const MarkerType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  geometry: PropTypes.shape({
    coordinates: PropTypes.shape({ lng: PropTypes.number, lat: PropTypes.number }),
  }),
  info: PropTypes.string,
  properties: PropTypes.shape({}),
});

Markers.propTypes = {
  popupClassName: PropTypes.string,
  markerClassName: PropTypes.string,
  markers: PropTypes.arrayOf(PropTypes.arrayOf(MarkerType)),
  onClickMarker: PropTypes.func,
  getPropertyLink: PropTypes.func,
  clusterer: PropTypes.shape({}).isRequired,
  neighborhoodExploration: PropTypes.bool,
};

Markers.defaultProps = {
  popupClassName: '',
  markerClassName: '',
  markers: [],
  onClickMarker: () => {},
  getPropertyLink: () => {},
  neighborhoodExploration: false,
};

export default Markers;
