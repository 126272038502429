import { useRef, useState } from 'react';
import classNames from 'classnames';

import { SortIcon, SortDESCIcon, SortAlphabetASCIcon, SortAlphabetDESCIcon } from '../../../Icons';
import { Popover } from 'antd';
import { OptionsPopup } from 'components';
import AscDesc from 'pages/Properties/SearchResults/LocalHeader/Sort/AscDesc';

import { useOutsideClick } from 'hooks';
import { SORT_DESC, SORT_ASC } from 'settings/constants/sort';

import styles from './styles.module.scss';
import { GreenCheckMark } from 'components/Icons';
import { SORTING_OPTIONS } from '../LeadSourcesBreakdownMenu';

const AlphabeticalSortOption = ({ sort, active = false, onChange }) => (
  <div className={styles.alphabetSortOption} onClick={onChange}>
    <p>Lead Sources {sort === SORT_ASC ? 'A-Z' : 'Z-A'}</p>
    {active && <GreenCheckMark className={styles.checkMark} />}
  </div>
);

export const SortMenu = ({ setSortOption }) => {
  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState<string | null>(SORT_ASC);

  const [sortAlphabetical, setSortAlphabetical] = useState<string | null>(null);

  const sortButtonRef = useRef<any>();
  const popupRef = useRef<any>();

  useOutsideClick([popupRef, sortButtonRef], () => setOpen(false));

  const onClickHandler = () => {
    setOpen(!open);
  };

  const onChangeSort = (order) => {
    setSort(order);
    setSortAlphabetical(null);
    setSortOption(
      order === SORT_ASC ? SORTING_OPTIONS.ASC_TOTAL_VOLUME : SORTING_OPTIONS.DESC_TOTAL_VOLUME,
    );
  };

  const onChangeSortAlphabetical = (order) => {
    setSortAlphabetical(order);
    setSort(null);
    setSortOption(
      order === SORT_ASC ? SORTING_OPTIONS.ASC_ALPAHBETICALLY : SORTING_OPTIONS.DESC_ALPAHBETICALLY,
    );
  };

  const getSortIcon = () => {
    if (sortAlphabetical === SORT_ASC) {
      return <SortAlphabetASCIcon className={styles.sortIconAlphabet} />;
    } else if (sortAlphabetical === SORT_DESC) {
      return <SortAlphabetDESCIcon className={styles.sortIconAlphabet} />;
    } else if (sort === SORT_ASC) {
      return <SortDESCIcon className={styles.sortIconAlphabet} />;
    } else return <SortIcon className={styles.sortIcon} />;
  };

  return (
    <div className={styles.sortMenu}>
      <Popover
        placement="top"
        overlayClassName={classNames('mosaikTooltip')}
        getPopupContainer={(triggerNode) => triggerNode}
      >
        <div ref={sortButtonRef} onClick={onClickHandler} className={classNames(styles.sort)}>
          {getSortIcon()}
        </div>
      </Popover>
      <OptionsPopup
        className={styles.sortPopup}
        ref={popupRef}
        offsetTop={5}
        parentRef={sortButtonRef}
        open={open}
        offsetLeft={-220}
      >
        <p className={styles.sortByTitle}>Sort by</p>
        <AscDesc onChange={onChangeSort} activeOrder={sort} className={styles.ascDesc} />
        <AlphabeticalSortOption
          sort={SORT_ASC}
          active={sortAlphabetical === SORT_ASC}
          onChange={() => onChangeSortAlphabetical(SORT_ASC)}
        />
        <AlphabeticalSortOption
          sort={SORT_DESC}
          active={sortAlphabetical === SORT_DESC}
          onChange={() => onChangeSortAlphabetical(SORT_DESC)}
        />
      </OptionsPopup>
    </div>
  );
};
