import { FC } from 'react';
import styles from './styles.module.scss';
import { map } from 'lodash-es';
import { IAssessmentHistory } from 'store/reducers/radarPortfolio/types';
import NumberFormat from 'react-number-format';
import { TooltipIcon } from 'components/Icons';
import { Tooltip } from 'components-antd';
import classNames from 'classnames';

interface ITax {
  data: IAssessmentHistory[];
}

export const Tax: FC<ITax> = ({ data }) => {
  const getTaxData = () => {
    const transformed: any[] = [];

    // Sort data based on the tax year in descending order
    const sortedData = data.sort((a, b) => b.tax.taxYearAssessed - a.tax.taxYearAssessed);

    for (let i = 0; i < sortedData.length; i++) {
      const current = sortedData[i];

      const currentYear = current?.tax?.taxYearAssessed;
      const currentPrice = current?.tax?.taxAmt;

      // Extract additional values from calculations
      const landValue = current?.calculations?.calcLandValue;
      const additionsValue = current?.calculations?.calcImprValue;
      const assessedValue = current?.calculations?.calcTtlValue;

      // Create the object for the current year
      const currentObj: any = {
        date: currentYear,
        price: currentPrice ? `$${currentPrice?.toLocaleString()}` : '-',
        land: landValue ? `$${landValue?.toLocaleString()}` : '-',
        additions: additionsValue ? `$${additionsValue?.toLocaleString()}` : '-',
        assessedValue: assessedValue ? `$${assessedValue?.toLocaleString()}` : '-',
      };

      // Calculate percentage change for land to total assessed value
      if (landValue !== undefined && assessedValue !== undefined) {
        const landChangePercent = ((assessedValue - landValue) / landValue) * 100;
        currentObj.landChange = `${landChangePercent >= 0 ? '+' : ''}${landChangePercent.toFixed(
          1,
        )}%`;
        currentObj.landChangeType = landChangePercent >= 0 ? 'increased' : 'decreased';
      }

      // Calculate percentage change from the next year's tax amount, if available
      if (i === 0 && sortedData.length > 1) {
        const nextPrice = sortedData[i + 1].tax.taxAmt;
        const changePercent = ((currentPrice - nextPrice) / nextPrice) * 100;

        currentObj.change = `${changePercent >= 0 ? '+' : ''}${changePercent.toFixed(1)}%`;
        currentObj.changeType = changePercent >= 0 ? 'increased' : 'decreased';
      }

      // Calculate percentage change with respect to the previous year for other entries
      if (i > 0 && i < sortedData.length - 1) {
        const previousPrice = sortedData[i - 1].tax.taxAmt;
        const changePercent = ((currentPrice - previousPrice) / previousPrice) * 100;

        currentObj.change = `${changePercent >= 0 ? '+' : ''}${changePercent.toFixed(1)}%`;
        currentObj.changeType = changePercent >= 0 ? 'increased' : 'decreased';
      }

      transformed.push(currentObj);
    }

    return transformed;
  };

  return (
    <div className={styles.table}>
      {map(
        [{ date: 'Year', price: 'Price', change: '', changeType: '' }, ...getTaxData()],
        (
          { date, price, change = '', changeType = '', assessedValue, land, additions, landChange },
          index,
        ) => (
          <div className={styles.tableRow}>
            <div className={styles.column}>
              <p className={index === 0 ? styles.labelHeader : styles.label}>{date}</p>
            </div>
            <div className={styles.column}>
              <p className={index === 0 ? styles.labelHeader : styles.value}>
                {index === 0 ? (
                  price
                ) : (
                  <NumberFormat
                    thousandSeparator
                    displayType="text"
                    value={price}
                    prefix="$"
                    renderText={(val) => <>{val}</>}
                  />
                )}
                <span className={styles[changeType]}>{change}</span>
              </p>
              {index !== 0 && (
                <Tooltip
                  getPopupContainer={(container) => container}
                  placement="top"
                  overlayClassName={classNames(styles.overlay, 'mosaikTooltip')}
                  title={
                    <div className={styles.tooltip}>
                      <div className={styles.row1}>
                        <p>Land</p>
                        <p>Additions</p>
                        <p>
                          <b>Assessed Value</b>
                        </p>
                      </div>
                      <div className={styles.row2}>
                        <p>
                          {land} <span>{landChange && `(${landChange})`}</span>
                        </p>
                        <p>
                          {additions && '+'} {additions}
                        </p>
                        <p>
                          {assessedValue && '='} {assessedValue}
                        </p>
                      </div>
                    </div>
                  }
                >
                  <span className={styles.icon}>
                    <TooltipIcon size={'16'} />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        ),
      )}
    </div>
  );
};
