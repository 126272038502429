import { useState } from 'react';

import styles from './styles.module.scss';

import { RoleSelectView } from './RoleSelectView';
import { AccessLevelView } from './AccessLevelView';
import { useDispatch } from 'react-redux';
import {
  setTeamTransactionPermissionsEffect,
  updateTeamTransactionPermissionsEffect,
} from 'store/effects';

export const ManageRoleAndAccess = (props) => {
  const { Id, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onUpdateDetails = (details, cb?) => {
    setLoading(true);
    dispatch(
      updateTeamTransactionPermissionsEffect(
        { ...details, addToTransaction: true, memberId: Id },
        {},
        (error, response) => {
          if (!error) {
            const { TransactionPermissions } = response.data;

            dispatch(
              setTeamTransactionPermissionsEffect({
                data: { TransactionPermissions },
              }),
            );
            setLoading(false);
            cb?.();
          } else {
            setLoading(false);
          }
        },
      ),
    );
  };

  const componentProps = {
    ...rest,
    onUpdateDetails,
    loading,
  };

  return (
    <div className={styles.manageRoleAndAccessWrapper}>
      <div className={styles.flexedItem}>
        <label>Default Transaction role:</label>
        <RoleSelectView {...componentProps} />
      </div>

      <div className={styles.flexedItem}>
        <label>Default Access level:</label>
        <AccessLevelView {...componentProps} />
      </div>
    </div>
  );
};
