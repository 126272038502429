import React, { useMemo, useState } from 'react';
import moment from 'moment';

import { EmptyChartPlaceholder } from 'components/EmptyChartPlaceholder';
import { MultiLineChart } from 'components/MultiLineChart';
import { LeadSourceTableModal } from 'pages/ClarityRevamped/Components/Stats/Content/Components/TableView/TableModal/Volume/LeadSourceTableModal';

import styles from './styles.module.scss';

type ChartPoint = {
  year: number | null;
  month: number | null;
  isoWeek: number | null;
  date: string | null;
  category: string | null;
};

type ClarityMultiLineChartProps = {
  attributes: string[];
  seriesData: any[];
  colors: string[];
  timePeriod: string | number;
  selectedAgent?: any;
};

export const ClarityMultiLineChart: React.FC<ClarityMultiLineChartProps> = ({
  attributes,
  seriesData,
  colors,
  selectedAgent,
  timePeriod,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [chartPoint, setChartPoint] = useState<ChartPoint>({
    year: null,
    month: null,
    isoWeek: null,
    date: null,
    category: null,
  });

  const configProps = useMemo(() => {
    let chartData;
    chartData = attributes.map((attribute) => {
      const lineData = seriesData?.[attribute] || [];
      return {
        name: attribute,
        data: lineData.map((source) => source.TotalVolume),
        color: lineData?.color,
        numberTransactions: lineData.map((source) => source.NumberTransactions),
        year: lineData.map((source) => source?.Year),
        month: lineData.map((source) => source?.MonthNumber),
        isoWeek: lineData.map((source) => source?.ISOWeek),
        date: lineData.map((source) => source?.Date),
        colors: lineData.map((source) => source.color),
      };
    });

    let categories, dates;

    const firstSeries = seriesData?.[attributes[0]];
    const previousYear = moment().subtract(1, 'year').year();
    if (timePeriod === previousYear) {
      categories = firstSeries?.map((item) => `${item?.Month} ${previousYear}`);
      dates = firstSeries?.map((item) =>
        moment(`${item?.Month} 1, ${previousYear}`).format('MMMM'),
      );
    } else if (timePeriod === 'YTD') {
      categories = firstSeries?.map((item) => item?.Month);
      dates = firstSeries?.map((item) =>
        moment(`${item?.Month} 1, ${moment().year()}`).format('MMMM'),
      );
    } else if (timePeriod === 'QTD') {
      categories = firstSeries?.map((item) => item?.Week);
      dates = firstSeries?.map((item) => item?.Week);
    } else if (timePeriod === 'MTD' || timePeriod === 'WEEK') {
      categories = firstSeries?.map((item) => item?.Date);
      dates = firstSeries?.map((item) => moment(item?.Date).format('MMMM DD'));
    }

    return {
      colors,
      data: chartData,
      categories: categories,
      dates: dates,
      timePeriod,
      className: styles.smallCardAreaChart,

      onClickDataPoint: (seriesIndex: number, dataPointIndex: number) => {
        const lineData = chartData[seriesIndex];
        if (lineData.data[dataPointIndex] !== 0) {
          setChartPoint({
            year: lineData?.year[dataPointIndex],
            month: lineData?.month[dataPointIndex],
            isoWeek: lineData?.isoWeek[dataPointIndex],
            date: lineData?.date[dataPointIndex],
            category: lineData?.name,
          });
          setOpenModal(true);
        }
      },
    };
  }, [seriesData]);

  return (
    <>
      {attributes?.length ? <MultiLineChart {...configProps} /> : <EmptyChartPlaceholder />}
      {openModal && (
        <LeadSourceTableModal
          year={chartPoint.year}
          month={chartPoint.month}
          isoWeek={chartPoint.isoWeek}
          date={chartPoint.date}
          open={openModal}
          onCancel={() => setOpenModal(false)}
          selectedAgent={selectedAgent}
          sourceList={[chartPoint.category]}
          modalHeading={chartPoint.category}
        />
      )}
    </>
  );
};
