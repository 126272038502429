import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getClientDashboardMyTransactionsEffect,
  getClientDashboardNeedsAttionEffect,
  getClientDashboardUpcomingShowingsEffect,
} from 'store/effects/clientDashboard';
import {
  getClientDashboardMyTransactionsSelector,
  getClientDashboardNeedsAttentionSelector,
  getClientDashboardShowingsSelector,
} from 'store/selectors/clientDashboard';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getCommonProfileData, getUserId } from 'store/selectors/user';
import { TimelineCard } from '../ClientSearchDashboard/TimelineCard';
import { ITimelineItem, TimelineType } from '../ClientSearchDashboard/TimelineCard/types';
import { MyTransactions } from './MyTransactions';
import { NeedsAttention } from './NeedsAttention';
import styles from './styles.module.scss';
import { getLocaleDate, subtractTimeZone } from 'helpers/formatters';
import { combineMultipleObjects, formatDate, isToday, sortObjectByDateKeys } from 'helpers';
import { getMilestoneAggregateEffect } from 'store/effects/milestoneAggregate';
import { getMilestoneDataSelector } from 'store/selectors/milestoneAggregate';

export const ClientTransactionsDashboard = () => {
  const dispatch = useDispatch();
  const selectedContext = useSelector(getCurrentContextSelector);
  const clientId = useSelector(getUserId);
  const myTransactions = useSelector(getClientDashboardMyTransactionsSelector);
  const upcomingShowings = useSelector(getClientDashboardShowingsSelector);
  const milestonesData: any = useSelector(getMilestoneDataSelector);
  const needsAttention = useSelector(getClientDashboardNeedsAttentionSelector);
  const { email } = useSelector(getCommonProfileData);
  const transactionLoading = myTransactions?.state === 'pending';
  const showingsLoading = upcomingShowings?.state === 'pending';

  useEffect(() => {
    if (clientId && selectedContext.ContextKey) {
      dispatch(
        getClientDashboardMyTransactionsEffect(
          {
            clientId,
            contextId: selectedContext.ContextKey,
          },
          {
            silent: true,
          },
        ),
      );
      dispatch(
        getClientDashboardUpcomingShowingsEffect(
          {
            clientId,
            contextId: selectedContext.ContextKey,
          },
          {
            silent: true,
          },
        ),
      );
      dispatch(
        getMilestoneAggregateEffect({
          filters: {
            isArchive: false,
            isMobile: true,
          },
        }),
      );
    }
  }, [clientId, selectedContext.ContextKey]);

  const timelineData = useMemo(() => {
    const upcomingMilestones = milestonesData?.Upcoming || [];
    if (!upcomingShowings?.data?.length && upcomingMilestones?.length === 0) {
      return [];
    }

    const groupedShowings = upcomingShowings?.data?.reduce((acc, showing) => {
      const scheduleDateTime = moment(showing?.ScheduleDateTime);
      let formattedDate = scheduleDateTime.format('dddd, MMM DD, YYYY');

      let startTime =
        scheduleDateTime.minutes() === 0
          ? scheduleDateTime.format('h A')
          : scheduleDateTime.format('h:mm A');
      let tempEndTime = scheduleDateTime.add(showing?.ShowingDuration, 'minutes');

      let endTime =
        tempEndTime.minutes() === 0 ? tempEndTime.format('h A') : tempEndTime.format('h:mm A');
      showing?.appointment.forEach((appointment) => {
        if (appointment?.AppointmentStartTime && appointment?.AppointmentEndTime) {
          const startTimeM = getLocaleDate(appointment?.AppointmentStartTime);
          startTime = formatDate(startTimeM, startTimeM.minutes() === 0 ? 'h A' : 'h:mm A');
          const endTimeM = getLocaleDate(appointment?.AppointmentEndTime);
          endTime = formatDate(endTimeM, endTimeM.minutes() === 0 ? 'h A' : 'h:mm A');
          formattedDate = formatDate(appointment?.AppointmentDate, 'dddd, MMM DD, YYYY');
        } else if (appointment?.AppointmentStartTime) {
          const startTimeM = moment(getLocaleDate(appointment?.AppointmentStartTime));
          startTime =
            startTimeM.minutes() === 0 ? startTimeM.format('h A') : startTimeM.format('h:mm A');
          const endTimeM = startTimeM.add(showing?.ShowingDuration, 'minutes');
          endTime = endTimeM.minutes() === 0 ? endTimeM.format('h A') : endTimeM.format('h:mm A');
          formattedDate = formatDate(appointment?.AppointmentDate, 'dddd, MMM DD, YYYY');
        }
        const timeRange = `${startTime} - ${endTime}`;

        const entry = {
          top: 'Showing',
          main: timeRange,
          sub: `${showing?.Property?.Property?.Address?.Line1}`,
          type: TimelineType.Showing,
        };

        if (!acc[formattedDate]) {
          acc[formattedDate] = [];
        }
        acc[formattedDate].push(entry);
      });

      const sortedData = sortObjectByDateKeys(acc);
      return sortedData;
    }, {});

    const initGroupedMilestones = upcomingMilestones?.reduce((acc, milestone) => {
      let formattedDate = subtractTimeZone(milestone?.DueDate, 'dddd, MMM DD, YYYY');
      const entry = {
        top: '',
        main: milestone?.Title,
        sub: `${milestone?.Property?.Address?.Line1}`,
        type: TimelineType.Milestone,
      };
      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push(entry);
      return acc;
    }, {});
    const initTimelineData = combineMultipleObjects(groupedShowings, initGroupedMilestones);
    const sortedTimelineData = sortObjectByDateKeys(initTimelineData);

    return Object.entries(sortedTimelineData).map(([date, events]) => ({
      heading: isToday(date) ? 'Today' : formatDate(date, 'dddd, MMM D'),
      data: events as ITimelineItem[],
    }));
  }, [upcomingShowings, milestonesData]);

  const transactions = useMemo(() => {
    if (!myTransactions?.data?.length) {
      return [];
    }

    const getTransactionType = (representingRoles) => {
      if (representingRoles.includes('Seller') && representingRoles.includes('Buyer')) {
        return 'Dual';
      } else if (representingRoles.includes('Seller')) {
        return 'Listing';
      } else return 'Purchase';
    };

    const transactions = myTransactions?.data?.map((transaction) => {
      const closeDate = transaction.IsProject
        ? transaction?.DateControls.DL
        : transaction?.KeyDatesPrices?.CloseDate ||
          transaction?.KeyDatesPrices?.UnderContractCloseDate ||
          transaction?.KeyDatesPrices?.ListingExpireDate;
      const close = closeDate ? subtractTimeZone(closeDate, 'MM/DD') : 'N/A';
      const today = moment();
      const closingDate = moment(closeDate);
      const remaining = closeDate ? `${closingDate.diff(today, 'days')} days` : 'N/A';

      if (transaction.IsProject) {
        const projectName = `${transaction.Name}`;
        const projectCategory = `${transaction?.TransactionCategory?.Category || ''} `;
        return {
          Id: transaction.Id,
          projectName,
          projectCategory,
          close,
          remaining,
          route: `/workshop/transactions/${transaction.Id}/overview`,
          createdDate: transaction?.CreatedDate,
          isProject: true,
        };
      } else {
        const address = transaction?.Property?.Address;
        const address1 = `${address.Line1}`;
        const address2 = `${address?.City}, ${address?.State} ${address?.Zip}`;
        const transactionType = getTransactionType(transaction?.RepresentingRoles);

        return {
          Id: transaction.Id,
          address1,
          address2,
          close,
          remaining,
          route: `/workshop/transactions/${transaction.Id}/overview`,
          createdDate: transaction?.CreatedDate,
          transactionType,
        };
      }
    });

    return (
      transactions?.sort((a, b) => moment(b.createdDate).unix() - moment(a.createdDate).unix()) ??
      []
    );
  }, [myTransactions]);

  const needsAttentionData = useMemo(() => {
    if (!needsAttention?.data?.length) {
      return [];
    }
    return needsAttention?.data;
  }, [needsAttention]);
  return (
    <div className={styles.mainContentWrapper}>
      <div className={styles.dashboardContainer}>
        <div className={styles.transactionColumn}>
          <MyTransactions transactions={transactions} />
          <NeedsAttention needsAttentionData={needsAttentionData} />
        </div>
        <div className={styles.timelineColumn}>
          <TimelineCard timelineData={timelineData} viewAllRoute="" title="Schedule" />
        </div>
      </div>
    </div>
  );
};
