import { routes } from 'settings/navigation/routes';
import { Symphony } from 'pages';
import { AGENT } from 'settings/constants/roles';

export default [
  {
    path: routes.symphony,
    component: Symphony,
    exact: true,
    roles: [AGENT],
  },
];
