import { ActivityLogsTypesValue, ActivityLogsTypesValueLabel } from 'types/activityLogs';
//This is for formated the log in react components
export const ActivityLogsActionsTypes = [
  ActivityLogsTypesValue.TransactionStatusUpdated,
  ActivityLogsTypesValue.InviteAccepted,
  ActivityLogsTypesValue.InviteRejected,
  ActivityLogsTypesValue.CriteriaWizardCompleted,
  ActivityLogsTypesValue.CriteriaWizardSkipped,
  ActivityLogsTypesValue.PreApprovalRequested,
  ActivityLogsTypesValue.PreApprovalUploaded,
  ActivityLogsTypesValue.NewSearchInstanceCreated,
  ActivityLogsTypesValue.SearchInstanceRenamed,
  ActivityLogsTypesValue.SearchInstanceDeactivated,
  ActivityLogsTypesValue.SearchInstanceActivated,
  ActivityLogsTypesValue.FreeSearchTriggered,
  ActivityLogsTypesValue.SearchInstanceCriteriaEdited,
  ActivityLogsTypesValue.PropertyAddedToFavorites,
  ActivityLogsTypesValue.PropertyRemovedFromFavorites,
  ActivityLogsTypesValue.ShowingAppointmentRequested,
  ActivityLogsTypesValue.ShowingAppointmentCancelled,
  ActivityLogsTypesValue.ShowingAppointmentRescheduled,
  ActivityLogsTypesValue.ShowingAppointmentApproved,
  ActivityLogsTypesValue.ShowingAppointmentFeedback,
  ActivityLogsTypesValue.ShowingAppointmentLapsed,
  ActivityLogsTypesValue.ShowingAppointmentApprovalApproved,
  ActivityLogsTypesValue.ShowingAppointmentApprovalDeclined,
  ActivityLogsTypesValue.ShowingAppointmentApprovalCancelled,
  ActivityLogsTypesValue.ShowingLinkUpdated,
  ActivityLogsTypesValue.ShowingAnnouncement,
  ActivityLogsTypesValue.PropertyViewed,
  ActivityLogsTypesValue.NewTask,
  ActivityLogsTypesValue.TaskAssigned,
  ActivityLogsTypesValue.TaskCompleted,
  ActivityLogsTypesValue.TaskUpcoming,
  ActivityLogsTypesValue.TaskOverdue,
  ActivityLogsTypesValue.TaskTagged,
  ActivityLogsTypesValue.MilestoneAdded,
  ActivityLogsTypesValue.MilestoneEdited,
  ActivityLogsTypesValue.MilestoneCompleted,
  ActivityLogsTypesValue.MilestoneMissed,
  ActivityLogsTypesValue.MilestoneOverdue,
  ActivityLogsTypesValue.MilestoneDeadlineUpcoming,
  ActivityLogsTypesValue.TransactionStatusUpdated,
  ActivityLogsTypesValue.TransactionParticipantInvited,
  ActivityLogsTypesValue.UserTaggedInTask,
  ActivityLogsTypesValue.FormAssigned,
  ActivityLogsTypesValue.FormCompleted,
  ActivityLogsTypesValue.MessageReceived,
  ActivityLogsTypesValue.DocumentUploadedInTransaction,
  ActivityLogsTypesValue.UserReceivedNotification,
  ActivityLogsTypesValue.NewAdHocSearch,
  ActivityLogsTypesValue.QuoteRequested,
  ActivityLogsTypesValue.PropertyDetailsViewTimeSpent,
  ActivityLogsTypesValue.CounterAccepted,
  ActivityLogsTypesValue.TransactionTemplateApplied,
  ActivityLogsTypesValue.OfferExpired,
  ActivityLogsTypesValue.OfferWithdrawn,
  ActivityLogsTypesValue.TaskDeleted,
  ActivityLogsTypesValue.DocumentUpdated,
  ActivityLogsTypesValue.TransactionCanceled,
  ActivityLogsTypesValue.TransactionActiveListing,
  ActivityLogsTypesValue.TransactionPriceHistory,
  ActivityLogsTypesValue.TransactionNote,
  ActivityLogsTypesValue.TaskComment,
  ActivityLogsTypesValue.TaskStatusUpdated,
  ActivityLogsTypesValue.TaskStuck,
  ActivityLogsTypesValue.NewSearchInstanceCreated,
  ActivityLogsTypesValue.TourRequested,
  ActivityLogsTypesValue.TourCancelled,
];

export const ActivityLogsActionsTypesDashboard = [
  ActivityLogsTypesValue.InviteAccepted,
  ActivityLogsTypesValue.InviteRejected,
  ActivityLogsTypesValue.CriteriaWizardCompleted,
  ActivityLogsTypesValue.CriteriaWizardSkipped,
  ActivityLogsTypesValue.PreApprovalRequested,
  ActivityLogsTypesValue.PreApprovalUploaded,
  ActivityLogsTypesValue.NewSearchInstanceCreated,
  ActivityLogsTypesValue.SearchInstanceRenamed,
  ActivityLogsTypesValue.SearchInstanceDeactivated,
  ActivityLogsTypesValue.SearchInstanceActivated,
  ActivityLogsTypesValue.FreeSearchTriggered,
  ActivityLogsTypesValue.SearchInstanceCriteriaEdited,
  ActivityLogsTypesValue.PropertyAddedToFavorites,
  ActivityLogsTypesValue.PropertyRemovedFromFavorites,
  ActivityLogsTypesValue.ShowingAppointmentRequested,
  ActivityLogsTypesValue.ShowingAppointmentCancelled,
  ActivityLogsTypesValue.ShowingAppointmentRescheduled,
  ActivityLogsTypesValue.ShowingAppointmentApproved,
  ActivityLogsTypesValue.ShowingAppointmentFeedback,
  ActivityLogsTypesValue.ShowingAppointmentLapsed,
  ActivityLogsTypesValue.ShowingAppointmentApprovalApproved,
  ActivityLogsTypesValue.ShowingAppointmentApprovalDeclined,
  ActivityLogsTypesValue.ShowingAppointmentApprovalCancelled,
  ActivityLogsTypesValue.ShowingLinkUpdated,
  ActivityLogsTypesValue.ShowingAnnouncement,
  ActivityLogsTypesValue.PropertyViewed,
  ActivityLogsTypesValue.TaskAssigned,
  ActivityLogsTypesValue.TaskCompleted,
  ActivityLogsTypesValue.TaskUpcoming,
  ActivityLogsTypesValue.TaskOverdue,
  ActivityLogsTypesValue.TaskTagged,
  ActivityLogsTypesValue.MilestoneAdded,
  ActivityLogsTypesValue.MilestoneEdited,
  ActivityLogsTypesValue.MilestoneCompleted,
  ActivityLogsTypesValue.MilestoneMissed,
  ActivityLogsTypesValue.MilestoneOverdue,
  ActivityLogsTypesValue.MilestoneDeadlineUpcoming,
  ActivityLogsTypesValue.TransactionStatusUpdated,
  ActivityLogsTypesValue.UserTaggedInTask,
  ActivityLogsTypesValue.FormAssigned,
  ActivityLogsTypesValue.FormCompleted,
  ActivityLogsTypesValue.MessageReceived,
  ActivityLogsTypesValue.DocumentUploadedInTransaction,
  ActivityLogsTypesValue.UserReceivedNotification,
  ActivityLogsTypesValue.NewAdHocSearch,
  ActivityLogsTypesValue.QuoteRequested,
  ActivityLogsTypesValue.PropertyDetailsViewTimeSpent,
  ActivityLogsTypesValue.CounterAccepted,
  ActivityLogsTypesValue.TransactionTemplateApplied,
  ActivityLogsTypesValue.OfferExpired,
  ActivityLogsTypesValue.OfferWithdrawn,
  ActivityLogsTypesValue.TaskDeleted,
  ActivityLogsTypesValue.DocumentUpdated,
  ActivityLogsTypesValue.TransactionCanceled,
  ActivityLogsTypesValue.TransactionActiveListing,
  ActivityLogsTypesValue.TransactionPriceHistory,
  ActivityLogsTypesValue.TaskComment,
];

export const TransactionActivityLogsActionsTypes = [
  ActivityLogsTypesValue.TransactionStatusUpdated,
  ActivityLogsTypesValue.TransactionParticipantInvited,
  ActivityLogsTypesValue.TransactionParticipantRemoved,
  ActivityLogsTypesValue.NewTask,
  ActivityLogsTypesValue.TaskAssigned,
  ActivityLogsTypesValue.TaskCompleted,
  ActivityLogsTypesValue.TaskUpcoming,
  ActivityLogsTypesValue.TaskOverdue,
  ActivityLogsTypesValue.TaskTagged,
  ActivityLogsTypesValue.TaskStuck,
  ActivityLogsTypesValue.MilestoneAdded,
  ActivityLogsTypesValue.MilestoneEdited,
  ActivityLogsTypesValue.MilestoneCompleted,
  ActivityLogsTypesValue.MilestoneMissed,
  ActivityLogsTypesValue.MilestoneOverdue,
  ActivityLogsTypesValue.UserTaggedInTask,
  ActivityLogsTypesValue.FormAssigned,
  ActivityLogsTypesValue.FormCompleted,
  ActivityLogsTypesValue.DocumentUploadedInTransaction,
  ActivityLogsTypesValue.OfferLinkCreated,
  ActivityLogsTypesValue.OfferLinkUpdated,
  ActivityLogsTypesValue.OfferLinkEnabled,
  ActivityLogsTypesValue.OfferLinkDisabled,
  ActivityLogsTypesValue.OfferAnnouncement,
  ActivityLogsTypesValue.OfferAccepted,
  ActivityLogsTypesValue.CounterAccepted,
  ActivityLogsTypesValue.OfferSubmitted,
  ActivityLogsTypesValue.OfferCounterSent,
  ActivityLogsTypesValue.OfferRejected,
  ActivityLogsTypesValue.OfferWithdrawn,
  ActivityLogsTypesValue.OfferExpired,
  ActivityLogsTypesValue.CounterRejected,
  ActivityLogsTypesValue.DetailAdded,
  ActivityLogsTypesValue.DetailChanged,
  ActivityLogsTypesValue.ContactAdded,
  ActivityLogsTypesValue.ContactChanged,
  ActivityLogsTypesValue.ContactDeleted,
  ActivityLogsTypesValue.TransactionTemplateApplied,
  ActivityLogsTypesValue.ShowingAppointmentRequested,
  ActivityLogsTypesValue.ShowingAppointmentCancelled,
  ActivityLogsTypesValue.ShowingAppointmentRescheduled,
  ActivityLogsTypesValue.ShowingAppointmentApproved,
  ActivityLogsTypesValue.ShowingAppointmentFeedback,
  ActivityLogsTypesValue.ShowingAppointmentLapsed,
  ActivityLogsTypesValue.ShowingAppointmentApprovalApproved,
  ActivityLogsTypesValue.ShowingAppointmentApprovalDeclined,
  ActivityLogsTypesValue.ShowingAppointmentApprovalCancelled,
  ActivityLogsTypesValue.ShowingLinkCreated,
  ActivityLogsTypesValue.ShowingLinkEnabled,
  ActivityLogsTypesValue.ShowingLinkDisabled,
  ActivityLogsTypesValue.ShowingLinkUpdated,
  ActivityLogsTypesValue.ShowingAnnouncement,
  ActivityLogsTypesValue.TaskDeleted,
  ActivityLogsTypesValue.DocumentUpdated,
  ActivityLogsTypesValue.TransactionCanceled,
  ActivityLogsTypesValue.TransactionActiveListing,
  ActivityLogsTypesValue.TransactionPriceHistory,
  ActivityLogsTypesValue.TransactionNote,
  ActivityLogsTypesValue.TaskComment,
  ActivityLogsTypesValue.TaskStatusUpdated,
  ActivityLogsTypesValue.TaskStuck,
];

export const TransactionActivityLogsActionsFilterTypes = [
  {
    label: ActivityLogsTypesValueLabel.TransactionStatusUpdated,
    value: ActivityLogsTypesValue.TransactionStatusUpdated,
  },
  {
    label: ActivityLogsTypesValueLabel.TransactionParticipantInvited,
    value: ActivityLogsTypesValue.TransactionParticipantInvited,
  },
  {
    label: ActivityLogsTypesValueLabel.TransactionParticipantRemoved,
    value: ActivityLogsTypesValue.TransactionParticipantRemoved,
  },
  { label: ActivityLogsTypesValueLabel.NewTask, value: ActivityLogsTypesValue.NewTask },
  { label: ActivityLogsTypesValueLabel.TaskAssigned, value: ActivityLogsTypesValue.TaskAssigned },
  { label: ActivityLogsTypesValueLabel.TaskCompleted, value: ActivityLogsTypesValue.TaskCompleted },
  { label: ActivityLogsTypesValueLabel.TaskUpcoming, value: ActivityLogsTypesValue.TaskUpcoming },
  { label: ActivityLogsTypesValueLabel.TaskOverdue, value: ActivityLogsTypesValue.TaskOverdue },
  { label: ActivityLogsTypesValueLabel.TaskTagged, value: ActivityLogsTypesValue.TaskTagged },
  { label: ActivityLogsTypesValueLabel.TaskStuck, value: ActivityLogsTypesValue.TaskStuck },
  {
    label: ActivityLogsTypesValueLabel.MilestoneAdded,
    value: ActivityLogsTypesValue.MilestoneAdded,
  },
  {
    label: ActivityLogsTypesValueLabel.MilestoneEdited,
    value: ActivityLogsTypesValue.MilestoneEdited,
  },
  {
    label: ActivityLogsTypesValueLabel.MilestoneCompleted,
    value: ActivityLogsTypesValue.MilestoneCompleted,
  },
  {
    label: ActivityLogsTypesValueLabel.MilestoneMissed,
    value: ActivityLogsTypesValue.MilestoneMissed,
  },
  {
    label: ActivityLogsTypesValueLabel.MilestoneOverdue,
    value: ActivityLogsTypesValue.MilestoneOverdue,
  },
  {
    label: ActivityLogsTypesValueLabel.UserTaggedInTask,
    value: ActivityLogsTypesValue.UserTaggedInTask,
  },
  { label: ActivityLogsTypesValueLabel.FormAssigned, value: ActivityLogsTypesValue.FormAssigned },
  { label: ActivityLogsTypesValueLabel.FormCompleted, value: ActivityLogsTypesValue.FormCompleted },
  {
    label: ActivityLogsTypesValueLabel.DocumentUploadedInTransaction,
    value: ActivityLogsTypesValue.DocumentUploadedInTransaction,
  },
  {
    label: ActivityLogsTypesValueLabel.OfferLinkCreated,
    value: ActivityLogsTypesValue.OfferLinkCreated,
  },
  {
    label: ActivityLogsTypesValueLabel.OfferLinkUpdated,
    value: ActivityLogsTypesValue.OfferLinkUpdated,
  },
  {
    label: ActivityLogsTypesValueLabel.OfferLinkEnabled,
    value: ActivityLogsTypesValue.OfferLinkEnabled,
  },
  {
    label: ActivityLogsTypesValueLabel.OfferLinkDisabled,
    value: ActivityLogsTypesValue.OfferLinkDisabled,
  },
  {
    label: ActivityLogsTypesValueLabel.OfferAnnouncement,
    value: ActivityLogsTypesValue.OfferAnnouncement,
  },
  { label: ActivityLogsTypesValueLabel.OfferAccepted, value: ActivityLogsTypesValue.OfferAccepted },
  {
    label: ActivityLogsTypesValueLabel.CounterAccepted,
    value: ActivityLogsTypesValue.CounterAccepted,
  },
  {
    label: ActivityLogsTypesValueLabel.OfferSubmitted,
    value: ActivityLogsTypesValue.OfferSubmitted,
  },
  {
    label: ActivityLogsTypesValueLabel.OfferCounterSent,
    value: ActivityLogsTypesValue.OfferCounterSent,
  },
  { label: ActivityLogsTypesValueLabel.OfferRejected, value: ActivityLogsTypesValue.OfferRejected },
  {
    label: ActivityLogsTypesValueLabel.OfferWithdrawn,
    value: ActivityLogsTypesValue.OfferWithdrawn,
  },
  { label: ActivityLogsTypesValueLabel.OfferExpired, value: ActivityLogsTypesValue.OfferExpired },
  {
    label: ActivityLogsTypesValueLabel.CounterRejected,
    value: ActivityLogsTypesValue.CounterRejected,
  },
  { label: ActivityLogsTypesValueLabel.DetailAdded, value: ActivityLogsTypesValue.DetailAdded },
  { label: ActivityLogsTypesValueLabel.DetailChanged, value: ActivityLogsTypesValue.DetailChanged },
  { label: ActivityLogsTypesValueLabel.ContactAdded, value: ActivityLogsTypesValue.ContactAdded },
  {
    label: ActivityLogsTypesValueLabel.ContactChanged,
    value: ActivityLogsTypesValue.ContactChanged,
  },
  {
    label: ActivityLogsTypesValueLabel.ContactDeleted,
    value: ActivityLogsTypesValue.ContactDeleted,
  },
  {
    label: ActivityLogsTypesValueLabel.TransactionTemplateApplied,
    value: ActivityLogsTypesValue.TransactionTemplateApplied,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingAppointmentRequested,
    value: ActivityLogsTypesValue.ShowingAppointmentRequested,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingAppointmentCancelled,
    value: ActivityLogsTypesValue.ShowingAppointmentCancelled,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingAppointmentRescheduled,
    value: ActivityLogsTypesValue.ShowingAppointmentRescheduled,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingAppointmentApproved,
    value: ActivityLogsTypesValue.ShowingAppointmentApproved,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingAppointmentFeedback,
    value: ActivityLogsTypesValue.ShowingAppointmentFeedback,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingAppointmentLapsed,
    value: ActivityLogsTypesValue.ShowingAppointmentLapsed,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingAppointmentApprovalApproved,
    value: ActivityLogsTypesValue.ShowingAppointmentApprovalApproved,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingAppointmentApprovalDeclined,
    value: ActivityLogsTypesValue.ShowingAppointmentApprovalDeclined,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingAppointmentApprovalCancelled,
    value: ActivityLogsTypesValue.ShowingAppointmentApprovalCancelled,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingLinkCreated,
    value: ActivityLogsTypesValue.ShowingLinkCreated,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingLinkEnabled,
    value: ActivityLogsTypesValue.ShowingLinkEnabled,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingLinkDisabled,
    value: ActivityLogsTypesValue.ShowingLinkDisabled,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingLinkUpdated,
    value: ActivityLogsTypesValue.ShowingLinkUpdated,
  },
  {
    label: ActivityLogsTypesValueLabel.ShowingAnnouncement,
    value: ActivityLogsTypesValue.ShowingAnnouncement,
  },
  { label: ActivityLogsTypesValueLabel.TaskDeleted, value: ActivityLogsTypesValue.TaskDeleted },
  {
    label: ActivityLogsTypesValueLabel.DocumentUpdated,
    value: ActivityLogsTypesValue.DocumentUpdated,
  },
  {
    label: ActivityLogsTypesValueLabel.TransactionCanceled,
    value: ActivityLogsTypesValue.TransactionCanceled,
  },
  {
    label: ActivityLogsTypesValueLabel.TransactionActiveListing,
    value: ActivityLogsTypesValue.TransactionActiveListing,
  },
  {
    label: ActivityLogsTypesValueLabel.TransactionPriceHistory,
    value: ActivityLogsTypesValue.TransactionPriceHistory,
  },
  {
    label: ActivityLogsTypesValueLabel.TransactionNote,
    value: ActivityLogsTypesValue.TransactionNote,
  },
  {
    label: ActivityLogsTypesValueLabel.TaskComment,
    value: ActivityLogsTypesValue.TaskComment,
  },
  {
    label: ActivityLogsTypesValueLabel.TaskStatusUpdated,
    value: ActivityLogsTypesValue.TaskStatusUpdated,
  },
];
