import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { tableColumns } from './tableColumns';
import { CollapsibleTable, WorkshopTableWrapper } from 'pages/Workshop/common';

import { ActiveOfferStatus, ArchivedOfferStatus, OfferStatus } from 'types';

import {
  getOfferAggregateList,
  getOfferIsArchiveSelector,
  getOfferFiltersAppliedSelector,
  getOfferFiltersSelector,
} from 'store/selectors/offerAggregate';
import { link } from 'settings/navigation/link';
import { Wrapper } from 'components';
import { LocationService } from 'services';

import styles from './styles.module.scss';
import { getOfferTitle } from './helper';

interface OffersTableProps {
  isTransactionRoom?: boolean;
  onRowClick?: Function;
  className?: string;
  offerAggregate: any[];
  showSelection?: boolean;
  loading?: boolean;
  setOffersSelected?: Function;
}

export const OffersTable = ({
  isTransactionRoom = false,
  onRowClick,
  className,
  offerAggregate,
  showSelection,
  setOffersSelected,
  loading,
}: OffersTableProps) => {
  const { isPending } = useSelector(getOfferAggregateList);
  const { isArchive } = useSelector(getOfferIsArchiveSelector);
  const { isFiltersApplied } = useSelector(getOfferFiltersAppliedSelector);
  const {
    offersFilter: { searchString = '' },
  } = useSelector(getOfferFiltersSelector);

  const history = useHistory();

  const locationSrv = new LocationService();
  const location = useLocation();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  const redirectToOffers = (data) => {
    return history.push({
      pathname: link.toTransactionOffers(data?.PropertyId),
      state: {
        offerIterationUUID: data?.LatestOfferIteration?.UUID,
        triggerOfferDetail: true,
      },
    });
  };

  const filterSource = (data) => {
    if (searchString.trim().length && isTransactionRoom) {
      const searchedText = searchString.toLowerCase();

      return data.filter((sourceItem) => {
        const { OfferStatus, isChild, title, LatestOfferIteration, Amount, BuyingAgent } =
          sourceItem;

        const offerTitle = (isChild ? title : getOfferTitle(sourceItem))?.toLowerCase() || '';

        const offerStatus =
          (LatestOfferIteration?.IsViewed ? OfferStatus : 'New').toLowerCase() || '';

        const buyingAgentName = BuyingAgent.Name?.toLowerCase() || '';
        const buyingAgentBrokerage = BuyingAgent.Brokerage?.toLowerCase() || '';

        return (
          offerStatus.includes(searchedText) ||
          offerTitle.includes(searchedText) ||
          buyingAgentName.includes(searchedText) ||
          buyingAgentBrokerage.includes(searchedText) ||
          Amount.toString().includes(searchedText)
        );
      });
    }

    return data;
  };

  function newOffers() {
    return filterSource(offerAggregate.filter((item) => !item?.LatestOfferIteration?.IsViewed));
  }

  function pendingOffers() {
    return filterSource(
      offerAggregate.filter(
        (item) => item.OfferStatus === OfferStatus.Pending && item?.LatestOfferIteration?.IsViewed,
      ),
    );
  }

  function counterReceivedOffers() {
    return filterSource(
      offerAggregate.filter(
        (item) =>
          item.OfferStatus === OfferStatus.CounterReceived && item?.LatestOfferIteration?.IsViewed,
      ),
    );
  }

  function counterSentOffers() {
    return filterSource(
      offerAggregate.filter(
        (item) =>
          item.OfferStatus === OfferStatus.CounterSent && item?.LatestOfferIteration?.IsViewed,
      ),
    );
  }

  function acceptedOffers() {
    return filterSource(
      offerAggregate.filter(
        (item) => item.OfferStatus === OfferStatus.Accepted && item?.LatestOfferIteration?.IsViewed,
      ),
    );
  }

  function rejectedOffers() {
    return filterSource(
      offerAggregate
        .filter(
          (item) =>
            item.OfferStatus === OfferStatus.Rejected && item?.LatestOfferIteration?.IsViewed,
        )
        .map((item) => ({ ...item, OfferExpiryEndDate: item?.LatestOfferIteration?.RejectedOn })),
    ); // OfferExpiryEndDate is assigned RejectedOn value to show in the table column under Rejected column, see tableColumns.tsx line 325 for more details
  }

  function expiredOffers() {
    return filterSource(
      offerAggregate.filter(
        (item) => item.OfferStatus === OfferStatus.Expired && item?.LatestOfferIteration?.IsViewed,
      ),
    );
  }

  function withdrawnOffers() {
    return filterSource(
      offerAggregate.filter(
        (item) =>
          item.OfferStatus === OfferStatus.Withdrawn && item?.LatestOfferIteration?.IsViewed,
      ),
    );
  }

  function cancelledOffers() {
    return filterSource(
      offerAggregate.filter(
        (item) =>
          item.OfferStatus === OfferStatus.Cancelled && item?.LatestOfferIteration?.IsViewed,
      ),
    );
  }

  const tableProps = {
    columns: tableColumns(isTransactionRoom, showSelection, setOffersSelected),
    onRow: (record) => {
      return {
        onClick: (event) => {
          if (isTransactionRoom && onRowClick) onRowClick(record);
          else redirectToOffers(record);
        },
      };
    },
    tableClassName: styles.OffersTable,
  };

  const getOfferTables = () => {
    if (query.show === 'New')
      return (
        <WorkshopTableWrapper emptyText="No Offers." data={[...newOffers()]}>
          <CollapsibleTable title="New" dataSource={newOffers()} {...tableProps} />
        </WorkshopTableWrapper>
      );
    else if (query.status === OfferStatus.Pending)
      return (
        <WorkshopTableWrapper emptyText="No Offers." data={[...pendingOffers()]}>
          <CollapsibleTable title="Pending" dataSource={pendingOffers()} {...tableProps} />
        </WorkshopTableWrapper>
      );
    return (
      <WorkshopTableWrapper
        emptyText="No Offers."
        data={[
          ...pendingOffers(),
          ...newOffers(),
          ...counterReceivedOffers(),
          ...counterSentOffers(),
        ]}
      >
        <CollapsibleTable title="New" dataSource={newOffers()} {...tableProps} />
        <CollapsibleTable title="Pending" dataSource={pendingOffers()} {...tableProps} />
        <CollapsibleTable
          title="Counter Received"
          dataSource={counterReceivedOffers()}
          {...tableProps}
        />
        <CollapsibleTable title="Counter Sent" dataSource={counterSentOffers()} {...tableProps} />
      </WorkshopTableWrapper>
    );
  };

  const getArchivedOfferTables = () => {
    return (
      <>
        {!isTransactionRoom ? (
          <CollapsibleTable
            title="Accepted"
            defaultCollapse={!isTransactionRoom}
            dataSource={acceptedOffers()}
            {...tableProps}
            columns={tableColumns(
              isTransactionRoom,
              showSelection,
              setOffersSelected,
              false,
              true,
              false,
            )}
          />
        ) : (
          <></>
        )}

        <CollapsibleTable
          title="Rejected"
          defaultCollapse={!isTransactionRoom}
          dataSource={rejectedOffers()}
          {...tableProps}
          columns={tableColumns(
            isTransactionRoom,
            showSelection,
            setOffersSelected,
            true,
            true,
            false,
          )}
        />

        <CollapsibleTable
          title="Expired"
          defaultCollapse={!isTransactionRoom}
          dataSource={expiredOffers()}
          {...tableProps}
          columns={tableColumns(
            isTransactionRoom,
            showSelection,
            setOffersSelected,
            false,
            true,
            true,
          )}
        />
        <CollapsibleTable
          title="Withdrawn"
          dataSource={withdrawnOffers()}
          defaultCollapse={!isTransactionRoom}
          {...tableProps}
          columns={tableColumns(
            isTransactionRoom,
            showSelection,
            setOffersSelected,
            false,
            true,
            false,
          )}
        />

        <CollapsibleTable
          title="Cancelled"
          dataSource={cancelledOffers()}
          defaultCollapse={!isTransactionRoom}
          {...tableProps}
          columns={tableColumns(
            isTransactionRoom,
            showSelection,
            setOffersSelected,
            false,
            true,
            false,
          )}
        />
      </>
    );
  };

  const filterStatuses = (dataSource) => {
    const statuses = Object.values(isArchive ? ArchivedOfferStatus : ActiveOfferStatus);
    return dataSource.filter(({ OfferStatus, LatestOfferIteration }) => {
      return (!isArchive && !LatestOfferIteration?.IsViewed) || statuses.includes(OfferStatus);
    });
  };

  return (
    <Wrapper isPending={isPending || loading}>
      <div className={classNames(styles.OffersTableWrapper, className)}>
        {offerAggregate && (
          <div>
            {isFiltersApplied ? (
              <WorkshopTableWrapper
                data={[...filterStatuses(filterSource(offerAggregate))]}
                emptyText="No Offers."
              >
                <CollapsibleTable
                  title={`${filterStatuses(filterSource(offerAggregate)).length} Offers`}
                  dataSource={filterStatuses(filterSource(offerAggregate))}
                  disableCollapse
                  {...tableProps}
                />
              </WorkshopTableWrapper>
            ) : isArchive ? (
              <WorkshopTableWrapper
                data={[
                  ...(!isTransactionRoom ? acceptedOffers() : []),
                  ...rejectedOffers(),
                  ...expiredOffers(),
                  ...cancelledOffers(),
                  ...withdrawnOffers(),
                ]}
                emptyText="No Offers."
              >
                {getArchivedOfferTables()}
              </WorkshopTableWrapper>
            ) : (
              <WorkshopTableWrapper
                data={[
                  ...newOffers(),
                  ...pendingOffers(),
                  ...counterReceivedOffers(),
                  ...counterSentOffers(),
                ]}
                emptyText="No Offers."
              >
                {isTransactionRoom ? (
                  <CollapsibleTable
                    title=""
                    disableCollapse
                    dataSource={[
                      ...newOffers(),
                      ...pendingOffers(),
                      ...counterReceivedOffers(),
                      ...counterSentOffers(),
                    ]}
                    {...tableProps}
                  />
                ) : (
                  getOfferTables()
                )}
              </WorkshopTableWrapper>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};
