import { Col, Row } from 'components-antd';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFavoritesPropertiesV3Effect } from 'store/effects/feedv3';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getFeedSortSelector, getSubFilterTypeSelector } from 'store/selectors/feed';
import {
  getFavoritesPropertiesList,
  getFavoritesPropertiesMarkersSelector,
  getFeedCriteriaSelectorV3,
  getSavedSearchesPropertiesList,
  getSavedSearchesPropertiesMarkersSelector,
} from 'store/selectors/feedv3';
import ClientProperties from './components/ClientProperties';
import Map from '../common/Map';
import { Spinner } from 'components';

import { EDIT } from 'settings/constants/mode';
import {
  selectedSearchAction,
  setCriteriaAction,
  setCriteriaModeAction,
} from 'store/actions/feedv3';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import ClientSearch from '../ClientSearch';
import { FilterDrawer } from '../Feed/Drawers';
import { ClientHeader } from './components/ClientHeader';
import { ViewSwitcher } from './ViewSwitcher/ViewSwitcher';
import { useSearchInstanceV3Effect } from './hooks/useSearchInstanceV3Effect';
import styles from './styles.module.scss';
import { getSearchInstancesFormattedSelector } from 'store/selectors/mySearches';
import {
  getSearchInstancesEffect,
  sortFeedEffect,
  updateSearchInstanceEffect,
} from 'store/effects';
import { SEARCH_INSTANCE_STATUS } from 'app-constants';
import classNames from 'classnames';
import { SORT_DESC } from 'settings/constants/sort';
import { LocationType } from 'types';
import { toggleSearchMapDrawingModeAction } from 'store/actions/searchResults';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { useHistory } from 'react-router-dom';

const ClientFeed = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pathname = history.location.pathname;
  const isFeed = pathname === '/properties/feed';
  const urlParams = new URLSearchParams(window.location.search);
  const subFilter = urlParams.get('subFilter');
  const sort = useSelector(getFeedSortSelector);
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const { selectedSearch } = useSelector(getFeedMetaSelectorV3);
  const searchContext = useSelector(getCurrentContextSelector);
  const getSearchInstanceV3 = useSearchInstanceV3Effect();
  const [showSavedSearches, setShowSavedSearches] = useState<boolean>(false);
  const [isFavortiesSectionVisible, setIsFavortiesSectionVisible] = useState(false);
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const polygonLocations =
    criteria?.Locations?.filter(({ Type }) => Type === LocationType.Polygon) ?? [];
  const [viewType, setViewType] = useState('Split View');
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const {
    properties,
    isPending: savedPropertiesLoading,
    totalCount,
  } = useSelector(getSavedSearchesPropertiesList);
  const {
    properties: favProperties,
    isPending: favPropertiesLoading,
    totalCount: favTotalCount,
  } = useSelector(getFavoritesPropertiesList);
  const propertiesMarkers = useSelector(getSavedSearchesPropertiesMarkersSelector);
  const favPropertiesMarkers = useSelector(getFavoritesPropertiesMarkersSelector);
  const { isIdle, data: savedSearches, loaded } = useSelector(getSearchInstancesFormattedSelector);

  const noActiveSavedSearches = useMemo(() => {
    return (
      savedSearches?.filter((item) => item?.status === SEARCH_INSTANCE_STATUS.ACTIVE)?.length < 1
    );
  }, [savedSearches]);

  useEffect(() => {
    if (firstRender && subFilter) {
      setFirstRender(false);
      return;
    } else {
      setFirstRender(false);
      if (firstRender && !isIdle) return;
      if (selectedSearch && !isOpenSearchModal) {
        !isFavortiesSectionVisible
          ? getSearchInstanceV3(selectedSearch)
          : fetchFavoriteProperties();
      }
    }
  }, [subFilterType, sort, JSON.stringify(selectedSearch)]);

  useEffect(() => {
    if (subFilter) {
      dispatch(setSubFilterTypeAction(subFilter));
    }
    dispatch(setCriteriaModeAction({ mode: EDIT }));
    if (!isIdle) return;
    dispatch(sortFeedEffect({ order: SORT_DESC }));
  }, []);

  const fetchFavoriteProperties = () => {
    dispatch(
      getFavoritesPropertiesV3Effect({
        queryParams: {
          sortField: sort?.fields?.[0],
          sortOrder: sort?.order,
          contextId: searchContext?.ContextKey,
        },
      }),
    );
  };

  const isLoading = isFavortiesSectionVisible ? favPropertiesLoading : savedPropertiesLoading;

  let propertyProps = {
    isPending: isLoading,
    properties: isFavortiesSectionVisible ? favProperties : properties,
  };

  const [isOpenSearchModal, setIsOpenSearchModal] = useState<boolean>(false);
  const cfg = { contextKey: searchContext?.ContextKey };

  const fetchSavedSearches = ({ fetchActiveSearch }, fetch = false) => {
    dispatch(
      getSearchInstancesEffect(cfg, {}, (err, result) => {
        if (!err && fetchActiveSearch) {
          const savedSearches = result?.data?.result?.filter(
            (item) => item?.Status === SEARCH_INSTANCE_STATUS.ACTIVE,
          );
          if (savedSearches?.length > 0) {
            const data = savedSearches?.[0];
            const item = {
              id: data?.Id,
              name: data?.Name,
              criterias: data?.DefaultPropertySearchPreferences,
            };
            dispatch(selectedSearchAction({ selectedSearch: item }));
            if (fetch)
              !isFavortiesSectionVisible
                ? getSearchInstanceV3(selectedSearch)
                : fetchFavoriteProperties();
          }
        }
      }),
    );
  };

  useEffect(() => {
    if (!firstRender && !isIdle) {
      fetchSavedSearches({ fetchActiveSearch: true }, true);
    }
  }, [searchContext?.ContextKey]);

  useEffect(() => {
    if (searchContext && !selectedSearch && isIdle) {
      fetchSavedSearches({ fetchActiveSearch: !selectedSearch });
    }
  }, [searchContext, selectedSearch, isIdle]);
  const [showBlurBg, setShowBlurBg] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  useEffect(() => {
    if (isFirstRender && loaded) {
      setIsFirstRender(false);
    }
  }, [isFirstRender, loaded]);

  const getViewType = (value) => {
    setViewType(value);
  };

  const editModeCallback = (err) => {
    if (!err) {
      dispatch(
        getSearchInstancesEffect(cfg, {}, (err, result) => {
          if (!err) {
            const searchActive = result?.data?.result?.find(
              (item) => item?.Id === selectedSearch?.id,
            );
            const item = {
              id: searchActive?.Id,
              name: searchActive?.Name,
              criterias: searchActive?.DefaultPropertySearchPreferences,
            };
            dispatch(selectedSearchAction({ selectedSearch: item }));
          }
        }),
      );
    }
  };

  const setDrawnPolygonDispatchFn = (polygon) => {
    const newCriteria = {
      ...criteria,
      Locations: [polygon],
      id: selectedSearch?.id,
    };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    dispatch(updateSearchInstanceEffect(newCriteria, {}, (err) => editModeCallback(err)));
    dispatch(toggleSearchMapDrawingModeAction(false));
  };

  let mapProps = {
    disableDraw: isFavortiesSectionVisible,
    markers: isFavortiesSectionVisible ? favPropertiesMarkers : propertiesMarkers,
    properties: isFavortiesSectionVisible ? favProperties : properties,
    totalCount: isFavortiesSectionVisible ? favTotalCount : totalCount,
    setDrawnPolygonDispatchFn: setDrawnPolygonDispatchFn,
    polygonLocations: polygonLocations,
  };

  return (
    <div className={styles.clientFeedWrapper}>
      {!isFirstRender ? (
        <>
          {loaded && noActiveSavedSearches ? (
            <ClientSearch
              onClose={() => setIsOpenSearchModal(false)}
              hideCloseButton={noActiveSavedSearches}
              showSavedSearches={showSavedSearches}
              setShowSavedSearches={setShowSavedSearches}
            />
          ) : (
            <>
              <ClientHeader
                isFavortiesSectionVisible={isFavortiesSectionVisible}
                setIsFavortiesSectionVisible={setIsFavortiesSectionVisible}
                setIsOpenSearchModal={setIsOpenSearchModal}
                setShowSavedSearches={setShowSavedSearches}
                setShowBlurBg={(value) => setShowBlurBg(value)}
                isPending={propertyProps?.isPending}
              />
              <Row className={styles.clientFeed}>
                {showBlurBg && <div className={styles.backdropBlur} />}
                {viewType !== 'List Only' && (
                  <Col
                    md={viewType === 'Split View' ? 11 : 24}
                    sm={24}
                    xs={24}
                    className={styles.mapContainer}
                  >
                    <Map {...mapProps} />
                  </Col>
                )}
                {viewType !== 'Map Only' && (
                  <Col
                    md={viewType === 'Split View' ? 13 : 24}
                    sm={24}
                    xs={24}
                    className={styles.propertiesContainer}
                  >
                    <ClientProperties
                      {...propertyProps}
                      viewType={viewType}
                      searchInstanceId={
                        isFeed && selectedSearch?.id ? selectedSearch.id : undefined
                      }
                      isFavorites={isFavortiesSectionVisible}
                      isFavortiesSectionVisible={isFavortiesSectionVisible}
                    />
                    <FilterDrawer />
                  </Col>
                )}
              </Row>
              <div className={styles.mapSwitchers}>
                <ViewSwitcher getView={getViewType} />
              </div>
              {isOpenSearchModal && (
                <ClientSearch
                  onClose={() => setIsOpenSearchModal(false)}
                  showSavedSearches={showSavedSearches}
                  setShowSavedSearches={setShowSavedSearches}
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div className={styles.spinner}>
            <Spinner loaderClassName={classNames(styles.loader)} />
          </div>
        </>
      )}
    </div>
  );
};

export default ClientFeed;
