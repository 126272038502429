import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { link } from 'settings/navigation/link';
import { getPropertyAddress } from 'helpers';
import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { getThreadById } from 'store/selectors/sockets/threads';
import { resetMessagesEffect } from 'store/effects/sockets/messages';
import { getUserRoleSelector, getUserId } from 'store/selectors/user';

import {
  getViewContactsOption,
  getViewProfileOption,
  getArchiveThreadOption,
  getAddParticipantThreadOption,
  getViewAttachmentsOption,
  getQuoteActivityDrawerOption,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import ActionsHeader from 'components/Drawers/components/ActionsHeader';

const Header = ({ className, onBackToAllComments, threadId, shareProperty }) => {
  const dispatch = useDispatch();
  const thread = useSelector(getThreadById(threadId));
  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRoleSelector);
  const location = useLocation();
  const params = useParams();

  const onAllCommentsClick = () => {
    dispatch(openCommentsDrawerEffect({ open: true, type: null, currentThreadId: null }));
    dispatch(resetMessagesEffect());
    onBackToAllComments();
  };

  const actions = useMemo(() => {
    if (!thread) return [];
    return [
      ...[
        ...(thread.Participants.length > 2
          ? getViewContactsOption(dispatch, thread)
          : getViewProfileOption(dispatch, thread, userId)),
      ],
      ...getQuoteActivityDrawerOption(dispatch, thread, userId),
      ...getViewAttachmentsOption(dispatch, {
        threadId: thread?.Id,
        propertyId: shareProperty?.Id,
      }),
      ...getAddParticipantThreadOption(dispatch, {
        info: getPropertyAddress(shareProperty?.Address),
      }),
      // ...getArchiveThreadOption(dispatch, thread),
    ];
  }, [dispatch, thread, shareProperty?.Address, location.pathname, params.id]);

  return (
    <ActionsHeader
      label="All comments"
      actions={actions}
      onClick={onAllCommentsClick}
      className={className}
    />
  );
};

Header.propTypes = {
  className: PropTypes.string,
  threadId: PropTypes.number.isRequired,
  onBackToAllComments: PropTypes.func,
  shareProperty: PropTypes.shape({
    Address: PropTypes.shape({}),
  }),
};

Header.defaultProps = {
  className: '',
  onBackToAllComments: () => {},
  shareProperty: null,
};

export default Header;
