import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { openTransactionParticipantDrawerEffect } from 'store/effects/drawers/transactionParticipant';
import {
  getTransactionParticipant,
  getTransactionParticipantDrawerSelector,
} from 'store/selectors/drawers/transactionParticipant';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';
import { ConfirmModal, Drawer, Wrapper as PendingWrapper } from 'components';
import {
  getTransactionParticipantEffect,
  removeTransactionParticipantEffect,
} from 'store/effects/transactions';
import Header from './Header';
import Footer from './Footer';
import Content from './Content';
import { getUserId } from 'store/selectors/user';

import styles from './styles.module.scss';

const TransactionParticipant = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const { open, params } = useSelector(getTransactionParticipantDrawerSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const { isPending, participant } = useSelector(getTransactionParticipant);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isPendingDelete, setIsPendingDelete] = useState(false);

  useEffect(() => {
    if (open) {
      dispatch(getTransactionParticipantEffect({ participantId: params?.participantId }));
    } else {
      setOpenConfirmModal(false);
    }
  }, [open, params?.participantId]); // eslint-disable-line

  const onClose = () => {
    dispatch(openTransactionParticipantDrawerEffect({ open: false }));
  };

  const onRemove = () => {
    setOpenConfirmModal(true);
  };

  const onSubmitConfirmModal = () => {
    const config = { transactionId: transaction?.Id, participantId: participant?.Id };
    const options = { silent: true };
    setIsPendingDelete(true);
    dispatch(
      removeTransactionParticipantEffect(config, options, (err) => {
        if (!err) {
          dispatch(openTransactionParticipantDrawerEffect({ open: false }));
        }
        setIsPendingDelete(false);
      }),
    );
  };

  const getModalTitle = () => {
    const name = `${participant?.FirstName} ${participant?.LastName}`;

    return (
      <div testid="modal_title">
        <div>{`Are you sure you want to remove ${name} from this transaction?`}</div>
      </div>
    );
  };

  const onCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  return (
    <Drawer
      isOpen={open}
      onClose={onClose}
      className={classNames(styles.editTransactionDrawer, className)}
      header={<Header onClose={onClose} />}
      testid="transaction_participant_drawer"
    >
      <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
        <Content />
        {fullAccess && userId !== participant?.Id ? <Footer onRemove={onRemove} /> : <></>}
      </PendingWrapper>
      <ConfirmModal
        title={getModalTitle()}
        isOpen={openConfirmModal}
        onClose={onCloseConfirmModal}
        onSubmit={onSubmitConfirmModal}
        isPending={isPendingDelete}
        icon
      />
    </Drawer>
  );
};

TransactionParticipant.propTypes = {
  className: PropTypes.string,
};

TransactionParticipant.defaultProps = {
  className: '',
};

export default TransactionParticipant;
