import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getTransactionSelector } from 'store/selectors/transaction';
import { socketSetEditTransactionNoteEffect } from 'store/effects/sockets/transactionNotes';
import { getCurrentEditTransactionNoteSelector } from 'store/selectors/sockets/transactionNotes';
import { Comments } from '../../TransactionTasks/components/Task/View/components';

import styles from './styles.module.scss';
import { CommentsEntityType } from 'api/comments';

const Notes = (props) => {
  const { className, isModal, isTransactionDrawer } = props;
  const { transaction } = useSelector(getTransactionSelector);

  return (
    <Comments
      isModal={isModal}
      isTransactionDrawer={isTransactionDrawer}
      commentAutoFocus={true}
      inputPlaceholder="Start typing ..."
      emptyLabel="No notes have been added to this transaction (yet)."
      getEditMessageSelector={getCurrentEditTransactionNoteSelector}
      onCustomCancelEditSelector={socketSetEditTransactionNoteEffect}
      threadId={transaction.NotesThreadId}
      entityId={transaction.Id}
      entityType={CommentsEntityType.transactionNote}
      className={className}
    />
  );
};

Notes.propTypes = {
  className: PropTypes.string,
  handleCloseNotesModal: PropTypes.func,
  onCreateThreadAndSendNewNote: PropTypes.func,
  isModal: PropTypes.bool,
  isTransactionDrawer: PropTypes.bool,
};

Notes.defaultProps = {
  className: '',
  onCloseModal: () => {},
  isModal: false,
  isTransactionDrawer: false,
};

export default Notes;
