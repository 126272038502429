import classNames from 'classnames';
import { Rnd } from 'react-rnd';
import { useState, useRef, useEffect } from 'react';
import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDynamicFormQuestionEffect,
  selectDynamicFormFieldEffect,
  updateDynamicFormQuestionEffect,
} from 'store/effects/formProcess';
import { getDynamicFormEditorConfigSelector } from 'store/selectors/requestFormProcess';
import {
  DynamicFormEditorQuestion,
  DynamicFormSelectedField,
  FormTemplateWizardType,
  PDFEditorColor,
} from 'types';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';
import { removeSpaces } from 'utils';
import { Switch } from 'components-antd';

import { v4 as uuidv4 } from 'uuid';
import { useOutsideClick } from 'hooks';
import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';
import { RolesPopover } from '../RolesPopover';
import { InfoTooltip } from '../InfoTooltip';
import { StrokePopover } from '../StrokePopover';
import { StrokeColorPopover } from '../StrokeColorPopover';
import {
  INITIALS_MIN_HEIGHT,
  INITIALS_MIN_WIDTH,
  SIGNATURE_MIN_HEIGHT,
  SIGNATURE_MIN_WIDTH,
  shouldHideType,
} from './helper';

interface PDFEditorFieldRendererProps {
  field: DynamicFormEditorQuestion;
  index: number | undefined;
  isCohesiveFlow: boolean;
  documentIndex: number;
  scale: number;
}

export const FieldRenderer = ({
  field,
  index,
  documentIndex,
  isCohesiveFlow,
  scale,
}: PDFEditorFieldRendererProps) => {
  const dispatch = useDispatch();
  const popupRef = useRef<HTMLDivElement>(null);
  const { state: formState } = useLocation<FormTemplateWizardType>();

  const { selectedField, selectedFieldIndex } = useSelector(getDynamicFormEditorConfigSelector);

  const role = field.customRole;

  const fieldActionProps = {
    ...(formState?.editMode && { templateEditMode: true, roleId: role?.roleId }),
    isCohesiveFlow,
    documentIndex,
  };

  const viewModeProps = {
    roleId: role?.roleId,
  };

  const [isRequiredPopupVisible, setRequiredPopupVisible] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isDisabledDrag, setIsDisabledDrag] = useState(false);

  useOutsideClick([popupRef], () => setRequiredPopupVisible(false));
  const isHighlighted = selectedField?.UUID && selectedField?.UUID === field?.UUID;

  useEffect(() => {
    if (!isHighlighted && isDisabledDrag) {
      setIsDisabledDrag(false);
    }
  }, [isHighlighted]);

  const color = role?.color || ({} as PDFEditorColor);

  const width = field.width * scale;
  const height = field.height * scale;
  const x = field.x * scale;
  const y = field.y * scale;

  const isCheckbox = field.type === DYNAMIC_QUESTION_TYPE.CheckBox;

  const isStrikeThrough = field.type === DYNAMIC_QUESTION_TYPE.StrikeThrough;

  const signatureField = field.type === DYNAMIC_QUESTION_TYPE.Signature;
  const initialField = field.type === DYNAMIC_QUESTION_TYPE.Initials;

  const getWidthHeight = (width, height) => {
    if (signatureField) {
      width = width < SIGNATURE_MIN_WIDTH ? SIGNATURE_MIN_WIDTH : width;
      height = height < SIGNATURE_MIN_HEIGHT ? SIGNATURE_MIN_HEIGHT : height;
    } else if (initialField) {
      width = width < INITIALS_MIN_WIDTH ? INITIALS_MIN_WIDTH : width;
      height = height < INITIALS_MIN_HEIGHT ? INITIALS_MIN_HEIGHT : height;
    }

    return { width, height };
  };

  const handleFieldUpdate = (updatedProps) => {
    dispatch(
      updateDynamicFormQuestionEffect({
        ...field,
        index,
        ...updatedProps,
        ...fieldActionProps,
        ...viewModeProps,
      }),
    );
  };

  const handleHighLightField = () => {
    dispatch(
      selectDynamicFormFieldEffect({
        field: {
          pageNumber: field.pageNumber,
          roleName: field.roleName,
          UUID: field.UUID,
          roleId: role?.roleId,
        } as DynamicFormSelectedField,
      }),
    );
  };

  const getFieldText = () => {
    const _type = removeSpaces(field.type);
    const hideType = shouldHideType(field.type, width);
    if (
      field.type === DYNAMIC_QUESTION_TYPE.DateSigned ||
      field.type === DYNAMIC_QUESTION_TYPE.Date
    ) {
      return (
        <div
          className={classNames(styles.dateFieldWrap, { [styles.hiddenTypeWrap]: hideType })}
          id="fieldWrap"
        >
          <div className={styles.lineHeight}>
            <Icons
              className={styles.icon}
              style={{ '--border-color': color?.border } as any}
              variant={_type}
            />
          </div>
          {hideType ? <></> : 'MM/DD/YYYY'}
        </div>
      );
    }

    if (isCheckbox) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          className={styles.checkbox}
          style={{ '--border-color': color?.border } as any}
          id="fieldCheckbox"
        >
          <rect
            x="1"
            y="1"
            width="22"
            height="22"
            rx="3"
            fill="white"
            stroke="#747475"
            strokeWidth="2"
          />
          <path
            opacity="0.4"
            d="M7 12.3333L10.3333 15.6667L17 9"
            stroke="#676767"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }

    return (
      <div
        className={classNames(styles.dateFieldWrap, { [styles.hiddenTypeWrap]: hideType })}
        id="fieldElement"
      >
        <div className={styles.lineHeight}>
          <Icons
            className={_type === 'TextBox' ? styles.iconText : styles.icon}
            style={{ '--border-color': color?.border } as any}
            variant={_type}
          />
        </div>
        {hideType ? <></> : field.type}
      </div>
    );
  };

  const handleFieldDelete = () => {
    dispatch(
      deleteDynamicFormQuestionEffect({
        ...field,
        index,
        ...fieldActionProps,
      }),
    );
  };

  const handleFieldCopy = () => {
    const newField = {
      ...field,
      isNew: true,
      UUID: uuidv4(),
      y: field.y + height + 6,
      ...fieldActionProps,
    };

    delete newField.id;
    delete newField.order;

    dispatch(updateDynamicFormQuestionEffect(newField));
  };

  const handleFieldRequiredPopupClick = () => {
    setRequiredPopupVisible(!isRequiredPopupVisible);
  };

  const handleFieldRequired = (e) => {
    if (selectedField) {
      dispatch(
        updateDynamicFormQuestionEffect({
          ...selectedField,
          index: selectedFieldIndex,
          required: e,
          ...fieldActionProps,
          ...viewModeProps,
        }),
      );
    }
  };

  if (field.isDeleted) return <></>;

  const grabPointStyle = isHighlighted ? styles.grabPoint : undefined;
  const midGrabPointStyle = isHighlighted ? styles.line : undefined;

  const actionButtons = () => {
    if (!isHighlighted) return;

    const actionButtons = [
      {
        variant: ICON_VARIANT_TYPE.Copy,
        onClick: handleFieldCopy,
      },

      ...(!isStrikeThrough
        ? [
            {
              variant: ICON_VARIANT_TYPE.Required,
              onClick: handleFieldRequiredPopupClick,
              color: '#fff',
            },
          ]
        : []),

      {
        variant: ICON_VARIANT_TYPE.Delete,
        onClick: handleFieldDelete,
      },
    ];

    return (
      <div>
        <div
          className={classNames(styles.actionButtons, {
            [styles.strikeThroughActionButtons]: isStrikeThrough,
          })}
        >
          {!isStrikeThrough ? (
            <RolesPopover
              field={field}
              color={color}
              selectedFieldIndex={selectedFieldIndex}
              fieldActionProps={fieldActionProps}
            />
          ) : (
            <></>
          )}

          {isStrikeThrough ? (
            <>
              <StrokePopover
                field={field}
                selectedFieldIndex={selectedFieldIndex}
                fieldActionProps={fieldActionProps}
              />

              <StrokeColorPopover
                defaultColor={field.strokeColor}
                field={field}
                selectedFieldIndex={selectedFieldIndex}
                fieldActionProps={fieldActionProps}
                setIsDisabledDrag={setIsDisabledDrag}
              />
            </>
          ) : (
            <></>
          )}

          {actionButtons.map((button) => (
            <div key={button.variant} className={styles.actionButton} onClick={button.onClick}>
              <Icons variant={button.variant} color={button.color || ''} />
            </div>
          ))}
        </div>

        {isRequiredPopupVisible && (
          <div ref={popupRef} className={styles.actionRequiredWrap}>
            <div className={styles.actionRequiredTextWrap}>
              <Icons variant={'Required'} color={'#ff576d'} />
              <span className={styles.actionRequiredText}>Required</span>
            </div>
            <Switch defaultChecked={!!selectedField?.required} onChange={handleFieldRequired} />
          </div>
        )}
      </div>
    );
  };

  const rndResizeProps = {
    ...(isStrikeThrough && {
      enableResizing: {
        bottom: false,
        bottomLeft: false,
        bottomRight: false,
        left: true,
        right: true,
        top: false,
        topLeft: false,
        topRight: false,
      },
    }),
  };

  return (
    <Rnd
      name="Form-Field"
      {...rndResizeProps}
      default={{
        x,
        y,
        width,
        height,
      }}
      className={classNames(
        styles.fieldContainer,
        {
          [styles.highlightedField]: isHighlighted,
          [styles.strikeThroughFieldContainer]: isStrikeThrough,
        },
        'prevent-user-select',
      )}
      position={{ x, y }}
      size={{ height, width }}
      minHeight={isStrikeThrough ? 0 : 20}
      minWidth={20}
      style={{
        border: isStrikeThrough
          ? `${field.strokeWidth}px solid rgb(${field.strokeColor?.r}, ${field.strokeColor?.g}, ${field.strokeColor?.b})`
          : `2px solid ${color?.border}`,
        background: isHighlighted && !isStrikeThrough ? undefined : color?.background,
      }}
      onResizeStart={handleHighLightField}
      onDragStart={() => {
        setIsDragging(true);
        handleHighLightField();
      }}
      bounds="parent"
      onDragStop={(e, d) => {
        const { x, y } = d;

        setIsDragging(false);
        handleFieldUpdate({
          x: x / scale,
          y: y / scale,
        });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        const parsedWidth = parseInt(ref.style.width) / scale;
        const parsedHeight = parseInt(ref.style.height) / scale;

        const { width, height } = getWidthHeight(parsedWidth, parsedHeight);

        handleFieldUpdate({
          width,
          height,
          x: position.x / scale,
          y: position.y / scale,
        });
      }}
      dragGrid={[1, 1]}
      resizeGrid={[1, 1]}
      lockAspectRatio={isCheckbox}
      resizeHandleClasses={{
        bottomLeft: grabPointStyle,
        bottomRight: grabPointStyle,
        topLeft: grabPointStyle,
        topRight: grabPointStyle,
        ...(!isCheckbox && {
          top: midGrabPointStyle,
          bottom: midGrabPointStyle,
          left: midGrabPointStyle,
          right: midGrabPointStyle,
        }),
      }}
      disableDragging={isDisabledDrag}
      onTouchStart={(e) => {
        if (!e.target.id.includes('field')) {
          setIsDisabledDrag(true);
        } else {
          isDisabledDrag && setIsDisabledDrag(false);
        }
      }}
    >
      {!isStrikeThrough ? (
        <InfoTooltip text={isDragging ? '' : role?.roleName} id="fieldInfo">
          <span
            className={classNames(
              styles.fieldText,
              field.required && !isCheckbox && styles.required,
              {
                [styles.checkboxRequiredField]: isCheckbox && field.required,
                [styles.checkboxField]: isCheckbox,
              },
            )}
            id="fieldText"
          >
            {getFieldText()}
          </span>
        </InfoTooltip>
      ) : (
        <></>
      )}
      {actionButtons()}
    </Rnd>
  );
};
