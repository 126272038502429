import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { getScheduledTours } from 'store/selectors/tour';
import { TourStatus } from 'app-constants/enums/tours';
import { useMemo } from 'react';
import { Cross } from 'components/Icons';
import moment from 'moment';
import { TimelineItem } from '../TimelineItem';
import { Button } from 'components-antd';
import classNames from 'classnames';
import { GroupedTimeline } from '../GroupedTimeline';
import { LocalHeader } from '../LocalHeader';

export const Archive = ({ isArchive, setIsArchive }) => {
  const { tours } = useSelector(getScheduledTours);
  const archiveTours = useMemo(
    () =>
      tours.filter(
        (tour) =>
          tour.ScheduleDateTime &&
          (tour.Status === TourStatus.Canceled || moment(tour.ScheduleDateTime) < moment()),
      ),
    [tours],
  );
  const unscheduledTours = useMemo(
    () => tours.filter((tour) => !tour.ScheduleDateTime && tour.Status === TourStatus.Canceled),
    [tours],
  );

  return (
    <>
      {archiveTours?.length === 0 && !unscheduledTours?.length && (
        <div className={styles.emptyArchivetext}>
          <span>There are no Archive tours</span>
        </div>
      )}
      <div className={styles.archiveWrapper}>
        <GroupedTimeline
          timelineItemInfoWrapperClassName={styles.archiveView}
          tours={archiveTours}
        ></GroupedTimeline>
      </div>
      {!!unscheduledTours?.length && (
        <div className={styles.unscheduledWrapper}>
          <p className={styles.title}>
            Unscheduled <span className={styles.count}>({unscheduledTours?.length})</span>
          </p>
          <div className={styles.archiveWrapper}>
            {unscheduledTours.map((tour) => (
              <TimelineItem tour={tour} status={TourStatus.Canceled} hideTime>
                {tour.Status === TourStatus.Canceled && (
                  <span className={styles.cancelled}>Canceled</span>
                )}
              </TimelineItem>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
