import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reduce, trim } from 'lodash-es';
import Space from 'antd/lib/space';
import AntSkeleton from 'antd/lib/skeleton';
import axios from 'axios';
import classNames from 'classnames';
import fileDownload from 'js-file-download';

import { Alert, Modal, ModalProps, Paragraph, Title, Skeleton } from 'components-antd';
import Button from 'components/Button';
import { TitleValueTable, FormattedPhone, Avatar, PdfViewModal } from 'components';
import {
  ArrowLeft,
  TooltipIcon,
  Phone,
  FavoriteIcon,
  Email,
  Geotag,
  World,
  Profile,
} from 'components/Icons';
import {
  getLoadingPartnerData,
  getPartnerObject,
  getPartnerBusinessNameAndId,
} from 'store/selectors/singlePartner';
import {
  requestChangeRecommendPartnerStatus,
  requestGetPartnersListByCategoryIdEffect,
  requestGetRecommendedPartnersByAgentListEffect,
} from 'store/effects/partners';
import { requestGetPartnerByIdEffect } from 'store/effects/singlePartner';
import { getThreadsListSelector } from 'store/selectors/sockets/threads';
import { getClientAgentId, getUserRoleSelector } from 'store/selectors/user';
import { getUserId } from 'store/selectors/user';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { THIRD_PARTY, AGENT, CLIENT } from 'settings/constants/roles';
import { link } from 'settings/navigation/link';
import {
  convertNameToAvatarPlaceholder,
  getHrefLink,
  getIfExistThread,
  getPropertyAddress,
  getPropertyTwoLinesAddress,
} from 'helpers';
import { sortGroupedQuoteDocuments } from 'pages/Quotes/components/QuoteDetails/helpers';
import { requestGetQuoteDetailsEffect } from 'store/effects/quotes';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import Actions from 'pages/Quotes/components/QuoteDetails/components/Content/ThirdPartyQuote/Details/Actions';
import { RequestQuoteDocument } from './RequestQuoteDocument';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { getAgentTypeSelector } from 'store/selectors/user';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { AGENT_TYPE } from 'settings/constants/drawers';
import { TEAM_BASIC } from 'settings/constants/roles';
import { getAgentTeamIdSelector } from 'store/selectors/agentTeamDetail';
import { TransactionMessageIcon } from 'pages/ServicesCategory/components/AgentPartnersList/icons';
import { getConciergeSearchSelector } from 'store/selectors/concierge';
import { RecommendationModal } from 'pages/ServicesCategory/components/AgentPartnersList/components/PartnersList/components/RecommendationModal';
import { getFormattedLocationList } from 'pages/ServicesCategory/components/OthersRolesPartnersList/PartnerListItem';

import styles from './styles.module.scss';

interface PartnerProfileProps extends ModalProps {
  requestQuoteId: number;
  partnerId: number;
  setModalVisible: (open: boolean) => void;
}

export const PartnerProfile = ({
  partnerId,
  setModalVisible,
  requestQuoteId,
  open,
  ...props
}: PartnerProfileProps) => {
  const [expand, setExpand] = useState(false);
  const [fullProfile, setFullProfile] = useState(false);
  const [requestMode, setRequestMode] = useState(false);
  const [confirmChangeRecommendation, setConfirmChangeRecommendation] = useState(false);
  const [updateChangeRecommendation, setUpdateChangeRecommendation] = useState(false);
  const [quoteLoading, setQuoteLoading] = useState(true);

  useEffect(() => {
    if (requestQuoteId) {
      setFullProfile(false);
      setRequestMode(true);
    }
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();

  const { isPending, isIdle, metaId } = useSelector(getLoadingPartnerData);
  const { id } = useSelector(getPartnerBusinessNameAndId);
  const partnerData = useSelector(getPartnerObject);
  const userId = useSelector(getUserId);
  const threads = useSelector(getThreadsListSelector);
  const { quote, groupedByDateQuoteDocuments } = useSelector(getQuoteDetailsSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const teamId = useSelector(getAgentTeamIdSelector);
  const { areasOfOperation, category: categoryId } = useSelector(getConciergeSearchSelector);
  const isEmailVerified = partnerData?.ThirdParty?.User?.IsEmailVerified || false;
  const CompletedProfile = partnerData?.ThirdParty?.User?.CompletedProfile;
  const IsActive = partnerData?.IsActive;

  const getSortedDocuments = () =>
    Object.entries(groupedByDateQuoteDocuments).sort(sortGroupedQuoteDocuments);

  const isLoading = metaId !== partnerId || isPending || isIdle;
  const agentId = useSelector(getClientAgentId);
  const userRole = useSelector(getUserRoleSelector);

  useEffect(() => {
    if (open) {
      if (requestQuoteId) {
        dispatch(
          requestGetQuoteDetailsEffect({ id: requestQuoteId }, {}, (err, res) => {
            if (!err) setQuoteLoading(false);
          }),
        );
      }
      if (partnerId) {
        dispatch(requestGetPartnerByIdEffect({ partnerId }));
      }
    }
  }, [open]);

  useEffect(() => {
    if (partnerId && metaId !== partnerId && !isPending) {
      dispatch(requestGetPartnerByIdEffect({ partnerId }));
    }
  }, [partnerId, dispatch, isPending, metaId]);

  useEffect(() => {
    if (updateChangeRecommendation) {
      dispatch(requestGetPartnerByIdEffect({ partnerId }));
      setUpdateChangeRecommendation(false);
    }
  }, [updateChangeRecommendation]);

  const { address, categories, recommendedBy } = useMemo(
    () => ({
      address: getPropertyTwoLinesAddress(partnerData?.Address),
      categories: reduce(
        partnerData?.PartnerCategories,
        (acc, { Title }) => {
          if (acc) {
            return `${acc}, ${Title}`;
          }
          return Title;
        },
        '',
      ),
      recommendedBy: reduce(
        partnerData?.RecommendingAgents,
        (acc, { Id, Team }) => {
          if (Id !== agentId) {
            return trim(`${Team.Name}`);
          }
          return acc;
        },
        '',
      ),
    }),
    [
      partnerData?.Address,
      partnerData?.PartnerCategories,
      partnerData?.RecommendingAgents,
      agentId,
    ],
  );

  const initiateSendingMessageToPartner = useCallback(() => {
    setModalVisible(false);

    const thread = getIfExistThread(
      threads,
      [id, userId],
      ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT,
    );

    dispatch(openMessagesDrawerAction(true));

    if (!thread) {
      const firstName = partnerData?.ThirdParty?.User?.FirstName;
      const lastName = partnerData?.ThirdParty?.User?.LastName;

      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
          params: {
            threadId: thread?.Id || null,
            participants: [
              {
                id: partnerData?.Id,
                name: `${firstName || ''} ${lastName || ''} (${partnerData?.BusinessName || ''})`,
                value: partnerData?.Id,
                role: THIRD_PARTY,
                avatarUrl: partnerData?.LogoUrl,
                firstName: partnerData?.FirstName,
                lastName: partnerData?.LastName,
              },
            ],
          },
        }),
      );
    } else {
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.CHAT,
          params: {
            threadId: thread.Id,
          },
        }),
      );
    }
  }, [dispatch, threads, id, userId, partnerData]);

  const handleMessageButtonClick = useEmailVerificationWall(
    ActionToInterrupt.MESSAGE_TO_PARTNER,
    initiateSendingMessageToPartner,
    {
      categoryId,
    },
    userRole === CLIENT,
  );

  const handleRequestQuoteClick = useEmailVerificationWall(
    ActionToInterrupt.REQUEST_QUOTE,
    () => {
      history.push(link.toServicesCategoryPartnerQuote({ partnerId, categoryId }));
    },
    {
      categoryId,
    },
    userRole === CLIENT,
  );

  const recommendedAgent =
    userRole === AGENT &&
    partnerData?.RecommendingAgents.find(
      ({ Id, TeamId }) => Id === agentId || (TeamId && TeamId === teamId),
    );

  const validateAgentType = () =>
    agentType === AGENT_TYPE.Individual ||
    (agentType === AGENT_TYPE.Team && agentRole !== TEAM_BASIC);

  const showRecommendedBy = () => recommendedBy && userRole === CLIENT;

  const showRecommended = () => recommendedAgent && validateAgentType();

  const showRecommend = () => userRole === AGENT && validateAgentType();

  const modalHeader = () => (
    <div className={styles.modalHeader}>
      {isLoading ? (
        <AntSkeleton.Avatar shape="circle" active={isLoading} size="large" />
      ) : (
        <Avatar
          className={styles.avatarWrap}
          avatarClassName={styles.avatar}
          src={partnerData?.LogoUrl}
          placeholder={convertNameToAvatarPlaceholder(trim(partnerData?.BusinessName))}
        />
      )}

      <Skeleton showSkeleton={isLoading} rowCount={2}>
        {fullProfile && requestMode && (
          <div className={styles.goBackButton} onClick={() => setFullProfile(false)}>
            <ArrowLeft />
          </div>
        )}
        <Title level={3} className={styles.businessName}>
          {partnerData?.BusinessName}
        </Title>
        <Paragraph
          className={classNames(styles.categoriesParagraph, {
            [styles.isClient]: userRole === CLIENT,
          })}
        >
          {categories}
        </Paragraph>
      </Skeleton>
      <Space direction="horizontal">
        {isLoading ? (
          <>
            <AntSkeleton.Button active={isLoading} shape="round" size="large" />
            <AntSkeleton.Button active={isLoading} shape="round" size="large" />
          </>
        ) : (
          <>
            {userRole === CLIENT || userRole === AGENT ? (
              <div className={styles.headerBtnWrapper}>
                {!requestQuoteId && (
                  <Button
                    title="Request Quote"
                    className={styles.actionBtn}
                    onClick={handleRequestQuoteClick}
                    disabled={!(CompletedProfile && IsActive)}
                  ></Button>
                )}
                {isEmailVerified && (
                  <div className={styles.messageBtn} onClick={handleMessageButtonClick}>
                    <div>
                      <TransactionMessageIcon />
                    </div>
                    <span>Message</span>
                  </div>
                )}
                {requestMode && !fullProfile && (
                  <div className={styles.profileBtn} onClick={() => setFullProfile(true)}>
                    <TooltipIcon />
                    <span>View Profile</span>
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </Space>
      {!requestQuoteId && (
        <Skeleton showSkeleton={isLoading}>
          {showRecommendedBy() ? (
            <Alert
              className={styles.recommendedBar}
              message={
                <>
                  <FavoriteIcon className={styles.recommendedIcon} />
                  {'Recommended by '}
                  <span>{recommendedBy}</span>
                </>
              }
              type="error"
            />
          ) : showRecommended() ? (
            <Alert
              className={classNames(styles.recommendedBar, styles.clickable)}
              message={
                <>
                  <FavoriteIcon className={styles.recommendedIcon} />
                  Recommended
                </>
              }
              onClick={() => setConfirmChangeRecommendation(true)}
              type="error"
            />
          ) : showRecommend() ? (
            <Alert
              className={styles.unRecommendedBar}
              message={
                <>
                  <FavoriteIcon className={styles.unRecommendedIcon} active={false} />
                  Recommend
                </>
              }
              onClick={() => setConfirmChangeRecommendation(true)}
            />
          ) : null}
        </Skeleton>
      )}
    </div>
  );

  const onChangeRecommendation = useCallback(() => {
    dispatch(
      requestChangeRecommendPartnerStatus(
        {
          recommend: !!recommendedAgent,
          partnerId: partnerData?.Id,
        },
        (err) => {
          if (!err) {
            setConfirmChangeRecommendation(false);
            setUpdateChangeRecommendation(true);
            dispatch(requestGetRecommendedPartnersByAgentListEffect());
            setModalVisible(false);
          }
        },
      ),
    );
  }, [
    partnerData,
    dispatch,
    requestGetPartnersListByCategoryIdEffect,
    setConfirmChangeRecommendation,
    setUpdateChangeRecommendation,
    categoryId,
    areasOfOperation,
  ]);

  const onDownload = () => {
    axios.get(quote.RequestQuoteFile?.Url, { responseType: 'blob' }).then((response) => {
      fileDownload(response.data, `${getPropertyAddress(quote?.Property?.Address)}.pdf`);
    });
  };

  const onExpand = () => {
    setExpand(true);
  };

  const getClientName = () =>
    quote?.Client ? `${(quote.Client.FirstName ?? '') + (' ' + quote.Client.LastName)}` : undefined;

  const requestDetails = [
    {
      title: 'Property:',
      value: quote?.Property?.Address ? getPropertyTwoLinesAddress(quote.Property.Address) : '-',
    },
    {
      title: 'Client:',
      value: getClientName() || '-',
    },
    {
      title: 'Date & Time:',
      value: quote?.UpdatedDate || '-',
    },
  ];

  const getPointOfContact = () => {
    const firstName = partnerData?.ThirdParty?.User?.FirstName;
    const lastName = partnerData?.ThirdParty?.User?.LastName;

    return firstName || lastName ? (
      <Paragraph className={styles.contactParagraph}>
        <div className={styles.contactInner}>
          <div className={styles.contactIcon}>
            <Profile color="#aaabab" />
          </div>
          <span>Point of Contact</span>
        </div>
        <div className={styles.cpDetail}>{firstName ? `${firstName} ${lastName}` : lastName}</div>
      </Paragraph>
    ) : (
      <></>
    );
  };

  return (
    <Modal footer={null} className={styles.basePartnerProfile} open={open} {...props}>
      <div className={styles.partnerBody}>
        {modalHeader()}
        {(!requestMode || fullProfile) && (
          <div className={styles.contactContainer}>
            <Skeleton showSkeleton={isLoading} rowCount={4}>
              {getPointOfContact()}
              {partnerData?.PhoneNumber && (
                <Paragraph className={styles.contactParagraph}>
                  <div className={styles.contactInner}>
                    <div className={styles.contactIcon}>
                      <Phone color="#aaabab" />
                    </div>
                    <span>Phone</span>
                  </div>
                  <FormattedPhone
                    testid="phone"
                    className={classNames(styles.contactPhoneText, styles.cpDetail)}
                  >
                    {partnerData?.PhoneNumber}
                  </FormattedPhone>
                </Paragraph>
              )}
              {partnerData?.Email && (
                <Paragraph className={styles.contactParagraph}>
                  <div className={styles.contactInner}>
                    <div className={styles.contactIcon}>
                      <Email color="#aaabab" />
                    </div>
                    <span>Email</span>
                  </div>
                  <div className={styles.cpDetail}>{partnerData?.Email}</div>
                </Paragraph>
              )}
              {partnerData?.Address && (
                <Paragraph className={styles.contactParagraph}>
                  <div className={styles.contactInner}>
                    <div className={styles.contactIcon}>
                      <Geotag color="#aaabab" />
                    </div>
                    <span>Address</span>
                  </div>
                  <div className={classNames(styles.contactAddressText, styles.cpDetail)}>
                    {address.length && (
                      <>
                        <div>{address[0] || address[1] || '-'}</div>
                        {address[0] && address[1] && <div>{address[1]}</div>}
                        Unit/ Suite: {partnerData?.SuiteUnit}
                      </>
                    )}
                  </div>
                </Paragraph>
              )}
              {partnerData?.Website && (
                <Paragraph className={styles.contactParagraph}>
                  <div className={styles.contactInner}>
                    <div className={styles.contactIcon}>
                      <World color="#aaabab" />
                    </div>
                    <span>Website</span>
                  </div>
                  <div className={classNames(styles.cpDetail, styles.website)}>
                    {' '}
                    <a href={getHrefLink(partnerData?.Website)} target="_blank" rel="noreferrer">
                      {partnerData?.Website}
                    </a>
                  </div>
                </Paragraph>
              )}
            </Skeleton>
          </div>
        )}
        {(!requestMode || fullProfile) && partnerData?.AreasOfOperation?.length > 0 && (
          <div className={styles.areasServerContainer}>
            <Skeleton showSkeleton={isLoading} rowCount={4}>
              <Title level={5} className={styles.bodyHeader}>
                Areas Served
              </Title>
              <div className={styles.areasTags}>
                {partnerData.AreasOfOperation.map((area, index) => (
                  <span key={`${index}-areaServed`}>
                    {getFormattedLocationList(area, partnerData.AreasOfOperation.length > 1)}
                  </span>
                ))}
              </div>
            </Skeleton>
          </div>
        )}

        {requestMode && !fullProfile && (
          <div className={styles.descriptionContainer}>
            <Skeleton showSkeleton={quoteLoading} rowCount={4}>
              <Title
                level={5}
                className={classNames(styles.bodyHeader, styles.bodyHeaderSmallMargin)}
              >
                Most Recent Quote
              </Title>
              {quote?.QuoteDocuments?.length ? (
                <div className={styles.quoteDocument}>
                  <RequestQuoteDocument
                    sortedDocuments={getSortedDocuments() || []}
                    multi={quote?.QuoteDocuments?.length > 1}
                  />
                </div>
              ) : (
                <Paragraph className={styles.descriptionParagraph}>No Quotes Found</Paragraph>
              )}
            </Skeleton>
            <Skeleton showSkeleton={quoteLoading} rowCount={4}>
              <div className={styles.requestHeader}>
                <Title level={5} className={classNames(styles.bodyHeader, styles.noMargin)}>
                  Your Request
                </Title>
                <Actions onExpand={onExpand} onDownload={onDownload} />
              </div>
              {quote?.RequestQuoteFile && (
                <>
                  <TitleValueTable items={requestDetails} />
                  {expand && (
                    <PdfViewModal
                      isOpen={expand}
                      onClose={() => setExpand(false)}
                      file={{
                        Url: quote?.RequestQuoteFile?.Url,
                        Filename: `${getPropertyAddress(quote?.Property?.Address)}.pdf`,
                      }}
                    />
                  )}
                </>
              )}
            </Skeleton>
          </div>
        )}
        {(!requestMode || fullProfile) && partnerData?.Description && (
          <div className={styles.descriptionContainer}>
            <Skeleton showSkeleton={isLoading} rowCount={4}>
              <Title level={5} className={styles.bodyHeader}>
                Description
              </Title>
              <Paragraph
                className={classNames(styles.descriptionParagraph, {
                  [styles.descriptionEmpty]: !partnerData?.Description,
                })}
              >
                {partnerData?.Description || 'No description'}
              </Paragraph>
            </Skeleton>
          </div>
        )}
        {(!requestMode || fullProfile) && partnerData?.PartnerTags?.length > 0 && (
          <div className={styles.servicesContainer}>
            <Skeleton showSkeleton={isLoading} rowCount={4}>
              <Title level={5} className={styles.bodyHeader}>
                Services
              </Title>
              <div className={styles.servicesTags}>
                {partnerData.PartnerTags.map((pTag) => (
                  <span key={pTag.Id}>{pTag.Name}</span>
                ))}
              </div>
            </Skeleton>
          </div>
        )}
      </div>
      {confirmChangeRecommendation && (
        <RecommendationModal
          isOpen={confirmChangeRecommendation}
          selectedPartner={partnerData}
          onCloseModal={() => setConfirmChangeRecommendation(false)}
          isLoadingRecommendation={isPending}
          onChangeRecommendation={onChangeRecommendation}
        />
      )}
    </Modal>
  );
};
