import { PENDING, READY, ERROR } from 'settings/constants/apiState';
import { showErrorMessage } from 'helpers/errors';

export default class Api {
  static controllers = {};

  /**
   * @param action - redux-action function.
   * @param  method - request function returning Promise }
   * @returns function passing cfg = {}, options = {} for the method and cb(error, result) function
   */
  static execBase({ action, method }) {
    return (cfg = {}, options = {}, cb) => {
      const opts = { showError: true, ...options };
      return Api.execFunc({ cfg, options: opts, action, method, cb });
    };
  }

  /**
   *
   * @param action - redux action
   * @param method - function for sending request
   * @returns {function(*=, *=, *): function(*): Promise<void>}
   */
  static execResult({ action, method }) {
    return (cfg = {}, options = {}, cb) => {
      const opts = { showError: true, ...options };
      return Api.execFunc({ cfg, options: opts, action, method, pending: false, cb });
    };
  }

  /**
   *
   * @param cfg - params for request
   * @param options - passing callbacks for request
   * @param action - redux action
   * @param method - function for sending request
   * @param pending - inserted pending state to redux store
   * @param cb - callback by success or failure request
   * @returns {function(*): Promise<void>}
   */
  static execFunc({ cfg, options, action, method, pending = true, cb }) {
    const { showError, pendingMeta, ...opts } = options;

    return async (dispatch) => {
      let actionKey = action;
      // If action is a function, get the action type
      // This is to support redux-actions library
      if (typeof action === 'function') {
        const actionFn = action();
        if (typeof actionFn === 'string') {
          actionKey = actionFn;
        }
        if (typeof actionFn === 'object' && actionFn.type) {
          actionKey = actionFn.type;
        }
      }

      // Abort previous request if it exists
      if (Api.controllers[actionKey]) {
        try {
          Api.controllers[actionKey].abort();
        } catch (error) {
          console.warn('Api.js - execFunc - AbortController error', error);
        }
      }

      // Create a new AbortController
      const controller = new AbortController();

      Api.controllers[actionKey] = controller;

      // Add the signal to the options
      opts.signal = controller.signal;

      if (pending) {
        Api.setPending({ dispatch, action, meta: pendingMeta ? cfg : undefined });
      }

      try {
        const response = await method(cfg, opts);

        // Remove the controller as the request is complete
        delete Api.controllers[actionKey];

        Api.setData({
          dispatch,
          action,
          cfg: { ...cfg, ...response.meta },
          response,
          options: opts,
        });

        if (typeof cb === 'function') {
          cb(null, response, dispatch);
        }

        return response;
      } catch (err) {
        // Remove the controller as the request is complete
        delete Api.controllers[actionKey];

        const config = {
          ...cfg,
          status: err?.response?.status || err?.networkError?.statusCode,
          message: err.message,
        };

        const isCanceled = err.name === 'AbortError' || err.message === 'canceled';

        if (!isCanceled) Api.setError({ dispatch, action, cfg: config, response: err });

        if (typeof cb === 'function') {
          cb(err, null, dispatch);
        }

        // Do not show error message if the request was canceled
        if (showError && !isCanceled) {
          showErrorMessage(err);
        }
        // Throw error if the request was not canceled
        if (!isCanceled) {
          throw err;
        } else {
          console.warn(
            `The request was canceled using AbortController: actionKey: ${actionKey} - message: ${err.message}`,
          );
        }
      }
    };
  }

  /**
   *
   * @param dispatch - redux dispatch
   * @param action - redux action (redux-actions library)
   */
  static setPending({ dispatch, action, ...rest }) {
    dispatch(action({ state: PENDING, ...rest }));
  }

  /**
   *
   * @param dispatch - redux dispatch
   * @param action - redux action
   * @param cfg - request params
   * @param response
   * @param options
   */
  static setData({ dispatch, action, cfg, response, options }) {
    dispatch(action({ state: READY, data: response.data, meta: cfg, options }));
  }

  /**
   *
   * @param dispatch - redux dispatch
   * @param action - redux action
   * @param cfg - request params
   */
  static setError({ dispatch, action, cfg }) {
    dispatch(action({ state: ERROR, data: undefined, meta: cfg }));
  }
}
