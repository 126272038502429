import React from 'react';

import moment from 'moment';
import classNames from 'classnames';

import { Icons } from 'pages/Workshop/Icons';
import { subtractTimeZone } from 'helpers';
import { isBeforeToday, isToday, isYesterday } from 'helpers/date';

import styles from './styles.module.scss';
// import { mapTasksStatuses, tasksStatusesIds } from 'settings/constants/transactionTasks';
import { Button } from 'components-antd';

export const tasksStatusesIds = {
  new: 'New',
  inProgress: 'InProgress',
  stuck: 'Stuck',
  done: 'Done',
  overdue: 'Overdue',
  na: 'N/A',
};

export const mapTasksStatuses = {
  [tasksStatusesIds.new]: 'New',
  [tasksStatusesIds.inProgress]: 'In Progress',
  [tasksStatusesIds.stuck]: 'Stuck',
  [tasksStatusesIds.done]: 'Done',
  [tasksStatusesIds.overdue]: 'Overdue',
  [tasksStatusesIds.na]: 'N/A',
};

export function tableColumns(userId, onViewTask) {
  const me = 'Me';
  const getStatusClassName = (val) => {
    switch (val) {
      case tasksStatusesIds.new:
        return styles.new;
      case tasksStatusesIds.inProgress:
        return styles.inProgress;
      case tasksStatusesIds.stuck:
        return styles.stuck;
      case tasksStatusesIds.done:
        return styles.done;
      case tasksStatusesIds.overdue:
        return styles.overdue;
      case tasksStatusesIds.na:
        return styles.na;
      default:
        return '';
    }
  };

  const columns = [
    {
      title: 'Task',
      key: 'task',
      dataIndex: 'Title',
      width: 500,
      sorter: (a, b) => {
        if (a.Title < b.Title) return -1;
        if (a.Title > b.Title) return 1;
        return 0;
      },
      render: (text, row) => {
        let unreadCount = 0;
        row?.Comments?.forEach((comment) => {
          if (comment.SenderUserId !== userId && comment.AccessType !== me) {
            let isUnRead = false;
            comment.MessageReceipts.forEach((receipt) => {
              if (receipt.UserId === userId && !receipt.IsRead) {
                isUnRead = true;
              }
            });
            if (isUnRead) {
              unreadCount++;
            }
          }
        });
        const showIcon = row.canComment && (unreadCount > 0 || row.Comments?.length > 0);

        const getSecondLineText = () => {
          const { IsProject, Name, Property } = row?.Transaction ?? {};
          return IsProject ? Name : Property?.Address?.Line1;
        };

        return (
          <div className={styles.taskWrap}>
            <div className={styles.transactionTask}>
              <Icons variant={Icons.OFFER} className={styles.icon} onClick={(e) => {}} />
              <div className={styles.info}>
                <span className={styles.line1}>{text}</span>
                <span className={styles.line2}>{getSecondLineText()}</span>
              </div>
            </div>
            {showIcon && (
              <div
                className={classNames(styles.commentWrapper, unreadCount > 0 && styles.isUnread)}
              >
                <Icons className={styles.icon} variant={Icons.TASK_COMMENT} />
                {unreadCount > 0 && (
                  <span className={unreadCount > 9 ? styles.ninePlus : styles.count}>
                    {unreadCount > 9 ? '9+' : unreadCount}
                  </span>
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'Status',
      width: 100,
      sorter: (a, b) => {
        if (a.Status < b.Status) return -1;
        if (a.Status > b.Status) return 1;
        return 0;
      },
      render: (_, { Status }) => {
        return (
          <div
            className={classNames(styles.statusOnly, getStatusClassName(Status))}
            testid="task_status"
          >
            <span className={styles.statusText}>{mapTasksStatuses[Status]}</span>
          </div>
        );
      },
    },
    {
      title: 'Due Date',
      key: 'dueDate',
      dataIndex: 'DueDate',
      width: 148,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => moment(a.DueDate).unix() - moment(b.DueDate).unix(),
      render: (_, { DueDate }) => {
        let dueDate = DueDate ? subtractTimeZone(DueDate, 'M/D/YYYY') : 'N/A';
        let isPrimaryDate = false;

        if (isToday(dueDate)) {
          dueDate = 'Today';
          isPrimaryDate = true;
        }
        if (isYesterday(dueDate)) {
          dueDate = 'Yesterday';
          isPrimaryDate = true;
        }
        if (isBeforeToday(dueDate)) {
          isPrimaryDate = true;
        }

        return (
          <span className={classNames({ [styles.datePrimary]: isPrimaryDate })}>{dueDate}</span>
        );
      },
    },

    {
      title: '',
      key: '',
      dataIndex: '',
      width: 75,
      render: (_, row) => {
        return (
          <div testid="task_view_btn" onClick={() => {}} className={styles.viewBtn}>
            <Button
              variant="bordered-text-only"
              className={styles.signBtn}
              onClick={() => onViewTask(row)}
            >
              <span>View</span>
            </Button>
          </div>
        );
      },
    },
  ];

  return columns;
}
