import { Moment } from 'moment';
import React, { useState } from 'react';

import { Button, Col, Option, Popover, Row, MultiSelect } from 'components-antd';
import { DateRangePicker } from 'components/Form/DateRangePicker';
import { TagsType } from 'types';

import { Icons } from '../../../Icons';

import styles from './styles.module.scss';

interface FilterPopoverProps {
  filterIcon: React.ReactElement;
  onDone: (filterObj: any) => void;
  onReset: (filterObj: any) => void;
  options: TagsType | undefined;
  publishedByOptions?: any;
  open: boolean;
  setOpen: (value) => void;
  isRecentUploads?: boolean;
  showDropdown?: boolean;
}

export const FilterPopover = ({
  filterIcon,
  onDone,
  onReset,
  options,
  open,
  setOpen,
  isRecentUploads,
  showDropdown,
  publishedByOptions,
}: FilterPopoverProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [publishByIds, setPublishedByIds] = useState<string[]>([]);

  const [lastUpdatedFrom, setLastUpdatedFrom] = useState<Moment>();
  const [lastUpdatedTo, setLastUpdatedTo] = useState<Moment>();
  const [resetDates, setResetDates] = useState(false);

  const submitHandler = (e?) => {
    e.stopPropagation();
    onDone({
      filters: {
        [isRecentUploads ? 'uploadedBy' : 'categories']: selectedOptions,
        lastUpdatedFrom,
        lastUpdatedTo,
        ...(publishedByOptions && { publishByIds }),
      },
    });
    setOpen(false);
  };

  const resetHandler = (e) => {
    e.stopPropagation();
    setOpen(false);
    setSelectedOptions([]);
    setPublishedByIds([]);
    setLastUpdatedFrom(undefined);
    setLastUpdatedTo(undefined);

    onReset({
      filters: {
        ...(isRecentUploads
          ? { uploadedBy: [], lastUpdatedFrom: undefined, lastUpdatedTo: undefined }
          : {
              categories: [],
              publishByIds: [],
              lastUpdatedFrom: undefined,
              lastUpdatedTo: undefined,
            }),
      },
    });
  };

  const handleOpenChange = (newOpen: boolean) => setOpen(newOpen);

  const renderOptions = (dropdownOptions) => {
    return dropdownOptions?.map((el) => (
      <Option value={el.TagId} key={`tag-${el.TagId}`}>
        {el.Name}
      </Option>
    ));
  };

  const renderFields = () => (
    <>
      <Row>
        <Col span={24} className={styles.header}>
          <Icons variant={'filterRed'} className={styles.icon} />
          <span className={styles.title}>Filters</span>
        </Col>
      </Row>

      <Row className={styles.container} justify="start" align="stretch">
        <Col span={24}>
          <MultiSelect
            size="large"
            title={isRecentUploads ? 'Uploaded By' : 'Categories'}
            placeholderText="All"
            variant="none"
            onHandleChange={(values) => setSelectedOptions(values)}
            propClass={styles.multiSelect}
            optionFilterProp="children"
            wrapperClass={styles.multiSelectWrapper}
            values={selectedOptions}
          >
            {renderOptions(options)}
          </MultiSelect>

          {publishedByOptions ? (
            <MultiSelect
              size="large"
              title={'Published By'}
              placeholderText="All"
              variant="none"
              onHandleChange={(values) => setPublishedByIds(values)}
              propClass={styles.multiSelect}
              optionFilterProp="children"
              wrapperClass={styles.multiSelectWrapper}
              values={publishByIds}
            >
              {renderOptions(publishedByOptions)}
            </MultiSelect>
          ) : (
            <></>
          )}

          <DateRangePicker
            title="Uploaded"
            key={`${lastUpdatedFrom} ${lastUpdatedTo}`}
            onDatesChange={(from, to) => {
              resetDates && setResetDates(false);
              setLastUpdatedFrom(from);
              setLastUpdatedTo(to);
            }}
            dateRangePickerWrapperClass={styles.dateRangePicker}
            resetDates={resetDates}
            futureDisabled={true}
            fromDateValue={lastUpdatedFrom}
            toDateValue={lastUpdatedTo}
            dateIcon={<Icons variant="datePicker" className={styles.icon} />}
            containerClass={styles.columnsContainer}
          />
        </Col>
      </Row>

      <Row justify="space-between" align="middle" className={styles.footer}>
        <Col span={6}>
          <Button
            variant="default"
            className={styles.resetButton}
            title="Reset"
            onClick={resetHandler}
          >
            <span className={styles.btnText}>Reset</span>
          </Button>
        </Col>

        <Col span={6}>
          <Button
            variant="secondary"
            className={styles.submitButton}
            title="Done"
            onClick={submitHandler}
          >
            <span className={styles.btnText}>Done</span>
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <Popover
      content={renderFields}
      trigger="click"
      placement="bottomRight"
      open={open}
      onOpenChange={handleOpenChange}
      overlayClassName={styles.filesFilterPopover}
      getPopupContainer={(triggerNode) => triggerNode}
    >
      {filterIcon}
    </Popover>
  );
};
