import { Switch } from 'antd';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getAgentTeamDetailEffect, updateMemberProfileEffect } from 'store/effects';
import { getUserId } from 'store/selectors/user';
import { TEAM_OWNER } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

export const AllowInTransaction = (props) => {
  const { contact } = props;
  const { CanCreateTransaction: canCreateTransaction, Id: agentId } = contact || {};

  const [checked, setChecked] = useState(canCreateTransaction);
  const userId = useSelector(getUserId);
  const isLoggedInAgent = userId === agentId;

  const agentRole = useSelector(getAgentTeamRoleSelector);

  const isTeamOwner = agentRole === TEAM_OWNER;
  const isOwnerContact = contact.TeamRole === TEAM_OWNER;

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onChangeAllowance = (allowanceValue) => {
    setChecked(allowanceValue);
    setLoading(true);

    dispatch(
      updateMemberProfileEffect(
        { canCreateTransaction: allowanceValue },
        { agentId, silent: true },
        (err) => {
          if (!err) {
            setLoading(false);
            isLoggedInAgent && dispatch(getAgentTeamDetailEffect({}, { silent: true }));
          } else {
            setLoading(false);
            setChecked(canCreateTransaction);
          }
        },
      ),
    );
  };

  if (
    !isTeamOwner &&
    (!contact.IsManagedByCurrentUser || (contact.IsManagedByCurrentUser && contact.Id === userId))
  )
    return <></>;

  return (
    <div className={styles.transactionContainer}>
      <div className={styles.label}>Allow Transaction Creation</div>
      <Switch
        className={styles.switch}
        onChange={onChangeAllowance}
        disabled={loading || isOwnerContact}
        checked={checked}
      />
    </div>
  );
};
