import { useState, useCallback, useEffect, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { EditableContent } from 'components';
import classNames from 'classnames';

import styles from './styles.module.scss';

const TextareaAutosizeComponent = forwardRef((props, ref) => {
  const {
    label,
    value,
    onChange,
    placeholder,
    onKeyDown,
    inputFieldClassName,
    className,
    variant,
    id,
    disabled,
    sideBarChatView,
    placeholderClassName,
    editableContentWrapperClassName,
  } = props;
  const { replacePlaceholderWithLabelWhenHasValue } = props;
  const [inputValue, setTextareaValue] = useState(value);

  useEffect(() => setTextareaValue(value), [value]);

  const onChangeHandler = useCallback(
    (event) => {
      // ContentEditable component returns <br> when field is empty
      const value = parseValue(event.target.value);

      setTextareaValue(value);
      onChange(event, value);
    },
    [onChange],
  );

  const hasLabel =
    (label && !replacePlaceholderWithLabelWhenHasValue) ||
    ((label || placeholder) && replacePlaceholderWithLabelWhenHasValue && !!value);

  return (
    <div testid="wrapper" className={classNames(styles.inputWrapper, styles[variant], className)}>
      {hasLabel && <label htmlFor={id}>{label || placeholder}</label>}
      <EditableContent
        inputValue={inputValue}
        contentEditable={ref}
        onChangeHandler={onChangeHandler}
        handleKeyPress={onKeyDown}
        placeholder={placeholder}
        disabled={disabled}
        className={classNames(styles.inputField, inputFieldClassName)}
        sideBarChatView={sideBarChatView}
        placeholderClassName={placeholderClassName}
        editableContentWrapperClassName={editableContentWrapperClassName}
      />
      {props.error && (
        <div testid="validation" className={classNames(styles.error)}>
          {props.error}
        </div>
      )}
    </div>
  );
});

const parseValue = (value) => (value === '<br>' ? '' : value);

TextareaAutosizeComponent.LIGHT = 'light';
TextareaAutosizeComponent.LIGHT_FULL = 'lightFull';
TextareaAutosizeComponent.FULL = 'full';

TextareaAutosizeComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  inputFieldClassName: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  testid: PropTypes.string,
  onKeyDown: PropTypes.func,
  textareaStyle: PropTypes.shape({}),
  autoFocus: PropTypes.bool,
  replacePlaceholderWithLabelWhenHasValue: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  maxLength: PropTypes.number,
  cursorPosition: PropTypes.number,
  placeholderClassName: PropTypes.string,
  editableContentWrapperClassName: PropTypes.string,
};

TextareaAutosizeComponent.defaultProps = {
  id: undefined,
  name: undefined,
  placeholder: undefined,
  className: '',
  inputFieldClassName: '',
  label: undefined,
  onChange: () => {},
  cursorPosition: 0,
  value: '',
  rows: 5,
  disabled: false,
  variant: TextareaAutosizeComponent.FULL,
  error: undefined,
  testid: undefined,
  onKeyDown: () => {},
  textareaStyle: undefined,
  autoFocus: false,
  replacePlaceholderWithLabelWhenHasValue: false,
  minRows: undefined,
  maxRows: undefined,
  maxLength: undefined,
  placeholderClassName: '',
  editableContentWrapperClassName: '',
};

export default TextareaAutosizeComponent;
