import { createSelector } from 'reselect';
import { get, trim, map, floor, forEach } from 'lodash-es';

import { PENDING } from 'settings/constants/apiState';
import { MATCH_LEVEL } from 'settings/constants/common';
import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { getPrefLabelOptionById } from 'settings/constants/preferences';
import { softCriteria } from 'pages/Properties/ListingDetail/components/Match/MatchItem/Insight/constants';

const localState = ({ mlsScores }) => mlsScores;

export const getPreparedMustAndWantsMlsCriterias = (matchedCriterias, propertyInsights) => {
  const keywordPrefs = get(matchedCriterias, 'keywordFeatures') || [];
  const viewFeatures = get(matchedCriterias, 'ViewFeatures') || [];
  const interiorStylesFeatures = get(matchedCriterias, 'InteriorStylesFeatures') || [];
  const kitchenFeatures = get(matchedCriterias, 'KitchenFeatures') || [];
  const amenities = get(matchedCriterias, 'Amenities') || [];
  const homeFeatures = get(matchedCriterias, 'HomeFeatures') || [];
  const outdoorFeatures = get(matchedCriterias, 'OutdoorFeatures') || [];
  const neighborhoodFeatures = get(matchedCriterias, 'NeighborhoodFeatures') || [];
  const stories = get(matchedCriterias, 'Stories');
  const commutes = get(matchedCriterias, 'Commutes');
  const parking = get(matchedCriterias, 'Parking');
  const schools = get(matchedCriterias, 'Schools');

  const mustHave = [];
  const wants = [];

  forEach(
    [
      ...keywordPrefs,
      ...viewFeatures,
      ...kitchenFeatures,
      ...interiorStylesFeatures,
      ...amenities,
      ...homeFeatures,
      ...outdoorFeatures,
      ...neighborhoodFeatures,
      ...(stories ? [{ Feature: 'Stories', ...stories }] : []),
      ...(commutes
        ? commutes.map((commute) => {
            const searchCriteria = localStorage.getItem('searchCriteria');
            const CommutePrefs = searchCriteria
              ? JSON.parse(searchCriteria)?.CommutePrefs || []
              : [];
            const isProximity = CommutePrefs?.find(
              (c) => c.Name === commute.Name,
            )?.IsImportantLocation;
            return {
              Feature: `${isProximity ? softCriteria.ProximityTo : softCriteria.CommuteTo}${
                commute.Name
              }`,
              ...commute,
            };
          })
        : []),
      ...(schools
        ? schools.map((schoolOrDistrict) => {
            return { Feature: schoolOrDistrict.Name, ...schoolOrDistrict };
          })
        : []),
      ...(parking ? [{ Feature: 'Parking', ...parking }] : []),
    ],
    ({ Feature, Keyword, Importance, MatchLevel, DataSource, MatchType }) => {
      const item = {
        name: getPrefLabelOptionById(Feature || Keyword),
        isMatched: MatchLevel === MATCH_LEVEL.FULL || MatchLevel === MATCH_LEVEL.PARTIAL,
        matchLevel: MatchLevel,
        dataSource: DataSource,
        matchType: MatchType,
        criteriaInsight: propertyInsights?.[Feature || Keyword],
      };
      if (
        Importance === SEARCH_CRITERIA_IMPORTANCE.MUST ||
        Importance === SEARCH_CRITERIA_IMPORTANCE.VERY
      ) {
        mustHave.push(item);
      } else {
        wants.push(item);
      }
    },
  );

  return { mustHave, wants };
};

export const getPreparedAdditionalFeaturesParsed = (additionalFeatures) => {
  const additionalMustHave = [];
  const additionalWants = [];

  forEach(additionalFeatures, ({ keyword, response, description, type }) => {
    const item = {
      name: keyword,
      isMatched: response === 'yes',
      matchLevel: response === 'yes' ? MATCH_LEVEL.FULL : '',
      criteriaInsight: description,
    };
    if (type === SEARCH_CRITERIA_IMPORTANCE.MUST || type === SEARCH_CRITERIA_IMPORTANCE.VERY) {
      additionalMustHave.push(item);
    } else {
      additionalWants.push(item);
    }
  });
  return { additionalMustHave, additionalWants };
};

export const getMlsScoreByIdWithStatus = (mlsId) =>
  createSelector(localState, ({ mlsScores: { state, data } }) => {
    const mlsData = get(data, mlsId);

    return {
      isData: !!mlsData,
      buyers: mlsData?.length
        ? map(mlsData, (user) => ({
            score: floor(get(user, 'MatchScore')),
            avatar: get(user, 'User.AvatarUrl'),
            name: trim(`${get(user, 'User.FirstName')} ${get(user, 'User.LastName')}`) || 'Unknown',
            ...getPreparedMustAndWantsMlsCriterias(get(user, 'MatchedCriterias')),
            searchInstance: user?.SearchInstance,
          }))
        : null,
      isLoading: state === PENDING,
    };
  });

export const getAgentMatchScoreWithCriteria = (mlsId, { propertyInsights, additionalFeatures }) =>
  createSelector(localState, ({ mlsScores: { state, agentScore } }) => {
    const mlsData = get(agentScore, mlsId);
    return {
      isData: !!mlsData,
      matchScore: agentScore?.matchScore,
      matchedCriterias: getPreparedMustAndWantsMlsCriterias(
        agentScore?.matchedCriterias || {},
        propertyInsights,
        additionalFeatures,
      ),
      agentScoreMatchedCriterias: agentScore?.matchedCriterias,
      additionalFeatures: additionalFeatures
        ? getPreparedAdditionalFeaturesParsed(additionalFeatures)
        : null,
      isLoading: state === PENDING,
    };
  });

export const getPropertyInsights = createSelector(
  localState,
  ({ propertyInsight: { data, state } }) => ({
    propertyInsightsData: data,
    isLoading: state === PENDING,
  }),
);

export const getAdditionalFeatures = createSelector(
  localState,
  ({ additionalFeatures: { data, state } }) => ({
    additionalFeaturesData: data,
    isLoading: state === PENDING,
  }),
);
