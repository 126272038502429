import { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import classNames from 'classnames';

import Spinner from 'components/Spinner';

import styles from './styles.module.scss';
import { PDFWidgets } from './components/PDFWidgets';
import { useDocumentsLoader } from './hooks/useFetchPDF';

type PrintPDFProps = {
  pdfDocuments: any[];
  pageRender: (...params) => ReactElement;
  className?: string;
  classNamePdfDocument?: string;
  classNameDocumentPage?: string;
  handleScaleChange?: (scale) => void;
  children?: ReactNode;
  widgets?: ReactNode;
  currentPage?: number;
  currentDocument?: number;

  pdfLoading?: boolean;
  pdfDocRefs?: any;
  pdfDocAndPageRefs?: any;
  pdfPageClassName?: string;
  isEditor?: boolean;
  isDynamicView?: boolean;

  allDocuments?: any[];
  totalPages?: number;
  pageContentRef?: any;

  isMobileDevice?: boolean;
};

const MAX_ZOOM_SCALE = 2.5;
const SCALE_STEP = 0.5;

export const DEFAULT_PDF_SCALE = 1.5;

export const PrintPDF = ({
  pageRender,
  className,
  classNamePdfDocument,
  classNameDocumentPage,
  handleScaleChange,
  children,
  widgets,

  pdfLoading,
  pdfDocRefs,
  pdfDocAndPageRefs,
  pdfPageClassName,
  isDynamicView,
  allDocuments,
  pdfDocuments,
  pageContentRef,
  isMobileDevice,
}: PrintPDFProps) => {
  const [loader, setLoader] = useState(!isMobileDevice);

  const [scale, setScale] = useState(DEFAULT_PDF_SCALE);

  const { loadNextPageOrDocument, isFetching } = useDocumentsLoader({
    allDocuments,
    pdfDocuments,
    isMobileDevice,
  });

  const handleMobilePDFScroll = useCallback(() => {
    const pageRef = pageContentRef?.current;
    if (!pageRef) return;

    const { scrollTop, scrollHeight, clientHeight } = pageRef;

    const offset = 200;

    if (scrollTop + clientHeight >= scrollHeight - offset && !isFetching) {
      loadNextPageOrDocument?.();
    }
  }, [isFetching, loadNextPageOrDocument]);

  useEffect(() => {
    const pageRef = pageContentRef?.current;
    if (pageRef && isMobileDevice) {
      pageRef.addEventListener('scroll', handleMobilePDFScroll);
      return () => pageRef.removeEventListener('scroll', handleMobilePDFScroll);
    }
  }, [handleMobilePDFScroll, isMobileDevice]);

  const updateScale = (step) => {
    const newScale = scale + step * SCALE_STEP;
    if (newScale < DEFAULT_PDF_SCALE || newScale > MAX_ZOOM_SCALE) {
      return;
    }
    setScale(newScale);
    handleScaleChange?.(newScale);
  };

  const handleDocumentLoad = () => {
    setLoader(false);
  };

  const RenderPage = (page, pageIndex, documentIndex, pages, docZIndex, totalPages?) => {
    const size = page.getSize();

    const key = `pdf-page-${documentIndex}-${pageIndex}`;

    const pageZIndex = docZIndex !== -1 ? pages.length - pageIndex + docZIndex : -1;

    const pageStyle: any = {
      ...(isDynamicView && { zIndex: pageZIndex, position: 'relative' }),
    };

    return (
      <div
        key={key}
        id={key}
        className={classNameDocumentPage}
        ref={(el) => (pdfDocAndPageRefs ? (pdfDocAndPageRefs.current[key] = el) : undefined)}
        style={pageStyle}
      >
        <Page
          key={key}
          pageNumber={isMobileDevice ? 1 : pageIndex + 1}
          renderInteractiveForms={false}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          scale={scale}
          className={pdfPageClassName}
        >
          <div
            className={styles.renderFieldsContainer}
            style={{
              width: size.width * scale,
              height: size.height * scale,
            }}
            key={`page-field-container-${documentIndex}-${pageIndex}`}
          >
            {pageRender({ pageIndex, documentIndex, scale })}
          </div>

          <div className={styles.blackWrapper}>
            <div>{`${pageIndex + 1} of ${
              isMobileDevice ? totalPages || pageIndex + 1 : pages.length
            }`}</div>
          </div>
        </Page>
      </div>
    );
  };

  const renderMobilePDF = () => {
    return (
      <>
        {pdfDocuments.map((doc: any, index) => {
          const pages = doc.pages;
          const docZIndex = pdfDocuments.length - index;
          const docIndex = index;
          return (
            <div
              key={index}
              className={classNames(styles.pdfDocument, classNamePdfDocument)}
              style={isDynamicView ? { zIndex: docZIndex } : {}}
            >
              {pages.map((page, pageIndex) => {
                return (
                  <div
                    key={pageIndex}
                    id={`pdf-doc-${docIndex}`}
                    ref={(el) =>
                      pdfDocRefs
                        ? (pdfDocRefs.current[`pdf-doc-${docIndex}-${pageIndex}`] = el)
                        : undefined
                    }
                    className={classNames(styles.pdfDocument, classNamePdfDocument)}
                    style={isDynamicView ? { zIndex: docZIndex } : {}}
                  >
                    <Document key={`pdf-doc-${docIndex}`} file={page.pageBuffer}>
                      {RenderPage(
                        page.pageItem,
                        pageIndex,
                        docIndex,
                        pages,
                        docZIndex,
                        doc.totalPages,
                      )}
                    </Document>
                  </div>
                );
              })}

              {docIndex === 0 ? children : <></>}
            </div>
          );
        })}

        {isFetching ? (
          <Spinner
            className={classNames({ [styles.pdfSpinner]: pdfDocuments.length > 0 })}
            loaderClassName={classNames({ [styles.loadingSpinner]: pdfDocuments.length > 0 })}
          />
        ) : (
          <></>
        )}

        {widgets}

        {pdfDocuments.length > 0 && (
          <PDFWidgets
            updateScale={updateScale}
            disableZoomIn={scale === MAX_ZOOM_SCALE}
            disableZoomOut={scale === DEFAULT_PDF_SCALE}
            className={isDynamicView ? styles.dynamicViewerZoomWidget : ''}
          />
        )}
      </>
    );
  };

  const pdf = (
    <div className={classNames(styles.pdfContainer, className)}>
      {isMobileDevice ? (
        renderMobilePDF()
      ) : (
        <>
          {pdfDocuments && !pdfLoading ? (
            <>
              {pdfDocuments.map((doc, docIndex) => {
                const pages = (!loader && doc.pdfDoc?.getPages()) || [];

                const docZIndex = pdfDocuments.length - docIndex;

                return (
                  <div
                    key={docIndex}
                    id={`pdf-doc-${docIndex}`}
                    ref={(el) => (pdfDocRefs ? (pdfDocRefs.current[docIndex] = el) : undefined)}
                    className={classNames(styles.pdfDocument, classNamePdfDocument)}
                    style={isDynamicView ? { zIndex: docZIndex } : {}}
                  >
                    <Document
                      key={`pdf-doc-${docIndex}`}
                      file={doc.documentBuffer}
                      onLoadSuccess={handleDocumentLoad}
                    >
                      {pages.map((page, pageIndex) =>
                        RenderPage(page, pageIndex, docIndex, pages, docZIndex),
                      )}

                      {docIndex === 0 ? children : <></>}
                    </Document>
                  </div>
                );
              })}

              {widgets}

              {!loader && (
                <PDFWidgets
                  updateScale={updateScale}
                  disableZoomIn={scale === MAX_ZOOM_SCALE}
                  disableZoomOut={scale === DEFAULT_PDF_SCALE}
                  className={isDynamicView ? styles.dynamicViewerZoomWidget : ''}
                />
              )}
            </>
          ) : (
            <Spinner />
          )}
        </>
      )}
    </div>
  );

  return <>{pdf}</>;
};
