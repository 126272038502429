import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { getCtaData } from 'api/kits';
import { PageNotFound, Spinner } from 'components';
import { routes } from 'settings/navigation/routes';
import classNames from 'classnames';
import { validate as uuidValidate } from 'uuid';
import { useParams } from 'react-router-dom';
import { Footer } from 'components-antd/Footer/Footer';
import { formatPhoneNumber } from 'helpers';
import { useSelector } from 'react-redux';
import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { useHistory } from 'react-router-dom';

type KitCtaParamType = {
  uuid: string;
};

function ClientKitCta() {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [client, setClient] = useState<any>(null);
  const [agent, setAgent] = useState<any>(null);
  const { uuid } = useParams<KitCtaParamType>();
  const messageRedirectUrl = `${routes.clientDashboard}?drawer=message`;
  const { isClient } = useSelector(getUserRolesMapSelector);
  const userId = useSelector(getUserId);
  const history = useHistory();

  const fetchCtaData = async () => {
    try {
      const response = await getCtaData(uuid);
      if (response.status === 200) {
        const { Agent, Client, Kits, ThreadId, MessageId } = response.data.result;
        setClient(Client);
        setAgent(Agent);
        if (ThreadId && MessageId) {
          history.push(`/?threadId=${ThreadId}`);
        }
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userId && !isClient) history.push('/');
    if (!uuidValidate(uuid)) setError(true);
  }, [userId, isClient, uuid]);

  useEffect(() => {
    if (loading) fetchCtaData();
  }, [loading]);

  const getPrimaryPhoneNumber = () => {
    const primary = agent?.Phones.find((phone) => phone.IsPrimary);
    if (primary) return formatPhoneNumber(primary.PhoneNumber);
    return '';
  };

  if (loading) {
    return <Spinner loaderClassName={classNames(styles.loader)} />;
  } else if (error) {
    return (
      <div>
        <PageNotFound backUrl={routes.dashboard} />
      </div>
    );
  } else
    return (
      <div className={styles.cta}>
        <div className={styles.ctaContent}>
          <h1 className={styles.ctaContentHeading}>On it!</h1>
          <div className={styles.ctaContentArea}>
            <p className={classNames(styles.text, styles.bold)}>
              Hi {client?.User?.FirstName || ''},
            </p>
            <p className={classNames(styles.text, styles.bold, styles.marginShort)}>
              I got your note and will follow-up with you shortly.
            </p>
            <p className={styles.text}>{agent.FirstName || ''}</p>
            <p className={classNames(styles.contact)}>{getPrimaryPhoneNumber()}</p>
            <p className={classNames(styles.contact, styles.marginLong)}>{agent.Email || ''}</p>
            <a href={messageRedirectUrl} className={styles.messageButton}>
              Send {agent.FirstName || ''} Another Message
            </a>
          </div>
        </div>
        <div className={styles.ctaFooter}>
          <Footer />
        </div>
      </div>
    );
}

export default ClientKitCta;
