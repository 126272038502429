import React, { Key, useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { Modal, TableColumnsType } from 'antd';
import { ModalClose } from 'components/Icons';
import { Icons } from 'pages/Properties/common/Icons';
import { Checkbox, Input } from 'components-antd';
import tableStyles from '../../components/Table/styles.module.scss';
import parentStyles from '../styles.module.scss';
import classNames from 'classnames';
import { Table } from 'pages/Radar/components';
import { Icons as Icons2 } from 'pages/Workshop/Forms/components/NewFormModal/FormStages/components';
import { Button } from 'components';
import { DropdownMenu } from '../components/DropdownMenu';
import { ChevronDownIcon } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestGetCampaignByIdEffect,
  requestGetPublishedKitsEffect,
  requestUpdateCampaignEffect,
} from 'store/effects/radarKits';
import { getPublishedKitsSelector } from 'store/selectors/radarKits';
import { Campaign, Frequency } from 'store/reducers/radarKits/types';
import { formatFrequency } from '../helpers';
import { SelectedKit } from '../ViewSequence';
import { PreviewKitsModal } from '../PreviewKitsModal';

type DataType = {
  key: string;
  name: string;
  category: string;
  frequency: {
    name: string;
    currentFrequency: Frequency;
    eligibleFrequencies: Frequency[];
  };
  preview: number;
};

const columns = ({ handleCheckbox, selected, onPreview }): TableColumnsType<DataType> => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name: string, record) => (
        <div className={classNames(parentStyles.tableColNameWrapper, styles.cellWrapper)}>
          <div className={styles.multiSelect}>
            <Checkbox
              className={styles.checkbox}
              checked={!!selected.find((item) => item.id === record.key)}
              onChange={(e: any) => {
                e.stopPropagation();
                if (handleCheckbox) {
                  handleCheckbox(
                    record.key,
                    e.target.checked,
                    record.frequency.currentFrequency?.Id,
                  );
                }
              }}
            />
          </div>
          <div className={classNames(styles.detailsWrapper, styles.name)}>{name}</div>
        </div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
      className: tableStyles.tableCol,
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      sorter: (a, b) => a.frequency.name.localeCompare(b.frequency.name),
      className: tableStyles.tableCol,
      render: (value: DataType['frequency'], record: DataType) => {
        return (
          <div className={tableStyles.tableNormalCell}>
            <div>{formatFrequency(value.currentFrequency)}</div>
            <div className={styles.mt}>
              {}{' '}
              <DropdownMenu
                options={value.eligibleFrequencies.map((item) => ({
                  label: item.FrequencyName,
                  id: item.Id.toString(),
                }))}
                onSelect={(id) => handleCheckbox(record.key, true, id)}
                showValue={true}
                currentValue={value.currentFrequency?.Id?.toString()}
                id={record.key}
                btnIcon={<ChevronDownIcon />}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: 'Preview',
      dataIndex: 'preview',
      key: 'preview',
      className: classNames(tableStyles.tableCol, styles.alignRight),
      render: (id: number) => (
        <div className={styles.actionIcons}>
          <Icons2
            variant={'filePreview'}
            onClick={(e) => {
              e.stopPropagation();
              onPreview(id);
            }}
          />
        </div>
      ),
    },
  ];
};

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  title: string;

  sequenceId: string;
  selectedKits: SelectedKit[];
};

export const AddKitsModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  title,
  sequenceId,
  selectedKits: selectedKitsState,
}) => {
  const dispatch = useDispatch();
  const { data: publishedKits } = useSelector(getPublishedKitsSelector);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedKits, setSelectedKits] = useState<{ id: string; frequencyId: string }[]>(
    selectedKitsState.map((item) => ({
      id: item.KitId.toString(),
      frequencyId: item.FrequencyId.toString(),
    })),
  );
  const [isPreviewKitsModalOpen, setIsPreviewKitsModalOpen] = useState<boolean>(false);
  const [previewKitId, setPreviewKitId] = useState<number | null>(null);

  // Fetch Published Kits
  useEffect(() => {
    dispatch(requestGetPublishedKitsEffect({}, { silent: !!publishedKits }));
  }, []);

  const dataSource = ({ searchTerm }): DataType[] => {
    if (!publishedKits) return [];
    return publishedKits
      .map((item) => {
        const currentFrequency = selectedKitsState.find(
          (selected) => selected.KitId.toString() === item.Kits.Id.toString(),
        )?.FrequencyId;
        return {
          key: item.Kits.Id.toString(),
          name: item.Kits.Name,
          category: item.Kits.Category,
          frequency: {
            name: (currentFrequency
              ? item.Kits.EligibleFrequencies.find((freq) => freq.Id === currentFrequency)
                  ?.FrequencyName
              : item.Kits.EligibleFrequencies?.[0]?.FrequencyName) as string,
            currentFrequency: currentFrequency
              ? item.Kits.EligibleFrequencies.find((freq) => freq.Id === currentFrequency) ||
                item.CurrentFrequency
              : item.Kits.EligibleFrequencies?.[0] || item.CurrentFrequency,
            eligibleFrequencies: item.Kits.EligibleFrequencies,
          },
          preview: item.KitId,
        };
      })
      .filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleCheckbox = (key: Key, checked: boolean, frequencyId: string) => {
    if (checked) {
      setSelectedKits((prev) => {
        const filtered = prev.filter((item) => item.id.toString() !== key.toString());
        return [...filtered, { id: key.toString(), frequencyId }];
      });
    } else {
      setSelectedKits((prev) => {
        return prev.filter((item) => item.id !== key.toString());
      });
    }
  };

  const handleClose = () => {
    const selectedKitIds = selectedKits.map((kit) => Number(kit.id));
    const pristineKitIds = selectedKitsState.map((pristineKit) => pristineKit.KitId);
    dispatch(
      requestUpdateCampaignEffect(
        {
          Id: Number(sequenceId),
          kits: selectedKits.map((item) => ({
            kitId: Number(item.id),
            frequencyId: Number(item.frequencyId),
          })),
          deleted: pristineKitIds.filter(
            (pristineKitId) => !selectedKitIds.includes(pristineKitId),
          ),
        },
        {},
        (err, res) => {
          if (!err && res.data.result.Id) {
            closeModal();
          }
        },
      ),
    );
  };
  return (
    <>
      <Modal
        open={isOpen}
        centered
        maskStyle={{
          background: 'rgba(0, 0, 0, 0.60)',
          backdropFilter: 'blur(20px)',
        }}
        closable
        footer={null}
        destroyOnClose
        onCancel={closeModal}
        closeIcon={<ModalClose />}
        title={title}
        wrapClassName={styles.wrapper}
        width={1002}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.inputWrapper}>
              <div className={styles.iconWrapper}>
                <Icons variant={Icons.NEW_SEARCH} />
              </div>
              <div className={styles.inputContainer}>
                <Input
                  className={styles.input}
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className={classNames(styles.content, styles.tableContainer)}>
            <Table
              columns={columns({
                handleCheckbox,
                selected: selectedKits,
                onPreview: (id: number) => {
                  setPreviewKitId(id);
                  setIsPreviewKitsModalOpen(true);
                },
              })}
              data={dataSource({ searchTerm })}
              disableHeader={true}
            />
          </div>
          <div className={styles.btnWrapper}>
            <Button title="Done" className={styles.btn} onClick={handleClose} />
          </div>
        </div>
      </Modal>
      {isPreviewKitsModalOpen && previewKitId && (
        <PreviewKitsModal
          isOpen={isPreviewKitsModalOpen}
          closeModal={() => {
            setIsPreviewKitsModalOpen(false);
            setPreviewKitId(null);
          }}
          kitId={previewKitId}
          title="Preview"
        />
      )}
    </>
  );
};
