import classNames from 'classnames';
import { Dropdown, Menu, MenuItem, Radio } from 'components-antd';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';

import styles from './styles.module.scss';

import { useEffect, useMemo, useState } from 'react';

import { Check } from 'components/Icons';

export const DocumentsPopover = ({ allDocuments, scrolledDocumentId }) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState(
    scrolledDocumentId >= 0 ? scrolledDocumentId : -1,
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (scrolledDocumentId === -1) {
      setSelectedDocumentId(allDocuments[0].Id as number);
    } else if (selectedDocumentId !== scrolledDocumentId) {
      setSelectedDocumentId(scrolledDocumentId as number);
    }
  }, [scrolledDocumentId]);

  const onSelectDocument = (documentItem) => {
    if (documentItem.Id === selectedDocumentId || !allDocuments) return;

    setSelectedDocumentId(documentItem.Id);

    const docIndex = allDocuments.findIndex((item) => item.Id === documentItem.Id);

    const element = document.getElementById(`pdf-doc-${docIndex}`);
    element?.scrollIntoView({
      behavior: 'smooth',
    });

    setOpen(false);
  };

  const menu = (
    <Radio.Group className={styles.optionsItems}>
      <Menu>
        {allDocuments.map((item, idx) => (
          <MenuItem key={idx}>
            <Radio value={item.Id} key={idx} onClick={() => onSelectDocument(item)}>
              <div className={styles.option}>
                <div className={styles.optionIcon}>
                  <Icons variant={ICON_VARIANT_TYPE.Document} />
                </div>
                <div className={styles.optionValue}>{item.FormName}</div>
              </div>
              {item.Id === selectedDocumentId ? <Check className={styles.checkIcon} /> : <></>}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  const formName = useMemo(() => {
    const documentItem = allDocuments.find(({ Id }) => Id === selectedDocumentId);
    return documentItem?.FormName;
  }, [selectedDocumentId, scrolledDocumentId]);

  return (
    <div className={classNames(styles.documentDropdownWrapper)}>
      <Dropdown
        overlay={menu}
        placement="bottom"
        trigger={['click']}
        overlayClassName={styles.morePopover}
        onOpenChange={(visible) => setOpen(visible)}
      >
        <div
          className={classNames(styles.documentPopoverContainer, { [styles.openedPopover]: open })}
        >
          <div className={classNames(styles.itemContent, { [styles.openedDropdown]: open })}>
            <div className={styles.title}>{formName}</div>
            <div className={styles.arrowIcon}>
              <Icons variant={ICON_VARIANT_TYPE.DownArrow} />
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
