/* eslint-disable react/no-unescaped-entities */
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy, get, merge, cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import { PENDING } from 'settings/constants/apiState';
import { convertPrefs, prefsIds, PREF_TYPE } from 'settings/constants/preferences';

import { onBoardingWizardEffect } from 'store/effects/onBoarding';
import { getOnBoardingSelector } from 'store/selectors/onBoarding';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';

import Options from './Options';

import OnBoardingWizard from 'pages/OnBoardingWizard';
import { getWizardStageValue } from 'pages/OnBoardingWizard/helpers';
import { Question } from 'pages/RequestQuote/components';
import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';

import styles from './styles.module.scss';
import { AnswersContainer } from 'pages/OnBoarding/components/AnswersContainer';
import { getClientDefaultSearchSelector } from 'store/selectors/user';
import { addSearchInstance } from 'store/effects/clientInstances';

const StageMustHave = ({ onNext, Controls, stageIndex }) => {
  const dispatch = useDispatch();
  const onBoarding = useSelector(getOnBoardingSelector);
  const signUpRole = useSelector(getSignUpRoleSelector);
  const defaultClientSearch = useSelector(getClientDefaultSearchSelector);
  const { KeywordPrefs: keywordPrefsDefault, CommutePrefs: commutePrefsDefault } =
    defaultClientSearch || {};

  const { amenitiesPrefs, neighborhoodPrefs, parking, homePrefers } = OnBoardingWizard.propNames;

  const [stageValue, setStageValue] = useState([]);
  const [keywords, setKeywords] = useState([]);

  const convertPref = (stored = [], type) => {
    const mustHavePrefs = [];

    stored.forEach(({ Preference, ImportanceAndWeight }) => {
      if (ImportanceAndWeight?.Importance === OnBoardingWizard.importances.must) {
        mustHavePrefs.push({ id: Preference, type });
      }
    });

    return mustHavePrefs;
  };

  useEffect(() => {
    const commonProps = { obj: onBoarding, stageIndex, signUpRole };
    const storedMustHaveAmenities = getWizardStageValue({
      ...commonProps,
      propName: amenitiesPrefs,
    });
    const storedMustHaveNeighborHoods = getWizardStageValue({
      ...commonProps,
      propName: neighborhoodPrefs,
    });
    const storedMustHaveParking = getWizardStageValue({ ...commonProps, propName: parking });
    const storedMustHaveHomePrefs = getWizardStageValue({ ...commonProps, propName: homePrefers });

    const newStageAmenityValue = convertPref(storedMustHaveAmenities, amenitiesPrefs);
    const newStageNeighborHoodsValue = convertPref(storedMustHaveNeighborHoods, neighborhoodPrefs);
    const newStageHomePrefs = convertPref(storedMustHaveHomePrefs, homePrefers);

    const newStageParkingValue = { id: parking, type: parking };

    setKeywords(keywordPrefsDefault || []);

    setStageValue([
      ...newStageAmenityValue,
      ...newStageNeighborHoodsValue,
      ...newStageHomePrefs,
      ...(storedMustHaveParking?.ImportanceAndWeight?.Importance ===
      OnBoardingWizard.importances.must
        ? [newStageParkingValue]
        : []),
    ]);
  }, []); // eslint-disable-line

  const makeObj = (arr) => (arr || []).reduce((acc, { id }) => ({ ...acc, [id]: true }), {});

  const getPref = useCallback(
    (stage, propName) =>
      get(
        stage,
        `meta.Values.[${signUpRole}].SearchInstance.DefaultPropertySearchPreferences.[${propName}]`,
      ),
    [signUpRole],
  );

  const getPreferences = useCallback(() => {
    const clonedWizardStages = cloneDeep(onBoarding.wizard);
    delete clonedWizardStages?.[stageIndex];
    return cloneDeep(
      Object.values(clonedWizardStages || {}).filter(
        (stage) =>
          !!getPref(stage, PREF_TYPE.neighborhoodPrefs) ||
          !!getPref(stage, PREF_TYPE.commutePrefs) ||
          !!getPref(stage, PREF_TYPE.amenitiesPrefs) ||
          !!getPref(stage, prefsIds.parking) ||
          !!getPref(stage, PREF_TYPE.homePrefs) ||
          !!getPref(stage, PREF_TYPE.outdoorPrefs),
      ),
    );
  }, [onBoarding?.wizard, getPref, stageIndex]);

  const getExtractedPrefs = useCallback(
    () =>
      cloneDeep(
        getPreferences().map(
          (preference) =>
            get(
              preference,
              `meta.Values.[${signUpRole}].SearchInstance.DefaultPropertySearchPreferences`,
            ),
          [signUpRole, getPreferences],
        ),
      ),
    [getPreferences, signUpRole],
  );

  const mergePrefs = (prefs, mustPrefs, propName) => {
    const clonedPrefs = cloneDeep(prefs);
    mustPrefs.forEach((mustPref) => {
      const needChangePrefrenceIndex = (clonedPrefs?.[propName] || []).findIndex(
        (amenity) => amenity?.Preference === mustPref.Preference,
      );
      if (needChangePrefrenceIndex !== -1) {
        // eslint-disable-next-line no-param-reassign
        clonedPrefs[propName][needChangePrefrenceIndex].ImportanceAndWeight.Importance =
          mustPref.ImportanceAndWeight.Importance;
      }
    });
    return clonedPrefs[propName];
  };

  const onSave = () => {
    const groupedPrefs = groupBy(stageValue, 'type');

    const mustHaveCommutesIds = stageValue
      ?.filter(({ type }) => type === 'commute')
      ?.map(({ id }) => id);

    const mergedCommutesPrefs = (commutePrefsDefault || [])?.map((commute) => ({
      ...commute,
      ImportanceAndWeight: {
        Importance: mustHaveCommutesIds.includes(commute.Name)
          ? OnBoardingWizard.importances.must
          : OnBoardingWizard.importances.someWhat,
      },
    }));

    const mustHaveAmenitiesPrefs = convertPrefs(
      makeObj(groupedPrefs.AmenitiesPrefs),
      OnBoardingWizard.importances.must,
    );
    const mustHaveNeighborHoodsPrefs = convertPrefs(
      makeObj(groupedPrefs.NeighborhoodPrefs),
      OnBoardingWizard.importances.must,
    );

    const mustHaveHomePrefs = convertPrefs(
      makeObj(groupedPrefs.HomePrefs),
      OnBoardingWizard.importances.must,
    );

    const mustHaveInteriorPrefs = convertPrefs(
      makeObj(groupedPrefs.InteriorStylesPrefs),
      OnBoardingWizard.importances.must,
    );

    const mustHaveOutdoorPrefs = convertPrefs(
      makeObj(groupedPrefs.OutdoorPrefs),
      OnBoardingWizard.importances.must,
    );

    const allPrevPrefs = {};

    getExtractedPrefs().forEach((prefs) => {
      Object.entries(prefs || {}).forEach(([key, value]) => {
        if (value) {
          allPrevPrefs[key] = value;
        }
      });
    });

    const mustHaveParking = groupedPrefs?.Parking
      ? {
          ...allPrevPrefs?.Parking,
          ImportanceAndWeight: {
            Importance: OnBoardingWizard.importances.must,
          },
        }
      : undefined;

    const clonedAllPrevPrefs = cloneDeep(allPrevPrefs);

    const mergedAmenitiesPrefs = mergePrefs(
      clonedAllPrevPrefs,
      mustHaveAmenitiesPrefs,
      PREF_TYPE.amenitiesPrefs,
    );
    const mergedNeighborHoodsPrefs = mergePrefs(
      clonedAllPrevPrefs,
      mustHaveNeighborHoodsPrefs,
      PREF_TYPE.neighborhoodPrefs,
    );
    const mergedHomePrefs = mergePrefs(clonedAllPrevPrefs, mustHaveHomePrefs, PREF_TYPE.homePrefs);

    const mergedInteriorPrefs = mergePrefs(
      clonedAllPrevPrefs,
      mustHaveInteriorPrefs,
      PREF_TYPE.interiorStylesPrefs,
    );

    const mergedParking = merge(clonedAllPrevPrefs?.Parking || {}, mustHaveParking || {});
    const mergedOutdoorPrefs = mergePrefs(
      clonedAllPrevPrefs,
      mustHaveOutdoorPrefs,
      PREF_TYPE.outdoorPrefs,
    );

    const cfg = {
      stageIndex,
      WizardFinished: true,
      DefaultPropertySearchPreferences: {
        ...allPrevPrefs,
        [PREF_TYPE.interiorStylesPrefs]: mergedInteriorPrefs?.length
          ? mergedInteriorPrefs
          : undefined,
        [PREF_TYPE.commutePrefs]: mergedCommutesPrefs?.length ? mergedCommutesPrefs : undefined,
        [PREF_TYPE.keywordPrefs]: keywords?.length ? keywords : undefined,
        [OnBoardingWizard.propNames.amenitiesPrefs]: mergedAmenitiesPrefs?.length
          ? mergedAmenitiesPrefs
          : undefined,
        [OnBoardingWizard.propNames.neighborhoodPrefs]: mergedNeighborHoodsPrefs?.length
          ? mergedNeighborHoodsPrefs
          : undefined,
        [OnBoardingWizard.propNames.parking]: Object.keys(mergedParking)?.length
          ? mergedParking
          : undefined,
        [OnBoardingWizard.propNames.homePrefers]: mergedHomePrefs?.length
          ? mergedHomePrefs
          : undefined,
        [OnBoardingWizard.propNames.outdoorSpacePrefs]: mergedOutdoorPrefs?.length
          ? mergedOutdoorPrefs
          : undefined,
      },
    };

    dispatch(
      onBoardingWizardEffect(cfg, {}, (err) => {
        if (!err) {
          dispatch(addSearchInstance({ data: cfg.DefaultPropertySearchPreferences }));
          onNext();
        }
      }),
    );
  };

  const isPending = onBoarding.state === PENDING;

  return (
    <>
      <Controls onNext={onSave} className={styles.controls} variant="lightFull" />
      <OnBoardingContainer className={styles.middle}>
        <Question className={styles.question}>
          Of the items on your wish list, which are most important to you?
        </Question>
        <AnswersContainer
          isPending={isPending}
          onNext={() => {
            onSave();
          }}
        >
          <Options
            testid="must_have_options"
            onChange={setStageValue}
            value={makeObj(stageValue)}
            preferences={getExtractedPrefs()}
            keywords={keywords}
            setKeywords={setKeywords}
          />
        </AnswersContainer>
      </OnBoardingContainer>
    </>
  );
};

StageMustHave.propTypes = {
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

StageMustHave.defaultProps = {
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default StageMustHave;
