import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Tabs } from 'components-antd';
import { Volume } from './Volume';
import { Clients } from './Clients';
import { Financials } from './Financials';
import { getAgentTeamIdSelector, getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import {
  getAgentTeamStatsSelector,
  getTimePeriodDueFromSelector,
  getTimePeriodDueToSelector,
  getTimePeriodSelector,
  getTransactionFilter,
} from 'store/selectors/clarity';
import { postClarityFinancialData } from 'api/clarity';
import { useFetchFinancialStats, useFetchVolumeStats } from 'pages/ClarityRevamped/hooks';
import { getUserId } from 'store/selectors/user';
import { TEAM_BASIC } from 'settings/constants/roles';
import { useFetchLeadSourceStats } from 'pages/ClarityRevamped/hooks/useFetchLeadSourceStats';
import { GeneralLedgerKeys } from '../Stats/Content/ContentTypes/helper';

import styles from './styles.module.scss';

export const HeaderTabs = () => {
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const timePeriod = useSelector(getTimePeriodSelector);
  const teamId = useSelector(getAgentTeamIdSelector);
  const transactionFilter = useSelector(getTransactionFilter);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const userId = useSelector(getUserId);
  const dueFrom = useSelector(getTimePeriodDueFromSelector);
  const dueTo = useSelector(getTimePeriodDueToSelector);

  const [grossSummary, setGrossSummary] = useState([]);
  const [grossData, setGrossData] = useState([]);
  const [netSummary, setNetSummary] = useState([]);
  const [netData, setNetData] = useState([]);
  const [feesSummary, setFeesSummary] = useState([]);
  const [feesData, setFeesData] = useState([]);
  const [listingsSummary, setListingsSummary] = useState([]);
  const [listingsData, setListingsData] = useState([]);
  const [purchasesSummary, setPurchasesSummary] = useState([]);
  const [purchasesData, setPurchasesData] = useState([]);
  const [closedSummary, setClosedSummary] = useState([]);
  const [closedData, setClosedData] = useState([]);
  const [expiredSummary, setExpiredSummary] = useState([]);
  const [expiredData, setExpiredData] = useState([]);
  const [canceledSummary, setCanceledSummary] = useState([]);
  const [canceledData, setCanceledData] = useState([]);
  const [leadSourceSummary, setLeadSourceSummary] = useState([]);
  const [leadSourceData, setLeadSourceData] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [grossListSummary, setGrossListSummary] = useState([]);
  const [grossListData, setGrossListData] = useState([]);
  const [grossBuySummary, setGrossBuySummary] = useState([]);
  const [grossBuyData, setGrossBuyData] = useState([]);

  const fetchFinancialChartStats = async ({
    aggregationType,
    summaryType,
    getSummary,
    getData,
    ledgerRepresentingSideId = 0,
  }) => {
    const previousYear = moment().subtract(1, 'year').year();
    const { data, status } = await postClarityFinancialData({
      aggregationType: aggregationType,
      summaryType: summaryType,
      teamId,
      ...(!(dueFrom || dueTo) &&
        (timePeriod === previousYear ? { timePeriod: 'YTD', year: previousYear } : { timePeriod })),
      aggregateDataFormat: 'Series',
      ...(agentRole === TEAM_BASIC && { transactionAgentUserId: userId }),
      ...(teamStats.itemValue !== 'Team' && { transactionAgentUserId: teamStats.userId }),
      financialOptions: ['ActualOnly'],
      optionalColumns: [],
      ...((transactionFilter || ledgerRepresentingSideId) && {
        filterByLedgerRepresentingSideId: transactionFilter,
      }),
      ...(dueFrom && { dueFrom: dueFrom.format('MM-DD-YY') }),
      ...(dueTo && { dueTo: dueTo.format('MM-DD-YY') }),
    });
    if (status === 200) {
      getSummary(data.Summary);
      getData(data.Data);
    }
  };

  const getVolumeStats = useFetchVolumeStats();
  const getLeadSourceStats = useFetchLeadSourceStats();
  const getFinancialStats = useFetchFinancialStats();

  const getAllStats = async () => {
    setIsPending(true);

    await Promise.all([
      getVolumeStats({
        setSummary: setListingsSummary,
        setData: setListingsData,
        setIsPending: () => {},
        aggregateDataFormat: 'Series',
        summaryType: 'Listings',
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
      }),

      getVolumeStats({
        setSummary: setPurchasesSummary,
        setData: setPurchasesData,
        setIsPending: () => {},
        aggregateDataFormat: 'Series',
        summaryType: 'Purchases',
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
      }),

      getVolumeStats({
        setSummary: setClosedSummary,
        setData: setClosedData,
        setIsPending: () => {},
        aggregateDataFormat: 'Series',
        summaryType: 'Closed',
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
      }),

      getVolumeStats({
        setSummary: setExpiredSummary,
        setData: setExpiredData,
        setIsPending: () => {},
        aggregateDataFormat: 'Series',
        summaryType: 'Expired',
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
      }),

      getVolumeStats({
        setSummary: setCanceledSummary,
        setData: setCanceledData,
        setIsPending: () => {},
        aggregateDataFormat: 'Series',
        summaryType: 'Canceled',
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
      }),

      getLeadSourceStats({
        setSummary: setLeadSourceSummary,
        setData: setLeadSourceData,
        setIsPending: () => {},
        aggregateDataFormat: 'Series',
        year: null,
        quarter: null,
        month: null,
        isoWeek: null,
        date: null,
      }),

      fetchFinancialChartStats({
        aggregationType: 'Gross',
        summaryType: 'CommissionSplits',
        getSummary: setGrossSummary,
        getData: setGrossData,
      }),

      fetchFinancialChartStats({
        aggregationType: 'Net',
        summaryType: 'CommissionSplits',
        getSummary: setNetSummary,
        getData: setNetData,
      }),

      fetchFinancialChartStats({
        aggregationType: 'Net',
        summaryType: 'FeeSummary',
        getSummary: setFeesSummary,
        getData: setFeesData,
      }),

      getFinancialStats({
        setSummary: setGrossListSummary,
        setData: setGrossListData,
        setIsPending: () => {},
        key: GeneralLedgerKeys.GCI,
        aggregationType: 'Gross',
        summaryType: 'Commission',
        aggregateDataFormat: 'Series',
        representingSide: 1,
        isCommission: true,
      }),

      getFinancialStats({
        setSummary: setGrossBuySummary,
        setData: setGrossBuyData,
        setIsPending: () => {},
        key: GeneralLedgerKeys.GCI,
        aggregationType: 'Gross',
        summaryType: 'Commission',
        aggregateDataFormat: 'Series',
        representingSide: 2,
        isCommission: true,
      }),
    ]).then(() => setIsPending(false));
  };

  useEffect(() => {
    if (teamId) {
      getAllStats();
    }
  }, [teamStats, timePeriod, teamId, transactionFilter, dueFrom, dueTo]);

  const tabItems = [
    {
      key: '1',
      label: 'Volume',
      children: (
        <Volume
          listingsSummary={listingsSummary}
          listingsData={listingsData}
          purchasesSummary={purchasesSummary}
          purchasesData={purchasesData}
          closedSummary={closedSummary}
          closedData={closedData}
          expiredSummary={expiredSummary}
          expiredData={expiredData}
          canceledSummary={canceledSummary}
          canceledData={canceledData}
          leadSourceSummary={leadSourceSummary}
          leadSourceData={leadSourceData}
          isPending={isPending}
        />
      ),
    },
    // {
    //   key: '2',
    //   label: 'Clients',
    //   children: <Clients />,
    //   disabled: true,
    // },
    {
      key: '2',
      label: 'Financials',
      children: (
        <Financials
          grossSummary={grossSummary}
          grossData={grossData}
          netSummary={netSummary}
          netData={netData}
          feesSummary={feesSummary}
          feesData={feesData}
          grossListSummary={grossListSummary}
          grossListData={grossListData}
          grossBuySummary={grossBuySummary}
          grossBuyData={grossBuyData}
          isPending={isPending}
        />
      ),
    },
  ];

  return <Tabs className={styles.headerTabs} defaultActiveKey="1" items={tabItems} />;
};
