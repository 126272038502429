import { Fragment, useEffect, useState } from 'react';

import { AccessLevel, AccessLevelLabel } from 'app-constants/enums/permissionSettings';
import { ConfirmationModal } from 'components-antd';

import { DynamicDropdown } from '../../../DynamicDropdown';

import styles from './style.module.scss';

type AccessLevelType = {
  TransactionAccess: boolean;
  TransactionRole?: { Title: string; Id: string };
  Id: number;
  Name: string;
  onUpdateDetails: (details, cb) => void;
  loading;
};

export const AccessLevelView = ({
  TransactionAccess,
  TransactionRole,
  Name,
  onUpdateDetails,
  loading,
}: AccessLevelType) => {
  const defaultAccessLevel = TransactionAccess ? AccessLevel.FullAccess : AccessLevel.LimitedAccess;
  const [accessLevel, setAccessLevel] = useState(defaultAccessLevel);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  useEffect(() => {
    setAccessLevel(TransactionAccess ? AccessLevel.FullAccess : AccessLevel.LimitedAccess);
  }, [TransactionAccess]);

  const onModifyAccessLevel = () => {
    onUpdateDetails(
      {
        transactionAccess: accessLevel === AccessLevel.FullAccess,
        transactionRoleId: TransactionRole?.Id,
      },
      () => {
        setConfirmationOpen(false);
      },
    );
  };

  const onCancel = () => {
    setConfirmationOpen(false);
  };

  return (
    <Fragment>
      <DynamicDropdown
        label={AccessLevelLabel[defaultAccessLevel]}
        value={defaultAccessLevel} // Will be changed to saved value
        setValue={(value) => {
          setAccessLevel(value as AccessLevel);
          setConfirmationOpen(true);
        }}
        options={Object.keys(AccessLevel).map((item) => ({
          label: AccessLevelLabel[item],
          value: item,
        }))}
        disabled={!TransactionRole}
      />

      <ConfirmationModal
        width={430}
        className={styles.accessLevelModal}
        confirmText={`Are you sure you want to grant ${Name} ${
          accessLevel === AccessLevel.FullAccess ? "'Full Access'" : "'Limited Access'"
        } to transaction?`}
        open={confirmationOpen}
        onOk={onModifyAccessLevel}
        onCancel={onCancel}
        closable={false}
        confirmLoading={loading}
      />
    </Fragment>
  );
};
