import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { RadioChangeEvent } from 'antd';
import { Footer } from 'components-antd';
import { LocalHeader } from './components/LocalHeader';
import { Content } from './components/Content';
import { requestGetSymphonyListEffect } from 'store/effects';
import { getStateAndDataFlag, getSymphonyListDataArray } from 'store/selectors/symphony';
import { AgentStatus } from 'types';

import styles from './styles.module.scss';

export const Symphony = () => {
  const dispatch = useDispatch();
  const { isIdle, isPending } = useSelector(getStateAndDataFlag);
  const data = useSelector(getSymphonyListDataArray);
  const [tableData, setTableData] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState(AgentStatus.All);

  const fetchTeamList = () => dispatch(requestGetSymphonyListEffect({}, { silent: !isIdle }));

  useEffect(() => {
    fetchTeamList();
  }, []);

  useEffect(() => {
    data.length && setTableData(data);
  }, [data]);

  const onChange = (e: RadioChangeEvent) => {
    const filter = e.target.value;
    setFilterValue(filter);
    const filteredEvents = data.filter((item) => {
      const status = item.IsActive;
      if (filter === AgentStatus.All) {
        return true;
      }
      if (filter === AgentStatus.Active) {
        return !!status;
      }
      if (filter === AgentStatus.Inactive) {
        return !status;
      }
    });
    setTableData(filteredEvents);
  };

  return (
    <div className={styles.symphonyPage}>
      <LocalHeader onChange={onChange} activeValue={filterValue} isPending={isPending} />
      <Content data={tableData} isPending={isPending} />
      <Footer className={styles.footer} />
    </div>
  );
};
