import { FormBundleDocument } from 'types';
import { dynamicManager } from '../../DynamicManager';
import { sortDocuments } from '../../helper';

export const MOBILE_WIDTH = 768;

export const getAllDocumentsWithPages = (documents) => {
  const formMeta = dynamicManager.getFormMeta();
  let allDocumentsWithPages: FormBundleDocument[] = [];

  const bundleDocuments = dynamicManager.getBundleDocumentsDetails();
  const dynamicDocuments = dynamicManager.getDynamicDocuments();

  if (bundleDocuments.length) {
    allDocumentsWithPages = dynamicDocuments.map((docItem, index) => ({
      ...docItem,
      totalPages: bundleDocuments[index]?.meta.totalPages,
    }));
  } else {
    const allDocuments = sortDocuments(documents, 'Order');

    allDocumentsWithPages = [
      {
        ...allDocuments[0],
        ...(formMeta && { totalPages: formMeta.totalPages }),
      },
    ];
  }

  return allDocumentsWithPages;
};

export const calculateTotalPages = (documents) => {
  return documents?.reduce((count, doc) => count + doc.totalPages, 0) || 0;
};
