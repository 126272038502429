import { MembersTypePopover } from '../MembersTypePopover';
import styles from './style.module.scss';
import { Input } from 'components-antd';
import { SearchGlass } from 'components/Icons';

export const AgentMemberSearchFilter = ({
  searchValue,
  setSearchValue,
  memberType,
  setMemberType,
}) => {
  return (
    <div className={styles.memberSearchDropdownWrapper}>
      <div className={styles.searchWrapper}>
        <Input
          size="large"
          value={searchValue}
          onChange={(event) => {
            const { value } = event.target;

            if (!searchValue && value.trim().length) {
              setSearchValue(value);
            } else if (searchValue) {
              setSearchValue(value);
            }
          }}
          className={styles.inputWrap}
          placeholder="Search by name..."
          prefix={<SearchGlass color="#AAABAB" className={styles.icon} />}
          allowClear={true}
        />
      </div>

      <MembersTypePopover selectedType={memberType} setSelectedType={setMemberType} />
    </div>
  );
};
