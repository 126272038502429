import React from 'react';
import { FinancialsTableModal } from './Financial';
import { VolumeTableModal } from './Volume';

type TableModalProps = {
  title: string;
  summaryType: string;
  clarityType: string;
  open: boolean;
  onCancel: any;
  aggregationType?: string;
  summaryKey?: string;
  columnType?: string;
  representingSide?: number | null;
  isCommission?: boolean;
  ledgerAccountId?: number;
  memo?: string;
  selectedAgent?: string;
  year?: number | null;
  month?: number | null;
  isoWeek?: number | null;
  date?: string | null;
};

export const TableModal: React.FC<TableModalProps> = ({
  title,
  aggregationType,
  summaryType,
  summaryKey,
  columnType,
  clarityType,
  open,
  onCancel,
  ledgerAccountId,
  memo,
  selectedAgent,
  representingSide,
  isCommission,
  year = null,
  month = null,
  isoWeek = null,
  date = null,
}) => {
  const getClarityTableModal = () => {
    if (clarityType === 'Financials') {
      return (
        <FinancialsTableModal
          title={title}
          aggregationType={aggregationType}
          summaryType={summaryType}
          summaryKey={summaryKey}
          columnType={columnType}
          ledgerAccountId={ledgerAccountId}
          memo={memo}
          year={year}
          month={month}
          isoWeek={isoWeek}
          date={date}
          open={open}
          onCancel={onCancel}
          selectedAgent={selectedAgent}
          representingSide={representingSide}
          isCommission={isCommission}
        />
      );
    } else {
      return (
        <VolumeTableModal
          title={title}
          summaryType={summaryType}
          year={year}
          month={month}
          isoWeek={isoWeek}
          date={date}
          open={open}
          onCancel={onCancel}
          selectedAgent={selectedAgent}
        />
      );
    }
  };

  return <>{getClarityTableModal()}</>;
};
