/* eslint-disable max-len */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GraphemeSplitter from 'grapheme-splitter';

import { emojiRegex } from 'helpers/regex';
import { convertNameToAvatarPlaceholder } from 'helpers/formatters';

import Avatar from './Avatar';
import MessageActions from './MessageActions';
import AttachmentsPreview from './AttachmentsPreview';
import ReadTick, { TickType } from '../components/ReadTick';
import { getOpenedChatThreadInfo } from 'store/selectors/sockets/threads';
import styles from './styles.module.scss';
import { SOCKET_MESSAGE_TYPES } from 'settings/constants/sockets';
import { Link, useHistory } from 'react-router-dom';
import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { link } from 'settings/navigation/link';
import { useDispatch, useSelector } from 'react-redux';
import { openMessagesDrawerAction } from 'store/actions/drawers/messages';
import { Tooltip } from 'components-antd';
import { requestGetClientsListEffect } from 'store/effects/clientsList';
import { getClientDetailsEffect } from 'store/effects/clientDetail';
import { getUserId, getUserRoleSelector } from 'store/selectors/user';
import { setProfileExpandedSection } from 'store/effects';
import { openThreadContactsDrawerAction } from 'store/actions/drawers/threadContacts';
import { DRAWER_THREAD_CONTACTS_TYPES } from 'settings/constants/drawers';
import moment from 'moment';

const splitter = new GraphemeSplitter();

const Message = (props) => {
  const {
    className,
    messageClassName,
    Id,
    AvatarUrl,
    Text,
    IsOwnMessage,
    IsDeleted,
    IsEdited,
    MessageReceipts,
    MessageType,
    MessageMeta,
    CreatedDate,
  } = props;
  const { Attachments, groupedTime, onEdit, onDelete, currentEditMessage, FirstName, LastName } =
    props;
  const isMessageSavedOnServer = Id !== undefined;
  const isEditMessage = isMessageSavedOnServer && currentEditMessage?.Id === Id;
  const { isGroupChat } = useSelector(getOpenedChatThreadInfo);
  const userRole = useSelector(getUserRoleSelector);
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();
  const history = useHistory();
  const getName = useCallback(() => {
    if (IsOwnMessage) {
      return (
        <span testid="author_name" className={styles.name}>
          Me
        </span>
      );
    }

    if (!FirstName) return null;

    return (
      <span testid="author_name" className={styles.name}>
        {FirstName} {LastName}
      </span>
    );
  }, [FirstName, LastName, IsOwnMessage]);

  const onTaggedUserClick = (tag) => {
    const isClient = tag.Role === 'Client';
    const isViewerAgent = userRole === 'Agent';
    const isViewerClient = userRole === 'Client';

    if (isClient && isViewerAgent) {
      dispatch(openMessagesDrawerAction(false));
      history.push(`/client-profile/${tag.ContactUserId}`);
    } else if (userId !== tag.ContactUserId) {
      dispatch(
        openThreadContactsDrawerAction({
          params: {
            // blocking client from viewing another client's profile.
            contactId: isViewerClient && isClient ? undefined : tag.ContactUserId,
            isTransactionMessages: false,
            isSingleContact: true,
          },
          open: true,
          type: DRAWER_THREAD_CONTACTS_TYPES.CONTACT,
        }),
      );
    }
  };

  const onTaggedTransactionClick = (tag) => {
    dispatch(openCommentsDrawerEffect({ open: false, params: {} }));
    dispatch(openMessagesDrawerAction(false));

    history.push(link.toTransactionOverview(tag.SourceId));
  };

  const getTagMarkup = (tag) => {
    let tagText = '';
    let tagNode = <></>;
    if (tag.Type === 'Property') {
      tagText = `@${tag.Address.Line1}`;

      tagNode = (
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          title={tag?.Address?.Line1 || 'Private Address'}
          placement="top"
        >
          <span onClick={() => onTaggedTransactionClick(tag)} className={styles.tagHyperLink}>{`@${
            tag?.Address?.Line1 || 'Private Address'
          }`}</span>
        </Tooltip>
      );
    } else if (tag.Type === 'Contact') {
      tagText = `@${tag.FirstName} ${tag.LastName}`;

      tagNode = (
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          title={`${tag.FirstName} ${tag.LastName} (${tag.Role})`}
          placement="top"
        >
          <span
            onClick={() => onTaggedUserClick(tag)}
            className={styles.tagHyperLink}
          >{`@${tag.FirstName} ${tag.LastName}`}</span>
        </Tooltip>
      );
    }

    return [tagText, tagNode];
  };

  const getText = () => {
    const textParts = [];
    let curserPosition = 0;

    MessageMeta?.Tags?.forEach((tag, index) => {
      let [tagText, tagNode] = getTagMarkup(tag);

      const tagPosition = Text.indexOf(tagText);

      if (tagPosition > -1) {
        textParts.push(Text.slice(curserPosition, tagPosition));
        curserPosition = tagPosition;
        textParts.push(tagNode);
        curserPosition = curserPosition + tagText.length;

        if (MessageMeta?.Tags.length - 1 === index) {
          textParts.push(Text.slice(curserPosition));
        }
      } else {
        textParts.push(Text.slice(curserPosition));
      }
    });

    /*
      Note: Following block will be used if we want to process smileys

      let messageText = [];
      if (textParts.length) {
        textParts.forEach((part) => {
          if (typeof part === 'string' && part !== '') {
            messageText = [...messageText, ...processSmileys(part)];
          } else {
            messageText.push(part);
          }
        });
      } else {
        messageText = [...processSmileys(Text)];
      }
    **/

    return textParts.length ? textParts : Text;
  };

  const processSmileys = (text) => {
    const graphemes = splitter.splitGraphemes(text);

    let isEmoji = false;
    const newText = graphemes.map((grapheme, index) => {
      const matches = emojiRegex.exec(grapheme);
      if (matches) {
        isEmoji = !isEmoji ? true : isEmoji;
        // eslint-disable-next-line react/no-array-index-key
        return (
          <span className={styles.emoji} key={index}>
            {grapheme}
          </span>
        );
      }
      return grapheme;
    });

    return newText;
  };

  const getReadReceipt = () => {
    if (!IsOwnMessage) {
      return;
    }

    const readByAll = MessageReceipts?.every((receipt) => receipt.IsRead);
    if (readByAll) {
      return <ReadTick tickType={TickType.READ} />;
    } else {
      return <ReadTick tickType={TickType.SENT} />;
    }
  };

  const isPropertyComment = () => MessageType === SOCKET_MESSAGE_TYPES.PROPERTY_COMMENT;

  const getKitPropertyAddress = () => {
    if (MessageMeta?.Tags?.length && MessageMeta?.Tags[0].Type === 'KIT')
      return (
        <sub className={styles.propertyHolder}>
          Property Address:{' '}
          <Link
            onClick={() => {
              if (MessageMeta?.EntityId) {
                dispatch(openCommentsDrawerEffect({ open: false, params: {} }));
                dispatch(openMessagesDrawerAction(false));
              }
            }}
            className={styles.photos}
            to={MessageMeta?.EntityId ? link.toFeedListingDetails(MessageMeta?.EntityId) : '#'}
          >
            {MessageMeta?.Tags[0]?.Address ? MessageMeta?.Tags[0]?.Address : 'Private Address'}
          </Link>
        </sub>
      );
    return <></>;
  };

  const getPropertyLink = () => {
    if (Array.isArray(MessageMeta)) {
      return (
        <div className={classNames(styles.propertyWrapper, { [styles.rightAlign]: IsOwnMessage })}>
          <sub className={styles.propertyHolder}>Property Comment: </sub>
          {MessageMeta.map((meta) => (
            <sub className={styles.propertyHolder}>
              <Link
                onClick={() => {
                  dispatch(openCommentsDrawerEffect({ open: false, params: {} }));
                  dispatch(openMessagesDrawerAction(false));
                }}
                className={styles.photos}
                to={link.toFeedListingDetails(meta?.EntityId)}
              >
                {(meta?.EntityId && meta?.EntityData?.PropertyAddress?.Line1) || 'Private Address'}
              </Link>
              ,{' '}
            </sub>
          ))}
        </div>
      );
    }
    return (
      <sub className={styles.propertyHolder}>
        Property Comment:{' '}
        <Link
          onClick={() => {
            dispatch(openCommentsDrawerEffect({ open: false, params: {} }));
            dispatch(openMessagesDrawerAction(false));
          }}
          className={styles.photos}
          to={link.toFeedListingDetails(MessageMeta?.EntityId)}
        >
          {(MessageMeta?.EntityId && MessageMeta?.EntityData?.PropertyAddress?.Line1) ||
            'Private Address'}
        </Link>
      </sub>
    );
  };

  const isFileTypeExists = (attachments) => {
    // find the object in attachments array which has ContentType includes 'application'
    return attachments.some((attachment) => attachment.ContentType.includes('application'));
  };

  return (
    <div
      testid="message_item"
      className={classNames(
        styles.messageWrapper,
        {
          [styles.isEditMessage]:
            isEditMessage || (!isMessageSavedOnServer && !!Attachments?.length),
        },
        { [styles.isOwnMessage]: IsOwnMessage },
        className,
      )}
    >
      {!!Attachments?.length && (
        <div className={classNames(styles.attachmentHolder)}>
          <Avatar
            placeholder={convertNameToAvatarPlaceholder(`${FirstName} ${LastName}`)}
            className={styles.avatarWrapper}
            avatarClassName={classNames(styles.avatar, { [styles.hideAvatar]: !!Text })}
            src={AvatarUrl}
            show={!IsOwnMessage}
          />
          <div
            testid="attachment"
            className={classNames(styles.attachmentOuter, {
              [styles.withActions]: isMessageSavedOnServer,
            })}
          >
            {isPropertyComment() && getPropertyLink()}
            {getKitPropertyAddress()}
            <div className={classNames(styles.attachmentInner)}>
              <MessageActions
                className={classNames(styles.actions, {
                  [styles.noAction]: !isMessageSavedOnServer,
                })}
                createdDate={CreatedDate}
                isOwnMessage={IsOwnMessage}
                onDeleteClick={() => onDelete(Id)}
                onEditClick={() => onEdit(Id)}
              />
              <div className={styles.attachmentInner}>
                <AttachmentsPreview
                  isOwnMessage={IsOwnMessage}
                  attachments={Attachments}
                  isMessageSavedOnServer={isMessageSavedOnServer}
                  className={isFileTypeExists(Attachments) ? styles.attachedFilePreview : ''}
                />
                {!Text && (
                  <div
                    className={classNames(styles.time, styles.attachmentTime, {
                      [styles.attachmentFileTime]: isFileTypeExists(Attachments),
                    })}
                  >
                    <span testid="message_time">{groupedTime}</span>
                    {IsEdited ? (
                      <span className={styles.editedMessageIndicator}>{'(Edited)'}</span>
                    ) : (
                      <></>
                    )}
                    {getReadReceipt()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {Text && (
        <div className={classNames(styles.messageHolder)}>
          {isGroupChat ? (
            <Avatar
              placeholder={convertNameToAvatarPlaceholder(`${FirstName} ${LastName}`)}
              className={styles.avatarWrapper}
              avatarClassName={styles.avatar}
              src={AvatarUrl}
              show={!IsOwnMessage}
            />
          ) : null}
          <div
            className={classNames(styles.messageOuter, {
              [styles.withActions]: isMessageSavedOnServer,
            })}
          >
            {isPropertyComment() && getPropertyLink()}
            {getKitPropertyAddress()}
            <div className={styles.messageOuterHolder}>
              <MessageActions
                className={classNames(styles.actions, {
                  [styles.noAction]: !isMessageSavedOnServer,
                })}
                isOwnMessage={IsOwnMessage}
                createdDate={CreatedDate}
                onDeleteClick={() => onDelete(Id)}
                onEditClick={() => onEdit(Id)}
              />
              <div className={classNames(styles.messageInner)}>
                <div className={classNames(styles.message, messageClassName)}>
                  {isGroupChat && !IsOwnMessage ? (
                    <p className={styles.participant}>
                      {FirstName} {LastName}
                    </p>
                  ) : null}
                  <p className={styles.messageText} testid="message_text">
                    {getText()}
                    <span
                      style={{
                        opacity: 0,
                        width: IsEdited ? '120px' : '100px',
                        display: 'inline-block',
                      }}
                    ></span>
                  </p>
                  <span className={classNames(styles.time)}>
                    <span testid="message_time" className={styles.groupedTime}>
                      {groupedTime}
                    </span>
                    {IsEdited ? (
                      <span className={styles.editedMessageIndicator}>{'(Edited)'}</span>
                    ) : (
                      <></>
                    )}
                    {getReadReceipt()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Message.propTypes = {
  Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  messageClassName: PropTypes.string,
  AvatarUrl: PropTypes.string,
  Text: PropTypes.string.isRequired,
  IsOwnMessage: PropTypes.bool,
  Attachments: PropTypes.arrayOf(PropTypes.shape({})),
  groupedTime: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  currentEditMessage: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  IsDeleted: PropTypes.bool,
};

Message.defaultProps = {
  Id: undefined,
  className: '',
  messageClassName: '',
  AvatarUrl: null,
  IsOwnMessage: false,
  Attachments: [],
  groupedTime: '',
  onEdit: () => {},
  onDelete: () => {},
  currentEditMessage: null,
  FirstName: '',
  LastName: '',
  IsDeleted: false,
};

export default Message;
