import { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from 'settings/navigation/routes';
import { link } from 'settings/navigation/link';
import {
  ReasonForCancellation,
  AdditionalComments,
  UploadFiles,
  CancellationComplete,
} from '../TransactionCreate/components/PreForm/Questions';
import {
  TransactionUserRole,
  progressForTransactionCancellation,
  transactionPreFormQuestionsIds,
} from 'settings/constants/transaction';
import {
  getTasksForCancelledTransactionFrEffect,
  getTransactionRolesEffect,
  moveToActiveListingEffect,
  setTransactionPreFormQuestionsEffect,
  uploadTransactionDocumentsEffect,
} from 'store/effects/transactions';
import { resetTransactionAction } from 'store/actions/transactions';
import { Controls } from './Controls';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { transactionStatuses } from 'settings/constants/transaction';
import { updateTransactionStatusEffect } from 'store/effects/transactions';
import { Progress } from 'components-antd';
import styles from './styles.module.scss';
import { ContentWrapper } from '../TransactionCreate/components';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { CancellationTasks } from '../TransactionClose/components/CancellationTasks';
import MoveTransactionToListing from '../TransactionCreate/components/PreForm/Questions/MoveTransactionToListing';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { getTransactionSelector } from 'store/selectors/transaction';
import { uploadTransactionDocuments } from 'api/transactions/documents/create';
import { Spinner } from 'components';
import { requestCreateUpdateOfferEffect } from 'store/effects';
import { requestCreateUpdateShowingEffect } from 'store/effects/showings';
import { TransactionStatus } from 'types';

let OfferLink: any = undefined;
let Showing: any = undefined;

export const TransactionCancellation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const [stage, setStage] = useState(transactionPreFormQuestionsIds.reasonForCancellation);

  const { preForm } = useSelector(getTransactionPreFormSelector);
  const isProject = useIsProjectRoute();
  const [loading, setLoading] = useState(false);
  const [initialStatus, setInitialStatus] = useState<TransactionStatus | null>(null);

  const stayOnPageOrOpenEmailVerificationModal = useEmailVerificationWall(
    ActionToInterrupt.CREATE_SHOWING,
    () => {},
  );
  const { transaction } = useSelector(getTransactionSelector);

  const showActiveListing = useMemo(
    () =>
      initialStatus
        ? initialStatus !== transactionStatuses.UnderContract
        : (transaction?.Status === transactionStatuses.UnderContract ||
            transaction?.Status === transactionStatuses.ClearToClose) &&
          ((transaction.RepresentingRoles.length == 1 &&
            transaction.RepresentingRoles[0] === TransactionUserRole.Seller) ||
            transaction?.OriginalDualTransaction),
    [initialStatus, transaction],
  );

  useEffect(() => {
    stayOnPageOrOpenEmailVerificationModal();
  });

  useEffect(() => {
    if (!OfferLink && transaction.OfferLink) {
      OfferLink = transaction.OfferLink;
    }

    if (!Showing && transaction.Showing) {
      Showing = transaction.Showing;
    }

    dispatch(getTasksForCancelledTransactionFrEffect({ id: params.id }));

    localStorage.removeItem('cancelledPendingTasks');

    return () => {
      OfferLink = undefined;
      Showing = undefined;
      localStorage.removeItem('cancelledPendingTasks');
    };
  }, []);

  const onSubmit = () => {
    if (isProject) history.push(routes.projects);
    else history.push(routes.transactions);
  };

  const onClose = () => {
    if (isProject) history.push(link.toProjectOverview(String(params?.id)));
    else history.push(link.toTransactionOverview(String(params?.id)));
  };

  const disableOfferLink = () => {
    if (OfferLink?.EnableLink) {
      const offerLinkAPIParams = {
        ...OfferLink,
        EnableLink: false,
        Id: +OfferLink.Id,
      };

      dispatch(requestCreateUpdateOfferEffect(offerLinkAPIParams, {}, undefined));
    }
  };

  const disableShowingLink = () => {
    if (Showing?.EnableShowingLink) {
      const showingLinkAPIParams = {
        ...Showing,
        EnableShowingLink: false,
        Id: +Showing.Id,
      };

      dispatch(requestCreateUpdateShowingEffect(showingLinkAPIParams, {}, undefined));
    }
  };

  const updateTransactionStatus = () => {
    setLoading(true);
    dispatch(
      uploadTransactionDocumentsEffect(
        { documents: preForm[transactionPreFormQuestionsIds.files] },
        {},
        (err) => {
          if (!err) {
            dispatch(
              setTransactionPreFormQuestionsEffect({
                [transactionPreFormQuestionsIds.files]: [],
              }),
            );
            if (!showActiveListing) {
              dispatch(
                updateTransactionStatusEffect(
                  { id: params.id, status: transactionStatuses.Canceled },
                  {},
                  (err) => {
                    if (!err) {
                      disableOfferLink();
                      disableShowingLink();
                      setLoading(false);
                      setStage(transactionPreFormQuestionsIds.cancellationComplete);
                    } else {
                      setLoading(false);
                    }
                  },
                ),
              );
            } else {
              setLoading(false);
              setStage(transactionPreFormQuestionsIds.moveToActiveListing);
            }
          } else {
            setLoading(false);
          }
        },
      ),
    );
  };

  const moveToActiveListing = () => {
    const moveToActiveListing = preForm[transactionPreFormQuestionsIds.moveToActiveListing];
    dispatch(
      moveToActiveListingEffect(
        { id: params.id, moveToActiveListing: moveToActiveListing === 'yes' },
        {},
        (err) => {
          if (!err) {
            setStage(transactionPreFormQuestionsIds.cancellationComplete);
          }
        },
      ),
    );
  };

  const calculateProgress = () => {
    return progressForTransactionCancellation[stage] || 30;
  };

  useEffect(() => {
    if (!initialStatus) setInitialStatus(transaction?.InitialStatus);
    dispatch(resetTransactionAction());
    dispatch(getTransactionRolesEffect());
    dispatch(
      setTransactionPreFormQuestionsEffect({
        [transactionPreFormQuestionsIds.files]: [],
      }),
    );
    return () => onClose();
  }, []);

  const renderStage = () => {
    switch (stage) {
      case transactionPreFormQuestionsIds.additionalComments:
        return (
          <AdditionalComments
            transactionId={params.id}
            currentQuestionId={transactionPreFormQuestionsIds.additionalComments}
            onNext={() => setStage(transactionPreFormQuestionsIds.triggeredTasks)}
          />
        );
      case transactionPreFormQuestionsIds.triggeredTasks:
        return (
          <CancellationTasks nextStage={() => setStage(transactionPreFormQuestionsIds.files)} />
        );
      case transactionPreFormQuestionsIds.files:
        return (
          <UploadFiles
            question="Please upload any cancellation documents."
            isAssignTask={false}
            currentQuestionId={transactionPreFormQuestionsIds.files}
            onPreFormSubmit={() => {
              updateTransactionStatus();
            }}
          />
        );
      case transactionPreFormQuestionsIds.moveToActiveListing:
        return <MoveTransactionToListing moveToActiveListing={() => moveToActiveListing()} />;
      case transactionPreFormQuestionsIds.cancellationComplete:
        return <CancellationComplete onSubmit={onSubmit} />;
      default:
        return (
          <ReasonForCancellation
            transactionId={params.id}
            currentQuestionId={transactionPreFormQuestionsIds.reasonForCancellation}
            onNext={() => setStage(transactionPreFormQuestionsIds.additionalComments)}
          />
        );
    }
  };

  const isControlsEnabled = () => {
    return !(stage === transactionPreFormQuestionsIds.cancellationComplete);
  };

  return (
    <>
      <Progress percent={calculateProgress()} />
      <div className={styles.transactionCancel}>
        {isControlsEnabled() ? (
          <div className={styles.head}>
            <Controls onClose={onClose} stage={stage} setStage={setStage} />
          </div>
        ) : null}
        <ContentWrapper className={isControlsEnabled() ? null : styles.cancellationComplete}>
          {loading ? <Spinner /> : renderStage()}
        </ContentWrapper>
      </div>
    </>
  );
};
