import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { priceConverter } from 'helpers';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const PieChart = ({
  data,
  numberTransactions,
  categories,
  onClickDataPoint,
  className,
  colors,
}) => {
  const chartOptions: ApexOptions = {
    chart: {
      events: {
        click: (event, chartContext, config) => {
          onClickDataPoint(config.dataPointIndex);
        },
      },
    },
    tooltip: {
      custom: function ({ seriesIndex }) {
        const total = data[seriesIndex];
        const numberTransaction = numberTransactions[seriesIndex];
        const category = categories[seriesIndex];

        return (
          '<div style="width: 172px; height: 79px; background: #FFF;">' +
          '<div style="width: 172px; height: 79px; border-radius: 16px; background: #FFF; padding: 15px 16px;">' +
          '<div style="display: flex; margin-bottom: 11px;">' +
          `<span style="color: #262626; font-feature-settings: 'liga' off, 'clig' off; font-family: Inter; font-size: 18px; font-style: normal; font-weight: 600; line-height: 20px; letter-spacing: -0.18px;">${priceConverter(
            total,
          )}</span>` +
          `<span style="color: #515151; margin-left: 2px; margin-top: 1px; font-feature-settings: 'liga' off, 'clig' off; font-family: Inter; font-size: 12px; font-style: normal; font-weight: 600; line-height: 20px; letter-spacing: -0.18px;">(${numberTransaction})</span>` +
          '</div>' +
          `<span style="color: #AAABAB; text-align: center; font-feature-settings: 'liga' off, 'clig' off; font-family: Inter; font-size: 14px; font-style: normal; font-weight: 500; line-height: 16px; letter-spacing: -0.33px;">${category}</span>` +
          '</div>' +
          '</div>'
        );
      },
    },
    labels: categories,
    stroke: {
      width: 0,
    },
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        const value = opts.w.config.series[opts.seriesIndex];
        return priceConverter(value);
      },
      style: {
        fontSize: '16px',
        colors: ['#fff'],
        fontWeight: 400,
      },
      dropShadow: {
        enabled: false,
      },
    },
    colors: colors,
    legend: {
      show: true,
    },
  };

  return (
    <div className={classNames(styles.pieChartContainer, className)}>
      <Chart options={chartOptions} series={data} type="donut" height={450} width={700} />
    </div>
  );
};
