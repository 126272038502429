export enum SignatoryStatus {
  Pending = 'Pending',
  Requested = 'Requested',
  Signed = 'Signed',
  Declined = 'Declined',
  Canceled = 'Canceled',
  Viewed = 'Viewed',
}

export enum FormEditorStatus {
  Pending = 'Pending',
  Requested = 'Requested',
  Completed = 'Completed',
  Canceled = 'Canceled',
  Voided = 'Voided',
}

export enum FormProcessLogAction {
  CREATED = 'Created',
  UPDATED = 'Updated',
  SIGNED = 'Signed',
  SIGNATURE_DECLINE = 'Signature_Decline',
  SENT_FOR_EDITING = 'Sent_For_Editing',
  SENT_FOR_SIGNING = 'Sent_For_Signing',
  VIEWED_FOR_EDITING = 'Viewed_For_Editing',
  VIEWED_FOR_SIGNING = 'Viewed_For_Signing',
  CANCELED = 'Canceled',
  UNLOCKED = 'Unlocked',
  COMPLETED = 'Completed',
  DEFERRED = 'Deferred',
  UNDO_DEFERRED = 'Undo_Deferred',
}

export enum ResentEmailEnum {
  signature = 'signature',
  editor = 'editor',
}

export enum transactionEnum {
  UNLISTED = 'Unlisted',
  NO_CLIENT = 'No_Client',
}
