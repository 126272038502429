import { ERROR, IDLE, PENDING, READY } from 'settings/constants/apiState';

export type Frequency = {
  Id: number;
  FrequencyName: string;
  ClosingDateRelated: boolean;
  OffsetOperator: null;
  OffsetValue: null;
  OffsetUnit: null;
  RepeatTimes: null;
  HasSpecificDates: boolean;
  SpecificDates: string[];
  CreatedBy: number;
  CreatedDate: string;
};

type Kit = {
  Id: number;
  Name: string;
  Published: boolean;
  Category: string;
  EligiblePropertyTypes: string;
  Slug: string;
  CreatedDate: string;
  UpdatedDate: string;
  CreatedBy: number;
  UpdatedBy: number | null;
  Notes: null;
  InAppReminder: boolean;
  EligibleLocations: any[];
  RestrictedLocations: any[];
  EligibleFrequencies: Frequency[];
};

type Stats = {
  numberOfPlans: number;
  numberOfClients: number;
  numberOfKitsSent: number;
};

export type PublishedKit = {
  KitId: number;
  TeamId: number;
  FrequencyId: number;
  Enabled: boolean;
  CreatedDate: string;
  UpdatedDate: string;
  CreatedBy: number;
  UpdatedBy: number | null;
  BrokerageId: number;
  CurrentFrequency: Frequency;
  Kits: Kit;
  permittedTo: string;
  stats: Stats;
  campaignStats: any[];
};

export const states = { IDLE, READY, PENDING, ERROR } as const;

export type RadarKitsInitialState = {
  publishedKits: {
    state: typeof states[keyof typeof states];
    data: null | PublishedKit[];
    meta: any;
  };
  campaigns: {
    state: typeof states[keyof typeof states];
    data: null | Campaign[];
    meta: any;
  };
  library: {
    state: typeof states[keyof typeof states];
    data: null | any;
    meta: any;
  };
  selectedCampaign: {
    state: typeof states[keyof typeof states];
    data: null | Campaign;
    meta: any;
  };
  selectedLibraryKitId: number | null;
  campaignTask: {
    state: typeof states[keyof typeof states];
    data: null | CampaignTask;
    meta: any;
  };
  campaignTaskMembers: {
    state: typeof states[keyof typeof states];
    data: null | any;
    meta: any;
  };
};

export type CreateCampaignPayload = {
  Name: string;
  Description?: string;
  Active: boolean;
  CampaignMembers: { ClientId: number; AddressId: number }[];
  kits: { kitId: number; frequencyId: number }[];
  IsPublishedByTeam?: boolean;
};

export type CampaignKits = {
  AgentId: number;
  BrokerageId: number;
  CampaignId: number;
  CreatedDate: string;
  FrequencyId: number;
  Id: number;
  KitId: number;
  KitStartDate: string;
  TeamId: number;
  UpdatedDate: string;
};

export type CampaignTask = {
  Id: number;
  Transaction?: any;
  TransactionId?: number;
  Campaign?: Campaign;
  CampaignId?: number;
  AssignorId: number;
  Title: string;
  Description: string;
  CategoryId?: number;
  PerformedById?: number;
  Status?: string;
  DueDate?: Date | string;
  UpcomingNotifiedTime?: Date;
  OverdueNotifiedTime?: Date;
  Assignor?: any;
  PerformedBy?: any;
  AssigneeList?: any[];
  CcList: any[];
  Links?: any[];
  Documents?: any[];
  CommentsThreadId: number;
  Comments: any[];
  EntityId?: string;
  IsRequired?: boolean;
  TaskTemplateInitial?: string;
  TaskTemplateOperator?: string;
  TaskTemplateDateOffset?: number;
  TaskTemplateInitialOperatorOffset?: string;
  OverdueStatusChangedBySystem?: boolean;
};

export type CampaignTaskTemplate = {
  AssigneeRoles: string[];
  CampaignId: number;
  CategoryId: number | null;
  CcRoles: string[];
  ControlOperatorOffset: string | null;
  CreatedBy: number;
  CreatedDate: string;
  CreatorId: number;
  Description: string | null;
  Documents: any[];
  DueDate: number;
  Id: number;
  Initial: string;
  IsGlobal: boolean;
  Links: any[];
  MilestonePublicId: string | null;
  Name: string;
  Operator: string;
  People: any[] | null;
  TeamId: number;
  Teams: any[] | null;
  TransactionTemplateId: number | null;
  UpdatedBy: number;
  UpdatedDate: string;
};

export type Campaign = {
  Name: string;
  Description: string;
  Active: boolean;
  Id: number;
  Agent: any;
  AgentId: number;
  Brokerage: any;
  BrokerageId: number;
  CampaignKits: CampaignKits[];
  CampaignMembers: any[];
  Team: any;
  TeamId: number;
  Tasks: CampaignTask[];
  IsPublishedByTeam: boolean;
  TaskTemplates: CampaignTaskTemplate[];
};

export enum CampaignEntityType {
  Kit = 'Kit',
  Task = 'Task',
}
