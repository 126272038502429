import React, { useState } from 'react';
import { Moment } from 'moment';

import { Col, Popover, Row } from 'components-antd';
import { MultiSelect } from 'components-antd/MultiSelect';
import { Icons } from '../../Icons';
import { ArrowDown } from 'components/Icons';
import { DateRangePicker } from 'components/Form/DateRangePicker';

import styles from './styles.module.scss';
import { Button } from 'components';

interface FilterPopoverProps {
  filterIcon: React.ReactElement;
  onDateChangeHandler: (_from?: Moment, _to?: Moment) => void;
  onActivityTypeSelectHandler: () => void;
  onDone: () => void;
  onReset: () => void;
  from: any;
  to: any;
}

export const FilterPopover = ({
  filterIcon,
  onDateChangeHandler,
  onActivityTypeSelectHandler,
  onDone,
  onReset,
  from,
  to,
}: FilterPopoverProps) => {
  const [open, setOpen] = useState(false);
  const [resetDates, setResetDates] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      if (!from && !to) onReset();
      else onDone();
    }
    setOpen(newOpen);
  };

  const renderTitle = () => {
    return (
      <>
        <Icons variant={'filter'} className={styles.filterIcon} />
        <span className={styles.titleText}>Filters</span>
      </>
    );
  };

  const renderFields = () => {
    return (
      <>
        <Row>
          <Col span={24} className={styles.filterHeader}>
            {renderTitle()}
          </Col>
        </Row>
        <Row className={styles.filterPopoverContainer} justify="start" align="stretch">
          <DateRangePicker
            onDatesChange={onDateChangeHandler}
            fromDateValue={from}
            toDateValue={to}
            dateRangePickerWrapperClass={styles.dateRangePickerWrapperClientActivity}
            resetDates={resetDates}
            futureDisabled={false}
          />
        </Row>
        <Row justify="space-between" align="middle">
          <Col span={6}>
            <Button
              className={styles.resetButton}
              title="Reset"
              onClick={() => {
                hide();
                onReset();
                setResetDates(true);
              }}
            />
          </Col>
          <Col span={12} />
          <Col span={6}>
            <Button
              className={styles.submitButton}
              title="Done"
              onClick={() => {
                onDone();
                hide();
                setResetDates(false);
              }}
            >
              <span className={styles.btnText}>Done</span>
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Popover
      content={renderFields}
      trigger="click"
      placement="bottomRight"
      open={open}
      onOpenChange={handleOpenChange}
      className={styles.filterPopover}
      overlayClassName={styles.clientActivityCardFilterPopover}
    >
      {filterIcon}
    </Popover>
  );
};
