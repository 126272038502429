import React, { useMemo, useState } from 'react';
import { Table } from '../Table';
import styles from './styles.module.scss';
import { DropdownCheck, Wrapper } from 'components';
import moment from 'moment';
import { AddressValuationDetails } from 'api/intel/types';
import NumberFormat from 'react-number-format';
import { appOpenModalEffect } from 'store/effects';
import ValuesModal from './ValuesModal';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { Icons, IconVariant } from '../../../icons';
import { AreaChart } from 'components/AreaChart';

type Props = {
  valuationData: AddressValuationDetails[];
  isPending?: boolean;
  params: any;
  rental?: boolean;
};

enum SWITCHER {
  GRAPH = 'graph',
  CHART = 'chart',
}

export const ValuationCard: React.FC<Props> = ({
  valuationData,
  isPending = false,
  params,
  rental = false,
}) => {
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  const dispatch = useDispatch();
  const [switcher, setSwitcher] = useState(SWITCHER.GRAPH);
  const [dataType, setDataType] = useState('attom');
  const [dateFrom, setDateFrom] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 6)),
  );

  const handleDateChange = (val) => {
    const today = new Date();
    let fromDate;

    switch (val) {
      case 'YTD':
        fromDate = new Date(today.getFullYear(), 0, 1);
        break;
      case '6M':
        fromDate = new Date(today.setMonth(today.getMonth() - 6));
        break;
      case '1Y':
        fromDate = new Date(today.setFullYear(today.getFullYear() - 1));
        break;
      case '2Y':
        fromDate = new Date(today.setFullYear(today.getFullYear() - 2));
        break;
      case 'MAX':
        fromDate = new Date(1970, 0, 1); // Or use another earliest date as needed
        break;
      default:
        fromDate = null;
    }

    setDateFrom(fromDate);
  };

  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  const handleSwitcher = () => {
    setSwitcher((prev) => (prev === SWITCHER.CHART ? SWITCHER.GRAPH : SWITCHER.CHART));
  };

  const homeValueData = useMemo(() => {
    if (!valuationData) return null;
    const valueToGet = rental ? 'rentalValue' : 'homeValue';

    const filteredValuationData = valuationData
      ?.filter((obj) => obj?.source === dataType && obj?.[valueToGet])
      ?.sort((a, b) => {
        const dateA = new Date(a.year, a.month - 1);
        const dateB = new Date(b.year, b.month - 1);

        return (dateB as any) - (dateA as any);
      });

    const clientValuationData: any = valuationData?.sort((a, b) => {
      const dateA = new Date(a.year, a.month - 1);
      const dateB = new Date(b.year, b.month - 1);

      return (dateB as any) - (dateA as any);
    });

    const estimatedValue = filteredValuationData[0]?.[valueToGet] ?? 0;

    // Calculate percentage change
    const prevValue = filteredValuationData[1]?.[valueToGet] ?? 0;
    const percChange: number = prevValue
      ? Number(
          (((Number(estimatedValue) - Number(prevValue)) / Number(prevValue)) * 100).toFixed(1),
        )
      : 0;

    const formattedPercChange = percChange > 0 ? `+${percChange}%` : `${percChange}%`;

    const changeType = percChange > 0 ? 'increase' : 'decrease';

    const transformData = (item, index, arr, time = { month: null, year: null }) => {
      const nextItem = arr[index + 1]; // Get the next item instead of the previous one
      const currentValue = item?.[valueToGet] ? parseFloat(item?.[valueToGet]) : undefined;
      const nextValue = nextItem ? parseFloat(nextItem?.[valueToGet] ?? '') : null;

      let change: string = '';
      let changeType: string = '';

      if (nextValue && currentValue) {
        const diff = currentValue - nextValue;
        change = ((diff / nextValue) * 100).toFixed(2);

        if (diff > 0) {
          changeType = 'increase';
          change = `+${change}%`;
        } else if (diff < 0) {
          changeType = 'decrease';
          change = `${change}%`;
        }
      }

      return {
        month: time?.month || item?.month,
        year: time?.year || item?.year,
        value: currentValue,
        change,
        changeType,
      };
    };

    const groupByMonthYearAndSource = (data: any) => {
      const groupedData: any = [];
      data.forEach((obj: any) => {
        const { month, year, source } = obj;
        let existingEntry: any = groupedData.find(
          (item: any) => item?.month === month && item?.year === year,
        );
        if (!existingEntry) {
          existingEntry = { month, year, attom: null, manual: null };
          groupedData.push(existingEntry);
        }
        if (source === 'attom') {
          existingEntry.attom = obj;
        } else if (source === 'manual') {
          existingEntry.manual = obj;
        }
      });
      return groupedData;
    };

    return {
      estimatedValue,
      percChange: formattedPercChange,
      changeType,
      historyAttom: filteredValuationData.map(transformData),
      historyAttomClient: groupByMonthYearAndSource(clientValuationData).map(
        (item, index, arr) => ({
          attom: transformData(item.attom, index, arr, { month: item?.month, year: item?.year }),
          manual: transformData(item.manual, index, arr, { month: item?.month, year: item?.year }),
        }),
      ),
    };
  }, [valuationData, dataType, dateFrom, rental]);

  const getSeries = () => {
    if (isAgent && dataType !== 'all')
      return [
        {
          name: 'Attom',
          data: homeValueData?.historyAttom.map(({ value }) => value) ?? [],
        },
      ];
    else
      return [
        {
          name: 'Attom',
          data: homeValueData?.historyAttomClient?.map((val) => val?.attom?.value || null) ?? [],
        },
        {
          name: 'Manual',
          data: homeValueData?.historyAttomClient?.map((val) => val?.manual?.value || null) ?? [],
        },
      ];
  };

  const categories = homeValueData?.[
    isAgent && dataType !== 'all' ? 'historyAttom' : 'historyAttomClient'
  ]
    ?.reverse()
    ?.map((obj) => {
      const { month, year } = isAgent && dataType !== 'all' ? obj : obj?.attom || {};
      return (
        moment()
          .month(month - 1)
          .format('MMM') +
        ', ' +
        year
      );
    });

  return (
    <div className={styles.valuationCard}>
      {isAgent && <ValuesModal rental={rental} params={params} key={`${rental ? '1' : '2'}`} />}
      <Wrapper isPending={isPending}>
        <div className={styles.valuationCardHeader}>
          <div className={styles.headingWrapper}>
            <div className={styles.title}>Estimated {rental ? 'Rental' : ''} Value</div>
            <div className={styles.value}>
              <NumberFormat
                thousandSeparator
                displayType="text"
                value={homeValueData?.estimatedValue}
                prefix="$"
              />
            </div>
            <div className={styles.percChange}>
              <span className={styles[homeValueData?.changeType ?? '']}>
                {homeValueData?.percChange}
              </span>{' '}
              since last month
            </div>
          </div>
          <div className={styles.optionsWrapper}>
            {isClient && (
              <div className={styles.iconWrapper} onClick={handleSwitcher}>
                <Icons
                  variant={switcher === SWITCHER.CHART ? IconVariant.TABLE : IconVariant.GRAPH}
                />
              </div>
            )}
            {isAgent && (
              <DropdownCheck
                className={styles.dropdown}
                defaultValue={'attom'}
                dropdownFields={[
                  { value: 'attom', label: 'ATTOM' },
                  { value: 'manual', label: 'Manual' },
                  { value: 'all', label: 'All' },
                ]}
                onChange={(val) => setDataType(val)}
                prefixLabel={'Source: '}
              />
            )}
            <DropdownCheck
              className={styles.dropdown}
              defaultValue={'6M'}
              dropdownFields={[
                { value: 'YTD', label: 'YTD' },
                { value: '6M', label: '6M' },
                { value: '1Y', label: '1Y' },
                { value: '2Y', label: '2Y' },
                { value: 'MAX', label: 'MAX' },
              ]}
              onChange={handleDateChange}
            />
            {isAgent && (
              <div
                className={styles.addButton}
                onClick={() => {
                  openModal(rental ? 'valuesModalRental' : 'valuesModalHome');
                }}
              >
                <span>+</span> Add
              </div>
            )}
          </div>
        </div>
        {isAgent || switcher === SWITCHER.GRAPH ? (
          <div id="chart">
            <AreaChart
              curve="straight"
              height={160}
              options={{
                ...(isAgent
                  ? {}
                  : {
                      fill: {
                        opacity: 0,
                        type: 'solid',
                      },
                    }),
                grid: {
                  show: true,
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  yaxis: {
                    lines: {
                      show: true,
                    },
                  },
                  strokeDashArray: 4,
                },
                xaxis: {
                  categories,
                  labels: {
                    show: true,
                    style: {
                      colors: '#000',
                      fontSize: '12px',
                    },
                  },
                  axisBorder: {
                    show: true,
                    color: '#000',
                  },
                  axisTicks: {
                    show: true,
                    color: '#000',
                  },
                },
                tooltip: {
                  enabled: true,
                  x: {
                    show: true,
                  },
                  y: {
                    formatter: function (value) {
                      return value ? '$' + value?.toLocaleString() : '-';
                    },
                  },
                },
              }}
              colors={['#928CDA', '#51BFE1']}
              chartSeries={getSeries()}
            />
          </div>
        ) : null}
        {isAgent || switcher === SWITCHER.CHART ? (
          <Table
            historyAttom={
              isClient || dataType === 'all'
                ? homeValueData?.historyAttomClient?.reverse() ?? []
                : homeValueData?.historyAttom?.reverse() ?? []
            }
            isClient={isClient || dataType === 'all'}
          />
        ) : null}
      </Wrapper>
    </div>
  );
};
