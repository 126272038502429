import PropTypes from 'prop-types';
import classNames from 'classnames';
import { processMediaToOrderedImages, filterImagesOnlyFromPhotoUrls } from 'helpers';
import { isEmpty } from 'lodash-es';
import { PropertyImage } from 'components/Icons';

import styles from './styles.module.scss';

const Photo = (props) => {
  const { propertyInfo, className } = props;
  const { Media, PhotoUrls, Market } = propertyInfo;
  const photos = !isEmpty(Media)
    ? processMediaToOrderedImages(Media, Market)
    : filterImagesOnlyFromPhotoUrls(PhotoUrls, Market);
  const imageToSend = photos[0];
  return (
    <div className={classNames(styles.photo, className)}>
      {imageToSend ? (
        <img loading={'lazy'} src={imageToSend} alt="xxx" />
      ) : (
        <PropertyImage className={styles.propertyImage} />
      )}
    </div>
  );
};

Photo.propTypes = {
  className: PropTypes.string,
  propertyInfo: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    PhotoUrls: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

Photo.defaultProps = {
  className: '',
};

export default Photo;
