import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { SignatureResponseType } from 'pages/FormBuilder/components';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { FormDocumentQuestionsType } from 'types';
const EMOJI_REGEX =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|\ud83d[\ude00-\ude4f]|\ud83d[\ude80-\udeff]|\ud83e[\udd00-\uddff]|\ud83c[\udf00-\udfff]|\ud83d[\udc00-\udcff])/g;

// eslint-disable-next-line no-useless-escape
export const VALID_KEYS_REGEX = /^[a-zA-Z0-9!@#$%^&*()_+|}{":?><~`\\\[\]'./,;= \t\n-]*$/;

export const getSignatureConfig = ({
  bundleDocumentsDetails,
  activeQuestions = [] as FormDocumentQuestionsType,
  responses,
  showWizard = false,
}) => {
  const isSignatureCohesiveFlow =
    bundleDocumentsDetails.length > 1 && bundleDocumentsDetails[0].activeQuestions;

  let questions: any[] = activeQuestions;
  let answers = responses;
  let wizard = showWizard;

  if (isSignatureCohesiveFlow) {
    questions = [];
    answers = {};
    wizard = false;

    bundleDocumentsDetails.forEach((item) => {
      questions = [...questions, ...item.activeQuestions];
      answers = { ...answers, ...item.answers };
      wizard = wizard || item.showWizard;
    });
  }

  return { questions, answers, wizard };
};

export const getCohesiveFieldAnswers = (bundleDocumentsDetails) => {
  let answers = {};

  bundleDocumentsDetails.forEach((item) => {
    answers = { ...answers, ...(item.answers || {}) };
  });

  return answers;
};

export const getCohesiveFieldQuestions = (bundleDocumentsDetails) => {
  let questions: any[] = [];

  bundleDocumentsDetails.forEach((item) => {
    questions = [...questions, ...(item.activeQuestions || [])];
  });

  return questions;
};

const getFieldType = (questions, uuid) => {
  return questions.find(({ UUID }) => uuid === UUID).FieldType;
};

export const getSignatureFieldsUUID = (questions) => {
  const signatureTypeUUID = questions
    .filter(({ FieldType }) =>
      [DYNAMIC_QUESTION_TYPE.Initials, DYNAMIC_QUESTION_TYPE.Signature].includes(FieldType),
    )
    .map(({ UUID }) => UUID);

  return signatureTypeUUID;
};

const prepareAnswers = (answers, updatedSignature, questions, questionUUID) => {
  let signatureUUIDs = getSignatureFieldsUUID(questions);
  signatureUUIDs = signatureUUIDs.filter((uuid) => uuid !== questionUUID);

  const allAnswers = { ...answers };

  signatureUUIDs.forEach((UUID) => {
    if (allAnswers[UUID]) {
      const { type: answerType, signature: answerSignature } = JSON.parse(allAnswers[UUID].Answer);
      const { type, signModalTab = '', ...rest } = JSON.parse(updatedSignature);

      const fieldType = getFieldType(questions, UUID);

      const isInitial = fieldType === DYNAMIC_QUESTION_TYPE.Initials;
      const isSignature = fieldType === DYNAMIC_QUESTION_TYPE.Signature;

      const signature = {
        ...rest,
        ...(isInitial && type === SignatureResponseType.Image
          ? { type: SignatureResponseType.Text }
          : isSignature && signModalTab === 'draw'
          ? { type: answerType, signature: answerSignature }
          : { type }),
      };

      allAnswers[UUID] = { ...allAnswers[UUID], Answer: JSON.stringify(signature) };
    }
  });

  return allAnswers;
};

export const getSignatureAnswers = (answers, data, questionUUID, isCohesiveFlow?) => {
  let answerDetails;

  if (isCohesiveFlow) {
    const documentDetails = dynamicManager.getBundleDocumentsDetails();

    documentDetails.forEach((item) => {
      item.answers = prepareAnswers(item.answers, data, item.activeQuestions, questionUUID);
    });

    answerDetails = documentDetails;
  } else {
    const questions = dynamicManager.getQuestions();
    answerDetails = prepareAnswers(answers, data, questions, questionUUID);
  }

  return { answerDetails };
};

export const modifySignatureUUID = (UUID) => {
  if (!UUID) return '';

  const plainText = UUID.replaceAll('-', '').toUpperCase();
  return `${plainText.substring(0, 14)}...`;
};

export const getDateFontSize = (width, defaultFontSize) => {
  if (width <= 70) return 3;
  else if (width <= 100) return 10;
  else if (width <= 130) return 12;

  return defaultFontSize;
};

export const hasEmoji = (value) => {
  return !VALID_KEYS_REGEX.test(value);
};

export const removeEmoji = (value) => {
  const replaced = value.replace(EMOJI_REGEX, '');
  return replaced;
};

export const isSafari = () => {
  const ua = navigator.userAgent;
  return ua.includes('Safari') && !ua.includes('Chrome') && !ua.includes('Chromium');
};
