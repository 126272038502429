import React, { useState } from 'react';
import { Table, TableProps } from 'antd';
import classNames from 'classnames';

import { Close } from 'components/Icons';
import Name from 'components/ClientsDrawer/ProfileClient/Name';
import { priceConverter } from 'helpers';
import { TableModal } from '../Stats/Content/Components/TableView/TableModal/TableModal';

import styles from './styles.module.scss';
interface DataType {
  key: number;
  TransactionAgent: string;
  Total: string;
  TotalVolume: string;
  NumberTransactions: string;
  Percentage: number;
  selected: boolean;
}

interface CommissionRateDataType {
  key: number;
  TransactionAgent: string;
  Average: string;
  NumberTransactions: string;
  selected: boolean;
}

type BreakdownMenuProps = {
  open: boolean;
  list: any[];
  handleClick: (record, rowIndex) => void;
  color: string;
  onClose: (agent, index) => void;
  title: string;
  aggregationType?: string;
  summaryType: string;
  summaryKey?: string;
  columnType?: string;
  ledgerAccountId?: number;
  memo?: string;
  selectedAgent?: any;
  clarityType: string;
  representingSide?: number | null;
  isCommission?: boolean;
};

export const BreakdownMenu: React.FC<BreakdownMenuProps> = ({
  open,
  list,
  handleClick,
  color,
  onClose,
  title,
  aggregationType,
  summaryType,
  summaryKey,
  columnType,
  ledgerAccountId,
  memo,
  selectedAgent = null,
  clarityType,
  representingSide,
  isCommission,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const selectedRowStyles = {
    border: `1.5px solid ${color}`,
    'background-color': `${color}14`,
    'border-radius': '4px',
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'TransactionAgent',
      key: 'TransactionAgent',
      width: '38%',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.TransactionAgent;
          const b = record2.TransactionAgent;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (TransactionAgent, record) => {
        return (
          <Name
            className={classNames(styles.agentName, { [styles.selectedAgent]: record?.selected })}
            name={`${TransactionAgent}`}
          />
        );
      },
    },
    {
      title: 'Total',
      dataIndex: 'Total',
      key: 'Total',
      width: '23%',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Total;
          const b = record2.Total;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (Total, record) => {
        return (
          <div className={classNames(styles.amount, { [styles.selectedAgent]: record?.selected })}>
            {priceConverter(Total || record.TotalVolume || 0, 1)}
          </div>
        );
      },
    },
    {
      title: 'Count',
      dataIndex: 'NumberTransactions',
      key: 'NumberTransactions',
      width: '17%',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.NumberTransactions;
          const b = record2.NumberTransactions;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (NumberTransactions, record) => {
        return (
          <div
            className={classNames(styles.requests, { [styles.selectedAgent]: record?.selected })}
            onClick={() => setOpenModal(true)}
          >
            <span className={styles.requestsText}> {NumberTransactions}</span>
          </div>
        );
      },
    },
    {
      title: 'Percentage',
      dataIndex: 'Percentage',
      key: 'Percentage',
      width: '22%',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Percentage;
          const b = record2.Percentage;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (Percentage, record) => {
        return (
          <div
            className={classNames(styles.percentage, { [styles.selectedAgent]: record?.selected })}
          >{`${Percentage || 0}%`}</div>
        );
      },
    },
  ];

  const commissionRateColumns: TableProps<CommissionRateDataType>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'TransactionAgent',
      key: 'TransactionAgent',
      width: '38%',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.TransactionAgent;
          const b = record2.TransactionAgent;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (TransactionAgent, record) => {
        return (
          <Name
            className={classNames(styles.agentName, { [styles.selectedAgent]: record?.selected })}
            name={`${TransactionAgent}`}
          />
        );
      },
    },
    {
      title: 'Avg.',
      dataIndex: 'Average',
      key: 'Average',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Average;
          const b = record2.Average;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (Total, record) => {
        return (
          <div className={classNames(styles.amount, { [styles.selectedAgent]: record?.selected })}>
            {`${record.Average}%`}
          </div>
        );
      },
    },
    {
      title: 'Count',
      dataIndex: 'NumberTransactions',
      key: 'NumberTransactions',
      width: '17%',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.NumberTransactions;
          const b = record2.NumberTransactions;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (NumberTransactions, record) => {
        return (
          <div
            className={classNames(styles.requests, { [styles.selectedAgent]: record?.selected })}
            onClick={() => setOpenModal(true)}
          >
            <span className={styles.requestsText}> {NumberTransactions}</span>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.breakdownWrapper}>
      <div className={styles.breakdownHeader}>
        <span>Breakdown</span>
        <Close onClick={onClose} className={styles.closeBtn} />
      </div>
      {list.length ? (
        <div className={classNames(styles.breakdownContent, { [styles.openMenuContent]: open })}>
          <Table
            className={styles.BreakdownTable}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  handleClick(record, rowIndex);
                },
                style: record?.selected ? selectedRowStyles : undefined,
              };
            }}
            columns={isCommission ? commissionRateColumns : columns}
            dataSource={list}
            pagination={false}
            sticky={true}
          />
        </div>
      ) : (
        <div className={styles.placeholder}>No Breakdown Data</div>
      )}
      {openModal && (
        <TableModal
          title={title}
          aggregationType={aggregationType}
          summaryType={summaryType}
          summaryKey={summaryKey}
          columnType={columnType}
          ledgerAccountId={ledgerAccountId}
          memo={memo}
          open={openModal}
          onCancel={() => setOpenModal(false)}
          selectedAgent={selectedAgent}
          representingSide={representingSide}
          clarityType={clarityType}
          isCommission={isCommission}
        />
      )}
    </div>
  );
};
