/**
 * Feature Flags
 *
 * Can be set either from env variable or from local storage
 */

const LOCAL_STORAGE_KEY = 'featureFlags';

function getFlagsFromLocalStorage() {
  let result = {};
  try {
    result = localStorage.getItem(LOCAL_STORAGE_KEY)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '')
      : {};
  } catch (e) {
    result = {};
  }

  return result;
}

function getFlagState(name: string) {
  return (
    // Check environment variables
    process.env[`REACT_APP_${name}`] === 'true' ||
    // Check local storage
    getFlagsFromLocalStorage()[name] ||
    false
  );
}

const getValue = (name) => {
  return process.env[`REACT_APP_${name}`];
};

const propertyDetailsWhitelist = getValue('FF_AI_PROPERTY_DETAILS_WHITELIST');

export const featureFlags = {
  propertyDetails: getFlagState('FF_AI_PROPERTY_DETAILS'),
  contracts: getFlagState('FF_AI_CONTRACTS'),
  customSearchPolygonDrawing: getFlagState('FF_CUSTOM_SEARCH_POLYGON_DRAWING'),
  hideMatchScoreSimulator: getFlagState('FF_HIDE_MATCH_SCORE_SIMULATOR'),
  gpt: getFlagState('FF_GPT'),
  paywall: getFlagState('FF_PAYWALL'),
  testClock: getFlagState('FF_TEST_CLOCK'),
  propertyDetailsWhitelist:
    propertyDetailsWhitelist && propertyDetailsWhitelist !== ''
      ? propertyDetailsWhitelist.split(',')
      : [],
};

(window as any).featureFlags = featureFlags;
