import { Autocomplete, Button, Input, Select } from 'components';
import { Col, Row } from 'components-antd';
import { FormikContextType } from 'formik';
import React, { MouseEvent, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAllServicesCategoriesList } from 'store/selectors/servicesCategories';
import { InviteVendorIcon } from '../../icons';
import { getConciergeSearchSelector } from 'store/selectors/concierge';

import styles from './styles.module.scss';

const Header: React.FC = () => {
  return (
    <div className={styles.header}>
      <div className={styles.iconWrapper}>
        <InviteVendorIcon />
      </div>
      <div>Invite a Vendor</div>
    </div>
  );
};

type Props = {
  isLoading: boolean;
  onClickEmail: (e: MouseEvent<HTMLButtonElement>) => void;
  form: FormikContextType<{
    BusinessName: string;
    Email: string;
    PartnerCategoryIds: { name: string; value: string }[];
  }>;
};

export const AddVendorForm: React.FC<Props> = ({ isLoading, onClickEmail, form }) => {
  const {
    values,
    touched,
    errors,
    isValid,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
  } = form;
  const allCategories = useSelector(getAllServicesCategoriesList);
  const { category: categoryId } = useSelector(getConciergeSearchSelector);

  const allCategoriesOptions = useMemo<{ name: string; value: string }[]>(() => {
    return allCategories.map((category) => ({ name: category.Title, value: category.Id }));
  }, [allCategories]);

  const categoryById = useMemo(() => {
    return (
      allCategories
        .filter(({ Id }) => Id === Number(categoryId))
        .map((category) => ({ name: category.Title, value: category.Id })) || []
    );
  }, [allCategories, categoryId]);

  useEffect(() => {
    setFieldValue('PartnerCategoryIds', categoryById);
  }, [categoryById]);

  return (
    <div className={styles.contentWrapper}>
      <Header />
      <form className={styles.contentBody} onSubmit={handleSubmit}>
        <Row>
          <Col span={24}>
            <Input
              className={styles.inputContainer}
              name="BusinessName"
              variant="lightFull"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.BusinessName}
              label="Name"
              error={touched.BusinessName ? (errors.BusinessName as string) : ''}
              placeholder={'Enter name'}
              maxLength={30}
              errorClassName={styles.error}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input
              className={styles.inputContainer}
              name="Email"
              variant="lightFull"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Email}
              label="Email"
              error={touched.Email ? (errors.Email as string) : ''}
              placeholder={'Enter email'}
              maxLength={75}
              errorClassName={styles.error}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.label}>Category</div>
          </Col>
          <Col span={24}>
            <Select
              multiple
              options={allCategoriesOptions}
              variant={Autocomplete.LIGHT}
              placeholder={'Select categories'}
              onSelect={(values) => setFieldValue('PartnerCategoryIds', values?.target?.value)}
              value={allCategoriesOptions.filter((option) =>
                values.PartnerCategoryIds.find((ids) => ids.value === option.value),
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} className={styles.emailDisclaimer}>
            <h5 className={styles.disclaimerTitle}>Prior to sending this invitation:</h5>
            <p className={styles.disclaimerContent}>
              Please notify the vendor via email or phone that you will be inviting them to Mosaik.
              This is a best practice that will help ensure vendor activation and engagement. If you
              have any questions in this regard, please feel free to contact{' '}
              <a href="mailto:support@mosaik.io" className={styles.emailHighlight}>
                support@mosaik.io
              </a>
              .
            </p>
          </Col>
        </Row>
        <Row className={styles.buttonContainer}>
          <Col xs={24} lg={11}>
            <Button
              title="See Email Template"
              className={styles.emailButton}
              onClick={onClickEmail}
            />
          </Col>
          <Col xs={24} lg={11}>
            <Button
              title="Send Invitation"
              type="submit"
              disabled={!isValid}
              className={styles.submitButton}
              isPending={isLoading}
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};
