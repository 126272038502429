import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { FC } from 'react';

interface IAreaChart {
  chartSeries: { data: number[]; name: string }[];
  colors: string[];
  className?: string;
  options: ApexOptions;
  height: number;
  curve?: 'smooth' | 'straight' | 'stepline' | 'linestep' | 'monotoneCubic';
}

export const AreaChart: FC<IAreaChart> = ({
  chartSeries,
  colors,
  className = '',
  options = {},
  height = 70,
  curve = 'smooth',
}) => {
  const chartOptions: ApexOptions = {
    chart: {
      sparkline: {
        enabled: true,
      },
      type: 'area',
      width: '100%',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve,
      width: 3,
      colors: colors,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    fill: {
      opacity: 0.7,
      type: 'gradient',
      colors: colors,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.7,
        stops: [0, 80],
      },
    },
    ...options,
  };

  return (
    <div className={className}>
      <Chart options={chartOptions} series={chartSeries} type="area" height={height} />
    </div>
  );
};
