import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Title } from '..';
import { Search } from './Search';
import { Filter } from './Filter';
import New from './New';

import styles from './styles.module.scss';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { useSelector } from 'react-redux';
import { Divider } from 'components-antd';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { useState, useMemo } from 'react';
import Upload from './Upload';
import { InProgressFormsPill } from './InProgressFormsPill';
import Icons from '../../Icons';

const Header = (props) => {
  const { className, folderData, handleBackClick, setIsFilterApplied, loading } = props;
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const { isClient, isThirdParty, isAgent } = useSelector(getUserRolesMapSelector);
  const [open, setOpen] = useState(false);
  const [modalUpload, setModalUpload] = useState({ open: false });

  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const hasLimitedAccess = useMemo(() => {
    return isClient || isThirdParty || !fullAccess;
  }, [isClient, isThirdParty, fullAccess]);

  return (
    <div className={classNames(styles.header, className)}>
      {folderData?.Title ? (
        <div className={styles.folderContainer}>
          <Icons
            variant={'back_arrow'}
            className={styles.backArrowIcon}
            onClick={handleBackClick}
          />

          <Title>
            <span className={'show-cursor'} onClick={handleBackClick}>
              Files
            </span>{' '}
            / {folderData.Title}
          </Title>
        </div>
      ) : (
        <Title>Files</Title>
      )}

      <div className={styles.actionsWrapper}>
        <div className={styles.formSearchContainer}>
          <Search />
          {isAgent && !loading && <InProgressFormsPill />}
        </div>

        <div className={styles.actions}>
          <Divider type="vertical" style={{ height: '100%', backgroundColor: '#000' }} />
          <>
            {hasLimitedAccess ? (
              <New
                open={open}
                setOpen={setOpen}
                onClickOptions={() => setModalUpload({ open: true })}
                hasLimitedAccess={hasLimitedAccess}
                uploadBtnProps={{
                  setIsFilterApplied: setIsFilterApplied,
                }}
                loading={loading}
              />
            ) : (
              <New
                open={open}
                setOpen={setOpen}
                onClickOptions={onClickOptions}
                isNewFolder={folderData?.isFolder}
                hasLimitedAccess={hasLimitedAccess}
                uploadBtnProps={{
                  setIsFilterApplied: setIsFilterApplied,
                  selectedFolderData: folderData,
                }}
                loading={loading}
              />
            )}
          </>
          <Upload
            setIsFilterApplied={setIsFilterApplied}
            modalUpload={modalUpload}
            setModalUpload={setModalUpload}
          />

          <Filter
            setIsFilterApplied={setIsFilterApplied}
            className={`${styles.btn} ${styles.filterBtn}`}
          />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};

export default Header;
