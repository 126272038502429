import React, { useCallback, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { convertNameToAvatarPlaceholder } from 'helpers';
import { getUserDataSelector } from 'store/selectors/user';
import { SendMessageFormView, Wrapper as PendingWrapper, CommentsView } from 'components';

import { socketSetEditOfferMessageEffect } from 'store/effects/sockets/offerMessages';
import { getCurrentEditOfferMessageSelector } from 'store/selectors/sockets/offerMessages';

import styles from './styles.module.scss';
import { addOfferComment } from 'api/offers';
import {
  addComment,
  CommentsEntityType,
  createCommentThread,
  deleteComment,
  getClientNotes,
  markThreadAsRead,
  updateComment,
} from 'api/comments';
import { MessageAccessType } from 'app-constants/enums/message';

interface IClientNotesProps {
  threadId: number;
  clientId: number;
  participantIds: number[];
}
const Comments: React.FC<IClientNotesProps> = ({ threadId, clientId, participantIds }) => {
  const commentViewWrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const currentEditMessage = useSelector(getCurrentEditOfferMessageSelector);
  const [isThreadCreating, setIsThreadCreating] = useState(false);
  const [comments, setComments] = useState<any[]>([]);
  const [currentThreadId, setCurrentThreadId] = useState<number | null>(null);

  const fetchClientNotes = useCallback(async () => {
    const { data } = await getClientNotes({ clientId });
    setComments(
      data?.Comments.map((comment) => ({
        ...comment,
        FirstName: comment?.Sender?.FirstName || comment.FirstName,
        LastName: comment?.Sender?.LastName || comment.LastName,
      })) || [],
    );
    const commentThreadId = data?.Comments?.[0].CommentThreadId;
    if (!currentThreadId) setCurrentThreadId(commentThreadId);
    if (commentThreadId) {
      await markThreadAsRead({ threadId: commentThreadId });
    }
  }, [clientId]);

  useEffect(() => {
    if (threadId) {
      setCurrentThreadId(threadId);
    }
  }, [dispatch, threadId]);

  useEffect(() => {
    let interval;
    if (clientId) {
      if (!interval) fetchClientNotes();
      interval = setInterval(() => {
        fetchClientNotes();
      }, 15000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [clientId]);

  const onCancelEditHandler = () => {
    dispatch(socketSetEditOfferMessageEffect({ editMessage: null }));
  };

  const onSendMessage = async (message, attachments, tagsValue, messageId, threadId) => {
    if (currentEditMessage) {
      onCancelEditHandler();
    }

    if (messageId) {
      const { data } = await updateComment({
        Id: messageId,
        Text: message,
        ...(Object.keys(attachments).length ? { Attachments: attachments } : {}),
        CommentMeta: { Tags: tagsValue },
        AccessType: MessageAccessType.Internal,
      });

      setComments((prevComments) =>
        prevComments.map((item) => (item.Id === messageId ? data.Comment : item)),
      );
    } else {
      const { data } = await addComment({
        ThreadId: threadId || currentThreadId,
        Text: message,
        Attachments: attachments,
        CommentMeta: { Tags: tagsValue },
        AccessType: MessageAccessType.Internal,
      });

      setComments((prevComments) => [
        ...prevComments,
        {
          ...data.Comment,
          CreatedDate: new Date().toISOString(),
          FirstName: user.FirstName,
          LastName: user.LastName,
        },
      ]);
    }
  };

  const onDeleteMessageHandler = async (messageId) => {
    setComments((prevComments) => prevComments.filter(({ Id }) => Id !== messageId));
    await deleteComment({ CommentId: messageId });
  };

  const onCreateThreadAndSendNewMessage = async (
    message,
    attachments,
    tagsValue,
    messageId,
    threadId,
  ) => {
    if (currentThreadId || threadId) {
      onSendMessage(message, attachments, tagsValue, messageId, currentThreadId || threadId);
    }

    setIsThreadCreating(true);
    const { data } = await createCommentThread({
      EntityId: Number(clientId),
      ParticipantIds: participantIds,
      EntityType: CommentsEntityType.clientNote,
      FirstComment: {
        Text: message,
        Attachments: attachments,
        CommentMeta: { Tags: tagsValue },
        AccessType: MessageAccessType.Internal,
      },
    });

    setComments([data?.CommentThread?.Comments?.[0]?.message]);
    setCurrentThreadId(data?.CommentThread?.Id);
    setIsThreadCreating(false);
  };

  const getUserName = () => `${user?.FirstName} ${user?.LastName}`;

  const onEditMessageHandler = useCallback(
    (messageId) => {
      const editMessage = comments.find((message) => message?.Id === messageId);
      dispatch(socketSetEditOfferMessageEffect({ editMessage }));
    },
    [dispatch, comments],
  );

  useEffect(() => {
    if (commentViewWrapperRef?.current && currentThreadId) {
      commentViewWrapperRef.current.scrollTop = commentViewWrapperRef?.current?.scrollHeight;
    }
  }, [comments, currentThreadId]);

  const getMessagesContent = () => {
    if (isThreadCreating) {
      return (
        <PendingWrapper
          isPending={isThreadCreating}
          className={styles.pendingWrapper}
          loaderClassName={styles.loader}
        />
      );
    }

    if (comments.length) {
      return (
        <div className={styles.CommentsViewWrapper} ref={commentViewWrapperRef}>
          <CommentsView
            list={comments}
            currentEditMessage={currentEditMessage}
            onEditMessage={onEditMessageHandler}
            className={styles.messages}
            onDeleteMessage={onDeleteMessageHandler}
            isModal
          />
        </div>
      );
    }

    return (
      <div className={styles.empty}>
        <span testid="no_comments" className={styles.message}>
          There are no notes yet
        </span>
      </div>
    );
  };

  return (
    <div className={classNames(styles.clientNotes)}>
      <div className={styles.holder}>
        <SendMessageFormView
          id="clientNotes"
          onSend={currentThreadId ? onSendMessage : onCreateThreadAndSendNewMessage}
          subheader={null}
          content={getMessagesContent()}
          footerClassName={styles.footer}
          footerInnerClassName={styles.footerInner}
          inputGroupClassName={styles.inputGroup}
          attachments={false}
          emoji={true}
          autocomplete={true}
          inputPlaceholder="Start typing..."
          sendButtonClassName={styles.sendButton}
          onCancelEdit={onCancelEditHandler}
          currentEditMessage={currentEditMessage}
          placeholder="Type to search users"
          placeholderClassName={styles.commentPlaceholder}
          isClientNote
          className={styles.commentsForm}
          avatar={true}
          avatarPlaceHolder={
            <div className={styles.avatarPlaceholder}>
              {convertNameToAvatarPlaceholder(getUserName())}
            </div>
          }
          showDropdown={false}
          subjectSetter={noop}
        />
      </div>
    </div>
  );
};

const noop = () => {};

export default Comments;
