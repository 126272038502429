import { Switch } from 'antd';
import styles from './styles.module.scss';
import { useState } from 'react';
import classNames from 'classnames';
import { ConfirmationModal } from 'components-antd';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamTransactionPermissionsEffect, updateMemberProfileEffect } from 'store/effects';
import { getUserId } from 'store/selectors/user';

export const TransactionCreationAccess = ({
  Id,
  Name,
  isOwner,
  CanCreateTransaction: canCreateTransaction,
}) => {
  const [enable, setEnable] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const loggedInUserId = useSelector(getUserId);

  const dispatch = useDispatch();

  const onModifyTransactionCreation = () => {
    setLoading(true);
    dispatch(
      updateMemberProfileEffect(
        { canCreateTransaction: enable },
        { agentId: Id, silent: true },
        (err) => {
          if (!err) {
            dispatch(
              getTeamTransactionPermissionsEffect({}, { silent: true }, () => {
                setLoading(false);
                setConfirmationOpen(false);
              }),
            );
          } else {
            setLoading(false);
          }
        },
      ),
    );
  };

  const onCancelAccess = () => {
    setConfirmationOpen(false);
    setEnable(!enable);
  };

  if (loggedInUserId === Id && !isOwner) return <></>;

  return (
    <div className={classNames(styles.transactionCreationAccess)}>
      <Switch
        className={styles.switch}
        checked={canCreateTransaction}
        onClick={(value) => {
          setEnable(value);
          setConfirmationOpen(true);
        }}
        disabled={isOwner}
      />

      <div className={classNames(styles.text, { [styles.enabledText]: canCreateTransaction })}>
        {canCreateTransaction ? 'Enabled' : 'Disabled'}
      </div>

      <ConfirmationModal
        width={430}
        className={styles.transactionAccessConfirmationModal}
        confirmText={`Are you sure you want to ${
          enable ? 'enable' : 'disable'
        } transaction creation for
            ${Name}?`}
        open={confirmationOpen}
        onOk={onModifyTransactionCreation}
        onCancel={onCancelAccess}
        closable={false}
        aria-modal="transactionCreationModal"
        confirmLoading={loading}
      />
    </div>
  );
};
