import { Fragment, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Spinner from 'components/Spinner';

import styles from './styles.module.scss';
import { PDFWidgets } from './components/PDFWidgets';

import { useDispatch } from 'react-redux';
import { dynamicFormScaleChangeEffect } from 'store/effects/formProcess';
import { getPageImage } from 'api/transactions';
import { A4_PAGE_HEIGHT, A4_PAGE_WIDTH, getImageDimensions } from './helper';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';

type PrintPDFProps = {
  pdfDocuments: any[];
  pageRender: (params: { pageIndex: number; documentIndex: number; scale: number }) => ReactElement;
  className?: string;
  classNamePdfDocument?: string;
  classNameDocumentPage?: string;
  children?: ReactNode;
  widgets?: ReactNode;
  pdfLoading?: boolean;
  pdfDocRefs?: any;
  pdfDocAndPageRefs?: any;
  isDynamicView?: boolean;
  allDocuments: any[];
  pageContentRef?: any;
  pdfPageClassName?: string;
};

type LoadedPDFsType = {
  pageWidth: number;
  pageHeight: number;
  documentLink: string;
  pageNumber: number;
  pageImageURL: string;
};

const MAX_ZOOM_SCALE = 2.5;
const SCALE_STEP = 0.5;
const LOAD_PAGES_COUNT = 4;

export const DEFAULT_PDF_SCALE = 1.5;

export const PrintFormPDF = ({
  pageRender,
  className,
  classNamePdfDocument,
  classNameDocumentPage,
  pdfPageClassName,

  children,
  widgets,

  pdfDocRefs,
  pdfDocAndPageRefs,
  pdfLoading,
  isDynamicView,
  allDocuments,
  pdfDocuments,
  pageContentRef,
}: PrintPDFProps) => {
  const [scale, setScale] = useState(DEFAULT_PDF_SCALE);
  const dispatch = useDispatch();
  const observer = useRef<IntersectionObserver | null>(null);
  const [loadedPages, setLoadedPages] = useState<LoadedPDFsType[]>([]);

  const observeUnloadedPages = () => {
    if (!observer.current) {
      observer.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && entry.target.classList.contains('page-loading')) {
              fetchUnloadedPDFs();
              observer.current?.unobserve(entry.target); // Stop observing once it's loaded
            }
          });
        },
        {
          root: pageContentRef.current,
          threshold: 0.1,
          rootMargin: '0px',
        },
      );
    }

    const pdfLoadingElements = document.querySelectorAll('.page-loading');

    pdfLoadingElements.forEach((el) => observer.current?.observe(el));
  };

  useEffect(() => {
    fetchUnloadedPDFs();

    // Cleanup the observer on unmount
    return () => observer.current?.disconnect();
  }, []);

  useEffect(() => {
    const pdfLoadingElements = document.querySelectorAll('.page-loading');

    pdfLoadingElements.forEach((el) => observer.current?.observe(el));

    if (loadedPages.length) {
      const documentLinks = allDocuments?.map(({ DocumentLink }) => DocumentLink);
      const removedDocuments = loadedPages.filter(
        ({ documentLink }) => !documentLinks.includes(documentLink),
      );

      if (removedDocuments.length) {
        const updatedLoadedPages = loadedPages.filter(({ documentLink }) =>
          documentLinks.includes(documentLink),
        );

        setLoadedPages(updatedLoadedPages);
      }
    }
  }, [allDocuments]);

  const removeSpinner = (elements) => {
    elements.forEach((element) => {
      const documentLink = element.getAttribute('document-id');
      const page = element.getAttribute('page-id');

      const spinnerClass = `pdf-spinner-${documentLink}-${page}`;
      const spinnerElement = document.getElementsByClassName(spinnerClass)[0] as HTMLElement;
      if (spinnerElement) {
        spinnerElement.classList.remove(spinnerClass);
        spinnerElement.style.display = 'none';
      }
    });
  };

  const fetchUnloadedPDFs = async () => {
    const pdfLoadingElements = document.querySelectorAll('.page-loading');
    const loadingElements = Array.from(pdfLoadingElements).slice(0, LOAD_PAGES_COUNT);

    loadingElements?.forEach((el) => el.classList.remove('page-loading'));

    const loadedPDFs = await Promise.all(
      loadingElements?.map(async (element) => {
        const documentLink = element.getAttribute('document-id');
        const page = element.getAttribute('page-id');

        const documentParams = dynamicManager.getLoadDocumentParams();

        const config = {
          DocumentVaultUUID: documentLink,
          params: { page, ...(documentParams && documentParams) },
          responseType: 'arraybuffer',
        };

        const imageResponse = await getPageImage(config);

        const blob = new Blob([imageResponse.data]);

        // Create a URL for the Blob
        const imageURL = URL.createObjectURL(blob);

        const { naturalWidth, naturalHeight } = await getImageDimensions(imageURL);

        element.classList.add('page-loaded');

        return {
          pageWidth: naturalWidth / 2,
          pageHeight: naturalHeight / 2,
          documentLink: documentLink || '',
          pageNumber: +(page || ''),
          pageImageURL: imageURL,
        };
      }),
    );

    removeSpinner(loadingElements);
    setLoadedPages((prev) => [...prev, ...loadedPDFs]);

    // Re-attach the observer to check for any remaining unloaded pages

    observeUnloadedPages();
  };

  const updateScale = (step) => {
    const newScale = scale + step * SCALE_STEP;
    if (newScale < DEFAULT_PDF_SCALE || newScale > MAX_ZOOM_SCALE) {
      return;
    }
    setScale(newScale);

    dispatch(dynamicFormScaleChangeEffect({ scale: newScale }));
  };

  const renderPages = (pages, docIndex, docZIndex, documentLink) => {
    return (
      <Fragment>
        {pages.map((_, pageIndex) => {
          const key = `pdf-page-${docIndex}-${pageIndex}`;
          const pageNumber = pageIndex + 1;
          const loadedPageItem = loadedPages.find(
            (item) => item.documentLink === documentLink && item.pageNumber === pageNumber,
          );

          const scaledPageWidth = loadedPageItem
            ? loadedPageItem.pageWidth * scale
            : A4_PAGE_WIDTH * scale;

          const scaledPageHeight = loadedPageItem
            ? loadedPageItem.pageHeight * scale
            : A4_PAGE_HEIGHT * scale;

          return (
            <div
              key={key}
              id={key}
              className={classNames(
                classNameDocumentPage,
                loadedPageItem ? 'page-loaded' : 'page-loading',
              )}
              ref={(el) => (pdfDocAndPageRefs ? (pdfDocAndPageRefs.current[key] = el) : undefined)}
              document-id={documentLink}
              page-id={pageNumber}
              style={{ ...(isDynamicView ? { zIndex: pages.length - pageIndex } : {}) }}
            >
              <div className={pdfPageClassName}>
                <div
                  className={styles.pagePlaceholder}
                  style={{
                    position: 'relative',
                    width: scaledPageWidth,
                    height: scaledPageHeight,
                    ...(loadedPageItem && {
                      backgroundRepeat: 'no-repeat',
                      backgroundImage: `url(${loadedPageItem.pageImageURL})`,
                      backgroundSize: `100% ${scaledPageHeight}px`,
                    }),
                  }}
                >
                  <Spinner
                    className={classNames(
                      styles.pdfSpinner,
                      !loadedPageItem
                        ? `pdf-spinner-${documentLink}-${pageNumber}`
                        : styles.hideSpinner,
                    )}
                    loaderClassName={styles.loadingSpinner}
                  />

                  <div
                    className={styles.renderFieldsContainer}
                    key={`page-field-container-${docIndex}-${pageIndex}`}
                  >
                    {pageRender({ pageIndex, documentIndex: docIndex, scale })}
                  </div>
                </div>

                <div className={styles.blackWrapper}>
                  <div>{`${pageNumber} of ${allDocuments?.[docIndex].totalPages}`}</div>
                </div>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  };

  const renderPDF = () => {
    return allDocuments && !pdfLoading ? (
      <>
        {allDocuments?.map((doc: any, docIndex) => {
          const pages = Array.from(Array(doc.totalPages).keys());
          const docZIndex = allDocuments.length - docIndex;
          return (
            <div
              key={docIndex}
              id={`pdf-doc-${docIndex}`}
              className={classNames(styles.pdfDocument, classNamePdfDocument)}
              style={isDynamicView ? { zIndex: docZIndex } : {}}
              ref={(el) =>
                pdfDocRefs ? (pdfDocRefs.current[`pdf-doc-${docIndex}`] = el) : undefined
              }
            >
              {renderPages(pages, docIndex, docZIndex, doc.DocumentLink)}

              {docIndex === 0 ? children : <></>}
            </div>
          );
        })}

        {widgets}

        {allDocuments.length > 0 && (
          <PDFWidgets
            updateScale={updateScale}
            disableZoomIn={scale === MAX_ZOOM_SCALE}
            disableZoomOut={scale === DEFAULT_PDF_SCALE}
            className={isDynamicView ? styles.dynamicViewerZoomWidget : ''}
          />
        )}
      </>
    ) : (
      <Spinner />
    );
  };

  return <div className={classNames(styles.pdfContainer, className)}>{renderPDF()}</div>;
};
