import styles from './styles.module.scss';
import classNames from 'classnames';
import { Spinner } from 'components';
import { useEffect, useRef, useState } from 'react';
import { useResize } from 'hooks';
import { MOBILE_WIDTH } from '../../../helper';
import { getDocumentThumbnails } from 'api/transactions';

type DocumentThumbnailType = {
  totalPages: number;
  thumbnails: { data: string }[];
};

export const FormPageSection = (props) => {
  const { pageNumber, docIndex, setOpenList, allDocuments } = props;

  const [pageLoading, setPageLoading] = useState(false);
  const [documentsThumbnails, setDocumentsThumbnails] = useState<DocumentThumbnailType[]>([]);

  const pageRefs = useRef({});

  const {
    screen: { width },
  } = useResize();

  const manageDocumentThumbnails = async () => {
    setPageLoading(true);

    const thumbnails: any = await Promise.all(
      allDocuments?.map(async (doc: any, index) => {
        const response = await getDocumentThumbnails({ DocumentVaultUUID: doc.DocumentLink });
        response.data.document = { ...doc };
        response.data.documentIndex = index;

        return response.data;
      }),
    );

    setDocumentsThumbnails(thumbnails);
    setPageLoading(false);
  };

  useEffect(() => {
    const element = document.getElementById(`page-${docIndex}-${pageNumber}`);
    if (element) {
      element?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [docIndex, pageNumber]);

  useEffect(() => {
    if (allDocuments.length) {
      manageDocumentThumbnails();
    }
  }, [allDocuments]);

  const onPageChange = (documentIndex, pageIndex) => {
    const key = `pdf-page-${documentIndex}-${pageIndex}`;

    const element = document.getElementById(key);

    element?.scrollIntoView({
      behavior: 'smooth',
    });

    width <= MOBILE_WIDTH && setOpenList(false);
  };

  if (!documentsThumbnails || documentsThumbnails.length === 0) {
    if (pageLoading) return <Spinner loaderClassName={styles.loadingSpinner} />;

    return <></>;
  }

  return (
    <div className={classNames(styles.pageSection, styles.pdfPageSection)}>
      {documentsThumbnails.map((document, index) => (
        <div key={`document-${index}`} className={styles.documentSection}>
          <div className={styles.pageSectionHeader}>
            <div className={styles.pagesTitle}>Pages</div>
            {!pageLoading && (
              <div className={styles.pagesCount}>
                {index === +props.docIndex ? props.pageNumber + 1 : 1} of {document.totalPages}
              </div>
            )}
          </div>

          {pageLoading ? (
            <Spinner loaderClassName={styles.loadingSpinner} />
          ) : (
            <div className={styles.pages}>
              {document.thumbnails.map((page, pageIndex) => (
                <div
                  ref={(el) => {
                    pageRefs.current[`pdf-page-${index}-${pageIndex}`] = el;
                  }}
                  key={`page-${index}-${pageIndex}`}
                  id={`page-${index}-${pageIndex}`}
                  className={classNames(styles.documentPage, {
                    [styles.activePage]: index === +docIndex && pageIndex === pageNumber,
                  })}
                  onClick={() => onPageChange(index, pageIndex)}
                >
                  <div
                    key={`pdf-page-${pageIndex}`}
                    className={styles.documentPageThumbnail}
                    style={{ backgroundImage: `url(data:image/jpeg;base64,${page.data})` }}
                  />

                  <div className={styles.pageNumber}>{pageIndex + 1}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
