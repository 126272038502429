/* eslint-disable no-case-declarations */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { MATCH_LEVEL } from 'settings/constants/common';
import { getListingDetailData } from 'store/selectors/listingDetail';
import { getKitchenAvgSizeSelector } from 'store/selectors/propertyFeatures';
import { getClientSearchInstancesSelector } from 'store/selectors/clientInstances';
import { softCriteria } from './constants';
import { prefsIds } from 'settings/constants/preferences';

import styles from './styles.module.scss';

export const Insight = ({ name, matchLevel, dataSource }) => {
  const details = useSelector(getListingDetailData);
  const kitchenAvgSize = useSelector(getKitchenAvgSizeSelector);
  const instances = useSelector(getClientSearchInstancesSelector);
  const searchInstance = instances.find((i) => i.Id === details.SearchInstanceId);
  const searchPref = searchInstance?.DefaultPropertySearchPreferences;

  const getCommuteToLocationTagline = (isProximity: boolean) => {
    const commuteName = name.split(
      isProximity ? softCriteria.ProximityTo : softCriteria.CommuteTo,
    )?.[1];

    const commute = details?.Commutes?.find((c) => c.Name === commuteName);

    if (commute) {
      const times = commute?.Times;
      const time = times?.Car || times?.PublicTransit || times?.Bicycle || times?.Walk || '';
      return `This property is within ${time} (minutes) of ${commuteName}`;
    }
    return '';
  };

  const getZonedSchoolsTagline = () => {
    const schoolName = name.split(softCriteria.ZonedTo)?.[1];
    return `This property is zoned to ${schoolName} school`;
  };

  const getFlooringTypeTagline = () => {
    const featureName = name.split(' - ').join('');
    const flooring = details?.MatchedCriterias?.HomeFeatures?.find(
      (f) => f.Feature === featureName,
    );
    const flooringPercent = flooring?.ExtraData?.Percentage;

    return flooring && flooringPercent
      ? `${flooringPercent}% of the flooring in this property is ${
          name.split(softCriteria.Flooring)[1]
        }`
      : '';
  };

  const getWalkabilityTagline = () => {
    const walkScore = details?.TransportationScore?.WalkScore;
    return walkScore ? `This property has a WalkScore of ${walkScore}` : '';
  };

  const getPublicTransitTagline = () => {
    const transitScore = details?.TransportationScore?.TransitScore;
    return transitScore ? `This property has a TransitScore of ${transitScore}` : '';
  };

  const getNewConstructionTagline = () => {
    const yearBuilt = details?.YearBuilt;
    return yearBuilt
      ? `This property was built in ${yearBuilt} and is classified as new construction`
      : '';
  };

  const getSoftParkingSpaceTagline = () => {
    const parking = details?.Parking;
    const spaces = parking?.NumOfParkingSpaces || parking?.NumOfGarageSpaces;
    const garageType = parking?.GarageType || '';
    const garageFeatures = parking?.ParkingFeatures || '';
    let text = '';
    text = spaces ? `This property has ${spaces} parking spot${spaces === 1 ? '' : 's'}.` : '';

    const extraInfoPieces: string[] = [];
    if (garageType) {
      extraInfoPieces.push(`${spaces === 1 ? 'is' : 'are'} ${garageType?.toLowerCase()}`);
    }

    if (garageFeatures) {
      extraInfoPieces.push(
        `${spaces === 1 ? 'has' : 'have'} the following features: ${garageFeatures
          .toLowerCase()
          .replace(',', ', ')}`,
      );
    }
    if (extraInfoPieces.length) {
      text += `The spot${spaces === 1 ? '' : 's'} ${extraInfoPieces.join(' and ')}`;
    }
    return text;
  };

  const getParkingSpaceTagline = () => {
    const parking = details?.Parking;
    const spaces = parking?.NumOfParkingSpaces || parking?.NumOfGarageSpaces || '';
    const garageType = parking?.GarageType || '';
    const garageFeatures = parking?.GarageFeatures || '';
    let text = spaces && `This property has ${spaces} parking spot${spaces === 1 ? '' : 's'}`;

    const extraInfoPieces: string[] = [];
    if (garageType) {
      extraInfoPieces.push(`${spaces === 1 ? 'is' : 'are'} ${garageType?.toLowerCase()}`);
    }

    if (garageFeatures) {
      extraInfoPieces.push(
        `${spaces === 1 ? 'has' : 'have'} the following features: ${garageFeatures
          .toLowerCase()
          .replace(',', ', ')}`,
      );
    }
    if (extraInfoPieces.length) {
      text += `. The spot${spaces === 1 ? '' : 's'} ${extraInfoPieces.join(' and ')}`;
    }
    return text;
  };

  const getStoriesTagline = () => {
    const stories = details?.NumOfStories;
    return stories ? `This property has ${stories} stor${stories === 1 ? 'y' : 'ies'}` : '';
  };

  const getKitchenLargerThanAverageTagline = () => {
    const kitchenFeatures = details?.CustomKitchenFeatures;
    const avgSize = kitchenAvgSize || kitchenFeatures?.AvgSize;
    const size = details?.Interior?.Rooms?.Kitchen?.Size;
    const area = kitchenFeatures?.Area;

    if (size && area && avgSize) {
      const percentage = Math.round(((area - avgSize) / avgSize) * 100);
      return `The kitchen size in this property is ${size} (${area} sq. ft.). The average kitchen size of properties currently on the market in this area${
        searchPref?.PriceRange?.Max ? ' and in this price range' : ''
      } is ${Math.round(avgSize)} sq. ft. This kitchen is ${percentage}% larger than the average`;
    }
    return '';
  };

  const getOutdoorSpaceTagline = () => {
    const outdoorInsights = details?.OutdoorInsights;
    return outdoorInsights?.length ? `This property has (${outdoorInsights.join(', ')})` : '';
  };

  const getHomeOfficeTagline = () => {
    let text = '';
    const customHomeOffice = details?.CustomHomeOfficeAvailable;
    const homeOfficeInsights = details?.HomeOfficeInsights;
    const numBeds = details?.NumBeds;
    const minBeds = searchPref?.NumBedroomsRange?.Min;
    if (customHomeOffice && homeOfficeInsights?.length) {
      text = `This property has (${homeOfficeInsights.join(
        ', ',
      )}). There may be potential for it to work as a home office`;
    } else {
      text = `This property has a total of ${numBeds} bedrooms, ${
        numBeds - (minBeds || 1)
      } more than your minimum. There may be potential for one of the bedrooms to work as a home office`;
    }
    return text;
  };

  const getLaundryRoomTagline = () => {
    return details?.CustomLaundryKind?.toLowerCase() === 'combined'
      ? `This property has laundry hook-ups, but may not have a dedicated laundry room`
      : '';
  };

  const addPlusWithCount = (nearbyType) => {
    const full = nearbyType?.FullScoreRadiusPlacesCount;
    const partial = nearbyType?.PartialScoreRadiusPlacesCount;

    return (matchLevel === MATCH_LEVEL.FULL && (full === 50 || full === 100)) ||
      (matchLevel === MATCH_LEVEL.PARTIAL && (partial === 50 || partial === 100))
      ? '+'
      : '';
  };

  const getNearbyPlacesTagline = (type, name) => {
    const nearbyType = details?.NearbyPlaces?.[type] || details?.External_NearbyPlaces?.[type];
    const score =
      matchLevel === MATCH_LEVEL.FULL
        ? nearbyType.FullScoreRadiusPlacesCount
        : nearbyType.PartialScoreRadiusPlacesCount;
    let radius: any = Number(
      matchLevel === MATCH_LEVEL.FULL
        ? nearbyType.FullScoreRadiusInMiles
        : nearbyType.PartialScoreRadiusInMiles,
    );

    if (radius > 0 && radius < 1) radius = String(radius).substring(1);

    return nearbyType
      ? `There are ${score}${addPlusWithCount(nearbyType)} ${name} within a ${radius} mile radius`
      : '';
  };

  const getInsightTagline = () => {
    if (
      matchLevel === MATCH_LEVEL.FULL ||
      (([
        softCriteria.Parking,
        softCriteria.RestaurantsBars,
        softCriteria.CoffeeShops,
        softCriteria.Fitness,
        softCriteria.Nightlife,
        softCriteria.ParksAndNature,
        softCriteria.ArtAndEntertainment,
      ].includes(name) ||
        name.includes(softCriteria.Flooring)) &&
        matchLevel === MATCH_LEVEL.PARTIAL)
    ) {
      let text = '';
      if (name.includes(softCriteria.ProximityTo) || name.includes(softCriteria.CommuteTo)) {
        const isProximity = name.includes(softCriteria.ProximityTo);
        text = getCommuteToLocationTagline(isProximity);
      }

      if (name.includes(softCriteria.ZonedTo)) {
        text = getZonedSchoolsTagline();
      }

      if (name.includes(softCriteria.Flooring)) {
        text = getFlooringTypeTagline();
      }

      switch (name) {
        case softCriteria.Walkability:
          text = getWalkabilityTagline();
          break;

        case softCriteria.PublicTransit:
          text = getPublicTransitTagline();
          break;

        case softCriteria.NewConstruction:
          text = getNewConstructionTagline();
          break;

        case softCriteria.ParkingSpaces:
          text = getParkingSpaceTagline();
          break;

        case softCriteria.Parking:
          text = getSoftParkingSpaceTagline();
          break;

        case softCriteria.Stories:
          text = getStoriesTagline();
          break;

        case softCriteria.KitchenFeaturesLargerThanAverage:
          text = getKitchenLargerThanAverageTagline();
          break;

        case softCriteria.OutdoorSpace:
          text = getOutdoorSpaceTagline();
          break;

        case softCriteria.HomeOffice:
          text = getHomeOfficeTagline();
          break;

        case softCriteria.LaundryRoom:
          text = getLaundryRoomTagline();
          break;

        case softCriteria.RestaurantsBars:
          text = getNearbyPlacesTagline(
            prefsIds.restaurants,
            softCriteria.RestaurantsBars.toLowerCase(),
          );
          break;

        case softCriteria.CoffeeShops:
          text = getNearbyPlacesTagline(
            prefsIds.coffeeShops,
            softCriteria.CoffeeShops.toLowerCase(),
          );
          break;

        case softCriteria.Fitness:
          text = getNearbyPlacesTagline(prefsIds.fitness, 'gyms and fitness studios');
          break;

        case softCriteria.ParksAndNature:
          text = getNearbyPlacesTagline(prefsIds.parksAndNature, 'parks');
          break;

        case softCriteria.Nightlife:
          text = getNearbyPlacesTagline(prefsIds.nightlife, 'nightlife spots');
          break;

        case softCriteria.ArtAndEntertainment:
          text = getNearbyPlacesTagline(
            prefsIds.artAndEntertainment,
            'arts and entertainment spots',
          );
          break;

        default:
          break;
      }

      const textWithDataSource =
        text && dataSource
          ? `${text} (${dataSourceToStringMapping[dataSource]}).`
          : text
          ? `${text}.`
          : null;

      return textWithDataSource ? <p className={styles.insight}>{textWithDataSource}</p> : null;
    }
  };

  return <>{getInsightTagline()}</>;
};

enum MatchScoreDataSource {
  MLS = 'MLS',
  MLS_KEYWORDS = 'MLS_KEYWORDS',
  MLS_AGGREGATED = 'MLS_AGGREGATED',
  MLS_HEURISTICS = 'MLS_HEURISTICS',
  THIRD_PARTY = 'THIRD_PARTY',
  WALKSCORE = 'WALKSCORE',
  GEO_DATA = 'GEO_DATA',
  IMAGE_AI = 'IMAGE_AI',
}

const dataSourceToStringMapping = {
  [MatchScoreDataSource.MLS]: 'per MLS data',
  [MatchScoreDataSource.MLS_KEYWORDS]: 'per MLS data',
  [MatchScoreDataSource.MLS_AGGREGATED]: 'per MLS data',
  [MatchScoreDataSource.MLS_HEURISTICS]: 'per MLS data',
  [MatchScoreDataSource.THIRD_PARTY]: 'per external data providers', // Not used but can be used as fallback
  [MatchScoreDataSource.WALKSCORE]: 'per Walkscore',
  [MatchScoreDataSource.GEO_DATA]: 'per geospatial data',
  [MatchScoreDataSource.IMAGE_AI]: 'per the listing photos',
};

Insight.propTypes = {
  name: PropTypes.string,
  matchLevel: PropTypes.string,
  dataSource: PropTypes.oneOf(Object.values(MatchScoreDataSource)),
};

Insight.defaultProps = {
  name: '',
  matchLevel: MATCH_LEVEL.NONE,
};
