import { handleActions } from 'redux-actions';
import { cloneDeep, get } from 'lodash-es';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';
import {
  setTeamStatsAction,
  setSelectedGraphAction,
  setTimePeriodAction,
  setExpandChartAction,
  setShowSubChartsAction,
  setTransactionFilterAction,
  setTimePeriodDueFromAction,
  setTimePeriodDueToAction,
} from 'store/actions/clarity';
import { Icons } from 'pages/Workshop/Icons';

const initialData = {
  state: IDLE,
  teamStats: { itemValue: 'Team', icon: Icons.TEAM_USER, iconColor: '#04a451' },
  isGraphSelected: false,
  graphName: '',
  timePeriod: 'YTD',
  expandChart: null,
  graphColor: '#827CCE',
  showSubCharts: false,
  transactionFilter: 0,
  dueFrom: null,
  dueTo: null,
  meta: {},
};

export default handleActions(
  {
    [setTeamStatsAction.toString()]: (state, { payload }) => ({
      ...state,
      teamStats: get(payload, 'value', initialData.teamStats) || state.teamStats,
      meta: get(payload, 'meta', initialData.meta),
    }),
    [setSelectedGraphAction.toString()]: (state, { payload }) => ({
      ...state,
      isGraphSelected: get(payload, 'isGraphSelected', initialData.isGraphSelected),
      graphName: get(payload, 'graphName', initialData.graphName) || state.graphName,
      graphColor: get(payload, 'graphColor', initialData.graphColor) || state.graphColor,
    }),
    [setTimePeriodAction.toString()]: (state, { payload }) => ({
      ...state,
      timePeriod: get(payload, 'value', initialData.timePeriod) || state.timePeriod,
    }),
    [setExpandChartAction.toString()]: (state, { payload }) => ({
      ...state,
      expandChart: get(payload, 'value', initialData.expandChart),
    }),
    [setShowSubChartsAction.toString()]: (state, { payload }) => ({
      ...state,
      showSubCharts: get(payload, 'value', initialData.showSubCharts),
    }),
    [setTransactionFilterAction.toString()]: (state, { payload }) => ({
      ...state,
      transactionFilter: get(payload, 'value', initialData.transactionFilter),
    }),
    [setTimePeriodDueFromAction.toString()]: (state, { payload }) => ({
      ...state,
      dueFrom: get(payload, 'value', initialData.dueFrom),
    }),
    [setTimePeriodDueToAction.toString()]: (state, { payload }) => ({
      ...state,
      dueTo: get(payload, 'value', initialData.dueTo),
    }),
    [appLogoutAction.toString()]: () => cloneDeep(initialData),
  },
  initialData,
);
