import React from 'react';

export const ResendKitIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.1" cx="16" cy="16" r="16" fill="#747475" />
      <path
        d="M10.3665 18C10.8724 19.3848 11.8703 20.5348 13.1699 21.2308C14.4695 21.9267 15.9798 22.1198 17.4127 21.7733C18.8456 21.4267 20.1008 20.5648 20.9388 19.3519C21.7767 18.139 22.1388 16.66 21.956 15.1972C21.7731 13.7344 21.0581 12.39 19.9474 11.4207C18.8366 10.4514 17.4079 9.92496 15.9338 9.9418C14.4597 9.95864 13.0433 10.5176 11.955 11.512C11.2061 12.1963 10.6487 13.0538 10.3251 14M10.0332 21.3334V18H13.3665"
        stroke="#515151"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
