import { useEffect, useState } from 'react';
import {
  FileSizeError,
  FormFileUploader,
  TemplateCheckboxFields,
  TemplateFields,
} from '../components';
import { FormHeader } from '../components/FormHeader';
import styles from './style.module.scss';
import { handleFileUpload, initialFieldState, initialUploadErrorState } from '../../helper';
import Navigation from '../components/Footer';
import { UploadFile } from 'antd';
import { uploadDocumentEffect } from 'store/effects/quotes';
import { useDispatch } from 'react-redux';
import { createNewFormEffect, createNewTemplateEffect } from 'store/effects/formBuilder';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';

type TemplateResponseType = {
  value: { formTemplate: { FormId; TemplateId; FormVersionId } };
};

type CreateNewFormType = {
  data: { value: { link; formId; formVersionId } };
};

export const NewTemplate = ({ stagesStep, setCurrentStageIndex }) => {
  const [templateFieldState, setTemplateState] = useState({
    ...initialFieldState,
    saveAsTemplate: true,
  });

  const [filesChangeCounter, setFilesChangeCounter] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();

  const [templateErrors, setTemplateErrors] = useState(initialUploadErrorState);
  const [files, setFiles] = useState<UploadFile<any>[]>([]);
  const [fileSizeError, setFileSizeError] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      setFilesChangeCounter(0);
    };
  }, []);

  const handlePrevious = () => {
    setCurrentStageIndex(stagesStep.FormCategories);
  };

  const onChangeTemplateField = (data) => {
    setTemplateState((prev) => ({ ...prev, ...data }));
  };

  const isValid = () => {
    let valid = true;
    const errors = { ...templateErrors };
    const { templateName, categories } = templateFieldState;

    if (!templateName.trim().length) {
      errors.templateNameError = 'Required!';
      valid = false;
    }
    if (!categories.length) {
      errors.categoriesError = 'Required!';
      valid = false;
    }

    !valid && setTemplateErrors(errors);
    return valid;
  };

  const redirectToTemplateWizard = ({ FormId, TemplateId, FormVersionId, ...rest }) => {
    history.push({
      pathname: routes.workshopFormsSignatoriesRequest,
      state: {
        templateName: templateFieldState.templateName,
        createNewTemplate: true,
        formId: FormId,
        templateId: TemplateId,
        formVersionId: FormVersionId,
        ...rest,
      },
    });
  };
  const onNext = async () => {
    if (isValid()) {
      setLoading(true);
      const isMultipleFiles = files.length > 1;

      try {
        const multipleFilesResult: any = isMultipleFiles
          ? await handleFileUpload({
              fileName: templateFieldState.templateName,
              files,
            })
          : null;

        const uploadRes: any = await dispatch(uploadDocumentEffect({ files }));
        if (!uploadRes?.data?.result?.DocumentVaultUUID) {
          throw new Error('DocumentVaultUUID is missing from the upload response');
        }

        const result = uploadRes.data.result;

        // Create new form
        const formRes: any = await dispatch(
          createNewFormEffect({
            formName: templateFieldState.templateName,
            link: result.DocumentVaultUUID,
            type: 'Agent',
            formCategoryTag: templateFieldState.categories,
          }),
        );

        if (!formRes) {
          throw new Error('Failed to create a new form');
        }

        // Create new template
        const { formId, formVersionId, link } = formRes.data.value;
        let navigationState: any = {
          formId,
          formVersionId,
          createNewTemplate: true,
          templateName: templateFieldState.templateName,
        };

        if (isMultipleFiles) {
          navigationState = {
            ...navigationState,
            documentLinks: multipleFilesResult.documentLink,
            categories: templateFieldState.categories,
            publishToTeam: templateFieldState.publishToTeam,
          };
        } else {
          const templateRes: any = await dispatch(
            createNewTemplateEffect({
              link,
              formId,
              formVersionId,
              templateName: templateFieldState.templateName,
              publishToTeam: templateFieldState.publishToTeam,
              saveAsTemplate: true,
            }),
          );

          if (!templateRes) {
            throw new Error('Failed to create a new template');
          }

          navigationState = {
            ...navigationState,
            templateId: templateRes.data?.value?.formTemplate?.TemplateId,
          };
        }

        history.push({
          pathname: routes.workshopFormsSignatoriesRequest,
          state: navigationState,
        });
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.newTemplateWrapper}>
      <FormHeader heading={'Create a Template'} handlePrevious={handlePrevious} />
      <div className={styles.newTemplateContent}>
        <TemplateFields
          fieldState={templateFieldState}
          onChange={onChangeTemplateField}
          errors={templateErrors}
          setErrors={setTemplateErrors}
          saveAsTemplate={templateFieldState.saveAsTemplate}
          className={styles.templateFieldsList}
          fileNameLabel="Name"
          fileNameRequired={true}
          isNewTemplate={true}
        />

        <TemplateCheckboxFields
          values={templateFieldState}
          onChange={onChangeTemplateField}
          setErrors={setTemplateErrors}
          isNewTemplate={true}
        />

        <div className={styles.formFileUploader}>
          <div className={styles.label}>Upload Files</div>
          <FormFileUploader
            updateFiles={setFiles}
            files={files}
            filesChangeCounter={filesChangeCounter}
            setFilesChangeCounter={setFilesChangeCounter}
            fileSizeError={fileSizeError}
            setFileSizeError={setFileSizeError}
          />
        </div>
      </div>

      {fileSizeError && <FileSizeError files={files} />}

      <Navigation
        onNext={onNext}
        loading={loading}
        disabled={!files.length || loading || fileSizeError}
        size="large"
      />
    </div>
  );
};
