import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as toastr from 'toastr';

import {
  AddParticipantsDrawer,
  AppLoading,
  ClientsDrawer,
  MessageAttachmentsDrawer,
  MessagesDrawer,
  NotificationsDrawer,
  QuotesDrawer,
  ThreadContactsDrawer,
  ViewQuoteActivityDrawer,
} from 'components';
import { AgentInvitation } from 'components-antd/Client/AgentInvitation';
import ScreenContext from 'contexts/screen';
import { useResize } from 'hooks';
import { appInitEffect } from 'store/effects/app';
import Hint from './Hint';
import { PageContentContainer } from './PageContentContainer';

import { EmailVerificationModal } from 'features/emailVerification/EmailVerificationModal';
import { MlsAccessModal } from 'features/mlsAccess/MlsAccessModal';
import { PaymentUpdateModel } from 'pages/Paywall/PaymentUpdateModal/PaymentUpdateModal';
import 'styles/global.scss';
import 'toastr/build/toastr.css';

toastr.options.positionClass = 'toast-bottom-right';

const App = () => {
  const specs = useResize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appInitEffect());
  }, []);

  return (
    <ScreenContext.Provider value={specs}>
      <AppLoading>
        <PageContentContainer />
        <AgentInvitation />
        <ClientsDrawer />
        <MessagesDrawer />
        <QuotesDrawer />
        <ThreadContactsDrawer />
        <MessageAttachmentsDrawer />
        <ViewQuoteActivityDrawer />
        <AddParticipantsDrawer />
        <NotificationsDrawer />
        <EmailVerificationModal />
        <MlsAccessModal />
        <Hint />
        <PaymentUpdateModel />
      </AppLoading>
    </ScreenContext.Provider>
  );
};

export default App;
