import { useSelector } from 'react-redux';
import { LeadSources, Fees, VolumeStats, FinancialStats } from './ContentTypes';
import { Other } from './ContentTypes/Financials/Other';
import { getExpandChart, getGraphNameSelector } from 'store/selectors/clarity';
import { GeneralLedgerKeys } from './ContentTypes/helper';
import { modalTableColumns } from './Components/TableView/TableModal/Financial';
import { LedgerAccountId } from 'settings/constants/ledger';

export const Content = ({ value }) => {
  const expandChart = useSelector(getExpandChart);

  const colors = {
    Listings: '#8882D9',
    Purchases: '#4673D1',
    Closed: '#04A451',
    Expired: '#FB913A',
    Canceled: '#FF576D',
    GCI: '#FB913A',
    AgentSplit: '#8882D9',
    TeamSplit: '#04A451',
    BrokerageSplit: '#FB913A',
    AgentNetIncome: '#4673D1',
    TeamNetIncome: '#FF576D',
    BrokerageNetIncome: '#8882D9',
    CommissionListSide: '#FB913A',
    CommissionBuySide: '#04A451',
  };

  const renderContent = () => {
    if (['Listings', 'Purchases', 'Closed', 'Expired', 'Canceled'].includes(value)) {
      return <VolumeStats title={value} clarityType={'Volume'} color={colors[value]} />;
    } else if (value === 'Lead Sources') {
      return <LeadSources />;
    } else {
      switch (value) {
        case 'GCI':
          return (
            <FinancialStats
              title={value}
              aggregationType={'Gross'}
              summaryType={'Commission'}
              summaryKey={GeneralLedgerKeys.GCI}
              columnType={modalTableColumns.TeamGrossNet}
              ledgerAccountId={LedgerAccountId.CommissionIncome}
              clarityType={'Financials'}
              expandedSummaryType={'CommissionExpanded'}
              color={colors.GCI}
            />
          );
        case 'Agent Split':
          return (
            <FinancialStats
              title={value}
              aggregationType={'Gross'}
              summaryType={'Splits'}
              summaryKey={GeneralLedgerKeys.AgentPayment}
              columnType={modalTableColumns.TeamGrossNet}
              ledgerAccountId={LedgerAccountId.AgentPayment}
              clarityType={'Financials'}
              expandedSummaryType={'SplitsExpanded'}
              color={colors.AgentSplit}
            />
          );
        case 'Split':
          return (
            <FinancialStats
              title={value}
              aggregationType={'Gross'}
              summaryType={'Splits'}
              summaryKey={GeneralLedgerKeys.AgentPayment}
              columnType={modalTableColumns.TeamGrossNet}
              ledgerAccountId={LedgerAccountId.AgentPayment}
              clarityType={'Financials'}
              expandedSummaryType={'SplitsExpanded'}
              color={colors.AgentSplit}
            />
          );
        case 'Team Split':
          return (
            <FinancialStats
              title={value}
              aggregationType={'Gross'}
              summaryType={'Splits'}
              summaryKey={GeneralLedgerKeys.TeamPayment}
              columnType={modalTableColumns.TeamGrossNet}
              ledgerAccountId={LedgerAccountId.TeamPayment}
              clarityType={'Financials'}
              expandedSummaryType={'SplitsExpanded'}
              color={colors.TeamSplit}
            />
          );
        case 'Brokerage Split':
          return (
            <FinancialStats
              title={value}
              aggregationType={'Gross'}
              summaryType={'Splits'}
              summaryKey={GeneralLedgerKeys.BrokeragePayment}
              columnType={modalTableColumns.TeamGrossNet}
              ledgerAccountId={LedgerAccountId.BrokeragePayment}
              clarityType={'Financials'}
              expandedSummaryType={'SplitsExpanded'}
              color={colors.BrokerageSplit}
            />
          );
        case 'Agent Net Income':
          return (
            <FinancialStats
              title={value}
              aggregationType={'Net'}
              summaryType={'Splits'}
              summaryKey={GeneralLedgerKeys.AgentPayment}
              columnType={modalTableColumns.TeamGrossNet}
              ledgerAccountId={LedgerAccountId.AgentPayment}
              clarityType={'Financials'}
              expandedSummaryType={'SplitsExpanded'}
              color={colors.AgentNetIncome}
            />
          );
        case 'Net Income':
          return (
            <FinancialStats
              title={value}
              aggregationType={'Net'}
              summaryType={'Splits'}
              summaryKey={GeneralLedgerKeys.AgentPayment}
              columnType={modalTableColumns.TeamGrossNet}
              ledgerAccountId={LedgerAccountId.AgentPayment}
              clarityType={'Financials'}
              expandedSummaryType={'SplitsExpanded'}
              color={colors.AgentNetIncome}
            />
          );
        case 'Team Net Income':
          return (
            <FinancialStats
              title={value}
              aggregationType={'Net'}
              summaryType={'Splits'}
              summaryKey={GeneralLedgerKeys.TeamPayment}
              columnType={modalTableColumns.TeamGrossNet}
              ledgerAccountId={LedgerAccountId.TeamPayment}
              clarityType={'Financials'}
              expandedSummaryType={'SplitsExpanded'}
              color={colors.TeamNetIncome}
            />
          );
        case 'Brokerage Net Income':
          return (
            <FinancialStats
              title={value}
              aggregationType={'Net'}
              summaryType={'Splits'}
              summaryKey={GeneralLedgerKeys.BrokeragePayment}
              columnType={modalTableColumns.TeamGrossNet}
              ledgerAccountId={LedgerAccountId.BrokeragePayment}
              clarityType={'Financials'}
              expandedSummaryType={'SplitsExpanded'}
              color={colors.BrokerageNetIncome}
            />
          );
        case 'Commission Rate: List-Side':
          return (
            <FinancialStats
              title={value}
              aggregationType={'Gross'}
              summaryType={'Commission'}
              summaryKey={GeneralLedgerKeys.GCI}
              columnType={modalTableColumns.TeamGrossNet}
              ledgerAccountId={LedgerAccountId.CommissionIncome}
              clarityType={'Financials'}
              expandedSummaryType={'CommissionExpanded'}
              color={colors.CommissionListSide}
              representingSide={1}
              isCommission={true}
            />
          );
        case 'Commission Rate: Buy-Side':
          return (
            <FinancialStats
              title={value}
              aggregationType={'Gross'}
              summaryType={'Commission'}
              summaryKey={GeneralLedgerKeys.GCI}
              columnType={modalTableColumns.TeamGrossNet}
              ledgerAccountId={LedgerAccountId.CommissionIncome}
              clarityType={'Financials'}
              expandedSummaryType={'CommissionExpanded'}
              color={colors.CommissionBuySide}
              representingSide={2}
              isCommission={true}
            />
          );
        default:
          if (expandChart === GeneralLedgerKeys.Fees) return <Fees />;
          else if (
            expandChart === GeneralLedgerKeys.OtherSplits ||
            expandChart === GeneralLedgerKeys.OtherNetIncomes
          )
            return <Other />;
          else return <></>;
      }
    }
  };

  return renderContent();
};
