import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const Icons = (props) => {
  const { className, variant, onClick, testid } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.TWO_WAY_ARROW: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_65315_439712)">
              <path
                d="M6 7.88208H17.5285M17.5285 7.88208L14.6463 5M17.5285 7.88208L14.6463 10.7642"
                stroke="#AAABAB"
                strokeWidth="1.97631"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.5285 16.1165H6M6 16.1165L8.88215 13.2344M6 16.1165L8.88215 18.9986"
                stroke="#AAABAB"
                strokeWidth="1.97631"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_65315_439712">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }

      case Icons.INFO: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_65305_439426)">
              <path
                d="M12.375 7C12.375 9.96853 9.96853 12.375 7 12.375C4.03147 12.375 1.625 9.96853 1.625 7C1.625 4.03147 4.03147 1.625 7 1.625C9.96853 1.625 12.375 4.03147 12.375 7Z"
                stroke="#747475"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 7C7 7.91122 7 8.27628 7 9.1875"
                stroke="#747475"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="7" cy="4.375" r="0.875" fill="#747475" />
            </g>
            <defs>
              <clipPath id="clip0_65305_439426">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }

      default: {
        return null;
      }
    }
  };

  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icons, className)}>
      {getIcons()}
    </div>
  );
};

Icons.TWO_WAY_ARROW = 'twoWayArrow';
Icons.INFO = 'infoIcon';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  testid: PropTypes.string,
  onClick: PropTypes.func,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};
