import { handleActions } from 'redux-actions';
import { get, cloneDeep } from 'lodash-es';
import {
  requestSavedSearchesAction,
  setRenameSavedSearchAction,
  setSavedSearchStatusAction,
  requestUpdateSavedSearchAction,
  deleteSavedSearchAction,
  resetSavedSearchesState,
} from 'store/actions/mySearches';
import { IDLE } from 'settings/constants/apiState';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
  timeoutId: null,
};

export default handleActions(
  {
    [requestSavedSearchesAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [resetSavedSearchesState]: (state) => ({
      ...state,
      state: IDLE,
      timeoutId: null,
    }),
    [requestUpdateSavedSearchAction]: (state, { payload }) => {
      const newSavedSearch = get(payload, 'data.result');
      const savedSearches = cloneDeep(state.data);
      const index = savedSearches.findIndex((search) => search?.Id === newSavedSearch?.Id);
      savedSearches[index] = newSavedSearch;

      return {
        ...state,
        data: savedSearches,
      };
    },
    [setRenameSavedSearchAction]: (state, { payload }) => {
      const savedSearches = cloneDeep(state.data);
      const index = savedSearches.findIndex((item) => item.Id === payload.id);
      savedSearches[index].Name = payload.name;

      return {
        ...state,
        data: savedSearches,
      };
    },
    [setSavedSearchStatusAction]: (state, { payload }) => {
      const savedSearches = cloneDeep(state.data);
      const index = savedSearches.findIndex((item) => item.Id === payload.id);
      savedSearches[index].Status = payload.status;

      return {
        ...state,
        data: savedSearches,
      };
    },
    [deleteSavedSearchAction]: (state, { payload }) => {
      const savedSearches = state.data.filter((item) => item.Id !== payload.id) || [];

      return {
        ...state,
        data: savedSearches,
      };
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
