import {
  createThread,
  sendThreadArchiveStatus,
  sendThreadMarkAsRead,
  updateThread,
  sendThreadOpenedInfo,
  sendThreadsClosedInfo,
  sendUserOfflineInfo,
  sendMsgMarkAsRead,
} from 'services/sockets';
import { showErrorMessage } from 'helpers/errors';
import {
  socketsChangeThreadArchiveStatusAction,
  socketsMarkThreadAsReadAction,
} from 'store/actions/sockets/threads';
import { getState } from 'store';
import { get, values } from 'lodash-es';
import { preparedAttachments } from '../helpers';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import { MessageAccessType } from 'app-constants/enums/message';
import { markPropertyCommentsAsRead } from 'store/actions/feedv3';

export function sendMarkThreadAsReadEffect({ threadId }) {
  return (dispatch) => {
    sendThreadMarkAsRead({ Id: threadId }, (err, data) => {
      if (err) {
        return showErrorMessage(err);
      }
      dispatch(socketsMarkThreadAsReadAction({ threadId }));
    });
  };
}

export function sendMarkMsgAsReadEffect({ threadId, msgIds }) {
  return (dispatch) => {
    sendMsgMarkAsRead({ threadId, msgIds }, (err) => {
      if (err) {
        return showErrorMessage(err);
      }
      dispatch(markPropertyCommentsAsRead({ msgIds }));
    });
  };
}

export function sendThreadArchiveStatusEffect({ threadIds, archived }) {
  return (dispatch) => {
    sendThreadArchiveStatus({ Ids: threadIds, Archived: archived }, (err) => {
      if (err) {
        return showErrorMessage(err);
      }
      dispatch(socketsChangeThreadArchiveStatusAction({ threadIds, archived }));
    });
  };
}

export function createNewThreadEffect(cfg, options, cb = (err, data) => {}) {
  return () => {
    const config = {
      Type: cfg?.Type,
      EntityId: cfg?.EntityId,
      TopicName: cfg?.TopicName,
      ParticipantIds: cfg?.ParticipantIds,
      FirstMessage: {
        Text: cfg?.FirstMessage?.Text || '',
        Attachments: preparedAttachments(cfg?.FirstMessage?.Attachments),
        MessageType: cfg?.FirstMessage?.MessageType,
        MessageMeta: cfg?.FirstMessage?.MessageMeta,
        AccessType: cfg?.FirstMessage?.AccessType ?? MessageAccessType.Everybody,
      },
      RequestTour: cfg?.RequestTour,
    };

    createThread(config, (err, data) => {
      if (err) {
        return showErrorMessage(err);
      }
      cb(err, data);
    });
  };
}

export function updateThreadEffect(cfg, options, cb = () => {}) {
  return () => {
    const config = {
      ThreadId: cfg?.threadId,
      ParticipantIds: cfg?.participantsIds,
      TopicName: cfg?.topicName,
    };

    updateThread(config, (err, data) => {
      if (err) {
        return showErrorMessage(err);
      }
      cb(err, data);
    });
  };
}

export function sendThreadOpenedEffect(cfg) {
  return () => {
    const config = {
      ThreadId: cfg?.threadId,
    };

    sendThreadOpenedInfo(config);
  };
}

export function sendThreadsClosedEffect() {
  return () => {
    sendThreadsClosedInfo();
  };
}

export function sendUserOfflineEffect(cfg) {
  return () => {
    const config = {
      ThreadId: cfg?.threadId,
    };

    sendUserOfflineInfo(config);
  };
}
