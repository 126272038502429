import { Typography } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { UPLOAD_FILE } from 'app-constants';
import classNames from 'classnames';
import { UploadDragger } from 'components-antd';
import { routes } from 'settings/navigation/routes';
import { Fragment, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash-es';
import { useHistory } from 'react-router-dom';

import {
  BUNDLE_FILES_LIMIT,
  SINGLE_FILE_LIMIT,
  calculateFilesSize,
  handleFileUpload,
  initialFieldState,
  initialUploadErrorState,
  validateFileSize,
} from '../../helper';

import {
  FileNameBox,
  FileSizeError,
  FileSizeInformation,
  FormHeader,
  Icons,
  TemplateCheckboxFields,
  TemplateFields,
} from '../components';
import Navigation from '../components/Footer';

import styles from './style.module.scss';
import { useAddFormsInBundle } from '../hooks/useAddFormsInBundle';
import { Spinner } from 'components';
import prettyBytes from 'pretty-bytes';

export const UploadFileInForm = ({
  stagesStep,
  onUpdate,
  setCurrentStageIndex,
  onClose,
  stageComponentExtraProps,
}) => {
  const [files, setFiles] = useState<UploadFile<any>[]>([]);
  const [templateFieldState, setTemplateState] = useState(initialFieldState);
  let [filesChangeCounter, setFilesChangeCounter] = useState(0);
  const [fileSizeError, setFileSizeError] = useState(false);

  const [templateErrors, setTemplateErrors] = useState(initialUploadErrorState);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const hasFiles = files.length > 0;

  const { isDynamicForm } = stageComponentExtraProps;
  const { addFormsInDynamicBundle, adding: addingDynamicForm } = useAddFormsInBundle({ onClose });

  useEffect(() => {
    return () => {
      setFilesChangeCounter(0);
    };
  }, []);

  const onAddNewFile = ({ fileList }: UploadChangeParam<any>) => {
    if (fileList?.length) {
      filesChangeCounter++;

      if (filesChangeCounter === fileList.length) {
        const isValidSize = validateFileSize([...fileList]);
        if (isDynamicForm) {
          if (isValidSize) {
            setFilesChangeCounter(0);
            uploadFilesInDynamicBundle(fileList);
          } else {
            setFiles([...fileList]);
            setFileSizeError(true);
            setFilesChangeCounter(filesChangeCounter);
          }
        } else {
          setFiles([...fileList]);
          setFilesChangeCounter(filesChangeCounter);
          !isValidSize && setFileSizeError(true);
          isValidSize && fileSizeError && setFileSizeError(false);
        }
      } else {
        setFilesChangeCounter(filesChangeCounter);
      }
    }
  };

  const deleteFile = (index) => {
    let newFiles = cloneDeep(files);

    newFiles = newFiles.filter((_, i) => i !== index);
    setFiles(newFiles);

    setFilesChangeCounter(newFiles.length);

    if (!newFiles.length) {
      setTemplateState(initialFieldState);
      setTemplateErrors(initialUploadErrorState);
      fileSizeError && setFileSizeError(false);
    } else if (fileSizeError) {
      const totalSize = calculateFilesSize(newFiles);
      const maxSize = newFiles.length === 1 ? SINGLE_FILE_LIMIT : BUNDLE_FILES_LIMIT;

      if (totalSize <= maxSize) {
        setFileSizeError(false);
        isDynamicForm && uploadFilesInDynamicBundle(newFiles);
      }
    }
  };

  // Fields onChange Handler
  const onChangeInFields = (data) => {
    setTemplateState((prev) => ({ ...prev, ...data }));
  };

  // Validating Form
  const validateTemplateForm = () => {
    let isValid = true;
    let errors = { ...templateErrors };

    if (templateFieldState.saveAsTemplate) {
      if (!templateFieldState.templateName.trim().length) {
        errors.templateNameError = 'Required!';
        isValid = false;
      }
      if (!templateFieldState.categories.length) {
        errors.categoriesError = 'Required!';
        isValid = false;
      }
    } else if (!templateFieldState.templateName.trim().length) {
      errors.templateNameError = 'Required!';
      isValid = false;
    }

    !isValid && setTemplateErrors(errors);
    return isValid;
  };

  const uploadFilesInDynamicBundle = async (files) => {
    addFormsInDynamicBundle({
      files,
    });
  };

  const onSubmit = async () => {
    let isValid = validateTemplateForm();
    const validSize = validateFileSize(files);

    !validSize && setFileSizeError(true);

    if (!isValid || !validSize) {
      return;
    }

    const { saveAsTemplate } = templateFieldState;
    const requestData = {
      saveAsTemplate,
      files,

      ...(saveAsTemplate && {
        fileName: templateFieldState.templateName,
        categories: templateFieldState.categories,
        publishToteam: templateFieldState.publishToTeam,
      }),

      ...(!saveAsTemplate && { fileName: templateFieldState.templateName }),
    };

    setLoading(true);

    const result = await handleFileUpload(requestData);

    if (result?.documentLink || result?.templateId) {
      history.push({
        pathname: routes.workshopFormProcessRequest,
        state: {
          documentLink: result.documentLink,
          templateId: result.templateId,
          customForm: true,
          name: requestData.fileName,
          ...(saveAsTemplate && {
            saveAsTemplate,
            categories: templateFieldState.categories,
            publishToTeam: templateFieldState.publishToTeam,
          }),
        },
      });

      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handlePrevious = (e) => {
    onUpdate(stagesStep.FormCategories);
    setCurrentStageIndex(stagesStep.FormCategories);
  };

  return (
    <div className={styles.uploadFileInFormWrapper}>
      <FormHeader heading={'Upload a File'} handlePrevious={handlePrevious} />

      {addingDynamicForm ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className={hasFiles ? styles.uploadFileContent : ''}>
            <div
              className={classNames(styles.uploadContainer, {
                [styles.uploadContainerNoFiles]: !files?.length,
                [styles.uploadedFilesContainer]: !!files?.length,
              })}
            >
              <UploadDragger
                action={UPLOAD_FILE}
                onChange={onAddNewFile}
                multiple={true}
                showUploadList={false}
                beforeUpload={() => false}
                accept="application/pdf"
                className={styles.uploadDragger}
                fileList={files}
              >
                <Icons variant={'uploadFile'} className={styles.uploadFileIcon} />

                <p className={styles.uploadHint}>Drag and drop files here</p>
                <p className={styles.uploadSecondHint}>or</p>
                <button className={styles.uploadButton}>
                  <Typography className={styles.uploadText}>Choose File</Typography>
                </button>
              </UploadDragger>
            </div>

            <FileSizeInformation />

            {files?.map((file, index) => (
              <FileNameBox
                key={`file-${file.name}-${index}`}
                file={file}
                onDelete={() => deleteFile(index)}
              />
            ))}

            {hasFiles && !isDynamicForm ? (
              <TemplateFields
                fieldState={templateFieldState}
                onChange={onChangeInFields}
                errors={templateErrors}
                setErrors={setTemplateErrors}
                saveAsTemplate={templateFieldState.saveAsTemplate}
                className={styles.templateFieldsList}
              />
            ) : (
              <></>
            )}
          </div>

          {hasFiles && !isDynamicForm ? (
            <TemplateCheckboxFields
              values={templateFieldState}
              errors={templateErrors}
              onChange={onChangeInFields}
              setErrors={setTemplateErrors}
            />
          ) : (
            <></>
          )}

          {fileSizeError && <FileSizeError files={files} />}

          {!isDynamicForm ? (
            <Navigation
              loading={loading}
              disabled={!files.length || fileSizeError}
              onNext={onSubmit}
            />
          ) : (
            <></>
          )}
        </Fragment>
      )}
    </div>
  );
};
