import { useDispatch } from 'react-redux';

import { setMlsAccessModalAction } from 'store/actions/mlsAccess';
import { Add } from 'components/Icons';
import { MlsItem } from './MlsItem';

import styles from './styles.module.scss';

const MlsIntegration = ({ AgentMlsServices, fetchListing }) => {
  const dispatch = useDispatch();

  const handleAddMlsClick = () => {
    dispatch(
      setMlsAccessModalAction({
        isModalOpened: true,
        showOnboardingText: false,
      }),
    );
  };
  return (
    <div className={styles.section}>
      <h2 className={styles.title}>MLS</h2>
      <div className={styles.mlsWrapper}>
        <div className={styles.mlsItemsWrapper}>
          {AgentMlsServices.map((mls) => (
            <MlsItem value={mls} setOnUpdate={fetchListing} />
          ))}
        </div>
        <div className={styles.addMls}>
          <Add className={styles.addMlsBtn} onClick={handleAddMlsClick} color={Add.ORANGE_CIRCLE} />
          <span>Add MLS</span>
        </div>
      </div>
    </div>
  );
};

export default MlsIntegration;
