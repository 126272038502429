import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { priceConverter } from 'helpers';
import moment from 'moment';

export const LineChart = ({
  seriesName,
  data,
  numberTransactions,
  categories,
  dates,
  years,
  colors,
  onClickDataPoint,
  className,
  isCommission,
  timePeriod,
}) => {
  const currentYear = moment().year();
  const previousYear = moment().subtract(1, 'year').year();
  const chartOptions: ApexOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
      events: {
        markerClick: (event, chartContext, { seriesIndex, dataPointIndex }) => {
          onClickDataPoint(dataPointIndex);
        },
      },
    },
    colors: colors,
    fill: {
      opacity: 0.7,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const total = data[dataPointIndex];
        const numberTransaction = numberTransactions[dataPointIndex];
        const date = dates[dataPointIndex];
        const year = years[dataPointIndex];
        if (total) {
          return (
            '<div style="width: 172px; height: 79px; border-radius: 16px; background: #FFF; padding: 15px 16px;">' +
            '<div style="display: flex; margin-bottom: 11px;">' +
            `<span style="color: #262626; font-feature-settings: 'liga' off, 'clig' off; font-family: Inter; font-size: 18px; font-style: normal; font-weight: 600; line-height: 20px; letter-spacing: -0.18px;">${
              isCommission ? `${total}%` : priceConverter(total)
            }</span>` +
            `<span style="color: #515151; margin-left: 2px; margin-top: 1px; font-feature-settings: 'liga' off, 'clig' off; font-family: Inter; font-size: 12px; font-style: normal; font-weight: 600; line-height: 20px; letter-spacing: -0.18px;">(${
              isCommission ? 'Avg.' : numberTransaction
            })</span>` +
            '</div>' +
            `<span style="color: #AAABAB; text-align: center; font-feature-settings: 'liga' off, 'clig' off; font-family: Inter; font-size: 14px; font-style: normal; font-weight: 500; line-height: 16px; letter-spacing: -0.33px;">${date} ${
              timePeriod === previousYear
                ? previousYear
                : timePeriod === 'YTD'
                ? currentYear
                : `, ${currentYear}`
            }</span>` +
            '</div>'
          );
        } else return '';
      },
      marker: {
        show: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
          value: 1,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 40,
      },
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `${priceConverter(value)}`;
        },
      },
    },
  };

  const chartSeries = [
    {
      name: seriesName,
      data: data,
    },
  ];

  return (
    <div className={className}>
      <Chart options={chartOptions} series={chartSeries} type="line" height={450} />
    </div>
  );
};
