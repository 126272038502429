import { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { convertNameToAvatarPlaceholder, showErrorMessage } from 'helpers';
import { getUserDataSelector } from 'store/selectors/user';
import { SendMessageFormView, Avatar, Wrapper as PendingWrapper, CommentsView } from 'components';
import { socketSetEditTransactionTaskMessageEffect } from 'store/effects/sockets/transactionTaskMessages';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getCurrentEditTransactionTaskMessageSelector } from 'store/selectors/sockets/transactionTaskMessages';
import { getTransactionTaskSelector } from 'store/selectors/transactionTask';
import { setShowCommentIconEffect } from 'store/effects/taskAggregate';
import { setShowCommentIconForTransactionEffect } from 'store/effects/transactions';
import { useLocation } from 'react-router-dom';

import styles from './styles.module.scss';
import { deleteTransactionMessageEffect } from 'store/effects/sockets/transactionMessages';
import {
  addComment,
  CommentsEntityType,
  createCommentThread,
  deleteComment,
  getTaskComments,
  getTransactionNotes,
  markThreadAsRead,
  updateComment,
} from 'api/comments';

let intervalId;

const Comments = (props) => {
  const {
    className,
    commentAutoFocus,
    inputPlaceholder,
    emptyLabel,
    isModal,
    isTransactionDrawer,
    getEditMessageSelector,
    onCustomCancelEditSelector,
    threadId,
    entityType,
    entityId,
  } = props;
  const commentViewWrapperRef = useRef(null);
  const hasScrolledToCommentRef = useRef(false);
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const { task } = useSelector(getTransactionTaskSelector);
  const location = useLocation();
  const [currentThreadId, setCurrentThreadId] = useState();
  const currentEditMessage = useSelector(getEditMessageSelector);
  const [isThreadCreating, setIsThreadCreating] = useState(false);

  const [comments, setComments] = useState([]);

  const fetchComments = useCallback(async () => {
    if (!entityId) {
      return;
    }

    if (entityType === CommentsEntityType.transactionNote) {
      const { data } = await getTransactionNotes({ transactionId: entityId });
      setComments(data.Comments);
      setCurrentThreadId(data?.Comments?.[0].CommentThreadId);
    } else {
      const { data } = await getTaskComments({ taskId: entityId });
      setComments(data.Comments);
      setCurrentThreadId(data?.Comments?.[0]?.CommentThreadId);
    }
  }, [entityId, entityType]);

  useEffect(() => {
    if (!intervalId) fetchComments();
    intervalId = setInterval(fetchComments, 15000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
    };
  }, [entityId, entityType]);

  const markAsRead = useCallback(async () => {
    if (currentThreadId || threadId) {
      markThreadAsRead({ threadId: currentThreadId || threadId });
    }
  }, [threadId, currentThreadId]);

  useEffect(() => {
    if (threadId) {
      setCurrentThreadId(threadId);
    }
    return () => {
      markAsRead();
    };
  }, [threadId]);

  const onCancelEditHandler = () => {
    dispatch(onCustomCancelEditSelector({ editMessage: null }));
  };

  const onSendMessage = async (
    message,
    attachments,
    tagsValue,
    messageId,
    threadId,
    accessType,
  ) => {
    if (currentEditMessage) {
      onCancelEditHandler();
    }

    if (messageId) {
      try {
        await updateComment({
          Text: message,
          CommentMeta: { Tags: tagsValue },
          AccessType: accessType,
          Id: messageId,
        });
        const payload = comments.find((message) => message.Id === messageId);
        setComments((prevState) =>
          prevState.map((item) =>
            item.Id === messageId
              ? {
                  ...payload,
                  Text: message,
                  CommentMeta: { Tags: tagsValue },
                  AccessType: accessType,
                }
              : item,
          ),
        );
      } catch (err) {
        showErrorMessage(err);
      }
    } else {
      try {
        const { data } = await addComment({
          ThreadId: threadId || currentThreadId,
          Text: message,
          CommentMeta: { Tags: tagsValue },
          AccessType: accessType,
        });
        setComments((prevState) => [
          ...prevState,
          { ...data.Comment, Receipients: data.Receipients },
        ]);
      } catch (err) {
        showErrorMessage(err);
      }
    }
  };

  const onDeleteMessage = async (messageId) => {
    try {
      await deleteComment({ CommentId: messageId });
      setComments((prevState) => prevState.filter(({ Id }) => Id !== messageId));
    } catch (err) {
      showErrorMessage(err);
    }
  };

  const onCreateThreadAndSendNewMessage = async (
    message,
    attachments,
    tagsValue,
    messageId,
    threadId,
    accessType,
  ) => {
    if (currentThreadId || threadId) {
      onSendMessage(message, attachments, null, tagsValue, currentThreadId, threadId, accessType);
      return;
    }

    setIsThreadCreating(true);
    try {
      const { data } = await createCommentThread({
        EntityId: entityId,
        ParticipantIds:
          entityType === CommentsEntityType.taskComment
            ? [
                task?.AssignorId,
                ...(task?.CcList?.map((cc) => cc.Id) || []),
                ...(task?.AssigneeList?.map((assignee) => assignee.Id) || []),
                // TODO: remove these temporarily added full access members once participants logic is moved to BE
                ...(transaction?.Participants || [])
                  .filter((participant) => participant.TransactionAccess)
                  .map((participant) => participant?.Id),
              ]
            : [
                ...new Set([
                  ...(transaction?.Participants || []).map((participant) => participant?.Id),
                  user?.Id,
                ]),
              ],
        EntityType: entityType,
        FirstComment: {
          Text: message,
          Attachments: attachments,
          CommentMeta: { Tags: tagsValue },
          AccessType: accessType,
        },
      });
      setCurrentThreadId(data?.CommentThread?.Id);
      setComments([data?.CommentThread?.Comments?.[0]?.message]);
      if (task?.Id) dispatch(setShowCommentIconEffect({ taskId: task.Id }));
      if (task?.Id && task?.TransactionId) {
        dispatch(
          setShowCommentIconForTransactionEffect({
            taskId: task.Id,
          }),
        );
      }
    } catch (err) {
      showErrorMessage(err);
    }
    setIsThreadCreating(false);
  };

  const getUserName = () => `${user?.FirstName} ${user?.LastName}`;

  const onEditMessageHandler = useCallback(
    (messageId) => {
      const editMessage = comments.find((message) => message?.Id === messageId);
      dispatch(onCustomCancelEditSelector({ editMessage }));
    },
    [dispatch, comments],
  );

  const scrollToComment = useCallback((messageId) => {
    if (commentViewWrapperRef?.current && messageId) {
      const targetMessage = commentViewWrapperRef.current.querySelector(`#message_${messageId}`);
      if (targetMessage) {
        setTimeout(() => {
          targetMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
          hasScrolledToCommentRef.current = true;
        }, 0);
      }
    }
  }, []);

  useEffect(() => {
    let messageId = null;
    if (comments?.length) {
      messageId = comments[comments?.length - 1]?.Id;
    }

    if (messageId && currentThreadId && !hasScrolledToCommentRef.current) {
      scrollToComment(messageId);
    } else if (commentViewWrapperRef?.current && currentThreadId) {
      commentViewWrapperRef.current.scrollTop = commentViewWrapperRef?.current?.scrollHeight;
    }
  }, [comments?.length, currentThreadId, location.state, scrollToComment]);

  const getMessagesContent = () => {
    if (isThreadCreating) {
      return (
        <PendingWrapper
          isPending={isThreadCreating}
          className={styles.pendingWrapper}
          loaderClassName={styles.loader}
        />
      );
    }

    if (currentThreadId) {
      return (
        <div className={styles.CommentsViewWrapper} ref={commentViewWrapperRef}>
          <CommentsView
            isModal={isModal}
            isTransactionDrawer={isTransactionDrawer}
            threadId={currentThreadId}
            list={comments}
            currentEditMessage={currentEditMessage}
            onEditMessage={onEditMessageHandler}
            className={styles.messages}
            onDeleteMessage={onDeleteMessage}
            useRestApi
          />
        </div>
      );
    }

    return (
      <div className={classNames(styles.empty, { [styles.emptyModal]: isModal })}>
        <span testid="no_comments" className={styles.message}>
          {emptyLabel}
        </span>
      </div>
    );
  };

  return (
    <div className={classNames(styles.comments, { [styles.commentsModal]: isModal })}>
      <div className={classNames(styles.holder, { [styles.holderModal]: isModal })}>
        <SendMessageFormView
          id="transactionTaskComments"
          onSend={currentThreadId ? onSendMessage : onCreateThreadAndSendNewMessage}
          subheader={null}
          content={getMessagesContent()}
          autoFocus={commentAutoFocus}
          footerClassName={classNames(styles.footer)}
          footerInnerClassName={styles.footerInner}
          inputGroupClassName={styles.inputGroup}
          attachments={false}
          emoji={true}
          showDropdown
          autocomplete={true}
          inputPlaceholder={inputPlaceholder}
          sendButtonClassName={styles.sendButton}
          onCancelEdit={onCancelEditHandler}
          currentEditMessage={currentEditMessage}
          placeholder="Type to search users"
          isTaskComments
          isModal={isModal}
          className={classNames(
            styles.commentsForm,
            { [styles.commentsFormModal]: isModal },
            className,
          )}
          avatar={true}
          avatarPlaceHolder={
            <div className={styles.avatarPlaceholder}>
              {convertNameToAvatarPlaceholder(getUserName())}
            </div>
          }
        />
      </div>
    </div>
  );
};

Comments.propTypes = {
  className: PropTypes.string,
  commentAutoFocus: PropTypes.bool,
  isModal: PropTypes.bool,
  isTransactionDrawer: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
  emptyLabel: PropTypes.string,
  getEditMessageSelector: PropTypes.func,
  onCustomCancelEditSelector: PropTypes.func,
  threadId: PropTypes.oneOf([PropTypes.number, undefined]),
  entityType: CommentsEntityType,
  entityId: PropTypes.number,
};

Comments.defaultProps = {
  className: '',
  commentAutoFocus: false,
  isModal: false,
  isTransactionDrawer: false,
  onCustomCancelEditHandler: () => {},
  getEditMessageSelector: getCurrentEditTransactionTaskMessageSelector,
  onCustomCancelEditSelector: socketSetEditTransactionTaskMessageEffect,
  inputPlaceholder: 'Add a comment ...',
  emptyLabel: 'No Comments',
  threadId: undefined,
  entityType: CommentsEntityType.taskComment,
};

export default Comments;
