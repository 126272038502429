import classNames from 'classnames';
import { Dropdown, Menu, MenuItem, Radio } from 'components-antd';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';

import styles from './styles.module.scss';

import { useState } from 'react';

import { Check } from 'components/Icons';
import { PermissionTypes, PermissionTypesLabel } from 'app-constants/enums/permissionSettings';
import { DynamicDropdown } from '../../DynamicDropdown';
import { useDispatch } from 'react-redux';
import {
  getTeamTransactionPermissionsEffect,
  setTeamTransactionPermissionsEffect,
  updateTeamTransactionPermissionsEffect,
} from 'store/effects';

type PermissionTypeDropdownType = {
  addToTransaction: boolean;
  memberId: number;
  permissionType: string;
  setExpandedRowKeys: (keys) => void;
};

export const PermissionTypeDropdown = ({
  addToTransaction,
  memberId,
  setExpandedRowKeys,
}: PermissionTypeDropdownType) => {
  const [selectedType, setSelectedType] = useState(
    addToTransaction ? PermissionTypes.AutomaticallyAdd : PermissionTypes.ViewOnly,
  );

  const dispatch = useDispatch();

  const onSelectPermissionType = (value) => {
    setSelectedType(value);

    if (!addToTransaction) return;

    dispatch(
      updateTeamTransactionPermissionsEffect(
        {
          memberId,
          addToTransaction: false,
        },
        {},
        (err, resp) => {
          if (!err) {
            const { TransactionPermissions } = resp.data;
            dispatch(
              setTeamTransactionPermissionsEffect({
                data: { TransactionPermissions },
              }),
            );
          }
        },
      ),
    );
  };

  return (
    <DynamicDropdown
      label={PermissionTypesLabel[selectedType]}
      value={selectedType}
      setValue={(value) => {
        onSelectPermissionType(value as PermissionTypes);
        setExpandedRowKeys(value);
      }}
      options={Object.keys(PermissionTypes).map((item) => ({
        label: PermissionTypesLabel[item],
        value: item,
      }))}
      dropdownContainerClassName={
        selectedType === PermissionTypes.AutomaticallyAdd ? styles.fullWidthContainer : ''
      }
    />
  );
};
