import styles from './styles.module.scss';

import { DraggableCombineMenuList } from 'components-antd';
import { useEffect, useState } from 'react';
import { getOrderedBundleDetails, prepareBundleForms } from '../helper';
import { useDispatch, useSelector } from 'react-redux';
import { getDynamicFormSelector } from 'store/selectors/requestFormProcess';
import {
  deleteDocumentEffect,
  deleteTemplateFromBundleEffect,
  reorderDynamicBundleDocumentEffect,
  updateDynamicFormEffect,
  updateLazyLoadedDocumentsEffect,
} from 'store/effects/formProcess';
import { useHistory, useLocation } from 'react-router-dom';

import { sortDetails } from 'pages/FormProcess/helper';
import { FormItem } from '../FormItem';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { sortDocuments } from 'pages/FormProcess/DynamicForm/helper';

import { MOBILE_WIDTH } from '../../../helper';

export const FormsSection = (props) => {
  const { loading, scrolledDocumentId, isTemplate, setLoading, screenWidth, setOpenList } = props;

  const { dynamicFormDocuments, bundleDocumentsDetails = [] } = useSelector(getDynamicFormSelector);
  // const { pdfDocuments } = useSelector(getLazyLoadedDocumentSelector);

  const [selectedDocumentId, setSelectedDocumentId] = useState(scrolledDocumentId);

  const [sortedDocumentForms, setSortedDocumentForms] = useState([]);
  const location = useLocation();
  const history = useHistory() as any;
  const dispatch = useDispatch();

  const templateBundleId = dynamicManager.getTemplateBundleId();

  useEffect(() => {
    if (sortedDocumentForms.length !== dynamicFormDocuments?.length) {
      setSortedDocumentForms(prepareBundleForms(dynamicFormDocuments));
    }
  }, [dynamicFormDocuments, loading]);

  useEffect(() => {
    setSelectedDocumentId(scrolledDocumentId);
    const element = document.getElementById(`form-item-${scrolledDocumentId}`);

    if (element) {
      element?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [scrolledDocumentId]);

  const updateDocumentsInState = (documents) => {
    history.replace(location.pathname, {
      ...history.location.state,
      ...(isTemplate ? { allTemplates: documents } : { allDocuments: documents }),
    });
  };

  const updateInDynamicForm = (details, lazyLoadDocuments = false) => {
    if (lazyLoadDocuments) {
      dispatch(updateLazyLoadedDocumentsEffect(details));
    } else {
      dispatch(updateDynamicFormEffect(details));
    }
  };

  const onSequenceUpdate = (sequencedForms) => {
    const orderedDocuments = sequencedForms.map(({ sequence, id }) => ({ id, order: sequence }));
    const order = sortDetails(orderedDocuments, 'order');

    const { FormProcessId } = sequencedForms[0];

    setSortedDocumentForms(sortDocuments(sequencedForms, 'sequence'));

    setLoading(true);
    dispatch(
      reorderDynamicBundleDocumentEffect(
        {
          bundleId: isTemplate ? templateBundleId : FormProcessId,
          order,
          ...(isTemplate && { isTemplateBundle: true }),
        },
        (error, response) => {
          if (!error) {
            const { value: sequencedDocuments } = response.data;

            updateDocumentsInState(sequencedDocuments);

            const { bundleDetails } = getOrderedBundleDetails(
              sequencedDocuments,
              bundleDocumentsDetails,
              isTemplate,
            );

            const newDocIndex = sequencedDocuments.findIndex(({ Id }) => Id === scrolledDocumentId);

            updateInDynamicForm({
              dynamicFormDocuments: sequencedDocuments,
              bundleDocumentsDetails: bundleDetails,
              dynamicScrolledDocumentId: scrolledDocumentId,
              dynamicScrolledDocumentIndex: newDocIndex,
            });

            setLoading(false);
          }
        },
      ),
    );
  };

  const loadNextDocument = (documentIndex) => {
    const element = document.getElementById(`pdf-doc-${documentIndex}`);

    if (element) {
      element?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const onSelectDocument = (documentId) => {
    if (documentId === selectedDocumentId || !dynamicFormDocuments) return;

    const documentIndex = dynamicFormDocuments.findIndex(({ Id }) => Id === documentId) as number;

    if (documentIndex >= 0) {
      setSelectedDocumentId(documentId);
      loadNextDocument(documentIndex);
      screenWidth <= MOBILE_WIDTH && setOpenList(false);
    }
  };

  const onRemoveDocument = (documentId, setPopupLoading, setPopupOpen) => {
    setPopupLoading(true);
    setLoading(true);

    const callback = (error) => {
      if (!error && dynamicFormDocuments) {
        setPopupLoading(false);
        setPopupOpen(false);

        let documents = [...dynamicFormDocuments];

        const docIndex = documents.findIndex(({ Id }) => Id === documentId);

        const { PublicId: docPublicId, Id: docTemplateId } = documents[docIndex];

        let filteredDocuments = documents.filter((item) => item.Id !== documentId);

        let documentDetails = bundleDocumentsDetails?.filter(({ PublicId, templateId, Id }) =>
          isTemplate ? (templateId || Id) !== docTemplateId : PublicId !== docPublicId,
        );

        updateDocumentsInState(filteredDocuments);

        let nextDocId;

        let nextIndex = filteredDocuments[docIndex] ? docIndex : filteredDocuments.length - 1;

        nextDocId = filteredDocuments[nextIndex].Id;

        updateInDynamicForm({
          dynamicFormDocuments: filteredDocuments,
          bundleDocumentsDetails: documentDetails,
        });

        setSortedDocumentForms(prepareBundleForms(filteredDocuments));

        if (selectedDocumentId === documentId) {
          setSelectedDocumentId(nextDocId);
        }
      } else {
        setPopupLoading(false);
      }
      setLoading(false);
    };

    const deleteEffect = isTemplate
      ? deleteTemplateFromBundleEffect(documentId, callback)
      : deleteDocumentEffect(documentId, callback);

    dispatch(deleteEffect);
  };

  const renderFormItem = (item) => {
    return (
      <div>
        <FormItem
          formItem={item}
          selected={item.id === selectedDocumentId}
          onSelect={onSelectDocument}
          onRemoveDocument={onRemoveDocument}
          isLast={sortedDocumentForms?.length === 1}
          loading={loading}
        />
      </div>
    );
  };

  if (!sortedDocumentForms.length) return <></>;

  return (
    <div className={styles.bundleFormsSection}>
      {sortedDocumentForms.length > 1 ? (
        <DraggableCombineMenuList
          initialItems={sortedDocumentForms}
          onItemUpdate={onSequenceUpdate}
          renderFunction={renderFormItem}
        />
      ) : (
        renderFormItem(sortedDocumentForms[0])
      )}
    </div>
  );
};
