import { useState, useEffect, useRef, Fragment, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { Modal, Row, Col } from 'components-antd';
import { isBeforeToday, subtractTimeZone } from 'helpers';
import { Wrapper as PendingWrapper, InputLabel } from 'components';
import { getUserId } from 'store/selectors/user';
import { getTransactionTaskSelector } from 'store/selectors/transactionTask';
import {
  Actions,
  Comments,
  Subtitle,
  UploadDocument,
  Links,
  DueDate,
  Checklists,
  AssignTo,
  AssignedBy,
  CC,
  OverdueLabel,
} from './components';
import { Documents } from '../..';
import { Plus, Minus } from 'components/Icons';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import {
  socketsGetTransactionTaskMessagesByThreadIdEffect,
  resetTransactionTaskMessagesEffect,
} from 'store/effects/sockets/transactionTaskMessages';
import { updateUnReadCommentsEffect } from 'store/effects/taskAggregate';
import { updateUnReadCommentsForTransactionEffect } from 'store/effects/transactions';
import {
  getTransactionAccessByTaskSelector,
  getTransactionSelector,
} from 'store/selectors/transaction';

import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';
import workshop from 'settings/navigation/routes/workshop';
import { NamesContainer } from 'pages/Workshop/Transactions/components/NamesContainer';
import { getTransactionTaskAccess } from 'store/selectors/transactionTasks';
import {
  getTransactionTaskParamsSelector,
  getTransactionTaskAccessSelector,
} from 'store/selectors/transactionTask';
import { tasksStatusesIds } from 'settings/constants/transactionTasks';
import { CommentsEntityType } from 'api/comments';
import TaskClient from './components/TaskClient';

const View = (props) => {
  const { className, isOpen, onCloseModal } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const isAggregatedTasks = location?.pathname === workshop.tasks;
  const { transaction } = useSelector(getTransactionSelector);
  const { campaignId } = useSelector(getTransactionTaskParamsSelector);

  const transactionAddress = transaction?.Property?.Address ?? {};

  const userId = useSelector(getUserId);
  const { isPending, task, isError, status } = useSelector(getTransactionTaskSelector);

  const { hasAccess } = useSelector(getTransactionTaskAccess);
  const { fullAccess: taskFullAccess } = useSelector(getTransactionTaskAccessSelector);

  const [addFiles, setAddFiles] = useState(false);
  const [addComments, setAddComments] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showChecklists, setShowChecklists] = useState(true);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const { hasFullAccess } = useSelector(getTransactionAccessByTaskSelector);

  const isNotFound = isError && status && status === 404;

  const handleCommentsToggle = () => {
    setAddComments(true);
    setShowComments((val) => !val);
  };

  const campaignTaskFullAccess = useMemo(
    () => campaignId && taskFullAccess,
    [taskFullAccess, campaignId],
  );

  const { CcList, Address } = task || {};

  const containerRef = useRef();

  useEffect(() => {
    const showCommentsFromState = location.state?.showComments;
    if (showCommentsFromState) {
      setShowComments(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (isOpen) {
      const unreadCount = task?.Comments?.reduce((acc, comment) => {
        if (comment.SenderUserId !== userId) {
          return comment.MessageReceipts.some(
            (receipt) => receipt?.UserId === userId && !receipt.IsRead,
          )
            ? acc + 1
            : acc;
        }
        return acc;
      }, 0);
      setAddComments(!!task?.Comments?.length);
      setUnreadMessagesCount(unreadCount);
    } else {
      setUnreadMessagesCount(0);
    }
  }, [task?.Id, isOpen]);

  useEffect(() => {
    if (showComments) {
      if (task?.Id) {
        dispatch(updateUnReadCommentsEffect({ taskId: task?.Id, userId: userId }));
      }
      if (task?.Id && task?.TransactionId) {
        dispatch(updateUnReadCommentsForTransactionEffect({ taskId: task?.Id, userId: userId }));
      }
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    setUnreadMessagesCount(0);
  }, [showComments]);

  const SubtitleLeftSection = () => {
    const { IsProject } = transaction;

    return (
      <p className={styles.streetAddress}>
        {IsProject
          ? `${transaction?.Name}`
          : `${transactionAddress?.Line1}
          ${transactionAddress?.Line2 ? transactionAddress?.Line2 : ''}`}
      </p>
    );
  };

  const SubtitleRightSection = () => {
    const { IsProject } = transaction;
    const names = IsProject ? transaction?.AgentsOrOwners : transaction?.TransactionClients;

    return (
      <p className={styles.transactionClients}>
        <span className={styles.label}>{IsProject ? 'Owner(s)' : 'Client(s)'}:</span>
        {names?.length > 0 ? (
          <NamesContainer names={names} maxDisplayCount={1} tooltipClass={styles.marginLeft} />
        ) : (
          'None'
        )}
      </p>
    );
  };

  const renderCommentSection = () => {
    return (
      <>
        {task?.canComment && (
          <>
            {addComments ? (
              <>
                <div className={styles.customLine} />
                <Row className={styles.comments}>
                  <Col xs={20} sm={20} md={23} lg={23} xl={23}>
                    <div className={styles.subHeading}>
                      <span>Comments</span>
                      {!showComments ? (
                        <>
                          {' '}
                          <span
                            className={
                              unreadMessagesCount ? styles.unreadCount : styles.regularCount
                            }
                          >
                            (
                            {unreadMessagesCount
                              ? `${unreadMessagesCount} New`
                              : task?.Comments?.length || 0}
                            )
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col xs={4} sm={4} md={1} lg={1} xl={1}>
                    <div className={styles.iconContainer}>
                      <button className={styles.collapseBtn} onClick={handleCommentsToggle}>
                        {showComments ? <Minus /> : <Plus />}
                      </button>
                    </div>
                  </Col>
                </Row>
                {showComments ? (
                  <Comments
                    commentAutoFocus={true}
                    isModal={false}
                    entityId={task.Id}
                    entityType={CommentsEntityType.taskComment}
                    threadId={task?.CommentsThreadId}
                  />
                ) : null}
              </>
            ) : (
              <div className={styles.addCommentsOption} onClick={() => handleCommentsToggle()}>
                <Icon variant={Icon.ADD} />
                &nbsp;&nbsp;&nbsp;
                <span className={styles.addLabel}>Add Comments</span>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const showOverDueLabel = () =>
    ![tasksStatusesIds.done, tasksStatusesIds.na].includes(task?.Status);

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={() => {
        setAddFiles(false);
        setAddComments(false);
        setShowComments(false);
        dispatch(resetTransactionTaskMessagesEffect());
        onCloseModal();
      }}
      maskClosable={false}
      destroyOnClose
      className={styles.taskViewModal}
    >
      <div testid="task_view" className={classNames(styles.view, className)}>
        <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
          {isNotFound ? (
            <div className={styles.notFoundMessage}>The task has already been deleted</div>
          ) : (
            <Fragment>
              <p className={styles.heading}>
                <span>{task?.Title}</span>
              </p>
              {isAggregatedTasks && task?.TransactionId && (
                <div
                  className={classNames(styles.subtitle, {
                    [styles.isAggregratedSubtitle]: isAggregatedTasks,
                  })}
                >
                  <SubtitleLeftSection />
                  <div className={styles.seperatorContainer}>
                    <div className={styles.seperator} />
                  </div>
                  <SubtitleRightSection />
                </div>
              )}
              <div ref={containerRef} className={styles.content}>
                <Row>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Icon variant={Icon.CALENDER} />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={(styles.headerInfo, { [styles.flexHeaderInfo]: !task?.Category })}
                  >
                    <DueDate />
                    <Subtitle category={task?.Category} className={styles.subtitle} />
                  </Col>
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <Actions
                      assignor={task?.Assignor}
                      transactionId={task?.TransactionId}
                      dueDate={task?.DueDate}
                      fullAccess={campaignTaskFullAccess}
                    />
                  </Col>
                </Row>

                {showOverDueLabel() &&
                  isBeforeToday(subtractTimeZone(task?.DueDate, 'M/DD/YYYY')) && <OverdueLabel />}

                {Address ? (
                  <div className={styles.client}>
                    <InputLabel label="Client:" className={styles.subHeading} />
                    <TaskClient />
                  </div>
                ) : (
                  <></>
                )}

                <div
                  className={classNames({
                    [styles.alignStart]: task?.AssigneeList?.length > 1,
                    [styles.assign]: !Address,
                    [styles.assignCompact]: !!Address,
                  })}
                >
                  <AssignTo className={styles.to} />
                  <AssignedBy className={styles.by} />
                </div>
                {CcList && CcList.length ? (
                  <div className={styles.cc}>
                    <InputLabel label="CC:" className={styles.subHeading} />
                    <CC />
                  </div>
                ) : (
                  <></>
                )}
                {task?.Description ? (
                  <div testid="description" className={styles.description}>
                    <InputLabel label="Description" className={styles.subHeading} />
                    <p className={styles.descriptionText}>{task?.Description}</p>
                  </div>
                ) : (
                  <></>
                )}
                {task?.Links && task?.Links.length ? (
                  <div testid="links" className={styles.linkContainer}>
                    <InputLabel label="Links" className={styles.subHeading} />
                    <Links value={task?.Links} className={styles.links} />
                  </div>
                ) : (
                  <></>
                )}
                <div className={styles.customLine} />
                {task?.Checklists?.length ? (
                  <>
                    <Row className={styles.checklists}>
                      <Col xs={20} sm={20} md={23} lg={23} xl={23}>
                        <InputLabel
                          label="Checklist"
                          altLabel={`(${task?.Checklists.length})`}
                          className={styles.subHeading}
                          altClassName={styles.checklistCount}
                        />
                      </Col>
                      <Col xs={4} sm={4} md={1} lg={1} xl={1}>
                        <div className={styles.iconContainer}>
                          <button
                            className={styles.collapseBtn}
                            onClick={() => setShowChecklists(() => !showChecklists)}
                          >
                            {showChecklists ? <Minus /> : <Plus />}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {showChecklists ? (
                      <Checklists
                        className={styles.taskViewChecklist}
                        taskId={task?.Id}
                        transactionId={task?.TransactionId}
                        checklists={task?.Checklists}
                        fullAccess={hasFullAccess(task?.TransactionId)}
                      />
                    ) : (
                      <></>
                    )}
                    <div className={styles.customLine} />
                  </>
                ) : (
                  <></>
                )}
                {task?.Documents?.length > 0 || addFiles ? (
                  <>
                    {(hasFullAccess(task?.TransactionId) ||
                      hasAccess(task?.Id) ||
                      campaignTaskFullAccess) && (
                      <Row align="middle" className={styles.files}>
                        <Col xs={20} sm={20} md={23} lg={23} xl={23}>
                          <InputLabel label="Files" className={styles.subHeading} />
                        </Col>
                        <Col xs={4} sm={4} md={1} lg={1} xl={1}>
                          <UploadDocument />
                        </Col>
                      </Row>
                    )}
                    <Documents value={task?.Documents} taskId={task?.Id} />
                    {!addComments ? <div className={styles.customLine} /> : <></>}
                  </>
                ) : (
                  <div
                    className={styles.addFilesOption}
                    onClick={() =>
                      hasFullAccess(task?.TransactionId) ||
                      hasAccess(task?.Id) ||
                      campaignTaskFullAccess
                        ? setAddFiles(true)
                        : null
                    }
                  >
                    <Icon variant={Icon.ADD} />
                    &nbsp;&nbsp;&nbsp;
                    <span className={styles.addLabel}>Add Files</span>
                  </div>
                )}
                {renderCommentSection()}
              </div>
            </Fragment>
          )}
        </PendingWrapper>
      </div>
    </Modal>
  );
};

View.propTypes = {
  className: PropTypes.string,
};

View.defaultProps = {
  className: '',
};

export default View;
