import { OnBoardingContainer } from 'pages/OnBoarding/components/OnBoardingContainer';
import { Question } from 'pages/RequestQuote/components';
import styles from './styles.module.scss';
import { AnswersContainer } from '../../AnswersContainer';
import { FC } from 'react';
import { IHomePrefsProps } from './types';
import LocationPrefsList from './LocationPrefsList';

const HomePrefs: FC<IHomePrefsProps> = ({ onNext, Controls }) => {
  return (
    <>
      <Controls className={styles.controls} variant="lightFull" />
      <OnBoardingContainer>
        <Question className={styles.question}>
          What is important to you with regard to location?
        </Question>
        <AnswersContainer>
          <LocationPrefsList onNext={() => onNext('searchLocations')} />
        </AnswersContainer>
      </OnBoardingContainer>
    </>
  );
};

export default HomePrefs;
