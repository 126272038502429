import { Row, Col } from 'components-antd';
import { AreaGraphTemplate } from '../../Graphs/AreaGraphTemplate';
import { GeneralLedgerKeys } from '../../Stats/Content/ContentTypes/helper';
import { CollapseRow } from '../../CollapseRow';

export const OwnerAdminView = ({
  grossSummary,
  grossData,
  netSummary,
  netData,
  feesSummary,
  feesData,
  grossListSummary,
  grossListData,
  grossBuySummary,
  grossBuyData,
  isPending,
}) => {
  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col
          span={8}
          xs={{ span: 24, order: 1 }}
          sm={{ span: 12, order: 1 }}
          lg={{ span: 8, order: 1 }}
          className="gutter-row"
        >
          <AreaGraphTemplate
            title={'GCI'}
            color={'#FB913A'}
            hoverColor={'#E28234'}
            summary={
              grossSummary.filter((s) => s.SummaryColumn === GeneralLedgerKeys.GCI)?.[0] || {}
            }
            data={grossData?.[GeneralLedgerKeys.GCI] || []}
            isPending={isPending}
            isExpandableChart={false}
          />
        </Col>
        <Col
          span={8}
          xs={{ span: 24, order: 2 }}
          sm={{ span: 12, order: 2 }}
          lg={{ span: 8, order: 2 }}
          className="gutter-row"
        >
          <AreaGraphTemplate
            title={'Fees'}
            color={'#4673D1'}
            hoverColor={'#3F68BC'}
            summary={
              feesSummary.filter((s) => s.SummaryColumn === GeneralLedgerKeys.FeeSummary)?.[0] || {}
            }
            data={feesData?.[GeneralLedgerKeys.FeeSummary] || []}
            isPending={isPending}
            isExpandableChart={true}
          />
        </Col>
      </Row>
      <CollapseRow title={'Splits'} isDefaultCollapsed={true}>
        <Row gutter={[24, 24]}>
          <Col
            span={8}
            xs={{ span: 24, order: 3 }}
            sm={{ span: 12, order: 3 }}
            lg={{ span: 8, order: 3 }}
            className="gutter-row"
          >
            <AreaGraphTemplate
              title={'Agent Split'}
              color={'#8882D9'}
              hoverColor={'#7570B9'}
              summary={
                grossSummary.filter(
                  (s) => s.SummaryColumn === GeneralLedgerKeys.AgentPayment,
                )?.[0] || {}
              }
              data={grossData?.[GeneralLedgerKeys.AgentPayment] || []}
              isPending={isPending}
              isExpandableChart={false}
            />
          </Col>
          <Col
            span={8}
            xs={{ span: 24, order: 4 }}
            sm={{ span: 12, order: 4 }}
            lg={{ span: 8, order: 4 }}
            className="gutter-row"
          >
            <AreaGraphTemplate
              title={'Team Split'}
              color={'#04A451'}
              hoverColor={'#049440'}
              summary={
                grossSummary.filter(
                  (s) => s.SummaryColumn === GeneralLedgerKeys.TeamPayment,
                )?.[0] || {}
              }
              data={grossData?.[GeneralLedgerKeys.TeamPayment] || []}
              isPending={isPending}
              isExpandableChart={false}
            />
          </Col>
          <Col
            span={8}
            xs={{ span: 24, order: 5 }}
            sm={{ span: 12, order: 5 }}
            lg={{ span: 8, order: 5 }}
            className="gutter-row"
          >
            <AreaGraphTemplate
              title={'Brokerage Split'}
              color={'#FB913A'}
              hoverColor={'#E28234'}
              summary={
                grossSummary.filter(
                  (s) => s.SummaryColumn === GeneralLedgerKeys.BrokeragePayment,
                )?.[0] || {}
              }
              data={grossData?.[GeneralLedgerKeys.BrokeragePayment] || []}
              isPending={isPending}
              isExpandableChart={false}
            />
          </Col>
          {grossData?.[GeneralLedgerKeys.CustomAccountPayment]?.length && (
            <Col
              span={8}
              xs={{ span: 24, order: 6 }}
              sm={{ span: 12, order: 6 }}
              lg={{ span: 8, order: 6 }}
              className="gutter-row"
            >
              <AreaGraphTemplate
                title={'Other Splits'}
                color={'#4673D1'}
                hoverColor={'#3F68BC'}
                summary={
                  grossSummary.filter(
                    (s) => s.SummaryColumn === GeneralLedgerKeys.CustomAccountPayment,
                  )?.[0] || {}
                }
                data={grossData?.[GeneralLedgerKeys.CustomAccountPayment] || []}
                isPending={isPending}
                isExpandableChart={true}
              />
            </Col>
          )}
        </Row>
      </CollapseRow>
      <CollapseRow title={'Net Income'} isDefaultCollapsed={true}>
        <Row gutter={[24, 24]}>
          <Col
            span={8}
            xs={{ span: 24, order: 7 }}
            sm={{ span: 12, order: 7 }}
            lg={{ span: 8, order: 7 }}
            className="gutter-row"
          >
            <AreaGraphTemplate
              title={'Agent Net Income'}
              color={'#4673D1'}
              hoverColor={'#3F68BC'}
              summary={
                netSummary.filter((s) => s.SummaryColumn === GeneralLedgerKeys.AgentPayment)?.[0] ||
                {}
              }
              data={netData?.[GeneralLedgerKeys.AgentPayment] || []}
              isPending={isPending}
              isExpandableChart={false}
            />
          </Col>
          <Col
            span={8}
            xs={{ span: 24, order: 8 }}
            sm={{ span: 12, order: 8 }}
            lg={{ span: 8, order: 8 }}
            className="gutter-row"
          >
            <AreaGraphTemplate
              title={'Team Net Income'}
              color={'#FF576D'}
              hoverColor={'#ED4F64'}
              summary={
                netSummary.filter((s) => s.SummaryColumn === GeneralLedgerKeys.TeamPayment)?.[0] ||
                {}
              }
              data={netData?.[GeneralLedgerKeys.TeamPayment] || []}
              isPending={isPending}
              isExpandableChart={false}
            />
          </Col>
          <Col
            span={8}
            xs={{ span: 24, order: 9 }}
            sm={{ span: 12, order: 9 }}
            lg={{ span: 8, order: 9 }}
            className="gutter-row"
          >
            <AreaGraphTemplate
              title={'Brokerage Net Income'}
              color={'#8882D9'}
              hoverColor={'#7570B9'}
              summary={
                netSummary.filter(
                  (s) => s.SummaryColumn === GeneralLedgerKeys.BrokeragePayment,
                )?.[0] || {}
              }
              data={netData?.[GeneralLedgerKeys.BrokeragePayment] || []}
              isPending={isPending}
              isExpandableChart={false}
            />
          </Col>
          {netData?.[GeneralLedgerKeys.CustomAccountPayment]?.length && (
            <Col
              span={8}
              xs={{ span: 24, order: 10 }}
              sm={{ span: 12, order: 10 }}
              lg={{ span: 8, order: 10 }}
              className="gutter-row"
            >
              <AreaGraphTemplate
                title={'Other Net Income'}
                color={'#4673D1'}
                hoverColor={'#3F68BC'}
                summary={
                  netSummary.filter(
                    (s) => s.SummaryColumn === GeneralLedgerKeys.CustomAccountPayment,
                  )?.[0] || {}
                }
                data={netData?.[GeneralLedgerKeys.CustomAccountPayment] || []}
                isPending={isPending}
                isExpandableChart={true}
              />
            </Col>
          )}
        </Row>
      </CollapseRow>
      {window.location.origin === 'https://app.mosaik.io' ? (
        <></>
      ) : (
        <CollapseRow title={'Rates'} isDefaultCollapsed={true}>
          <Row gutter={[24, 24]}>
            <Col
              span={8}
              xs={{ span: 24, order: 11 }}
              sm={{ span: 12, order: 11 }}
              lg={{ span: 8, order: 11 }}
              className="gutter-row"
            >
              <AreaGraphTemplate
                title={'Commission Rate: List-Side'}
                color={'#FB913A'}
                hoverColor={'#E28234'}
                summary={grossListSummary}
                data={grossListData}
                isPending={isPending}
                isExpandableChart={false}
                showAverage={true}
              />
            </Col>
            <Col
              span={8}
              xs={{ span: 24, order: 12 }}
              sm={{ span: 12, order: 12 }}
              lg={{ span: 8, order: 12 }}
              className="gutter-row"
            >
              <AreaGraphTemplate
                title={'Commission Rate: Buy-Side'}
                color={'#04A451'}
                hoverColor={'#049440'}
                summary={grossBuySummary}
                data={grossBuyData}
                isPending={isPending}
                isExpandableChart={false}
                showAverage={true}
              />
            </Col>
          </Row>
        </CollapseRow>
      )}
    </div>
  );
};
