import { useEffect, useCallback, useState, useRef, Fragment } from 'react';
import classNames from 'classnames';
import { Modal } from 'components-antd';
import { Spinner } from 'components';
import { Header } from './components/Header';
import { Note } from './components/Note';
import { OfferExpiration } from './components/OfferExpiration';
import { Documents } from './components/Documents';
import { getOfferIterationDetailsById, sendCounterForExternalUser } from 'api/offers';
import { Highlights } from './components/Highlights';
import { initState } from './initState';
import { Accordion } from 'components';
import { Button } from 'components-antd';
import { CounterOfferForm } from 'types';
import { limitTextLength, showErrorMessage, showSuccessMessage } from 'helpers';
import { sendCounterOfferEffect } from 'store/effects/offers';
import { getUserFirstName, getUserLastName } from 'store/selectors/user';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getofferSelector } from 'store/selectors/offers';
import { offerListEffect } from 'store/effects/offers';
import { useExternalVerificationRestriction } from 'features/externalVerification/useExtermalVerificationRestriction';

interface SendCounterOfferModalProps {
  isOpen: boolean;
  onClose: any;
  offerIterationData: any;
  offerLinkDetails?: any;
  BuyingAgentName?: string;
  isExternal: boolean;
  uuid: string;
  OfferCount?: string;
}

export const SendCounterOfferModal = ({
  isOpen,
  onClose,
  offerIterationData,
  offerLinkDetails,
  BuyingAgentName,
  isExternal = false,
  OfferCount,
  uuid,
}: SendCounterOfferModalProps) => {
  const sendingToAgentName = offerLinkDetails
    ? offerLinkDetails.OfferDetails?.BuyingAgentName
    : offerIterationData?.OfferDetails?.BuyingAgentName;
  const sendingAgentBrokerage = offerLinkDetails
    ? offerLinkDetails.OfferDetails?.BuyingAgentBrokerage
    : offerIterationData?.OfferDetails?.BuyingAgentBrokerage;

  const [offerIteration, setOfferIteration]: any = useState(initState);
  const offerList = useSelector(getofferSelector);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const [counterOfferFormData, setCounterOfferFormData] = useState<CounterOfferForm>({});
  const [validation, setValidation] = useState({
    termiteInspection: false,
    termiteInspectionResponsibility: false,
    homeWarranty: false,
    homeWarrantCost: false,
    homeWarrantResponsibility: false,
    occupancy: false,
    occupancyDays: false,
    inspectionError: true,
    financingError: false,
    appraisalError: false,
  });
  const contingenciesRef = useRef<HTMLDivElement | null>(null);
  const [openHighlights, setOpenHighlights] = useState(false);
  const onHightAnimationEnd = () => {
    if (validation.appraisalError || validation.financingError || validation.inspectionError) {
      if (contingenciesRef.current) {
        contingenciesRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };
  const validateContigencies = () => {
    let isContigenciesValid = true;
    const { Appraisal, Financing, Inspection } = counterOfferFormData?.Contingencies || {};

    if (Inspection?.selected && !Inspection?.days) {
      setValidation({
        ...validation,
        inspectionError: true,
      });

      isContigenciesValid = false;
    }
    if (Financing?.selected && !Financing?.days) {
      setValidation({
        ...validation,
        financingError: true,
      });
      isContigenciesValid = false;
    }
    if (Appraisal?.selected && !Appraisal?.days) {
      setValidation({
        ...validation,
        appraisalError: true,
      });
      isContigenciesValid = false;
    }
    return isContigenciesValid;
  };

  const validated = () => {
    if (
      counterOfferFormData.TermiteInspection === null ||
      counterOfferFormData.TermiteInspection === undefined
    ) {
      setValidation({
        ...validation,
        termiteInspection: true,
      });
      return false;
    }
    if (
      counterOfferFormData.TermiteInspection &&
      !counterOfferFormData.TermiteInspectionResponsibility
    ) {
      setValidation({
        ...validation,
        termiteInspectionResponsibility: true,
      });
      return false;
    }
    if (
      counterOfferFormData.HomeWarranty === null ||
      counterOfferFormData.HomeWarranty === undefined
    ) {
      setValidation({
        ...validation,
        homeWarranty: true,
      });
      return false;
    }
    if (counterOfferFormData.HomeWarranty && !counterOfferFormData.HomeWarrantyCost) {
      setValidation({
        ...validation,
        homeWarrantCost: true,
      });
      return false;
    }
    if (counterOfferFormData.HomeWarranty && !counterOfferFormData.HomeWarrantyResponsibility) {
      setValidation({
        ...validation,
        homeWarrantResponsibility: true,
      });
      return false;
    }
    if (counterOfferFormData.Occupancy === null || counterOfferFormData.Occupancy === undefined) {
      setValidation({
        ...validation,
        occupancy: true,
      });
      return false;
    }
    if (counterOfferFormData.Occupancy && !counterOfferFormData.OccupancyDays) {
      setValidation({
        ...validation,
        occupancyDays: true,
      });
      return false;
    }
    if (!validateContigencies()) {
      setOpenHighlights(true);
      if (contingenciesRef.current) {
        contingenciesRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return;
      }
      return false;
    }

    return true;
  };

  const fetchOfferIterationDetails = useCallback(async (UUID) => {
    setIsLoading(true);
    const { data } = await getOfferIterationDetailsById({ Id: UUID });
    if (data?.value) setOfferIteration(data.value);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setCounterOfferFormData({});
    if (isOpen && !isExternal) {
      fetchOfferIterationDetails(offerIterationData?.UUID);
    } else setOfferIteration(offerIterationData);
  }, [isOpen]);
  const { token: externalToken } = useExternalVerificationRestriction();

  const fetchTransactionById = (callback?) => {
    dispatch(
      offerListEffect(
        { transactionId: offerList?.['TransactionId'] },
        { silent: true },
        (err) => {},
      ),
    );
  };

  useEffect(() => {
    setValidation({
      termiteInspection: false,
      termiteInspectionResponsibility: false,
      homeWarranty: false,
      homeWarrantCost: false,
      homeWarrantResponsibility: false,
      occupancy: false,
      occupancyDays: false,
      inspectionError: false,
      appraisalError: false,
      financingError: false,
    });
  }, [counterOfferFormData]);

  const renderSuccessToast = () => {
    const toaster = `Counter offer submitted`;

    showSuccessMessage(
      toaster,
      {
        closeOnHover: false,
        hideDuration: 500,
        toastClass: styles.customToast,
        containerId: styles.toastContainerId,
        messageClass: styles.successMessage,
        closeButton: true,
        closeClass: styles.toastCloseButton,
        closeDuration: 200,
      },
      true,
    );
  };

  const counterSentForLoggedInuser = async () => {
    setIsLoading(true);
    counterOfferFormData.OfferExpiryIndefinitely = !counterOfferFormData.OfferExpiryEndDate
      ? true
      : false;
    dispatch(
      sendCounterOfferEffect(
        {
          ...getOldValIfFieldIsEmpty(),
          OfferId: offerIterationData.OfferId,
          CounterParentId: Number(offerIterationData.Id),
          TransactionId: offerList?.TransactionId,
          UUID: uuid,
        },
        {},
        (err, res) => {
          setIsLoading(false);
          if (!err && res?.data.statusCode == 200) {
            const apptData = res?.data?.value;
            setCounterOfferFormData({
              ...getOldValIfFieldIsEmpty(),
              ...apptData,
            });
            showSuccessMessage('Request Submitted');
          } else {
            showErrorMessage(res?.data.errorMessage);
          }
          fetchTransactionById();
          history.push(`/workshop/transactions/${offerList?.['TransactionId']}/offers`);
        },
      ),
    );
  };

  const counterSentForExternalUser = async () => {
    setIsLoading(true);
    try {
      const { data } = await sendCounterForExternalUser(
        uuid,
        {
          ...getOldValIfFieldIsEmpty(),
          OfferId: offerIterationData.OfferId,
          CounterParentId: Number(offerIterationData.Id),
        },
        { externalToken },
      );
      if (data?.statusCode === 200) {
        renderSuccessToast();
        onClose();
      } else {
        showErrorMessage(data?.errorMessage);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showErrorMessage(error);
    }
  };

  const getOldValIfFieldIsEmpty = () => {
    return {
      ...counterOfferFormData,
      PurchasePrice: counterOfferFormData?.PurchasePrice || offerIteration?.PurchasePrice,
      DownPayment: counterOfferFormData?.DownPayment || offerIteration?.DownPayment,
      EarnestMoneyDeposit:
        counterOfferFormData?.EarnestMoneyDeposit || offerIteration?.EarnestMoneyDeposit,
      ClosingDate: counterOfferFormData?.ClosingDate || offerIteration?.ClosingDate,
      FinancingType: counterOfferFormData?.FinancingType || offerIteration?.FinancingType,
      Contingencies: counterOfferFormData?.Contingencies || offerIteration?.Contingencies,
      Notes: counterOfferFormData?.Notes || offerIteration?.Notes,
      OfferIterationDocument:
        counterOfferFormData?.OfferIterationDocument || offerIteration?.OfferIterationDocument,
    };
  };
  const isOfferSubmission = () => {
    const linkMatchRegex = window.location.href.match(/\/offer\/details\/([^/]+)/);
    return linkMatchRegex;
  };
  const getSendingToName = () => {
    if (isOfferSubmission()) {
      return `${limitTextLength(
        offerLinkDetails?.SellerAgent?.FirstName || '',
        15,
      )} (${limitTextLength((offerLinkDetails?.SellerAgent?.LastName || '') ?? '', 15)})`;
    }
    return `${limitTextLength(
      sendingToAgentName ?? BuyingAgentName ?? userFirstName + ' ' + userLastName,
      15,
    )} (${limitTextLength(sendingAgentBrokerage ?? '', 15)})`;
  };

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      className={styles.offerDetailsModal}
    >
      <div>
        <h1 className={styles.heading}>
          Counter Offer #{OfferCount}-{Number(offerIterationData?.IterationCount) + 1}
        </h1>
        <div className={styles.subHeading}>
          <h3 className={styles.subTitle}>Sending To:</h3>
          <div className={styles.headingAlignment}>
            <span className={styles.agentName}>{getSendingToName()}</span>
          </div>
        </div>
      </div>

      {!isLoading ? (
        <Fragment>
          <div className={styles.content}>
            <Note
              data={offerIteration}
              counterOfferFormData={counterOfferFormData}
              setCounterOfferFormData={setCounterOfferFormData}
            />
            <Documents
              data={offerIteration}
              counterOfferFormData={counterOfferFormData}
              setCounterOfferFormData={setCounterOfferFormData}
              BuyingAgentName={BuyingAgentName}
            />
            <Accordion
              className={styles.accordion}
              title="Offer Expiration"
              allowOverflowWhenOpen={true}
              accordionOpenedClass={styles.overflowVisible}
            >
              <OfferExpiration
                data={offerIterationData}
                counterOfferFormData={counterOfferFormData}
                setCounterOfferFormData={setCounterOfferFormData}
              />
            </Accordion>
            <Accordion
              className={styles.accordion}
              title="Highlights"
              eagerRender={true}
              open={openHighlights}
              resetOpen={setOpenHighlights}
              onAnimationComplete={onHightAnimationEnd}
            >
              <Highlights
                data={{ ...offerIteration, OfferCount }}
                counterOfferFormData={counterOfferFormData}
                setCounterOfferFormData={setCounterOfferFormData}
                validation={validation}
                contingenciesRef={contingenciesRef}
              />
            </Accordion>
          </div>

          <div className={styles.action}>
            <Button
              onClick={() => {
                if (!validated()) return;
                !isExternal ? counterSentForLoggedInuser() : counterSentForExternalUser();
              }}
              className={styles.submitButton}
              title="Send"
              variant="secondary"
            >
              <span className={styles.btnText}>Send</span>
            </Button>
          </div>
        </Fragment>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};
