import { combineReducers } from 'redux';

import savedSearchesProperties from './savedSearchesProperties';
import savedSearchesPropertiesDraw from './savedSearchesPropertiesDraw';
import agentProperties from './agentProperties';
import agentPropertiesDraw from './agentPropertiesDraw';
import favorites from './favorites';
import sort from './sort';
import meta from './meta';
import pageInfo from './pageInfo';
import criteria from './criteria';

export default combineReducers({
  savedSearchesProperties,
  savedSearchesPropertiesDraw,
  agentPropertiesDraw,
  agentProperties,
  favorites,
  sort,
  meta,
  pageInfo,
  criteria,
});
