import styles from './styles.module.scss';

import { FormPageSection } from './FormPageSection';
import { FormsSection } from './FormsSection';
import { AddForm } from './AddForm';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { FormFilesName } from './FormFilesName';

export const FormFilesList = (props) => {
  const { openList, documentName } = props;

  if (!openList) return <></>;

  return (
    <div
      testid="users_list"
      className={styles.bundleFormslistWrapper}
      onClick={(e) => e.stopPropagation()}
    >
      <FormFilesName documentName={documentName} />
      <FormsSection {...props} isTemplate={dynamicManager.isTemplateForm()} />

      <AddForm />

      <FormPageSection {...props} />
    </div>
  );
};
