import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Textfit } from 'react-textfit';
import classNames from 'classnames';
import { Fragment, useCallback, useEffect, useState } from 'react';

import { DYNAMIC_QUESTION_TYPE, SignFormFields } from 'app-constants';
import { FormDocumentQuestionType } from 'types';
import {
  getDynamicFormRequiredErrorSelector,
  getDynamicFormScaleSelector,
  getRequestFormProcessSelector,
} from 'store/selectors/requestFormProcess';
import {
  ImageFonts,
  SignModal,
  SignatureResult,
  SignatureResponseType,
  DefaultSystemFont,
} from 'pages/FormBuilder/components';
import { PencilIcon, SignIcon } from 'pages/FormBuilder/components/PDFSign/PDFSignIcons';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { extractSignatureFieldData } from 'pages/FormProcess/DynamicForm/helper';

import styles from './styles.module.scss';
import { InfoTooltip } from 'pages/FormProcess/DynamicForm/Containers/DynamicEditor/components/InfoTooltip';
import { TextFitField } from './TextFitField';
import { getSignatureAnswers, modifySignatureUUID } from './helper';
import {
  updateDynamicFormEffect,
  updateDynamicFormSignatureConfigEffect,
} from 'store/effects/formProcess';
import { getFieldInitialFontSize } from './TextFitField/helper';

export const signatureFieldType = [
  DYNAMIC_QUESTION_TYPE.DateSigned,
  DYNAMIC_QUESTION_TYPE.Initials,
  DYNAMIC_QUESTION_TYPE.Signature,
];

interface SignatureFieldProps {
  question: FormDocumentQuestionType;
  disabled?: boolean;
  color: { border: string; background: string };
  isHighlighted?: boolean;
  answer: string;
  isCohesiveFlow?: boolean;
  documentIndex?: number;
  isOtherSignatory?: boolean;
}

export const SignatureField = ({
  question,
  disabled,
  answer,
  color,
  isHighlighted,
  documentIndex,
  isOtherSignatory,
  isCohesiveFlow,
}: SignatureFieldProps) => {
  const [signModal, setSignModal] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { currentUserSignature: defaultSignature } = useSelector(getRequestFormProcessSelector);

  const currentUserSignature: SignatureResult | undefined = defaultSignature
    ? {
        font: defaultSignature?.Font,
        fullName: defaultSignature?.FullName,
        initials: defaultSignature?.Initials,
        signature: defaultSignature?.Signature,
        signatureUUID: modifySignatureUUID(defaultSignature?.UUID),
      }
    : undefined;

  const scale = useSelector(getDynamicFormScaleSelector);

  const errors = useSelector(getDynamicFormRequiredErrorSelector);
  const isRequired = errors.some((error) => error.questionUUID === question?.UUID);

  const questionType = question.Type as DYNAMIC_QUESTION_TYPE;

  const field = question?.Fields?.[0];
  const widget = field?.widgets?.[0];
  const { x, y, width, height } = widget;
  const fieldInitialFontSize = getFieldInitialFontSize(height);

  let response: SignatureResult | undefined;

  try {
    if (answer) response = JSON.parse(answer);
  } catch (e) {
    response = undefined;
  }

  const responseData = extractSignatureFieldData(response, questionType);

  useEffect(() => {
    const isDateSigned = questionType === DYNAMIC_QUESTION_TYPE.DateSigned;
    const autoFillName = !responseData && isDateSigned && currentUserSignature;
    if (autoFillName) {
      handleFieldClick();
    }
  }, [currentUserSignature]);

  const getStyles = () => {
    return {
      style: {
        width: width * scale,
        height: height * scale,
        left: x * scale,
        top: y * scale,
        background: response ? undefined : color.background,
      },
    };
  };

  const getFieldProps = () => {
    return {
      className: classNames(styles.PDFFieldOverlay, styles.signatureContainer),
    };
  };

  const getProps = useCallback(
    () => ({
      ...getStyles(),
      ...getFieldProps(),
    }),
    [scale, isHighlighted, disabled, response, isRequired],
  );

  const getContainerText = () => {
    let text = 'Sign';

    switch (questionType) {
      case DYNAMIC_QUESTION_TYPE.DateSigned:
        text = 'Date';
        break;
      case DYNAMIC_QUESTION_TYPE.Initials:
        text = 'Initial';
        break;

      default:
        break;
    }

    return text;
  };

  const handleFieldClick = () => {
    if (disabled) return;
    if (questionType === DYNAMIC_QUESTION_TYPE.DateSigned) {
      dynamicManager.handleQuestionResponse(
        question.UUID,
        JSON.stringify({
          signatureDate: moment().format('MM/DD/YYYY'),
          type: SignatureResponseType.Text,
          font: DefaultSystemFont,
        } as SignatureResult),

        documentIndex,
      );
    } else {
      if (currentUserSignature && !response) {
        const result = handleSignatureResult(currentUserSignature, true);

        if (result) {
          return;
        }
      }

      setSignModal(true);
    }
  };

  const drawResponse = () => {
    return <img src={responseData} width={width * scale} height={height * scale} />;
  };

  const renderContainer = () => {
    if (responseData) {
      const isInitials = questionType === DYNAMIC_QUESTION_TYPE.Initials;

      // TODO: Manage initials image
      if (response?.type == SignatureResponseType.Image && !isInitials) {
        return (
          <Fragment>
            <div
              className={classNames(styles.signedInfo, styles.signedTop, {
                [styles.initialSignedInfo]: isInitials,
              })}
            >
              <div>{isInitials ? 'Mosaik' : 'Mosaik Verified'}</div>
            </div>

            <div
              className={styles.signatureImage}
              style={{ border: `2px solid ${color.border}` }}
              onClick={() => !disabled && setSignModal(true)}
            >
              <PencilIcon className={styles.imageEditIcon} />
              {drawResponse()}
            </div>

            {currentUserSignature ? (
              <div className={classNames(styles.signedInfo, styles.signedBottom)}>
                <div>{`#${currentUserSignature.signatureUUID}`}</div>
              </div>
            ) : (
              <></>
            )}
          </Fragment>
        );
      } else {
        const isDateSigned = questionType === DYNAMIC_QUESTION_TYPE.DateSigned;
        const isSignature = questionType === DYNAMIC_QUESTION_TYPE.Signature;

        return (
          <div className={styles.fieldContainer}>
            {isDateSigned ? (
              <div
                className={classNames(
                  styles.signatureFieldContainer,
                  styles.dateSignedTextFitFieldContainer,
                )}
                style={{
                  border: `2px solid ${color.border}`,
                  borderRadius: '8px',
                  fontSize: fieldInitialFontSize,
                }}
              >
                <TextFitField
                  disabled={true}
                  className={styles.dateSignedTextFit}
                  defaultValue={responseData}
                  textFitType="dateSigned"
                  fieldInitialFontSize={fieldInitialFontSize}
                />
              </div>
            ) : (
              <Fragment>
                <div
                  className={classNames(styles.signedInfo, styles.signedTop, {
                    [styles.initialSignedInfo]: isInitials,
                  })}
                >
                  <div>{isInitials ? 'Mosaik' : 'Mosaik Verified'}</div>
                </div>

                <div
                  className={styles.signatureFieldContainer}
                  style={{ border: `2px solid ${color.border}`, cursor: 'pointer' }}
                  onClick={handleFieldClick}
                >
                  <Textfit
                    style={{
                      fontFamily: response?.font,
                      ...(response?.centerText ? { textAlign: 'center' } : {}),
                    }}
                    className={classNames(styles.textFit)}
                    mode="single"
                    forceSingleModeWidth={false}
                  >
                    {responseData}
                  </Textfit>
                  <PencilIcon
                    className={classNames(styles.editIcon, {
                      [styles.signedEditIcon]: isSignature,
                    })}
                  />
                </div>

                {currentUserSignature && isSignature ? (
                  <div className={classNames(styles.signedInfo, styles.signedBottom)}>
                    <div>{`#${currentUserSignature.signatureUUID}`}</div>
                  </div>
                ) : (
                  <></>
                )}
              </Fragment>
            )}
          </div>
        );
      }
    } else {
      const text = getContainerText();
      let toolTipText: any = '';
      let tooltipProps: any = {};

      if (isRequired) {
        toolTipText = <div className={styles.requiredPopover}>Required</div>;
      } else if (isOtherSignatory) {
        toolTipText = 'This field can only be completed by the designated assignee';
        tooltipProps = {
          setOpenChange: () => setShowInfo(false),
          trigger: 'click',
          autoClose: false,
        };
      }

      return (
        <InfoTooltip text={toolTipText} tooltipOpen={isRequired || showInfo} {...tooltipProps}>
          <div
            className={classNames(styles.clickToSignContainer, 'prevent-user-select', {
              [styles.required]: question.Required,
            })}
            style={{
              border: `2px solid ${color.border}`,
            }}
            onClick={() => {
              if (isOtherSignatory) setShowInfo(true);
              handleFieldClick();
            }}
          >
            <SignIcon stroke={color.border} />
            <span className={styles.signText}>{text}</span>
          </div>
        </InfoTooltip>
      );
    }
  };

  const isSameAnswer = (data) => {
    const answers = dynamicManager.getSignatureAnswer();

    if (answers?.[question.UUID]?.Answer) {
      return answers[question.UUID].Answer === data;
    }

    return false;
  };

  const handleSignatureResult = (signatureDetails: SignatureResult, preventSave = false) => {
    let { signModalTab, ...result } = signatureDetails;

    let data = {
      font: result.font,
    } as Partial<SignatureResult>;

    const isImage = ImageFonts.includes(result.font as any);
    const isInitialField = questionType === DYNAMIC_QUESTION_TYPE.Initials;

    if (isInitialField && ((!isImage && result.initials) || (isImage && result.initialsImage))) {
      data = {
        ...data,
        ...result,
        ...(isImage
          ? { type: SignatureResponseType.Image }
          : {
              type: SignatureResponseType.Text,
              centerText: true,
            }),
        signModalTab,
        signature: currentUserSignature?.signature || response?.signature,
      };
    } else if (
      questionType === DYNAMIC_QUESTION_TYPE.Signature &&
      (result.signature || result.fullName)
    ) {
      const signatureImage = !!result.signature;

      data = {
        ...data,
        ...result,
        ...(signatureImage && signModalTab === 'draw'
          ? {
              signature: result.signature,
              type: SignatureResponseType.Image,
            }
          : {
              type: SignatureResponseType.Text,
              centerText: true,
            }),
      };
    } else {
      if (signModal) setSignModal(false);
      return false;
    }

    const stringifiedData = JSON.stringify(data);
    let sameAnswer = isSameAnswer(stringifiedData);

    dynamicManager.handleQuestionResponse(
      question.UUID,
      stringifiedData,
      documentIndex,
      fieldInitialFontSize,
    );

    if (!preventSave && !sameAnswer) {
      setLoading(true);

      delete result.signatureUUID;

      const resultPayload = {
        ...result,
        ...(isInitialField && {
          signature: currentUserSignature?.signature || response?.signature,
        }),
      };

      dynamicManager.handleSaveSignature(resultPayload, () => {
        const answers = dynamicManager.getSignatureAnswer();

        const { answerDetails } = getSignatureAnswers(
          answers,
          stringifiedData,
          question.UUID,
          isCohesiveFlow,
        );

        const callback = () => {
          setLoading(false);
          setSignModal(false);
          return true;
        };

        if (isCohesiveFlow) {
          dispatch(updateDynamicFormEffect({ bundleDocumentsDetails: answerDetails }));
        } else {
          dispatch(updateDynamicFormSignatureConfigEffect({ responses: answerDetails }));
        }

        setTimeout(() => callback(), 500);
      });
    } else {
      setSignModal(false);
      return true;
    }
  };

  const getFieldKey = () => {
    if (questionType === DYNAMIC_QUESTION_TYPE.Initials) {
      return SignFormFields.Initials;
    } else if (questionType === DYNAMIC_QUESTION_TYPE.Signature) {
      return SignFormFields.Signature;
    }
  };

  return (
    <>
      {signModal && (
        <SignModal
          open={signModal}
          fields={question?.Fields}
          onCancel={() => setSignModal(false)}
          onResult={handleSignatureResult}
          response={
            currentUserSignature || response
              ? ({
                  ...(response || {}),
                  ...(currentUserSignature || {}),
                } as any)
              : undefined
          }
          fieldKey={getFieldKey()}
          dynamicForm
          confirmLoading={loading}
        />
      )}

      <div {...getProps()} itemID={`question-${question.UUID}`}>
        {renderContainer()}
      </div>
    </>
  );
};
