import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Modal } from 'components-antd';
import {
  getTimePeriodDueFromSelector,
  getTimePeriodDueToSelector,
  getTimePeriodSelector,
} from 'store/selectors/clarity';
import { Wrapper as PendingWrapper } from 'components';
import { getModalTableColumns, modalTableColumns } from './ModalTableColumns';
import { useFetchFinancialStats } from 'pages/ClarityRevamped/hooks';
import { ExportCSV } from 'pages/ClarityRevamped/Components/ExportCSV';
import { generateCSVFileName } from '../../helper';

import styles from '../styles.module.scss';

export const FinancialsTableModal = ({
  title,
  aggregationType,
  summaryType,
  summaryKey,
  columnType,
  ledgerAccountId,
  memo,
  year,
  month,
  isoWeek,
  date,
  open,
  onCancel,
  selectedAgent,
  representingSide,
  isCommission,
}) => {
  const dueFrom = useSelector(getTimePeriodDueFromSelector);
  const dueTo = useSelector(getTimePeriodDueToSelector);
  let timePeriod = useSelector(getTimePeriodSelector);
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(false);

  if (timePeriod === moment().subtract(1, 'year').year()) timePeriod = 'YTD';

  const getStats = useFetchFinancialStats();

  const formatCSVData = (data) => {
    const formattedData = data?.map((row) => {
      return {
        Date: row.Date,
        Amount: `"$${row.TotalValue.toLocaleString()}"`,
        Transaction: `"${row.Transaction}"`,
        Agent: row?.Agent.join(', '),
        DeductFrom: row?.DeductFrom,
        ApplyTo: row?.ApplyTo,
        Representing: row?.RepresentingSide,
      };
    });
    if (columnType === modalTableColumns.TeamGrossNet) {
      return formattedData.map(({ Date, Transaction, Amount, Representing, Agent }) => ({
        Date,
        Transaction,
        Amount,
        Representing,
        Agent,
      }));
    } else if (
      columnType === modalTableColumns.AgentGrossNet ||
      columnType === modalTableColumns.CustomSplits
    ) {
      return formattedData.map(({ Date, Transaction, Amount, Representing, Agent }) => ({
        Date,
        Transaction,
        Amount,
        Representing,
        Agent,
      }));
    } else if (columnType === modalTableColumns.Fees) {
      return formattedData.map(({ Date, Transaction, DeductFrom, ApplyTo, Amount, Agent }) => ({
        Date,
        Transaction,
        DeductFrom,
        ApplyTo,
        Amount,
        Agent,
      }));
    }
  };

  useEffect(() => {
    getStats({
      setSummary: () => {},
      setData,
      setIsPending,
      key: summaryKey,
      aggregationType: aggregationType,
      summaryType: summaryType,
      aggregateDataFormat: 'Tabular',
      memo,
      ledgerAccountId,
      year: year,
      quarter: null,
      month: timePeriod === 'YTD' ? month : null,
      isoWeek: timePeriod === 'QTD' ? isoWeek : null,
      date: timePeriod === 'MTD' || timePeriod === 'WEEK' || dueFrom || dueTo ? date : null,
      transactionAgentUserId: selectedAgent?.TransactionAgentUserId,
      transactionAgentContactId: selectedAgent?.TransactionAgentContactId,
      representingSide,
      isCommission,
    });
  }, [timePeriod, year, month, isoWeek, dueFrom, dueTo]);

  return (
    <Modal
      width={1000}
      className={styles.clarityTableModal}
      open={open}
      footer={null}
      onCancel={onCancel}
    >
      <header className={styles.tableHeader}>
        <h2 className={styles.modalHeader}>{title}</h2>
        <ExportCSV data={formatCSVData(data)} fileName={generateCSVFileName(title)} />
      </header>
      <PendingWrapper isPending={isPending} className={styles.tableContainer}>
        <Table
          className={styles.statsTable}
          columns={getModalTableColumns(columnType, isCommission) as any}
          dataSource={data}
          pagination={false}
        />
      </PendingWrapper>
    </Modal>
  );
};
