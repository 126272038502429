import classNames from 'classnames';

import { priceConverter } from 'helpers';

import styles from './styles.module.scss';

export const SourceDetailsBox = ({ source }) => {
  const selectedRowStyles = {
    background: `${source?.color}14`,
  };

  const selectedTextStyles = {
    color: `${source?.color}`,
  };

  const colorIndicator = {
    background: `${source?.color}`,
  };

  return (
    <div
      className={classNames(styles.sourceContainer, { [styles.selected]: source?.selected })}
      style={source?.selected && selectedRowStyles}
    >
      <div className={styles.colorBox} style={colorIndicator}></div>
      <div className={styles.sourceInfo}>
        <div className={classNames(styles.title, { [styles.selected]: source?.selected })}>
          {source.LeadSource}
        </div>
        <div className={styles.infoRow} style={source?.selected && selectedTextStyles}>
          <div className={classNames(styles.total, { [styles.selected]: source?.selected })}>
            {priceConverter(source.TotalVolume)}
          </div>
          {source?.Percentage && (
            <div className={styles.percentage}>{`(${source?.Percentage}%)`}</div>
          )}
        </div>
      </div>
    </div>
  );
};
