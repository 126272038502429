import { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getClientDashboardNeedsAttentionSelector } from 'store/selectors/clientDashboard';
import styles from './styles.module.scss';
import { ArrowRight2 } from 'components/Icons';
import FormsModal from '../../dashboards/ClientTransactionsDashboard/NeedsAttention/Modals/Forms/FormsModal';

export const FormsToSignAction = () => {
  const [openModal, setOpenModal] = useState(false);
  const needsAttention = useSelector(getClientDashboardNeedsAttentionSelector);

  const needsAttentionData = useMemo(() => {
    if (!needsAttention?.data?.length) {
      return [];
    }
    return needsAttention?.data;
  }, [needsAttention]);

  const waitingOnYouForms = needsAttentionData.length && needsAttentionData[0][0].waitingOnYou;

  const toggleModal = () => {
    setOpenModal((prev) => !prev);
  };

  if (!waitingOnYouForms?.length) return <></>;

  const isPlural = waitingOnYouForms.length > 1;
  return (
    <Fragment>
      <div className={styles.formsToSignActionMenu} onClick={toggleModal}>
        <div className={styles.needsAttentionText}>Needs Your Attention:</div>
        <div className={styles.formsToSignActionMenuLayout}>
          <div className={styles.formsToSignMenuText}>
            <div className={styles.formsCountText}>
              <div>
                {waitingOnYouForms.length}
                {waitingOnYouForms.length > 5 ? '+' : ''}
              </div>
            </div>
            <div className={styles.formText}>{`Form${isPlural ? 's' : ''} to Sign`}</div>
          </div>
          <ArrowRight2 className={styles.rightIcon} />
        </div>
      </div>

      <FormsModal isOpen={openModal} onCloseModal={toggleModal} formDetails={waitingOnYouForms} />
    </Fragment>
  );
};
