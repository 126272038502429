import { Table, TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Modal } from 'components-antd';
import {
  getAgentTeamStatsSelector,
  getTimePeriodDueFromSelector,
  getTimePeriodDueToSelector,
  getTimePeriodSelector,
} from 'store/selectors/clarity';
import { Wrapper as PendingWrapper } from 'components';
import { useFetchLeadSourceStats } from 'pages/ClarityRevamped/hooks';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { hideBreakdownMenu } from '../../../../ContentTypes/helper';
import { ExportCSV } from 'pages/ClarityRevamped/Components/ExportCSV';
import { generateCSVFileName } from '../../helper';

import styles from '../styles.module.scss';

interface DataType {
  Date: string;
  Transaction: string;
  TotalVolume: number;
  PropertyTransactionId: number;
  RepresentingSide: string;
  LeadSource: string;
  TransactionAgentUser: string;
  Agents: string[];
}

export const LeadSourceTableModal = ({
  year,
  month,
  isoWeek,
  date,
  open,
  onCancel,
  selectedAgent,
  sourceList,
  modalHeading,
}) => {
  const dueFrom = useSelector(getTimePeriodDueFromSelector);
  const dueTo = useSelector(getTimePeriodDueToSelector);
  let timePeriod = useSelector(getTimePeriodSelector);
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  if (timePeriod === moment().subtract(1, 'year').year()) timePeriod = 'YTD';

  const getStats = useFetchLeadSourceStats();

  const leadSourceColumns: TableProps<DataType>['columns'] = [
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
      sorter: {
        compare: (record1, record2) => {
          const a = moment(record1.Date);
          const b = moment(record2.Date);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value) {
        return <span className={styles.dateColumn}>{value}</span>;
      },
    },
    {
      title: 'Transaction',
      dataIndex: 'Transaction',
      key: 'Transaction',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Transaction;
          const b = record2.Transaction;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value, record) {
        return (
          <a
            target="_blank"
            className={styles.linkToTransaction}
            href={`/workshop/transactions/${record.PropertyTransactionId}/financials`}
            rel="noreferrer"
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'TotalVolume',
      key: 'TotalVolume',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.TotalVolume;
          const b = record2.TotalVolume;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (TotalVolume) => {
        return TotalVolume > 0
          ? `$${TotalVolume.toLocaleString()}`
          : `-$${Math.abs(TotalVolume).toLocaleString()}`;
      },
    },
    !modalHeading
      ? {
          title: 'Lead Source',
          dataIndex: 'LeadSource',
          key: 'LeadSource',
          sorter: {
            compare: (record1, record2) => {
              const a = record1.LeadSource;
              const b = record2.LeadSource;
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
        }
      : {},
    {
      title: 'Representing',
      dataIndex: 'RepresentingSide',
      key: 'RepresentingSide',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.RepresentingSide;
          const b = record2.RepresentingSide;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    hideBreakdownMenu(agentRole, teamStats)
      ? {
          title: 'Agent',
          dataIndex: 'Agents',
          key: 'Agents',
          sorter: {
            compare: (record1, record2) => {
              const a = record1.Agents;
              const b = record2.Agents;
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
          render: (Agents) => {
            return Agents.join(', ');
          },
        }
      : {},
  ];

  const formatCSVData = (data) => {
    return data?.map((row) => {
      return {
        Date: row.Date,
        Transaction: `"${row.Transaction}"`,
        Amount: `"$${row.TotalVolume.toLocaleString()}"`,
        LeadSource: row.LeadSource,
        RepresentingRole: row.RepresentingSide,
        ...(hideBreakdownMenu(agentRole, teamStats) && { Agents: row.Agents.join(', ') }),
      };
    });
  };

  useEffect(() => {
    getStats({
      setSummary: () => {},
      setData,
      setIsPending,
      aggregateDataFormat: 'Tabular',
      year: year,
      quarter: null,
      month: timePeriod === 'YTD' ? month : null,
      isoWeek: timePeriod === 'QTD' ? isoWeek : null,
      date: timePeriod === 'MTD' || timePeriod === 'WEEK' || dueFrom || dueTo ? date : null,
      transactionAgentUserId: selectedAgent?.TransactionAgentUserId,
      transactionAgentContactId: selectedAgent?.TransactionAgentContactId,
      sourceList: sourceList,
      expand: true,
    });
  }, [timePeriod, year, month, isoWeek, dueFrom, dueTo]);

  return (
    <Modal
      width={1000}
      className={styles.clarityTableModal}
      open={open}
      footer={null}
      onCancel={onCancel}
    >
      <header className={styles.tableHeader}>
        <h2 className={styles.modalHeader}>{modalHeading ?? 'Lead Sources'}</h2>
        <ExportCSV data={formatCSVData(data)} fileName={generateCSVFileName('Lead Sources')} />
      </header>
      <PendingWrapper isPending={isPending} className={styles.tableContainer}>
        <Table
          className={styles.statsTable}
          columns={leadSourceColumns}
          dataSource={data}
          pagination={false}
        />
      </PendingWrapper>
    </Modal>
  );
};
