import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import {
  getPropertyReferenceDetailsAction,
  updatePropertyReferenceRadiusAction,
} from 'store/actions/radarPortfolio';
import { IPropertyReferenceDetails, IPropertyReferenceDetailsState } from './types';

const initialState: IPropertyReferenceDetailsState = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [getPropertyReferenceDetailsAction as any]: (state, { payload }) => ({
      state: get(payload, 'state'),
      data: get(payload, 'data', null),
      meta: get(payload, 'meta'),
    }),
    [updatePropertyReferenceRadiusAction as any]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        address: {
          ...state.data?.address,
          neighbourhoodRadius: payload.data?.address?.neighbourhoodRadius,
        },
      } as IPropertyReferenceDetails,
    }),
  },
  initialState,
);
