import PropTypes from 'prop-types';
import { routes } from 'settings/navigation/routes';
import { PageWrapper } from 'components/SettingsGroup';
import SettingsFullProfile from './SettingsFullProfile';
import { Notifications } from './Notifications';
import Password from './Password';
import Subscription from './Subscription';
import SettingsPartner from './SettingsPartner';
import { ChangePlan } from './Subscription/ChangePlan';
import { SettingsExport } from './SettingsExport';
import { SettingsCoBuyer } from './SettingsCoBuyer';
import Integrations from './Integrations';
import SettingsDisplay from './SettingsDisplay';
import { SettingPermissions } from './SettingPermissions';

const getPageByRoute = (path) => {
  switch (path) {
    case routes.settings:
      return <SettingsFullProfile />;
    case routes.settingsPartner:
      return <SettingsPartner />;
    case routes.settingsCoBuyer:
      return <SettingsCoBuyer />;
    case routes.settingsNotifications:
      return <Notifications />; // INFO: New notifications
    case routes.settingsPassword:
      return <Password />;
    case routes.settingsSubscription:
      return <Subscription />;
    case routes.settingsSubscriptionChange:
      return <ChangePlan />;
    case routes.settingsPreApproval:
      return 'PreApproval';
    case routes.settingsThirdPartyIntegrations:
      return <Integrations />;
    case routes.settingsExport:
      return <SettingsExport />;
    case routes.settingsDisplay:
      return <SettingsDisplay />;
    case routes.settingPermissions:
      return <SettingPermissions />;
    default:
      return `No page for ${path}`;
  }
};

const Settings = ({ match: { path } }) => <PageWrapper>{getPageByRoute(path)}</PageWrapper>;

Settings.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default Settings;
