import { apiServer } from 'settings/web-services/api';
import { MULTIPLE_TASKS } from 'app-constants/endpoints';

export function updateTransactionTask(cfg) {
  const { transactionId, taskId, ...config } = cfg;
  return apiServer.put(`/transaction/${transactionId}/tasks/${taskId}`, config);
}

export function deleteTransactionTask(cfg) {
  return apiServer.delete(`/transaction/${cfg?.transactionId}/tasks/${cfg?.taskId}`);
}

export function uploadTransactionTaskDocuments(cfg) {
  const { transactionId, taskId, ...config } = cfg;
  return apiServer.post(`/transaction/${transactionId}/tasks/${taskId}/documents`, config);
}

export function deleteTransactionTaskDocument(cfg) {
  const { transactionId, taskId, documentId } = cfg;
  return apiServer.delete(`/transaction/${transactionId}/tasks/${taskId}/documents/${documentId}`);
}

export function getTransactionTaskDocuments(cfg) {
  const { DocumentVaultUUID, ...config } = cfg;
  return apiServer.get(`/documentVault/${DocumentVaultUUID}/document`, config);
}

export function getPageImage(cfg) {
  const { DocumentVaultUUID, ...config } = cfg;
  return apiServer.get(`/documentVault/${DocumentVaultUUID}/getImage`, config);
}

export function getDocumentThumbnails(cfg) {
  const { DocumentVaultUUID, ...config } = cfg;
  return apiServer.get(`/documentVault/${DocumentVaultUUID}/thumbnails`, config);
}

export function getNonVoidDocument(cfg) {
  const { formProcessId, formProcessDocumentId, ...config } = cfg;
  return apiServer.get(
    `/formProcess/formdetails/downloadForm/${formProcessId}/${formProcessDocumentId}`,
    config,
  );
}

export function updateManyTransactionTasks(cfg) {
  const { transactionId, config } = cfg;
  return apiServer.put(`/transaction/${transactionId}/tasks/many`, config);
}

export function updateTransactionTaskChecklist(cfg) {
  const { transactionId, taskId, checklistId, ...config } = cfg;
  return apiServer.put(
    `/transaction/${transactionId}/tasks/${taskId}/checklist/${checklistId}`,
    config,
  );
}

export const updateMultipleTasks = (tasks) => apiServer.put(MULTIPLE_TASKS, tasks);

export const deleteMultipleTasks = (taskIds) =>
  apiServer.delete(MULTIPLE_TASKS, { params: { taskIds } });
