import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { PdfCache, Spinner } from 'components';
import { PageWrapper } from 'components-antd';
import { Topbar, EditorPage, PDFEditorPageProps, FormFilesList } from './components';
import {
  getDynamicFormEditorConfigSelector,
  getDynamicFormSelector,
  getLazyLoadedDocumentSelector,
} from 'store/selectors/requestFormProcess';

import { SignatureEditorWidgets } from './components/SignatureEditorWidgets';
import { BundleDataType } from 'types';
import styles from './styles.module.scss';
import { useResize } from 'hooks';
import { MOBILE_WIDTH, getAllDocumentsWithPages } from './helper';
import { useDocumentScroll } from './hooks/useDocumentScroll';

import { PrintFormPDF } from 'pages/FormProcess/components';

export const DynamicEditor = () => {
  const { state: formState } = useLocation<BundleDataType>();
  const state = useMemo(() => formState, []);

  const {
    screen: { width },
  } = useResize();

  const { meta: { documentName } = {}, dynamicFormDocuments = [] } =
    useSelector(getDynamicFormSelector);

  let isBundle = !!state?.formBundle || !!state?.templateBundle || dynamicFormDocuments.length;

  const windowState = window.history.state;
  const isWebView = state?.isWebView || windowState?.isWebView;

  const [currentPage, setCurrentPage] = useState(0);
  const [currentDocIndex, setCurrentDocIndex] = useState(0);

  const [openList, setOpenList] = useState(width > MOBILE_WIDTH && isBundle);
  const [loading, setLoading] = useState(false);

  let pdfDocRefs = useRef({});
  let pdfDocAndPageRefs = useRef({});
  let pageContentRef = useRef(null);

  const editorConfig = useSelector(getDynamicFormEditorConfigSelector);
  const { pdfDocuments } = useSelector(getLazyLoadedDocumentSelector);

  const allDocuments = dynamicFormDocuments || state?.allDocuments || state?.allTemplates || [];

  const [activeScrolledDocumentId, setScrolledDocumentId] = useState(
    allDocuments.length ? allDocuments?.[0].Id || -1 : -1,
  );

  useDocumentScroll({
    loading,
    allDocuments,
    pdfDocAndPageRefs,
    isBundle,
    setScrolledDocumentId,
    setCurrentDocIndex,
    setCurrentPage,
  });

  return editorConfig ? (
    <DndProvider options={HTML5toTouch}>
      <PageWrapper
        mainPageContentStyle={classNames(styles.mainContainer, styles.questionContainer)}
        pageContainerClassName={isWebView ? styles.webViewPageContainer : ''}
        version={3}
        showFooter={false}
        pageContentRef={pageContentRef}
      >
        <Topbar
          isBundle={isBundle}
          isWebView={isWebView}
          openBundleList={openList}
          bundleName={state?.bundleName || state?.templateName}
          setOpenBundleList={setOpenList}
        />

        <div className={openList ? styles.editorWithBundle : ''}>
          <FormFilesList
            formStateDetails={state}
            openList={openList}
            pageNumber={currentPage}
            loading={loading}
            docIndex={currentDocIndex}
            scrolledDocumentId={activeScrolledDocumentId}
            setLoading={setLoading}
            setOpenList={setOpenList}
            documentName={state?.bundleName || documentName}
            onPageChange={(docIndex, pageIndex, docId) => {
              setCurrentDocIndex(+docIndex);
              setCurrentPage(+pageIndex);
              docId && setScrolledDocumentId(docId);
            }}
            screenWidth={width}
            allDocuments={allDocuments}
          />

          <PrintFormPDF
            className={classNames(styles.pdfDocument, {
              [styles.editorPdfDocument]: isBundle && openList,
            })}
            classNamePdfDocument={classNames(styles.pdfDocumentClass)}
            classNameDocumentPage={classNames(styles.documentPage, {
              [styles.pageScrollwiseDocument]: isBundle,
            })}
            pageRender={(props) => <EditorPage {...(props as unknown as PDFEditorPageProps)} />}
            widgets={
              isBundle ? <SignatureEditorWidgets isBundleList={isBundle && openList} /> : <></>
            }
            pdfLoading={loading}
            pdfDocRefs={pdfDocRefs}
            pdfDocAndPageRefs={pdfDocAndPageRefs}
            pdfDocuments={pdfDocuments || []}
            pageContentRef={pageContentRef}
            allDocuments={getAllDocumentsWithPages(allDocuments)}
          />
        </div>
      </PageWrapper>
    </DndProvider>
  ) : (
    <Spinner />
  );
};
