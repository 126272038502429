import { AntCard, Button } from 'components-antd';

import { TimelineItem } from '../TimelineItem';

import styles from './styles.module.scss';
import moment from 'moment';
import { getFormattedDate } from 'pages/Workshop/Tours/helper';
import classNames from 'classnames';
import { Cross } from 'components/Icons';
import { TourStatus } from 'app-constants/enums/tours';

export const GroupedTimeline = (props) => {
  const { tours, groupedTimelineClassName, timelineItemInfoWrapperClassName, onTourCancel } = props;
  const groupsToursByDate = tours.reduce((groups, tour) => {
    const date = moment(tour.ScheduleDateTime).format('MM/DD/YYYY');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(tour);
    return groups;
  }, {});

  return (
    <>
      {Object.keys(groupsToursByDate)
        .sort()
        .map((item, i) => (
          <AntCard
            title={getFormattedDate(groupsToursByDate[item][0].ScheduleDateTime)}
            className={classNames(styles.groupedItems, groupedTimelineClassName)}
            key={i}
          >
            {groupsToursByDate[item].map((tour, index) => (
              <TimelineItem
                tour={tour}
                key={index}
                infoWrapClassName={classNames(
                  styles.clientInfoWrapper,
                  timelineItemInfoWrapperClassName,
                )}
              >
                {tour.Status === TourStatus.Canceled && (
                  <span className={styles.cancelled}>Canceled</span>
                )}
                {tour.Status === TourStatus.Approved && moment(tour.ScheduleDateTime) > moment() && (
                  <Button
                    className={styles.buttonReject}
                    variant="red-outlined"
                    onClick={() => onTourCancel(tour)}
                  >
                    <Cross className={styles.icon} color="#EC455E" />
                  </Button>
                )}
              </TimelineItem>
            ))}
          </AntCard>
        ))}
    </>
  );
};
