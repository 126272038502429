import React, { useMemo, useState } from 'react';

import { EmptyChartPlaceholder } from 'components/EmptyChartPlaceholder';
import { PieChart } from 'components/PieChart';
import { LeadSourceTableModal } from 'pages/ClarityRevamped/Components/Stats/Content/Components/TableView/TableModal/Volume/LeadSourceTableModal';

import styles from './styles.module.scss';

type ChartDataType = {
  data: number[];
  numberTransactions: number[];
  categories: string[];
  colors: string[];
};

type ChartPoint = {
  category: string | null;
};

type ClarityPieChartProps = {
  data: any[];
  selectedAgent?: any;
};

export const ClarityPieChart: React.FC<ClarityPieChartProps> = ({ data, selectedAgent }) => {
  const [openModal, setOpenModal] = useState(false);
  const [chartData, setChartData] = useState<ChartDataType>({
    data: [],
    numberTransactions: [],
    categories: [],
    colors: [],
  });

  const [chartPoint, setChartPoint] = useState<ChartPoint>({
    category: null,
  });

  useMemo(() => {
    if (data?.length) {
      const values = {
        data: data?.map((item) => item.TotalVolume),
        numberTransactions: data?.map((item) => item?.NumberTransactions),
        categories: data?.map((item) => item?.LeadSource),
        colors: data?.map((item) => item.color),
      };
      setChartData({ ...values });
    }
  }, [data]);

  const configProps = {
    data: chartData?.data,
    numberTransactions: chartData?.numberTransactions,
    categories: chartData?.categories,
    colors: chartData?.colors,
    className: styles.smallCardAreaChart,
    onClickDataPoint: (index) => {
      if (chartData?.data[index] !== 0) {
        setChartPoint({
          category: chartData?.categories[index],
        });
        setOpenModal(true);
      }
    },
  };

  return (
    <>
      {data?.length ? <PieChart {...configProps} /> : <EmptyChartPlaceholder />}
      {openModal && (
        <LeadSourceTableModal
          year={null}
          month={null}
          isoWeek={null}
          date={null}
          open={openModal}
          onCancel={() => setOpenModal(false)}
          selectedAgent={selectedAgent}
          sourceList={[chartPoint.category]}
          modalHeading={chartPoint.category}
        />
      )}
    </>
  );
};
