import moment, { Moment } from 'moment';
import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';
import {
  setTimePeriodAction,
  setTimePeriodDueFromAction,
  setTimePeriodDueToAction,
} from 'store/actions/clarity';
import { DateRangePicker } from 'components/Form/DateRangePicker';
import {
  getTimePeriodDueFromSelector,
  getTimePeriodDueToSelector,
  getTimePeriodSelector,
} from 'store/selectors/clarity';

import styles from './styles.module.scss';

type Props = {
  defaultOption?: string;
  wrapperClassname?: string;
};
export const DateRangeFilter = ({ ...props }: Props) => {
  const dispatch = useDispatch();
  const timePeriod = useSelector(getTimePeriodSelector);
  const [resetDates, setResetDates] = useState(false);
  const selectedDueFrom = useSelector(getTimePeriodDueFromSelector);
  const selectedDueTo = useSelector(getTimePeriodDueToSelector);
  const [dueFrom, setDueFrom] = useState<Moment | undefined>(selectedDueFrom);
  const [dueTo, setDueTo] = useState<Moment | undefined>(selectedDueTo);
  const [isCustomDate, setIsCustomDate] = useState(!!(selectedDueFrom || selectedDueTo));
  const [isOpen, setIsOpen] = useState(false);

  const getCustomDateText = () =>
    `${dueFrom?.format('DD MMM, YYYY')}${dueTo ? ' - ' + dueTo.format('DD MMM, YYYY') : ''}`;

  const getDefaultOption = () => {
    if (dueFrom || dueTo) return getCustomDateText();
    if (props.defaultOption)
      return props.defaultOption === 'WEEK' ? 'This Week' : props.defaultOption;
    return 'YTD';
  };
  const previousYear = moment().subtract(1, 'year').year();
  const dropdownFields = ['This Week', 'MTD', 'QTD', 'YTD', previousYear, 'Custom'];

  const [value, setValue] = useState(getDefaultOption());

  const onChange = (e) => {
    const selectedOption = e.target.value;
    const value = selectedOption === 'This Week' ? 'WEEK' : selectedOption;
    if (selectedOption === 'Custom') {
      setIsCustomDate(true);
      const startDate = moment().startOf('year');
      const endDate = moment();
      setDueFrom(startDate);
      setDueTo(moment());
      setValue(`${startDate.format('DD MMM, YYYY')} - ${endDate.format('DD MMM, YYYY')}`);
    } else {
      dispatch(setTimePeriodAction({ value }));
      setIsOpen(false);
      setValue(selectedOption);
    }
  };

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => {
          return (
            <MenuItem key={idx}>
              <Radio value={item} key={idx}>
                <div className={styles.option}>
                  <span className={styles.optionValue}>{item}</span>
                </div>
                {value === item && <Check className={styles.checkIcon} />}
              </Radio>
              {(idx === 3 || idx === 4) && <div className={styles.customLine} />}
            </MenuItem>
          );
        })}
      </Menu>
    </Radio.Group>
  );

  const customMenu = (
    <div className={styles.datePickerContainer}>
      <div className={styles.title}>Custom</div>
      <DateRangePicker
        span={11}
        title={''}
        onDatesChange={(from, to) => {
          resetDates && setResetDates(false);
          setDueFrom(from);
          setDueTo(to);
        }}
        fromDateValue={dueFrom}
        toDateValue={dueTo}
        dateRangePickerWrapperClass={styles.dateRangePicker}
        containerClass={styles.dateColContainer}
        toTextClass={styles.toText}
        resetDates={resetDates}
        futureDisabled={false}
        startDateSubtitle="Start Date"
        endDateSubtitle="End Date"
      />
      <div className={styles.footer}>
        <div
          onClick={() => {
            setIsOpen(false);
            setIsCustomDate(false);
            if (dueFrom || dueTo) {
              setDueFrom(undefined);
              setDueTo(undefined);
              dispatch(setTimePeriodDueFromAction({ value: null }));
              dispatch(setTimePeriodDueToAction({ value: null }));
              dispatch(setTimePeriodAction({ value: 'YTD' }));
              setValue('YTD');
            } else {
              setValue(timePeriod === 'WEEK' ? 'This Week' : timePeriod);
            }
          }}
          className={classNames(styles.btn, styles.removeBtn)}
        >
          Remove
        </div>
        <div
          onClick={() => {
            setIsCustomDate(true);
            dispatch(setTimePeriodDueFromAction({ value: dueFrom }));
            dispatch(setTimePeriodDueToAction({ value: dueTo }));
            setValue(getCustomDateText());
            setIsOpen(false);
          }}
          className={classNames(styles.btn, styles.applyBtn)}
        >
          Apply
        </div>
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.clarityFilterWrap, props.wrapperClassname)}>
      <Dropdown
        overlay={isCustomDate ? customMenu : menu}
        placement="bottomRight"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
        open={isOpen}
      >
        <a
          onClick={(e) => {
            e.preventDefault();
            if (value !== 'Custom') setIsOpen(!isOpen);
          }}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.optionValue}>{value}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} size={16} />
        </a>
      </Dropdown>
    </div>
  );
};
