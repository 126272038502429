import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { readFiles } from 'helpers';

import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';
import { Button } from 'components';
import DocumentsModal from 'components/Transactions/UploadDocuments/DocumentsModal';
import { getTransactionParticipantsFormattedList } from 'store/selectors/transaction';
import {
  getTransactionDocumentsFilterEffect,
  resetTransactionDocumentsFiltersEffect,
  setTransactionDocumentsFilterLoadingEffect,
  uploadTransactionDocumentsEffect,
} from 'store/effects/transactions';
import { uploadFilePopupModes } from 'settings/constants/common';

import styles from './styles.module.scss';
import { getTransactionDocumentsSelector } from 'store/selectors/transactionDocuments';
import { moveTransactionDocumentEffect } from 'store/effects/transactions/folders/update';
import { PENDING } from 'settings/constants/apiState';

const Upload = (props) => {
  const { className, selectedFolderData, setIsFilterApplied } = props;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const participants = useSelector(getTransactionParticipantsFormattedList);
  const { documents } = useSelector(getTransactionDocumentsSelector);
  const dispatch = useDispatch();

  const onOptionFileChange = (event) => {
    readFiles(event.target.files).then((values) => {
      setUploadedFiles(values);
      props.setModalUpload({ open: true, mode: uploadFilePopupModes.uploaded });
    });
  };

  const moveDocuments = (docIds) => {
    dispatch(
      moveTransactionDocumentEffect(
        { folderId: selectedFolderData?.Id, documentId: docIds },
        {},
        (err) => {
          if (!err) {
            dispatch(getTransactionDocumentsFilterEffect());
          }
        },
      ),
    );
  };

  const onSaveHandler = (newValues, cb) => {
    setIsPending(true);

    dispatch(
      uploadTransactionDocumentsEffect({ documents: newValues, participants }, {}, (err, data) => {
        if (!err && data) {
          const docIds = data.data.result.map(({ Id }) => Id);
          props.setModalUpload({ open: false });
          setUploadedFiles([]);
          if (selectedFolderData?.isFolder) {
            moveDocuments(docIds);
          }

          dispatch(resetTransactionDocumentsFiltersEffect());
          dispatch(setTransactionDocumentsFilterLoadingEffect({ state: PENDING }));
          dispatch(getTransactionDocumentsFilterEffect({}, { silent: true }));
          setIsFilterApplied(false);
        }
        setIsPending(false);
        if (cb) cb();
      }),
    );
  };

  const onCloseModal = () => {
    setUploadedFiles([]);
    props.setModalUpload({ open: false });
  };

  return (
    <div className={classNames(styles.new, className)}>
      <DocumentsModal
        modal={props.modalUpload}
        files={uploadedFiles}
        isPending={isPending}
        onSave={onSaveHandler}
        onCloseModal={onCloseModal}
        onOptionFileChange={onOptionFileChange}
        withCategory
        withPermissions
        isCategoryOptional
        dropzone
        filesRequired={true}
      />
    </div>
  );
};

Upload.propTypes = {
  className: PropTypes.string,
};

Upload.defaultProps = {
  className: '',
};

export default Upload;
