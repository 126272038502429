import { Locations } from 'components';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';

import classNames from 'classnames';
import { getFeedCriteriaSelectorV3 } from 'store/selectors/feedv3';
import styles from './styles.module.scss';
import * as yup from 'yup';
import { setCriteriaAction } from 'store/actions/feedv3';
import { useSearchInstanceV3Effect } from 'pages/Properties/ClientFeed/hooks/useSearchInstanceV3Effect';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { defaultPropertyStatusToFetch, subFilterTypes } from 'settings/constants/properties';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { useAgentSearchInstance } from 'pages/Properties/AgentFeed/hooks/useAgentSearchInstance';

const initialValues = {
  Locations: [],
};

type Props = {
  formClassname?: string;
  valuesWrapperClassName?: string;
  searchWrapperClassName?: string;
  isSearchPage?: boolean;
  disabled?: boolean;
  valueClassName?: string;
  isAgent?: boolean;
  userId?: number;
};

const validationSchema = yup.object().shape({
  Locations: yup.array().min(1, 'Please enter a location'),
});

export const LocationSearch: React.FC<Props> = ({
  formClassname,
  valuesWrapperClassName,
  searchWrapperClassName,
  isSearchPage = false,
  disabled = false,
  valueClassName,
  userId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const getAgentSearchInstance = useAgentSearchInstance();
  const getSearchInstanceV3 = useSearchInstanceV3Effect();

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: (values) => {
      const criteria: any = {
        Locations: values.Locations,
        Status: defaultPropertyStatusToFetch,
      };
      const isCass = values?.Locations?.some(({ deliveryLine1 }) => deliveryLine1);
      if (!isCass) {
        criteria.HomeType = ['Single', 'Townhouse', 'Condo'];
      }
      dispatch(
        setCriteriaAction({
          criteria,
        }),
      );
      if (pathname !== routes.searchResults) {
        history.push(
          routes.searchResults + history.location.search + (userId ? `?userId=${userId}` : ''),
        );
      } else if (isAgent) {
        getAgentSearchInstance({
          criterias: criteria,
        });
      } else {
        getSearchInstanceV3({ criterias: criteria });
      }
    },
  });

  useEffect(() => {
    if (criteria?.Locations && isSearchPage) {
      formik.setFieldValue('Locations', criteria?.Locations);
    }
  }, [criteria, isSearchPage]);

  return (
    <form onSubmit={formik.handleSubmit} className={classNames(styles.formWrapper, formClassname)}>
      <Locations
        shouldFilterStateLevelResults={true}
        searchIcon
        className={styles.locations}
        activeInputClassName={styles.locationsInput}
        activeInputIconClassName={styles.locationsIcon}
        valuesWrapperClassName={classNames(styles.locationsValues, valuesWrapperClassName)}
        valueClassName={classNames(styles.locationsValue, valueClassName)}
        dropdownClassName={styles.locationsDropdown}
        popupClassName={styles.locationsPopup}
        name="Locations"
        onResult={(result, preparedData) => {
          formik.setFieldValue('Locations', preparedData);
        }}
        placeholder="Enter a city, address, neighborhood, or ZIP"
        error={formik.touched.Locations ? (formik.errors.Locations as string) : ('' as string)}
        value={formik?.values?.Locations || []}
        testid="search_locations"
        showSuffix={!disabled}
        suffixClassName={styles.suffixIcon}
        variant={Locations.FULL}
        searchWrapperClassName={classNames(searchWrapperClassName, styles.searchWrapper)}
        placeholderClassName={styles.placeholder}
        allowedSmartyAddress={true}
        loaderClassName={styles.loader}
        disabled={disabled}
      />
    </form>
  );
};
