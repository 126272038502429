import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash-es';

import { priceConverter } from 'helpers';
import { DateRangeFilter } from 'pages/ClarityRevamped/Components/Filters/DateRangeFilter';
import { TableViewFilter } from 'pages/ClarityRevamped/Components/Filters/TableViewFilter';
import { Wrapper as PendingWrapper } from 'components';
import { useEffect, useState } from 'react';
import { ModalHeader } from '../../Components/ModalHeader/ModalHeader';
import { AggregateResultHeader } from '../../Components/ModalHeader';
import { BreakdownMenu } from '../../../../BreakdownMenu';
import TableView from '../../Components/TableView/TableView';
import {
  getAgentTeamStatsSelector,
  getTimePeriodDueFromSelector,
  getTimePeriodDueToSelector,
  getTimePeriodSelector,
} from 'store/selectors/clarity';
import { useFetchVolumeStats } from 'pages/ClarityRevamped/hooks';
import { ClarityLineChart } from 'components/ClarityLineChart';
import { ClarityBarChart } from 'components/ClarityBarChart';
import { LineChart, BarChart } from 'components/Icons';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { hideBreakdownMenu } from '../helper';
import moment from 'moment';

import styles from '../../styles.module.scss';

export const VolumeStats = ({ title, clarityType, color }) => {
  const [isGraphView, setIsGraphView] = useState<boolean>(true);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openCompareModal, setOpenCompareModal] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [toggleGraph, setToggleGraph] = useState(false);
  const [summary, setSummary] = useState<any>({});
  const [data, setData] = useState([]);
  const [breakdownSummary, setBreakdownSummary] = useState<any>([]);
  const [breakdownList, setBreakdownList] = useState<any>([]);
  const [selectedAgent, setSelectedAgent] = useState<any>(null);
  const [teamSummary, setTeamSummary] = useState<any>([]);
  const timePeriod = useSelector(getTimePeriodSelector);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const dueFrom = useSelector(getTimePeriodDueFromSelector);
  const dueTo = useSelector(getTimePeriodDueToSelector);
  const getStats = useFetchVolumeStats();

  useEffect(() => {
    getStats({
      setSummary,
      setData,
      setIsPending,
      summaryType: title,
      aggregateDataFormat: isGraphView ? 'Series' : 'Tabular',
      transactionAgentUserId: selectedAgent?.TransactionAgentUserId,
      transactionAgentContactId: selectedAgent?.TransactionAgentContactId,
    });
  }, [timePeriod, isGraphView, selectedAgent, dueFrom, dueTo]);

  useEffect(() => {
    if (
      'TotalVolume' in summary &&
      teamStats.itemValue === 'Team' &&
      (firstRender || !selectedAgent)
    )
      setTeamSummary(summary);
  }, [summary, timePeriod, dueFrom, dueTo]);

  useEffect(() => {
    if (firstRender && breakdownList?.length) {
      setOpenMenu(true);
      setFirstRender(false);
    } else if (!breakdownList?.length) setOpenMenu(false);
  }, [breakdownList]);

  useEffect(() => {
    if (selectedAgent) {
      getStats({
        setSummary: setTeamSummary,
        setData: () => {},
        setIsPending,
        summaryType: title,
        aggregateDataFormat: isGraphView ? 'Series' : 'Tabular',
      });
    }
    if (hideBreakdownMenu(agentRole, teamStats)) {
      getStats({
        setSummary: setBreakdownSummary,
        setData: () => {},
        setIsPending,
        summaryType: title,
        aggregateDataFormat: 'Series',
        agentSummary: true,
      });
    }
  }, [timePeriod, dueFrom, dueTo]);

  useEffect(() => {
    if (breakdownSummary.length && 'TotalVolume' in teamSummary) {
      let selectedAgentIndex = 0;
      if (selectedAgent) {
        const index = breakdownSummary.findIndex(
          (row) => row.TransactionAgent === selectedAgent.TransactionAgent,
        );
        if (index === -1) {
          setSelectedAgent(null);
          selectedAgentIndex = 0;
        } else {
          selectedAgentIndex = index + 1;
        }
        selectedAgentIndex = index === -1 ? 0 : index + 1;
      }

      const newList = cloneDeep([
        {
          TransactionAgent: 'Team',
          Total: teamSummary?.TotalVolume || 0,
          NumberTransactions: teamSummary?.NumberTransactions || 0,
          Percentage: 100,
        },
        ...breakdownSummary,
      ]);

      const selected = newList[selectedAgentIndex];
      newList[selectedAgentIndex] = { ...selected, selected: true };
      setBreakdownList(newList);
    } else {
      setBreakdownList([]);
    }
  }, [breakdownSummary, teamSummary]);

  const handleClick = (agent, index) => {
    let newList;
    if (agent?.TransactionAgent === 'Team') {
      setSelectedAgent(null);
      newList = cloneDeep([
        {
          TransactionAgent: 'Team',
          Total: teamSummary?.TotalVolume || 0,
          NumberTransactions: teamSummary?.NumberTransactions || 0,
          Percentage: 100,
          selected: true,
        },
        ...breakdownSummary,
      ]);
    } else {
      setSelectedAgent(agent);
      newList = cloneDeep([
        {
          TransactionAgent: 'Team',
          Total: teamSummary?.TotalVolume || 0,
          NumberTransactions: teamSummary?.NumberTransactions || 0,
          Percentage: 100,
        },
        ...breakdownSummary,
      ]);
      newList[index] = { ...agent, selected: true };
    }
    setBreakdownList(newList);
  };

  const getGraph = () => {
    return toggleGraph || timePeriod === 'MTD' || timePeriod === 'WEEK' ? (
      <ClarityBarChart
        data={data}
        timePeriod={dueFrom || dueTo ? 'MTD' : timePeriod}
        colors={[color]}
        title={title}
        selectedAgent={selectedAgent}
        clarityType={clarityType}
        summaryType={title}
        open={openMenu}
      />
    ) : (
      <ClarityLineChart
        data={data}
        timePeriod={dueFrom || dueTo ? 'MTD' : timePeriod}
        colors={[color]}
        title={title}
        selectedAgent={selectedAgent}
        clarityType={clarityType}
        summaryType={title}
      />
    );
  };

  return (
    <PendingWrapper isPending={false} className={styles.clarityContentWrapper}>
      <div className={styles.headerRows}>
        <ModalHeader title={title} />
        <div className={styles.infoRow}>
          <AggregateResultHeader
            totalAmount={priceConverter(summary?.TotalVolume || 0)}
            totalRequests={summary?.NumberTransactions}
            textColor={{ color: color }}
          />
          <div className={styles.filtersContainer}>
            <DateRangeFilter
              defaultOption={timePeriod}
              wrapperClassname={styles.dateRangeFilterWrapper}
            />
            {hideBreakdownMenu(agentRole, teamStats) ? (
              <div
                className={classnames(styles.breakdownBtn, { [styles.openMenu]: openMenu })}
                onClick={() => setOpenMenu(!openMenu)}
              >
                Breakdown
              </div>
            ) : (
              <></>
            )}
            <TableViewFilter
              setIsGraphView={setIsGraphView}
              openCompareModal={() => setOpenCompareModal(true)}
              setIsPending={setIsPending}
            />
          </div>
        </div>
      </div>
      <div className={styles.bodyRow}>
        <PendingWrapper
          isPending={isPending}
          className={classnames(
            styles.graphContainer,
            { [styles.openMenu]: isGraphView && openMenu },
            { [styles.tableContainer]: !isGraphView && openMenu },
          )}
        >
          {isGraphView ? (
            <div className={styles.graphContent}>
              {(timePeriod === 'YTD' ||
                timePeriod === 'QTD' ||
                timePeriod === moment().subtract(1, 'year').year() ||
                dueFrom ||
                dueTo) && (
                <div className={styles.graphToggleContainer}>
                  <div
                    className={styles.grpahToggleBtn}
                    onClick={() => setToggleGraph(!toggleGraph)}
                  >
                    {toggleGraph ? <LineChart /> : <BarChart />}
                  </div>
                </div>
              )}
              {getGraph()}
            </div>
          ) : (
            <TableView
              open={openMenu}
              data={data}
              title={title}
              selectedAgent={selectedAgent}
              clarityType={clarityType}
              summaryType={title}
            />
          )}
        </PendingWrapper>
        {hideBreakdownMenu(agentRole, teamStats) ? (
          <div className={classnames(styles.breakdownMenu, { [styles.openMenu]: openMenu })}>
            <BreakdownMenu
              open={openMenu}
              list={breakdownList}
              handleClick={handleClick}
              color={color}
              onClose={() => setOpenMenu(false)}
              title={title}
              selectedAgent={selectedAgent}
              clarityType={clarityType}
              summaryType={title}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </PendingWrapper>
  );
};
