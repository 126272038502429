import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Checkbox, Col, Popover, Row, Tooltip } from 'components-antd';
import { ArrowDown, ArrowUp } from 'components/Icons';
import { Card } from '../../../../Main/Pulse/components/Card';
import { Wrapper as PendingWrapper } from 'components';
import {
  setExpandChartAction,
  setSelectedGraphAction,
  setShowSubChartsAction,
} from 'store/actions/clarity';
import { priceConverter } from 'helpers';
import { getTimePeriodSelector } from 'store/selectors/clarity';
import { ClarityAreaGraph } from 'components/ClarityAreaGraph';

import styles from './styles.module.scss';

type AreaGraphTemplateProps = {
  title: string;
  color: string;
  hoverColor: string;
  options?: string[];
  defaultOption?: string;
  showAmount?: boolean;
  showRequests?: boolean;
  summary: any;
  data: any[];
  isPending: boolean;
  isExpandableChart: boolean;
  showAverage?: boolean;
};

export const AreaGraphTemplate: React.FC<AreaGraphTemplateProps> = ({
  title,
  color,
  hoverColor,
  options = [],
  defaultOption = '',
  showAmount = true,
  showRequests = true,
  summary,
  data,
  isPending,
  isExpandableChart = false,
  showAverage = false,
}) => {
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [filter, setFilter] = useState(defaultOption);

  const textColor = { color: color };

  const handleChange = (checkedValues) => {
    setOpenDropdown(false);
    const currentValue = checkedValues.target.value;
    setFilter(currentValue);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpenDropdown(newOpen);
  };

  const popoverContent = () => (
    <ul className={styles.filterOptions}>
      {options.map((value, index) => {
        return (
          <li>
            <Checkbox
              className={styles.sortCheckbox}
              key={index}
              onChange={handleChange}
              checked={value == filter}
              value={value}
            >
              <div className={styles.checkboxHeading}>{value}</div>
            </Checkbox>
          </li>
        );
      })}
    </ul>
  );

  const handleGraphClick = () => {
    if (showAverage && !!summary?.Averge) return;
    if (!(summary?.Total || summary?.TotalVolume)) return;
    if (isExpandableChart) {
      dispatch(setExpandChartAction({ value: title }));
      dispatch(setShowSubChartsAction({ value: true }));
    } else {
      dispatch(
        setSelectedGraphAction({ isGraphSelected: true, graphName: title, graphColor: color }),
      );
      dispatch(setShowSubChartsAction({ value: false }));
    }
  };

  const renderHoverColor = (e) => {
    e.target.style.color = hoverColor;
  };

  const renderRegularColor = (e) => {
    e.target.style.color = textColor.color;
  };

  const renderAggregateStats = () => {
    switch (true) {
      case showAverage:
        return (
          <span
            className={styles.textLarge}
            style={textColor}
            onMouseEnter={renderHoverColor}
            onMouseLeave={renderRegularColor}
          >
            {`${summary?.Average || 0}%`}
            <span className={styles.textSmallAvg}>{'(Avg.)'}</span>
          </span>
        );
      case showAmount && showRequests && !!summary?.NumberTransactions:
        return (
          <span
            className={styles.textLarge}
            style={textColor}
            onMouseEnter={renderHoverColor}
            onMouseLeave={renderRegularColor}
          >
            {priceConverter(summary?.Total || summary?.TotalVolume || 0)}
            <Tooltip
              title={'Number of Transactions'}
              placement="top"
              overlayClassName={styles.toolTip}
            >
              <span className={styles.textSmall}>{`(${summary.NumberTransactions})`}</span>
            </Tooltip>
          </span>
        );
      case showAmount:
        return (
          <span
            className={styles.textLarge}
            style={textColor}
            onMouseEnter={renderHoverColor}
            onMouseLeave={renderRegularColor}
          >
            {'NumberLeadSources' in summary
              ? summary.NumberLeadSources
              : priceConverter(summary?.Total || summary?.TotalVolume || 0)}
          </span>
        );
      case showRequests && !!summary?.NumberTransactions:
        return (
          <span
            className={styles.textLarge}
            style={textColor}
            onMouseEnter={renderHoverColor}
            onMouseLeave={renderRegularColor}
          >
            {summary.NumberTransactions}
          </span>
        );
    }
  };
  return (
    <>
      <Card
        cardTitle={title}
        showHeader={false}
        cardWrapperClassName={styles.cardWrapper}
        cardHeaderClassName={styles.cardHeader}
        cardBodyClassName={styles.cardBody}
        onCardClick={handleGraphClick}
      >
        <PendingWrapper
          className={styles.pendingWrapper}
          spinnerClassname={styles.centerSpinner}
          isPending={isPending}
          loaderClassName={styles.loader}
        >
          <header className={styles.widgetHeader}>
            <div>
              <h3>{title}</h3>
            </div>
            {options.length ? (
              <div>
                <Popover
                  content={popoverContent}
                  trigger="click"
                  open={openDropdown}
                  onOpenChange={handleOpenChange}
                  overlayClassName={styles.filterDropdown}
                  placement="bottomRight"
                  className={styles.dropdown}
                >
                  <span className={styles.switchButton} onClick={(e) => e.preventDefault()}>
                    {filter}
                  </span>
                  {openDropdown ? (
                    <ArrowUp className={styles.icon} />
                  ) : (
                    <ArrowDown className={styles.icon} color={'#747475'} />
                  )}
                </Popover>
              </div>
            ) : (
              <></>
            )}
          </header>

          <div
            className={classNames({
              [styles.graphWrapper]: !!summary?.Total || !!summary?.TotalVolume,
            })}
          >
            <Row className={styles.countContainer}>{renderAggregateStats()}</Row>

            <Row align="middle">
              <Col xs={24} sm={24}>
                <ClarityAreaGraph
                  data={data}
                  colors={[color]}
                  showAverage={showAverage}
                ></ClarityAreaGraph>
              </Col>
            </Row>
          </div>
        </PendingWrapper>
      </Card>
    </>
  );
};
