import moment from 'moment';
import { useRef, useEffect } from 'react';
import { dispatch } from 'store';
import { getClientDashboardAddressesValuationEffect } from 'store/effects/clientDashboard';

export const useClientAddressPoller = ({ addresses, valuationData, duration = 15000 }) => {
  const addressesRef = useRef(addresses);
  const valuationDataRef = useRef(valuationData);

  useEffect(() => {
    addressesRef.current = addresses;
  }, [addresses]);

  useEffect(() => {
    valuationDataRef.current = valuationData;
  }, [valuationData]);

  useEffect(() => {
    const pollForRecentAddresses = () => {
      const allAddressIds = addressesRef.current.map(({ Id }) => Id);

      const recentAddresses = addressesRef.current.filter((address) => {
        const createdDate = address?.CreatedDate;
        const valuation = valuationDataRef.current?.find(
          (valuation) => Number(valuation.addressId) === Number(address.Id),
        );
        const hasNoValuations = !valuation?.addressValuations?.homeValue;
        return createdDate && moment().diff(moment(createdDate), 'minutes') <= 5 && hasNoValuations;
      });

      if (recentAddresses.length > 0) {
        dispatch(
          getClientDashboardAddressesValuationEffect(
            { addressId: allAddressIds },
            { silent: true },
          ),
        );
      }
    };

    const intervalId = setInterval(pollForRecentAddresses, duration);

    return () => {
      return clearInterval(intervalId);
    };
  }, [dispatch]);
};
