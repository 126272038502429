import { TableColumnsType } from 'antd';
import classNames from 'classnames';
import { KitIcon } from 'components/Icons/KitIcon';
import { TaskIcon } from 'components/Icons/TaskIcon';
import moment from 'moment';
import { CancelKitIcon } from 'pages/Radar/RadarKits/components/icons/CancelKitIcon';
import { ResendKitIcon } from 'pages/Radar/RadarKits/components/icons/ResendKitIcon';
import { Icons } from 'pages/Workshop/Icons';
import { Button, Tooltip } from 'components-antd';

import styles from './styles.module.scss';
import tableStyles from '../../components/Table/styles.module.scss';
import { DashIcon, PlusIcon } from '../components';
import parentStyles from '../styles.module.scss';

export type PastDataType = {
  key: string;
  name: {
    title: string;
    info: string;
  };
  completed: {
    on: string;
    by: string;
  };
  isOpened: {
    date: string;
    time: string;
  } | null;
  isClicked: {
    date: string;
    time: string;
  } | null;
};

export type UpcomingDataType = {
  key: string;
  name: {
    title: string;
    info: string;
  };
  assignee: string;
  scheduled: string;
};

export type FailedDataType = {
  key: string;
  name: {
    title: string;
    info: string;
  };
  failed: string;
  error: string;
  id: number;
};

export type OverdueTaskType = {
  name: string;
  assignee: string;
  scheduled: string;
};

export type CancelledDataType = {
  name: string;
  assignee: string;
  canceled: Date | string;
};

export type UseCampaignClientExecInfoProps = {
  onCancel?: (execId) => void;
  onRetry?: (execId) => void;
};

export const useCampaignClientExecInfo = (props?: UseCampaignClientExecInfoProps) => {
  const { onCancel, onRetry } = props || {};

  const pastColumns: TableColumnsType<PastDataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.name.title.localeCompare(b.name.title),
      render: (group: any) => (
        <div className={parentStyles.tableColNameWrapper}>
          <div className={parentStyles.iconWrapper}>
            {group.info === 'KIT' ? <KitIcon /> : <TaskIcon />}
          </div>
          <div className={parentStyles.detailsWrapper}>
            <div className={parentStyles.title}>{group.title}</div>
            <div className={parentStyles.info}>{group.info}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed',
      className: tableStyles.tableCol,
      sorter: (a, b) => moment(a.completed.on).diff(moment(b.completed.on)),
      render: (completed: any) => (
        <div className={parentStyles.tableColNameWrapper}>
          <div className={parentStyles.detailsWrapper}>
            <div className={parentStyles.title}>{completed.on}</div>
            <div className={parentStyles.info}>{completed.by}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Opened',
      dataIndex: 'isOpened',
      key: 'isOpened',
      className: tableStyles.tableCol,
      sorter: (a, b) => (a.isOpened ? 1 : -1),
      render: (isOpened: { date: string; time: string } | null) => (
        <div className={tableStyles.tableNormalCell}>
          {isOpened ? (
            <Tooltip
              placement="top"
              getPopupContainer={(trigger) => trigger}
              overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
              title={
                <p className={styles.descInfo}>
                  <div className={styles.heading1}>Opened:</div>
                  <div className={styles.heading2}>{isOpened.date}</div>
                  <div className={styles.heading3}>{isOpened.time}</div>
                </p>
              }
            >
              <div className={styles.checkmark}>
                <Icons variant={Icons.GREEN_LIGHT_CHECKMARK} />
              </div>
            </Tooltip>
          ) : (
            <div className={styles.icon}>
              <DashIcon />
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Clicked',
      dataIndex: 'isClicked',
      key: 'isClicked',
      className: tableStyles.tableCol,
      sorter: (a, b) => (a.isClicked ? 1 : -1),
      render: (isClicked: { date: string; time: string } | null) => (
        <div className={tableStyles.tableNormalCell}>
          {isClicked ? (
            <Tooltip
              placement="top"
              getPopupContainer={(trigger) => trigger}
              overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
              title={
                <p className={styles.descInfo}>
                  <div className={styles.heading1}>Clicked:</div>
                  <div className={styles.heading2}>{isClicked.date}</div>
                  <div className={styles.heading3}>{isClicked.time}</div>
                </p>
              }
            >
              <div className={styles.checkmark}>
                <Icons variant={Icons.GREEN_LIGHT_CHECKMARK} />
              </div>
            </Tooltip>
          ) : (
            <div className={styles.icon}>
              <DashIcon />
            </div>
          )}
        </div>
      ),
    },
  ];

  const upcomingColumns: TableColumnsType<UpcomingDataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.name.title.localeCompare(b.name.title),
      render: (group: any) => (
        <div className={parentStyles.tableColNameWrapper}>
          <div className={parentStyles.iconWrapper}>
            {group.info === 'KIT' ? <KitIcon /> : <TaskIcon />}
          </div>
          <div className={parentStyles.detailsWrapper}>
            <div className={parentStyles.title}>{group.title}</div>
            <div className={parentStyles.info}>{group.info}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      key: 'assignee',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.assignee.localeCompare(b.assignee),
    },
    {
      title: 'Scheduled',
      dataIndex: 'scheduled',
      key: 'scheduled',
      className: tableStyles.tableCol,
      sorter: (a, b) => moment(a.scheduled).diff(moment(b.scheduled)),
    },
  ];

  const failedColumns: TableColumnsType<FailedDataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.name.title.localeCompare(b.name.title),
      render: (group: any) => (
        <div className={parentStyles.tableColNameWrapper}>
          <div className={parentStyles.iconWrapper}>
            {group.info === 'KIT' ? <KitIcon /> : <TaskIcon />}
          </div>
          <div className={parentStyles.detailsWrapper}>
            <div className={parentStyles.title}>{group.title}</div>
            <div className={parentStyles.info}>{group.info}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Failed',
      dataIndex: 'failed',
      key: 'failed',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.failed.localeCompare(b.failed),
    },
    {
      title: 'Error',
      dataIndex: 'error',
      key: 'error',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.error.localeCompare(b.error),
      render: (error: string) => (
        <div className={tableStyles.tableNormalCell}>
          <div className={styles.errorTableText}>{error}</div>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'isRetryAble',
      key: 'isRetryAble',
      className: tableStyles.tableCol,
      render: (isRetryAble: boolean, record) => (
        <div className={tableStyles.tableNormalCell}>
          <div className={styles.retryCancelWrapper}>
            <Tooltip
              placement="top"
              getPopupContainer={(trigger) => trigger}
              overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
              title={<p className={styles.descInfo}>Cancel</p>}
            >
              <Button
                variant="default"
                className={styles.iconButton}
                onClick={() => onCancel?.(record.id)}
                icon={<CancelKitIcon />}
              />
            </Tooltip>

            <Tooltip
              placement="top"
              getPopupContainer={(trigger) => trigger}
              overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
              title={
                <p className={styles.descInfo}>
                  {!isRetryAble
                    ? 'This KIT cannot be resent until the error is addressed.'
                    : 'Send Again'}
                </p>
              }
            >
              <Button
                variant="default"
                disabled={!isRetryAble}
                className={styles.iconButton}
                onClick={() => onRetry?.(record.id)}
                icon={<ResendKitIcon />}
              />
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  const overdueColumns: TableColumnsType<OverdueTaskType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name: any) => (
        <div className={parentStyles.tableColNameWrapper}>
          <div className={parentStyles.iconWrapper}>
            <TaskIcon />
          </div>
          <div className={parentStyles.detailsWrapper}>
            <div className={parentStyles.title}>{name}</div>
            <div className={parentStyles.info}>{'TASK'}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      key: 'assignee',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.assignee.localeCompare(b.assignee),
    },
    {
      title: 'Scheduled',
      dataIndex: 'scheduled',
      key: 'scheduled',
      className: tableStyles.tableCol,
      sorter: (a, b) => moment(a.scheduled).diff(moment(b.scheduled)),
    },
  ];

  const canceledColumns: TableColumnsType<CancelledDataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name) => (
        <div className={parentStyles.tableColNameWrapper}>
          <div className={parentStyles.iconWrapper}>
            <KitIcon />
          </div>
          <div className={parentStyles.detailsWrapper}>
            <div className={parentStyles.title}>{name}</div>
            <div className={parentStyles.info}>{'KIT'}</div>
          </div>
        </div>
      ),
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      key: 'assignee',
      className: tableStyles.tableCol,
      sorter: (a, b) => a.assignee.localeCompare(b.assignee),
    },
    {
      title: 'Canceled',
      dataIndex: 'canceled',
      key: 'canceled',
      className: tableStyles.tableCol,
      sorter: (a, b) => moment(a.canceled).diff(moment(b.canceled)),
    },
  ];

  return {
    pastColumns,
    upcomingColumns,
    failedColumns,
    overdueColumns,
    canceledColumns,
  };
};
