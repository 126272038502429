import { Role } from 'app-constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { requestGetTeamListEffect } from 'store/effects';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { getUserRoleSelector, getUserSelector } from 'store/selectors/user';

export const useFetchTeamList = () => {
  const dispatch = useDispatch();
  const userRole = useSelector(getUserRoleSelector);
  const [loading, setLoading] = useState(true);

  const [teamMembers, setTeamMembers] = useState<
    { name: string; value: string; Email: string; Id: number }[]
  >([]);

  const [teamMembersPayload, setTeamMembersPayload] = useState<any[]>([]);

  const agentRole = useSelector(getAgentTeamRoleSelector);

  const isMember = ![TEAM_ADMIN, TEAM_OWNER].includes(agentRole);
  const loggedInUser = useSelector(getUserSelector);
  const userEmail = loggedInUser?.data?.Email;

  const fetchTeamList = async () => {
    dispatch(
      requestGetTeamListEffect({ Status: 'Accepted' }, {}, (err, resp) => {
        if (!err && resp.data?.length) {
          const activeMembers = resp.data.filter(({ IsActive, TeamRole }) => {
            return IsActive && (isMember ? [TEAM_ADMIN, TEAM_OWNER].includes(TeamRole) : true);
          });

          const members = activeMembers.map((member) => {
            return {
              Name: member.FirstName + (member.LastName ? ' ' + member.LastName : ''),
              Id: member.Id,
              TagId: member.Id,
            };
          });

          if (isMember && activeMembers.length) {
            const userEmail = loggedInUser?.data?.Email;

            const loggedInMember = resp.data.find(
              ({ Email, IsActive }) => IsActive && Email === userEmail,
            );

            loggedInMember &&
              members.push({
                Name:
                  loggedInMember.FirstName +
                  (loggedInMember.LastName ? ' ' + loggedInMember.LastName : ''),
                Id: loggedInMember.Id,
                TagId: loggedInMember.Id,
              });
          }

          setTeamMembersPayload(resp.data ?? []);
          setTeamMembers(members);
        }
        setLoading(false);
      }),
    );
  };

  useEffect(() => {
    userRole !== Role.SuperUser && fetchTeamList();
  }, [userRole]);

  return { loading, teamMembers, teamMembersPayload };
};
