import { useRef, useState, useEffect } from 'react';
import { Button } from 'components-antd';

import styles from './styles.module.scss';

export const TruncatedContent = ({ content, maxLines = 3 }) => {
  const paragraphRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);

  useEffect(() => {
    if (paragraphRef.current) {
      // Check if content overflows
      const { scrollHeight, clientHeight } = paragraphRef.current;
      setIsTruncated(scrollHeight > clientHeight);
    }
  }, [content]);

  return (
    <div className={styles.truncatedContentContainer}>
      <p
        ref={paragraphRef}
        className={styles.content}
        style={{
          WebkitLineClamp: showFullContent ? 'none' : maxLines,
        }}
      >
        {content}
      </p>
      {isTruncated && (
        <Button
          variant="link"
          className={styles.expandBtn}
          onClick={() => setShowFullContent(!showFullContent)}
        >
          {showFullContent ? '- Hide' : '+ Show More'}
        </Button>
      )}
    </div>
  );
};
