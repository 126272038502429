import { createAction } from 'redux-actions';

export const requestGetSavedSearchesPropertiesAction = createAction(
  'REQUEST/GET_SAVED_SEARCHES_PROPERTIES_V3',
);
export const requestGetSavedSearchesPropertiesDrawAction = createAction(
  'REQUEST/GET_SAVED_SEARCHES_PROPERTIES_DRAW_V3',
);

export const requestGetFavoritesPropertiesAction = createAction(
  'REQUEST/GET_FAVORITES_PROPERTIES_V3',
);
export const sortFeedAction = createAction('FEED/SORT_V3');

export const selectedSearchAction = createAction('FEED/META_SELECTED_SEARCH_V3');

export const setIsSimpleHeaderTitleVisibleAction = createAction('FEED/META_SIMPLE_HEADER_V3');

export const setCriteriaModeAction = createAction('FEED/META_MODE_V3');

export const setSavedSearchTabAction = createAction('FEED/META_SAVED_TAB_V3');

export const setAgentClientModeAction = createAction('FEED/META_AGENT_CLIENT_MODE_V3');

export const setSelectedClientSavedSearchAction = createAction(
  'FEED/META_SELECTED_SAVED_SEARCH_V3',
);

export const setIsClientMatchesAction = createAction('FEED/CLIENT_MATCHES');

export const setFeedV3CurrentPageInfoAction = createAction('FEED/SET_CURRENT_PAGE_INFO_V3');

export const setFeedV3PageInfoAction = createAction('FEED/SET_PAGE_INFO_V3');

export const setCriteriaAction = createAction('FEED/CRITERIA_V3');

export const setFilterAction = createAction('FEED/FILTER_V3');

// AGENT SIDE

export const requestGetAgentPropertiesAction = createAction('REQUEST/GET_AGENT_PROPERTIES_V3');
export const requestGetAgentPropertiesDrawAction = createAction(
  'REQUEST/GET_AGENT_PROPERTIES_DRAW_V3',
);
export const markPropertyCommentsAsRead = createAction('FEED/MARK_PROPERTY_COMMENTS_AS_READ');
