import React from 'react';

export const CancelKitIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.1" cx="16" cy="16" r="16" fill="#747475" />
      <path d="M20.5 20.5L11.5 11.5" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
      <path d="M11.5 20.5L20.5 11.5" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
