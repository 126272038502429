import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import Icon from 'pages/Workshop/Transactions/TransactionOverview/Icons';
import { updateTransactionEffect } from 'store/effects/transactions';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';
import { SingleLocation, Input } from 'components';
import { Button } from 'components-antd';
import { isPropertyAddress } from 'pages/Workshop/Transactions/TransactionCreate/helpers';

import { getLocationAddress } from 'helpers/locations';
import styles from './styles.module.scss';
import transactionViewStyles from './../TransactionView/styles.module.scss';

const PropertyTitle = (props) => {
  const { className, setEditId, isDisableField } = props;
  const { transaction, address } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const [isEdit, setIsEdit] = useState(false);
  const [updatedAddress, setUpdatedAddress] = useState({});
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();
  const editKey = 'propertyTitle';

  useEffect(() => {
    if (transaction?.Id) setUpdatedAddress(transaction?.Property?.Address);
  }, [transaction]); // eslint-disable-line

  const onEdit = () => {
    if (!fullAccess || isDisableField()) return null;
    setIsEdit(true);
    setEditId(editKey);
  };

  const isTheSameAddress = (
    initialAddress = transaction?.Property?.Address,
    currentAddress = updatedAddress,
  ) =>
    initialAddress?.City === currentAddress?.City &&
    initialAddress?.State === currentAddress?.State &&
    initialAddress?.Line1 === currentAddress?.Line1 &&
    initialAddress?.Line2 === currentAddress?.Line2 &&
    initialAddress?.PlaceName === currentAddress?.PlaceName &&
    initialAddress?.ProviderPlaceId === currentAddress?.ProviderPlaceId;

  const onSuiteUnitChange = (e, val) => {
    setUpdatedAddress({ ...(updatedAddress || {}), Line2: val || undefined });
  };

  const updateTransaction = () => {
    setIsPending(true);
    dispatch(
      updateTransactionEffect(
        {
          id: transaction?.Id,
          address: updatedAddress,
        },
        {},
        (err) => {
          if (!err) {
            setIsEdit(false);
            setEditId(null);
          }
          setIsPending(false);
        },
      ),
    );
  };

  const onCancel = () => {
    setIsEdit(false);
    setEditId(null);
  };

  return (
    <div
      className={classNames(
        styles.propertyTitle,
        { [transactionViewStyles.disableField]: !!isDisableField(editKey) },
        className,
      )}
    >
      {!isEdit ? (
        <>
          <div
            onClick={onEdit}
            className={classNames(styles.title, { [styles.fullAccess]: fullAccess })}
          >
            <span testid="address_1" className={styles.address}>{`${address?.Line1} ${
              address?.Line2 ? address?.Line2 : ''
            }`}</span>
            {fullAccess && !isDisableField(editKey) && (
              <Icon testid="edit_icon" className={styles.editIcon} variant={Icon.EDIT} />
            )}
          </div>
          <div testid="address_2" className={styles.subtitle}>{`${address?.City}, ${
            address?.State
          } ${address?.Zip || address?.Zipcode}`}</div>
        </>
      ) : (
        <div className={transactionViewStyles.editWrapper}>
          <SingleLocation
            inputLabel={'Address'}
            variant={Input.LIGHT_ROUND}
            searchIcon={false}
            className={styles.field}
            placeholder="Enter property address..."
            types={['address']}
            onResult={(result) => setUpdatedAddress(getLocationAddress(result))}
            testid="address"
            value={updatedAddress}
            onClear={() => setUpdatedAddress({})}
            disabled={isPending}
          />
          <Input
            label="Suite/Unit"
            className={styles.field}
            placeholder="Enter Suite or Unit"
            variant="lightRound"
            value={updatedAddress?.Line2}
            onChange={onSuiteUnitChange}
            disabled={isPending}
            testid="suite_unit_input"
          />
          <div className={transactionViewStyles.buttons}>
            <Button
              variant="secondary"
              size="large"
              disabled={isTheSameAddress() || !isPropertyAddress(updatedAddress)}
              onClick={updateTransaction}
              loading={isPending}
            >
              Update
            </Button>
            <Button variant="hollow-bordered" size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

PropertyTitle.propTypes = {
  className: PropTypes.string,
};

PropertyTitle.defaultProps = {
  className: '',
};

export default PropertyTitle;
