import { createAction } from 'redux-actions';

export const setTeamStatsAction = createAction('REQUEST/SET_TEAM_STATS');
export const setSelectedGraphAction = createAction('REQUEST/SET_SELECTED_GRAPH');
export const setTimePeriodAction = createAction('REQUEST/SET_TIME_PERIOD');
export const setExpandChartAction = createAction('REQUEST/SET_EXPAND_CHART');
export const setShowSubChartsAction = createAction('REQUEST/SET_SHOW_SUBCHARTS');
export const setTransactionFilterAction = createAction('REQUEST/SET_TRANSACTION_FILTER');
export const setTimePeriodDueFromAction = createAction('REQUEST/SET_TIME_PERIOD_DUE_FROM');
export const setTimePeriodDueToAction = createAction('REQUEST/SET_TIME_PERIOD_DUE_TO');
