export const A4_PAGE_WIDTH = 595;
export const A4_PAGE_HEIGHT = 842;

export const getImageDimensions = async (
  src,
): Promise<{ naturalWidth: number; naturalHeight: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      resolve({ naturalWidth: img.naturalWidth, naturalHeight: img.naturalHeight });
    };

    img.onerror = (error) => {
      reject(new Error(`Failed to load image at ${src}`));
    };
  });
};
