import React, { useCallback, useEffect, useState } from 'react';
import { Header, ConfirmationModal } from './components';
import { Spinner } from 'components';
import { InboundOutBoundContainer } from './components/InboundOutboundContainer/InboundOutBoundContainer';
import {
  getOfferDetailsForExternalUser,
  getOfferPortalExternalVerificationData,
  sendOfferPortalExternalVerificationLink,
  setOfferIterationViewedStatusForExternalUser,
  updateExternalOfferIterationStatus,
} from 'api/offers';
import { OfferStatus } from 'types/offers';
import { SendCounterOfferModal } from 'pages/Workshop/Transactions/TransactionOffers/SendCounterOfferModal';

import styles from './styles.module.scss';
import { EditModal } from './components';
import { ExternalVerificationWallModal } from 'features/externalVerification/ExternalVerificationWallModal/ExternalVerificationWallModal';
import { useExternalVerificationRestriction } from 'features/externalVerification/useExtermalVerificationRestriction';
import { getTransactionPrice } from 'utils/keyDateHelpers';

export const OfferPortal = (props) => {
  const { uuid: offerPortalUuid } = props.match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [offer, setOfferIteration] = useState<any>({});
  const [sellerAgent, setSellerAgent] = useState();
  const [sellerAgentName, setSellerAgentName] = useState('');
  const [sellerAgentPhone, setSellerAgentPhone] = useState('');
  const [sellerAgentEmail, setSellerAgentEmail] = useState('');
  const [buyingAgent, setBuyingAgent] = useState('');
  const [UUID, setLinkUUID] = useState();
  const [linkStatus, setLinkStatus] = useState('');
  const [transactionData, setTransactionData] = useState({
    transactionAddress: '',
    transactionPrice: '',
    transactionLineOne: '',
    transactionCity: '',
  });
  const [offerDeadline, setOfferDeadline] = useState('');
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [status, setIterationStatus] = useState('');
  const [actionMessage, setActionMessage] = useState({
    confirmText: '',
    okText: '',
  });
  const [showEditModal, setEditModalVisibility] = useState(false);
  const [counterOfferModal, setCounterOfferModal] = useState(false);
  const [offerLinkDetails, setOfferLinkDetails] = useState({});
  const onCounterClose = () => {
    setCounterOfferModal(false);
    fetchOfferIterationDetails(offerPortalUuid);
  };
  const onEditModalClose = () => {
    setEditModalVisibility(false);
    fetchOfferIterationDetails(offerPortalUuid);
  };
  const [reason, setReason] = useState('');

  const { token: externalToken, isTokenLoaded: isExternalTokenLoaded } =
    useExternalVerificationRestriction();
  const [isVerificationModalVisible, setVerificationModalVisible] = useState(false);

  const fetchOfferIterationDetails = useCallback(
    async (offerPortalUuid) => {
      setIsLoading(true);
      let data: any;
      try {
        const { data: responseData } = await getOfferDetailsForExternalUser(offerPortalUuid, {
          externalToken,
        });
        data = { ...responseData };
        if (data?.value?.Offer[0]) {
          data?.value?.Offer[0].OfferIteration.sort(
            (first, second) => parseInt(second.Id) - parseInt(first.Id),
          );
        }
      } catch (err: any) {
        setIsLoading(false);
        if (err.response.data.code === 'EXTERNAL_AUTH_REQUIRED') {
          setVerificationModalVisible(true);
        } else {
          throw err;
        }
      }
      if (data?.value?.Offer[0]) {
        data?.value?.Offer[0].OfferIteration.forEach((iteration, index) => {
          if (iteration.CounterParentId) {
            if (iteration.PerformedBy && iteration.PerformedBy.Email) {
              iteration.status = OfferStatus.Inbound;
            } else if (iteration?.PerformedBy && !iteration?.PerformedBy?.Email) {
              iteration.status = OfferStatus.Outbound;
            }
          } else {
            iteration.status = OfferStatus.Initial;
          }
          index === 0
            ? ((iteration.collapsed = true), checkIfIterationReceived(iteration))
            : (iteration.collapsed = false);
        });
        setLinkStatus(data?.value?.Status);
        setOfferIteration(data?.value?.Offer[0]);
        setSellerAgent(data?.value?.SellerAgent);
        setSellerAgentName(
          data?.value?.ListingAgentName
            ? data?.value?.ListingAgentName
            : data?.value?.SellerAgent.FirstName + ' ' + data?.value?.SellerAgent.LastName,
        );
        setSellerAgentPhone(data?.value?.SellerAgent?.Phones?.[0]?.PhoneNumber);
        setSellerAgentEmail(
          data?.value?.ListingAgentEmail
            ? data?.value?.ListingAgentEmail
            : data?.value?.SellerAgent.Email,
        );
        setBuyingAgent(data?.value?.Offer[0]?.BuyingAgentName);
        setLinkUUID(data?.value?.UUID);
        setOfferLinkDetails(data?.value);
        const keyDatesPrices = data?.value?.PropertyTransaction?.KDP;

        const transactionPrice = getTransactionPrice(
          keyDatesPrices,
          data?.value?.PropertyTransaction?.Status,
        );

        setTransactionData({
          ...transactionData,
          transactionAddress: data?.value?.PropertyTransaction?.Property?.Address?.PlaceName,
          transactionLineOne: data?.value?.PropertyTransaction?.Property?.Address?.Line1,
          transactionCity: data?.value?.PropertyTransaction?.Property?.Address?.City,
          transactionPrice,
        });
        setOfferDeadline(data?.value?.DeadlineDate);
      }
      setIsLoading(false);
    },
    [offer, externalToken],
  );

  const checkIfIterationReceived = async (iteration) => {
    if (iteration.status === OfferStatus.Inbound && !iteration.IsViewed) {
      const { data } = await setOfferIterationViewedStatusForExternalUser(iteration.UUID, {
        externalToken,
      });
      iteration.IsViewed = true;
      iteration.ViewedOn = new Date();
    }
  };

  useEffect(() => {
    if (!isExternalTokenLoaded) {
      return;
    }

    if (isExternalTokenLoaded === true && !externalToken) {
      setVerificationModalVisible(true);
      return;
    }

    if (offerPortalUuid) {
      fetchOfferIterationDetails(offerPortalUuid);
    }
  }, [isExternalTokenLoaded, externalToken]);

  const updateOfferIterationStatus = useCallback(
    async (payload) => {
      setIsLoading(true);
      const { data } = await updateExternalOfferIterationStatus(offerPortalUuid, payload, {
        externalToken,
      });
      if (data.errorMessage === 'success') {
        fetchOfferIterationDetails(offerPortalUuid);
      }
      setIsLoading(false);
    },
    [fetchOfferIterationDetails],
  );
  const handleActionClick = (action) => {
    if (action === 'accept') {
      setActionMessage({
        ...actionMessage,
        confirmText: 'Are you sure you want to accept this offer?',
        okText: 'Accept',
      });
      setIterationStatus(OfferStatus.Accepted);
      setConfirmationModal(true);
    } else if (action === 'reject') {
      setActionMessage({
        ...actionMessage,
        confirmText: 'Are you sure you want to reject this offer?',
        okText: 'Reject',
      });
      setIterationStatus(OfferStatus.Rejected);
      setConfirmationModal(true);
    } else if (action === 'withdraw') {
      setActionMessage({
        ...actionMessage,
        confirmText: 'Are you sure you want to withdraw this offer?',
        okText: 'Withdraw',
      });
      setIterationStatus(OfferStatus.Withdrawn);
      setConfirmationModal(true);
    } else if (action === 'counter') {
      setCounterOfferModal(true);
    } else if (action === 'edit') {
      setEditModalVisibility(true);
    }
  };

  const getHeader = () => {
    return (
      <Header
        buyingAgent={buyingAgent}
        sellerAgentName={sellerAgentName}
        address={transactionData?.transactionLineOne}
        transactionAddress={transactionData?.transactionAddress}
        price={transactionData?.transactionPrice}
        deadline={offerDeadline}
        sellerAgentPhone={sellerAgentPhone}
        sellerAgentEmail={sellerAgentEmail}
      />
    );
  };

  const getUpdatedIterationData = () => {
    setEditModalVisibility(false);
    fetchOfferIterationDetails(offerPortalUuid);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headWrapper}>{getHeader()}</div>
      {!isLoading && offer && !isVerificationModalVisible ? (
        <div className={styles.inboundOutboundContainer}>
          <InboundOutBoundContainer
            offer={offer}
            LinkUUID={UUID}
            handleActionClick={handleActionClick}
            linkStatus={linkStatus}
          />
        </div>
      ) : (
        <Spinner />
      )}
      {confirmationModal && (
        <ConfirmationModal
          showConfirmationModal={confirmationModal}
          confirmText={<span>{actionMessage?.confirmText}</span>}
          setReason={setReason}
          updateOfferIterationStatus={updateOfferIterationStatus}
          iterationId={offer?.OfferIteration[0]?.UUID}
          status={status}
          reason={reason}
          okText={actionMessage?.okText}
          setConfirmationModal={setConfirmationModal}
        />
      )}

      {offer?.OfferIteration && (
        <SendCounterOfferModal
          isOpen={counterOfferModal}
          onClose={onCounterClose}
          offerIterationData={offer?.OfferIteration[0]}
          offerLinkDetails={offerLinkDetails}
          BuyingAgentName={offer?.BuyingAgentName}
          OfferCount={offer?.OfferCount}
          isExternal={true}
          uuid={offerPortalUuid}
        />
      )}

      {offer?.OfferIteration && (
        <EditModal
          isOpen={showEditModal}
          setEditModalVisibility={setEditModalVisibility}
          getUpdatedIterationData={getUpdatedIterationData}
          offerData={offer}
          offerLinkDetails={offerLinkDetails}
          seller={sellerAgent}
          data={offer}
          transaction={transactionData}
          isExternal={true}
        />
      )}

      <ExternalVerificationWallModal
        isModalOpen={isVerificationModalVisible}
        sendVerificationLink={async () => {
          const response = await sendOfferPortalExternalVerificationLink({
            uuid: offerPortalUuid,
          });

          return response.data.value;
        }}
        getExternalVerificationInfo={async () => {
          const response = await getOfferPortalExternalVerificationData({
            uuid: offerPortalUuid,
          });
          return response.data.value;
        }}
      />
    </div>
  );
};
