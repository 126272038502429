import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { Footer } from 'components-antd';
import { showErrorMessage } from 'helpers';
import { LocalHeader } from './LocalHeader';
import { PageWrapper } from 'pages/Workshop/PageWrapper';
import { Wrapper } from 'components';
import Task from '../Transactions/TransactionTasks/components/Task';
import {
  deleteMultipleTasksEffect,
  getTaskAggregateEffect,
  resetTaskAggregateEffect,
  updateMultipleTasksEffect,
} from 'store/effects/taskAggregate';
import { TaskSelectionFooter } from '../Transactions/components/TaskSelectionFooter';
import { TasksList } from '../Transactions/TransactionTasks/components/Tasks/components';
import {
  MultiTaskUpdateModal,
  UpdatedTaskEntities,
} from '../Transactions/components/MultiTaskUpdateModal';
import { LocationService } from 'services';
import { taskFilters } from 'settings/constants/transactionTasks';
import {
  setTaskFiltersAppliedEffect,
  setTaskFiltersEffect,
  setTransactionsTasksFilterEffect,
} from 'store/effects';
import { getTransactionAccessByTaskSelector } from 'store/selectors/transaction';
import {
  getFilteredTaskAggregateSelector,
  getTaskAggregateList,
} from 'store/selectors/taskAggregate';
import { keyBy } from 'lodash';
import { tasksStatusesIds } from 'settings/constants/transactionTasks';

export const Tasks = () => {
  const dispatch = useDispatch();

  const [showSelection, setShowSelection] = useState<boolean>(false);
  const [selectedTaskIds, setSelectedTaskIds] = useState<number[]>([]);
  const [showTaskUpdateModal, setShowTaskUpdateModal] = useState<boolean>(false);
  const { hasFullAccess } = useSelector(getTransactionAccessByTaskSelector);
  const { tasks } = useSelector(getFilteredTaskAggregateSelector);
  const { isPending } = useSelector(getTaskAggregateList);
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  const fetchAggregateTasks = (ids?: number[], filters = {}) =>
    dispatch(
      getTaskAggregateEffect({
        ids,
        filters,
      }),
    );

  const fetchAggregateTasksWithIds = () => {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    const ids = queryParams.get('ids');

    const taskNumbersArray: number[] = (ids || '')
      .split(',')
      .map(Number)
      .filter(Boolean)
      .filter((id) => !isNaN(id));

    if (taskNumbersArray?.length) {
      fetchAggregateTasks(taskNumbersArray);
    }
  };

  useEffect(() => {
    fetchAggregateTasksWithIds();
    return () => {
      dispatch(resetTaskAggregateEffect());
    };
  }, []);

  useEffect(() => {
    setSelectedTaskIds([]);
  }, [showSelection]);

  useEffect(() => {
    let filters = {};
    if ('teamStats' in query) {
      if (!query.teamStats) {
        dispatch(setTransactionsTasksFilterEffect(taskFilters.myTasks));
        filters = { assignee: 'me' };
      }

      if ('status' in query || 'dueToday' in query) {
        filters = {
          ...filters,
          ...('status' in query && { statuses: [query.status] }),
          ...('dueToday' in query && {
            dueFrom: moment().format('YYYY-MM-DD'),
            dueTo: moment().format('YYYY-MM-DD'),
            statuses: [
              tasksStatusesIds.new,
              tasksStatusesIds.inProgress,
              tasksStatusesIds.overdue,
              tasksStatusesIds.stuck,
            ],
          }),
        };
        dispatch(setTaskFiltersEffect(filters));
        dispatch(setTaskFiltersAppliedEffect(true));
      }
      dispatch(
        getTaskAggregateEffect({
          filters,
        }),
      );
    }
  }, []);

  const handleSelectTask = (taskId: number | number[], checked: boolean) => {
    let selectedTasks = [...selectedTaskIds];
    const isArray = Array.isArray(taskId);
    if (checked) {
      isArray ? selectedTasks.push(...taskId) : selectedTasks.push(taskId);
    } else {
      if (!isArray) {
        const deselectedTaskIndex = selectedTasks.indexOf(taskId);
        selectedTasks.splice(deselectedTaskIndex, 1);
      } else {
        selectedTasks = selectedTasks.filter((id) => !taskId.includes(id));
      }
    }

    setSelectedTaskIds(selectedTasks);
  };

  const handleContinueTaskUpdates = () => {
    setShowTaskUpdateModal(true);
  };

  const handleUpdateTasks = async (
    updatedTaskEntities: UpdatedTaskEntities,
    setLoading: (loading: boolean) => void,
  ) => {
    setLoading(true);
    const tasks = selectedTaskIds?.map((taskId) => ({ Id: taskId, ...updatedTaskEntities }));
    dispatch(
      updateMultipleTasksEffect({ tasks }, (err) => {
        setLoading(false);
        if (err) {
          showErrorMessage(err);
        }
        fetchAggregateTasksWithIds();
        setShowTaskUpdateModal(false);
        setShowSelection(false);
      }),
    );
  };

  const handleDeleteTasks = async (
    setLoading: (loading: boolean) => void,
    setShowModal: (open: boolean) => void,
  ) => {
    setLoading(true);
    dispatch(
      deleteMultipleTasksEffect(selectedTaskIds, (err) => {
        setLoading(false);
        if (err) {
          showErrorMessage(err);
        } else {
          setShowModal(false);
          setShowTaskUpdateModal(false);
          setShowSelection(false);
        }
      }),
    );
  };

  const tasksById = useMemo(() => keyBy(tasks || [], 'Id'), [tasks]);
  const fullAccess = useMemo(() => {
    return selectedTaskIds?.every((taskId) => {
      const task = tasksById[taskId];
      return task && hasFullAccess(task?.Transaction?.Id);
    });
  }, [tasksById, selectedTaskIds]);

  return (
    <PageWrapper>
      <MultiTaskUpdateModal
        fullAccess={fullAccess}
        showTaskUpdateModal={showTaskUpdateModal}
        isAggregatePage
        setShowTaskUpdateModal={setShowTaskUpdateModal}
        handleUpdateTasks={handleUpdateTasks}
        handleDeleteTasks={handleDeleteTasks}
      />
      <LocalHeader showSelection={showSelection} setShowSelection={setShowSelection} />
      <Wrapper isPending={isPending}>
        <TasksList
          isAggregatedTasks
          fetchAggregateTasks={fetchAggregateTasks}
          showSelection={showSelection}
          showSelectAll
          selectedTasks={selectedTaskIds}
          handleSelectTask={handleSelectTask}
        />
        <Task />
      </Wrapper>
      {showSelection && (
        <TaskSelectionFooter
          selectedTasks={selectedTaskIds?.length}
          handleContinueTaskUpdates={handleContinueTaskUpdates}
        />
      )}
    </PageWrapper>
  );
};
