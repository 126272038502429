import { AgentTeamMembers } from './AgentTeamMembers';
import { Icons } from './Icons';
import styles from './styles.module.scss';

export const SettingPermissions = () => {
  return (
    <div className={styles.permissions}>
      <h1 className={styles.pageTitle}>Permissions</h1>

      <div className={styles.contentWrap}>
        <div className={styles.contentTitle}>
          <Icons variant={'twoWayArrow'} />
          Transactions
        </div>

        <AgentTeamMembers />
      </div>
    </div>
  );
};
