import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { relatedEntitiesCompleteListGetDataEffect } from 'store/effects/relatedEntities';
import { getTaskCategoriesEffect } from 'store/effects/transactions';
import {
  requestGetProjectsEffect,
  requestGetTransactionsEffect,
} from 'store/effects/transactions/all';
import { getRelatedEntitiesCompleteListState } from 'store/selectors/relatedEntities';
import { getAllTransactionParticipantsSelector } from 'store/selectors/transaction';
import { getUserDataSelector } from 'store/selectors/user';
import { FilterSections } from './FilterSections/FilterSections';

interface FilterProps {
  isAggregatedTasks?: boolean;
}

export const Filter = ({ isAggregatedTasks }: FilterProps) => {
  const dispatch = useDispatch();

  const user = useSelector(getUserDataSelector);
  const participants = isAggregatedTasks
    ? useSelector(getRelatedEntitiesCompleteListState)
    : useSelector(getAllTransactionParticipantsSelector);

  useEffect(() => {
    dispatch(relatedEntitiesCompleteListGetDataEffect());
    dispatch(getTaskCategoriesEffect());
    isAggregatedTasks && dispatch(requestGetProjectsEffect({ filters: { isProject: true } }));
    isAggregatedTasks && dispatch(requestGetTransactionsEffect({}));
  }, []);

  const getAggregatedParticipants = () => {
    const users = [
      {
        Id: user.Id,
        Type: 'Contact',
        FirstName: user.FirstName,
        LastName: user.LastName,
        ContactUserId: user.Id,
        Email: user.Email,
        SearchableName: `${user.FirstName} ${user.LasName}`,
        Source: user.Roles[0],
        SourceId: null,
        Role: user.Roles[0],
      },
    ];
    users.push(...(participants || []).filter((entity) => entity.Type === 'Contact'));
    return users;
  };

  const getParticipants = () => (isAggregatedTasks ? getAggregatedParticipants() : participants);
  return <FilterSections participants={getParticipants()} isAggregatedTasks={isAggregatedTasks} />;
};
