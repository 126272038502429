import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AgentHeader, PropertiesList } from './components';
import Map from '../common/Map';
import { ViewSwitcher } from './components/ViewSwitcher/ViewSwitcher';

import { Col, Row } from 'components-antd';

import { useAgentSearchInstance } from './hooks/useAgentSearchInstance';
import {
  getAgentPropertiesList,
  getAgentPropertiesMarkersSelector,
  getFeedCriteriaSelectorV3,
  getFeedMetaSelectorV3,
} from 'store/selectors/feedv3';
import {
  getClientFavoriteMarkersSelector,
  getClientFavoritePropsSelector,
  getFeedSortSelector,
  getSubFilterTypeSelector,
} from 'store/selectors/feed';
import {
  getAgentClientsWithFavoritesCountEffect,
  getClientFavoritesPropsEffect,
  getSavedSearchesEffect,
  resetClientFavoritesPropsEffect,
  sortFeedEffect,
  updateSearchInstanceEffect,
} from 'store/effects';

import styles from './styles.module.scss';
import ClientSearch from '../ClientSearch';
import { getSavedSearchesFormattedSelector } from 'store/selectors/mySearches';
import { SORT_ASC } from 'settings/constants/sort';
import { subFilterTypes } from 'settings/constants/properties';
import { setSubFilterTypeAction } from 'store/actions/feed';
import { LocationType } from 'types';
import { toggleSearchMapDrawingModeAction } from 'store/actions/searchResults';
import { getClientsSearchInstancesEffect } from 'store/effects/clientSearches';
import {
  selectedSearchAction,
  setCriteriaAction,
  setCriteriaModeAction,
  setIsSimpleHeaderTitleVisibleAction,
} from 'store/actions/feedv3';
import { LocationService } from 'services';
import { useLocation } from 'react-router-dom';
import { EDIT } from 'settings/constants/mode';
import { PENDING } from 'settings/constants/apiState';

const AgentFeed = () => {
  const locationService = new LocationService();
  const dispatch = useDispatch();
  const location: any = useLocation();
  locationService.setLocation(location);
  const query = locationService.setLocation(location).getQuery();
  const getAgentSearchInstance = useAgentSearchInstance();
  const [showBlurBg, setShowBlurBg] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const {
    agentClientMode,
    isClientMatches,
    selectedClientSavedSearch,
    selectedSearch,
    isSimpleHeaderTitleVisible,
  } = useSelector(getFeedMetaSelectorV3);
  const setIsSimpleHeaderTitleVisible = (state) => {
    dispatch(setIsSimpleHeaderTitleVisibleAction({ isSimpleHeaderTitleVisible: state }));
  };
  const isFavorites =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Favorites';
  const isUnreadComments =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Unread Comments';
  const { properties, isPending, totalCount } = useSelector(getAgentPropertiesList);
  const agentPropertiesMarkers = useSelector(getAgentPropertiesMarkersSelector);
  const subFilterType = useSelector(getSubFilterTypeSelector);
  const sort = useSelector(getFeedSortSelector);
  const clientFavoritesProps = useSelector(getClientFavoritePropsSelector);
  const favouriteMarkers = useSelector(getClientFavoriteMarkersSelector);
  const [viewType, setViewType] = useState('Split View');
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const polygonLocations =
    criteria?.Locations?.filter(({ Type }) => Type === LocationType.Polygon) ?? [];
  const { isPending: isSavedSearchPending, isIdle } = useSelector(
    getSavedSearchesFormattedSelector,
  );
  const [isOpenSearchModal, setIsOpenSearchModal] = useState<boolean>(false);

  useEffect(() => {
    if (location?.state?.openNewSearch) {
      setIsOpenSearchModal(true);
    }
  }, []);

  const fetchMySearchProperties = () => {
    if (criteria) {
      const searchInstance = {
        criterias: criteria,
        id: selectedSearch?.id,
      };
      getAgentSearchInstance(
        searchInstance,
        undefined,
        selectedClientSavedSearch,
        isClientMatches,
        isUnreadComments,
      );
    }
  };

  const fetchClientFavorites = () => {
    dispatch(getClientFavoritesPropsEffect({ userId: clientFavoritesProps?.meta?.userId }));
  };

  const fetchProperties = () => {
    isFavorites ? fetchClientFavorites() : fetchMySearchProperties();
  };

  useEffect(() => {
    if (firstRender && (query?.option || query?.clientId)) {
      if (query?.clientId) {
        dispatch(
          getAgentClientsWithFavoritesCountEffect({}, {}, (err) => {
            if (!err) {
              dispatch(getClientFavoritesPropsEffect({ userId: query?.clientId }));
            }
          }),
        );
        dispatch(resetClientFavoritesPropsEffect());
      }
      return;
    }
    if (firstRender && !isIdle) return;
    fetchProperties();
  }, [subFilterType, JSON.stringify(sort)]);

  useEffect(() => {
    if (firstRender && isIdle)
      dispatch(
        getSavedSearchesEffect(undefined, undefined, (err, res) => {
          if (!err) {
            if (firstRender && (query?.option || query?.clientId)) return;
            const mySearch = res?.data?.result?.find(
              (search) => search?.Name === 'My Primary Search' && !search?.ClientId,
            );
            if (mySearch && !selectedSearch?.name) {
              dispatch(setCriteriaModeAction({ mode: EDIT }));
              dispatch(
                selectedSearchAction({
                  selectedSearch: { id: mySearch.Id, name: mySearch?.Name },
                }),
              );
              getAgentSearchInstance({
                id: mySearch.Id,
                criterias: mySearch?.DefaultPropertySearchPreferences,
              });
            }
          }
        }),
      );
  }, []);

  useEffect(() => {
    setIsSimpleHeaderTitleVisible({
      value: clientFavoritesProps.state !== 'idle',
      text: 'Favorites',
    });
  }, [clientFavoritesProps.state]);

  useEffect(() => {
    if (subFilterType !== SORT_ASC || sort !== subFilterTypes.ALL) {
      if (isIdle) dispatch(sortFeedEffect({ order: SORT_ASC }));
      if (query?.option && query?.option !== 'newMatches')
        dispatch(setSubFilterTypeAction(subFilterTypes.ALL));
    } else if (firstRender && isIdle) {
      fetchProperties();
    }
    setFirstRender(false);
  }, []);

  const isLoading = isFavorites
    ? clientFavoritesProps.state === PENDING
    : isPending || isSavedSearchPending;

  let propertyProps = {
    isPending: isLoading,
    properties: isFavorites ? clientFavoritesProps.data : properties,
  };

  const editModeCallback = (err, criterias) => {
    if (!err) {
      getAgentSearchInstance(
        { criterias, id: selectedSearch?.id },
        undefined,
        selectedClientSavedSearch,
        isClientMatches,
        isUnreadComments,
      );
      if (selectedClientSavedSearch) {
        dispatch(getClientsSearchInstancesEffect());
      } else {
        dispatch(getSavedSearchesEffect());
      }
    }
  };

  const setDrawnPolygonDispatchFn = (polygon) => {
    const newCriteria = {
      ...criteria,
      Locations: [polygon],
      id: selectedSearch?.id,
    };
    dispatch(setCriteriaAction({ criteria: newCriteria }));
    dispatch(
      updateSearchInstanceEffect(newCriteria, {}, (err) => editModeCallback(err, newCriteria)),
    );
    dispatch(toggleSearchMapDrawingModeAction(false));
  };

  let mapProps = {
    markers: isFavorites ? favouriteMarkers : agentPropertiesMarkers,
    properties: isFavorites ? clientFavoritesProps.data : properties,
    totalCount: isFavorites ? clientFavoritesProps.totalCount : totalCount,
    setDrawnPolygonDispatchFn: setDrawnPolygonDispatchFn,
    polygonLocations: polygonLocations,
    disableDraw: isFavorites || isUnreadComments,
  };

  const getViewType = (value) => {
    setViewType(value);
  };

  return (
    <div className={styles.agentFeedWrapper}>
      {isOpenSearchModal && (
        <ClientSearch onClose={() => setIsOpenSearchModal(false)} isAgent={true} />
      )}
      <AgentHeader
        isSimpleHeaderTitleVisible={isSimpleHeaderTitleVisible}
        setIsSimpleHeaderTitleVisible={setIsSimpleHeaderTitleVisible}
        setShowBlurBg={(value) => setShowBlurBg(value)}
        setIsOpenSearchModal={setIsOpenSearchModal}
      />
      <Row className={styles.agentFeed}>
        {showBlurBg && <div className={styles.backdropBlur} />}
        {viewType !== 'List Only' && (
          <Col
            md={viewType === 'Split View' ? 10 : 24}
            sm={24}
            xs={24}
            className={styles.mapContainer}
          >
            <Map {...mapProps} />
          </Col>
        )}
        {viewType !== 'Map Only' && (
          <Col
            md={viewType === 'Split View' ? 14 : 24}
            sm={24}
            xs={24}
            className={styles.propertiesContainer}
          >
            <PropertiesList
              {...propertyProps}
              isSimpleHeaderTitleVisible={isSimpleHeaderTitleVisible}
              isAgentType={agentClientMode?.value ? true : !isFavorites}
              agentClientMode={agentClientMode}
              viewType={viewType}
            />
          </Col>
        )}
      </Row>
      <div className={styles.mapSwitchers}>
        <ViewSwitcher getView={getViewType} />
      </div>
    </div>
  );
};

export default AgentFeed;
