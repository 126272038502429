import { createSelector } from 'reselect';
import { filter, groupBy, upperCase, includes, map, get, find } from 'lodash-es';
import { AGENT_CONNECTION_STATUSES } from 'settings/constants/common';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { AGENT, TEAM_OWNER } from 'settings/constants/roles';
import { Role } from 'app-constants';

export const localState = ({ teamList }) => teamList;

const userState = ({ user }) => user?.data || {};

export const teamSearchFilter = (list, search) =>
  filter(list, ({ name }) => includes(upperCase(name), upperCase(search)));

export const getStateAndDataFlag = createSelector(localState, ({ state, data }) => ({
  state,
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!data,
}));

export const getTeamListDataArray = createSelector(localState, ({ data }) => data || []);

export const getContextStatus = (item) => {
  if (item.IsActive) {
    if (item.InvitationStatus === AGENT_CONNECTION_STATUSES.PENDING) {
      return AGENT_CONNECTION_STATUSES.PENDING;
    }
    return AGENT_CONNECTION_STATUSES.ACTIVE;
  }
  return AGENT_CONNECTION_STATUSES.INACTIVE;
};

export const getFormattedTeamList = createSelector(getTeamListDataArray, (data) =>
  map(data, (item = {}) => {
    const role = get(item, 'Roles.0');
    return {
      id: item.Id,
      name: `${item.FirstName} ${item.LastName}`,
      firstName: item.FirstName,
      lastName: item.LastName,
      role: item?.Title?.Title || AGENT,
      teamRole: item.TeamRole,
      contextStatus: getContextStatus(item),
      avatarUrl: get(item, 'AvatarUrl'),
      deactivated: get(item, 'Deactivated'),
      sendDate: get(item, 'CreatedDate'),
      transactionInstances: get(item, [role, 'TransactionInstances']),
      IsManagedByCurrentUser: item.IsManagedByCurrentUser,
    };
  }),
);
export const getActiveTeamList = createSelector(getFormattedTeamList, (list) =>
  filter(list, ({ contextStatus }) => contextStatus === AGENT_CONNECTION_STATUSES.ACTIVE).map(
    (item) => ({ ...item, listType: 'Active' }),
  ),
);

export const getInactiveTeamList = createSelector(getFormattedTeamList, (list) =>
  filter(
    list,
    ({ deactivated, contextStatus }) =>
      contextStatus === AGENT_CONNECTION_STATUSES.INACTIVE || deactivated,
  ).map((item) => ({ ...item, listType: 'Inactive' })),
);

export const getPendingTeamList = createSelector(getFormattedTeamList, (list) =>
  filter(list, ({ contextStatus }) => contextStatus === AGENT_CONNECTION_STATUSES.PENDING).map(
    (item) => ({ ...item, listType: 'Pending' }),
  ),
);

export const getDeclinedTeamList = createSelector(getFormattedTeamList, (list) =>
  filter(list, ({ contextStatus }) => contextStatus === AGENT_CONNECTION_STATUSES.DECLINED),
);

export const getTeamMemberExistance = (participantId) =>
  createSelector(getActiveTeamList, (list) => {
    const teamMember = find(list, ({ id }) => id === participantId);
    return { isParticipantTeamMember: !!teamMember };
  });

export const getFormattedTransactionPermissionList = (teamTransactionPermissions) => {
  const owner = teamTransactionPermissions.filter(({ Role }) => Role === TEAM_OWNER);

  const otherUsers = teamTransactionPermissions
    .filter(({ Role }) => Role !== TEAM_OWNER)
    .map((member) => {
      return {
        Name: member.FirstName + (member.LastName ? ' ' + member.LastName : ''),
        TagId: member.Id,
        ...member,
      };
    });

  return [
    ...(owner.length
      ? [
          {
            Name: owner[0].FirstName + (owner[0].LastName ? ' ' + owner[0].LastName : ''),
            TagId: owner[0].Id,
            isOwner: true,
            ...owner[0],
          },
        ]
      : []),
    ...otherUsers,
  ];
};

export const getTeamTransactionPermissionstDataArray = createSelector(
  localState,
  ({ teamTransactionPermissions }) => {
    return teamTransactionPermissions
      ? getFormattedTransactionPermissionList(teamTransactionPermissions)
      : [];
  },
);

export const getTeamListForEntity = createSelector(
  getTeamListDataArray,
  userState,
  (data, user) => {
    return data
      .filter((item) => item.Id !== user.Id)
      .map((item) => ({
        Id: item.Id,
        FirstName: item.FirstName,
        LastName: item.LastName,
        Role: item.TeamRole === 'Basic' ? Role.Agent : item.TeamRole,
        SearchableName: `${item?.FirstName} ${item?.LastName}`,
        Type: 'Contact',
        Email: item?.Email,
        Source: 'Agent',
      }));
  },
);
