import classNames from 'classnames';
import { Dropdown, Menu, MenuItem, Radio } from 'components-antd';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';

import styles from './styles.module.scss';

import { useState } from 'react';

import { Check } from 'components/Icons';
import { PermissionTypes } from 'app-constants/enums/permissionSettings';

type DynamicDropdownTypes = {
  value: string;
  label: string;
  className?: string;
  overlayClassName?: string;
  dropdownContainerClassName?: string;
  menuOptionWrapperClassName?: string;
  setValue: (value: string) => void;
  options: { label: string; value: string }[];
  disabled?: boolean;
};

export const DynamicDropdown = ({
  value,
  label,
  setValue,
  options,
  className,
  overlayClassName,
  dropdownContainerClassName,
  menuOptionWrapperClassName,
  disabled,
}: DynamicDropdownTypes) => {
  const [open, setOpen] = useState(false);

  const onSelect = (selectedValue) => {
    setOpen(false);
    if (selectedValue === value) return;
    setValue(selectedValue);
  };

  const menu = (
    <Radio.Group className={classNames(styles.optionsItems, menuOptionWrapperClassName)}>
      <Menu>
        {options.map((item, idx) => (
          <MenuItem key={idx}>
            <Radio value={item.value} key={idx} onClick={() => onSelect(item.value)}>
              <div className={styles.option}>
                <div className={styles.optionValue}>{item.label}</div>
              </div>

              {value === item.value ? <Check className={styles.checkIcon} /> : <></>}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.dynamicDropdownWrapper, className)}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        trigger={['click']}
        overlayClassName={classNames(styles.dropdownOverlay, overlayClassName || '')}
        onOpenChange={(visible) => setOpen(visible)}
        disabled={disabled}
      >
        <div
          className={classNames(
            styles.dynamicDropdownContainer,
            {
              [styles.openedDropdown]: open,
              [styles.disabledDropdown]: disabled,
            },
            dropdownContainerClassName,
          )}
        >
          <div className={styles.title}>{label}</div>
          <div className={styles.arrowIcon}>
            <Icons variant={ICON_VARIANT_TYPE.DownArrow} />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
