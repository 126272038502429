import classNames from 'classnames';
import { ZoomIn, ZoomOut } from 'components/Icons';

import styles from './style.module.scss';

export const PDFWidgets = ({ className, updateScale, disableZoomIn, disableZoomOut }) => {
  return (
    <div className={classNames(styles.zoomWidgets, className)}>
      <div
        className={classNames(styles.zoomWidget, { [styles.disabledZoomWidget]: disableZoomIn })}
        onClick={() => updateScale(1)}
      >
        <ZoomIn />
      </div>
      <div
        className={classNames(styles.zoomWidget, { [styles.disabledZoomWidget]: disableZoomOut })}
        onClick={() => !disableZoomOut && updateScale(-1)}
      >
        <ZoomOut />
      </div>
    </div>
  );
};
