import { Modal, Select, Button, Form, Row, Checkbox } from 'components-antd';
import styles from './styles.module.scss';
import { Icons } from 'pages/Clarity/Icons';
import { CheckBox } from 'components/Icons';
import { publishKitById } from 'api/kits';
import { useEffect, useState } from 'react';
import { LocationProps, FormGeoLocations } from 'pages/FormBuilder/components/FormGeoLocations';
import { getFormattedLocations, showErrorMessage } from 'helpers';
import { Input } from 'components';
import { updateKitById } from 'api/kits';

type PublishModalPropsType = {
  showModal: Function;
  open: boolean;
  agents;
  teams;
  targetKit;
  setPermissionsHandler: Function;
  updateNotesHandler: Function;
};

function PublishModal(props: PublishModalPropsType) {
  const [form] = Form.useForm();
  const [publishing, setPublishing] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [allPeople, setAllPeople] = useState<boolean>(false);
  const [allTeams, setAllTeams] = useState<boolean>(props?.targetKit?.IsTeamPublished || false);
  const [locations, setLocations] = useState<LocationProps[]>([]);
  const [notes, setNotes] = useState<string>(props?.targetKit?.Notes || '');
  const [savingNotes, setSavingNotes] = useState<boolean>(false);
  const [savingNotesError, setSavingNotesError] = useState<boolean>(false);

  const permittedUsersIds = props.targetKit?.KitsUserPermission?.map((el) => el.Id);
  const savedTeamsIds = props.targetKit?.KitsTeamPermission?.map((el) => el.Id);

  const submitForm = async () => {
    try {
      const { teams, users, excludedTeams } = form.getFieldsValue();

      let People = [];
      let Teams = [];
      if (allPeople) {
        People = props.agents?.length
          ? props.agents?.map((el) => {
              return {
                Id: el.UserId,
                FrequencyId: props.targetKit?.EligibleFrequencies[0].Id,
              };
            })
          : [];
      } else {
        People = users?.length
          ? users?.map((el) => {
              return {
                Id: el,
                FrequencyId: props.targetKit?.EligibleFrequencies[0].Id,
              };
            })
          : [];
      }
      if (allTeams) {
        Teams =
          props.teams?.length && excludedTeams?.length
            ? props.teams
                ?.filter((el) => excludedTeams?.includes(el.Id))
                ?.map((el) => {
                  return {
                    Id: el.Id,
                    FrequencyId: props.targetKit?.EligibleFrequencies[0].Id,
                  };
                })
            : [];
      } else {
        Teams = teams?.length
          ? teams?.map((el) => {
              return {
                Id: el,
                FrequencyId: props.targetKit?.EligibleFrequencies[0].Id,
              };
            })
          : [];
      }

      setPublishing(true);
      await publishKitById(props.targetKit?.Id, {
        People,
        Teams,
        Locations: locations.map((item) => {
          return {
            ...item,
            FrequencyId: props.targetKit?.EligibleFrequencies[0].Id,
          };
        }),
        IsTeamPublished: allTeams,
      });
      setPublishing(false);
      form.resetFields();
      props.setPermissionsHandler();
      props.showModal(false);
    } catch (e) {
      const errorMessage = `Either no states areas matched are found in the MLS or this area isn't covered by your Agent's MLS. Please contact them with any questions.`;
      showErrorMessage(errorMessage);
      setPublishing(false);
      setError(true);
    }
  };

  const saveNotes = async () => {
    try {
      if (notes && notes !== props?.targetKit?.Notes) {
        setSavingNotes(true);
        await updateKitById(props.targetKit.Id, { Notes: notes });
        props.updateNotesHandler(props.targetKit.Id, notes);
        setSavingNotes(false);
        setSavingNotesError(false);
      }
    } catch (e) {
      setSavingNotes(false);
      setSavingNotesError(true);
    }
  };

  return (
    <Modal
      footer={null}
      width={675}
      open={props.open}
      onCancel={() => {
        form.resetFields();
        props.showModal(false);
      }}
      title={
        <span className={styles.headerTitle}>
          <Icons variant="kits" />
          <span className={styles.headerText}>{`${props.targetKit?.Name} KIT`}</span>
        </span>
      }
      className={styles.kitsPublishModal}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={submitForm}
        initialValues={{
          ['users']: permittedUsersIds?.length
            ? props.agents
                ?.filter((obj) => permittedUsersIds?.includes(obj.UserId))
                ?.map((el) => el.UserId)
            : [],
          ['teams']:
            !allTeams && savedTeamsIds?.length
              ? props.teams?.filter((obj) => savedTeamsIds?.includes(obj.Id))?.map((el) => el.Id)
              : [],
          ['excludedTeams']:
            allTeams && savedTeamsIds?.length
              ? props.teams?.filter((obj) => savedTeamsIds?.includes(obj.Id))?.map((el) => el.Id)
              : [],
        }}
      >
        <span className={styles.form}>
          <span className={styles.hr} />
          <span className={styles.permissionTitle}>Permissions</span>
          <span className={styles.warning}>
            If you do not select any option from dropdowns, it will be assumed that all the
            permissions have been revoked.
          </span>
          <div className={styles.permissionCheckboxes}>
            <div className={styles.allPermission}>
              <Checkbox
                className="mosaikCheckbox"
                onChange={(e) => {
                  setAllPeople(!allPeople);
                }}
                checked={allPeople}
              />
              <p className={styles.checkboxLabel}>All people</p>
            </div>
            <div className={styles.allPermission}>
              <Checkbox
                className="mosaikCheckbox"
                onChange={(v) => {
                  setAllTeams(!allTeams);
                }}
                checked={allTeams}
              />
              <p className={styles.checkboxLabel}>All teams</p>
            </div>
          </div>
          <Form.Item label="People" name="users">
            <Select
              large
              disabled={allPeople}
              showArrow={true}
              placeholder="Select People"
              mode="multiple"
              getPopupContainer={(triggerNode) => triggerNode}
              menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
              options={props.agents.map((el) => ({
                label: el.Name,
                value: el.UserId,
                key: `users-${el.UserId}`,
              }))}
              filterOption={(input, option) => {
                return String((option?.label as unknown as string) || '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            />
          </Form.Item>
          {allTeams ? (
            <Form.Item label="Exclude Teams" name="excludedTeams">
              <Select
                large
                disabled={!allTeams}
                showArrow={true}
                placeholder="Select Teams"
                mode="multiple"
                menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                options={props.teams.map((el) => ({
                  label: el.Name,
                  value: el.Id,
                  key: `Team-${el.Id}`,
                }))}
                filterOption={(input, option) =>
                  String((option?.label as unknown as string) || '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          ) : (
            <Form.Item label="Teams" name="teams">
              <Select
                large
                disabled={allTeams}
                showArrow={true}
                placeholder="Select Teams"
                mode="multiple"
                menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
                options={props.teams.map((el) => ({
                  label: el.Name,
                  value: el.Id,
                  key: `Team-${el.Id}`,
                }))}
                filterOption={(input, option) =>
                  String((option?.label as unknown as string) || '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}
          {/* <Form.Item label="Locations" name="locations">
            <FormGeoLocations
              showMiles={true}
              multiple={true}
              disabled={publishing}
              onChange={setLocations}
              locations={
                props.targetKit?.KitsLocation?.length
                  ? getFormattedLocations(props.targetKit?.KitsLocation)
                  : undefined
              }
            />
          </Form.Item> */}
        </span>
        <Row justify="center">
          <Button
            htmlType="submit"
            variant="secondary"
            className={styles.submitButton}
            loading={publishing}
            disabled={publishing || error}
          >
            {error ? 'Oops, something went wrong.' : 'Set Permissions'}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}

export default PublishModal;
