import { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { convertNameToAvatarPlaceholder, showErrorMessage } from 'helpers';
import { getUserDataSelector } from 'store/selectors/user';
import { SendMessageFormView, Avatar, Wrapper as PendingWrapper, CommentsView } from 'components';

import { socketSetEditOfferMessageEffect } from 'store/effects/sockets/offerMessages';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getCurrentEditOfferMessageSelector } from 'store/selectors/sockets/offerMessages';
import { setShowCommentIconEffect } from 'store/effects/taskAggregate';
import { setShowCommentIconForTransactionEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';
import { addOfferComment, getOfferComments } from 'api/offers';
import {
  CommentsEntityType,
  createCommentThread,
  deleteComment,
  markThreadAsRead,
  updateComment,
} from 'api/comments';

const Comments = (props) => {
  const { className, commentAutoFocus, readOnly } = props;
  const commentViewWrapperRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(getUserDataSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const currentEditMessage = useSelector(getCurrentEditOfferMessageSelector);
  const [isThreadCreating, setIsThreadCreating] = useState(false);
  const [comments, setComments] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState(null);

  const fetchOfferComments = useCallback(async () => {
    const { data } = await getOfferComments({ offerId: props?.offerData?.OfferId });
    setComments(data?.Comments || []);
    const commentThreadId = data?.Comments?.[0]?.CommentThreadId;
    if (!currentThreadId && commentThreadId) setCurrentThreadId(commentThreadId);
    if (commentThreadId) {
      await markThreadAsRead({ threadId: commentThreadId });
    }
  }, [props.OfferData]);

  useEffect(() => {
    if (currentThreadId) {
      markThreadAsRead({ threadId: currentThreadId });
    }
  }, [currentThreadId]);

  useEffect(() => {
    let interval;
    if (props.offerData.OfferId) {
      if (!interval) fetchOfferComments();
      interval = setInterval(() => {
        fetchOfferComments();
      }, 15000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [props.offerData]);

  const onCancelEditHandler = () => {
    dispatch(socketSetEditOfferMessageEffect({ editMessage: null }));
  };

  const onSendMessage = async (
    message,
    attachments,
    tagsValue,
    messageId,
    threadId,
    accessType,
  ) => {
    if (currentEditMessage) {
      onCancelEditHandler();
    }

    if (messageId) {
      try {
        const { data } = await updateComment({
          Id: messageId,
          Text: message,
          CommentMeta: { Tags: tagsValue },
          AccessType: accessType,
        });

        setComments((prevComments) =>
          prevComments.map((item) => (item.Id === messageId ? data.Comment : item)),
        );
      } catch (err) {
        showErrorMessage(err);
      }
    } else {
      try {
        const { data } = await addOfferComment({
          ThreadId: threadId || currentThreadId,
          Text: message,
          CommentMeta: { Tags: tagsValue },
          AccessType: accessType,
        });

        setComments((prevComments) => [
          ...prevComments,
          { ...data.Comment, Recipients: data.Recipients },
        ]);
      } catch (err) {
        showErrorMessage(err);
      }
    }
  };

  const onDeleteMessageHandler = async (messageId) => {
    try {
      await deleteComment({ CommentId: messageId });
      setComments((prevComments) => prevComments.filter(({ Id }) => Id !== messageId));
    } catch (err) {
      showErrorMessage(err);
    }
  };

  const onCreateThreadAndSendNewMessage = async (
    message,
    attachments,
    tagsValue,
    messageId,
    threadId,
    accessType,
  ) => {
    if (currentThreadId || threadId) {
      onSendMessage(message, attachments, null, currentThreadId || threadId, null, accessType);
    }

    setIsThreadCreating(true);
    try {
      const { data } = await createCommentThread({
        EntityId: Number(props.offerData?.OfferId),
        ParticipantIds: [
          ...new Set([
            ...(transaction?.Participants || []).map((participant) => participant?.Id),
            user?.Id,
          ]),
        ],
        EntityType: CommentsEntityType.offerComment,
        FirstComment: {
          Text: message,
          CommentMeta: { Tags: tagsValue },
          AccessType: accessType,
        },
      });

      setComments([data?.CommentThread?.Comments?.[0].message]);
      setCurrentThreadId(data?.CommentThread?.Id);
      if (props.offerData?.OfferId)
        dispatch(setShowCommentIconEffect({ taskId: props.offerData?.OfferId }));
      if (props.offerData?.OfferId && props.offerData?.TransactionId) {
        dispatch(
          setShowCommentIconForTransactionEffect({
            taskId: props.offerData?.OfferId,
          }),
        );
      }
    } catch (err) {
      showErrorMessage(err);
    }
    setIsThreadCreating(false);
  };

  const getUserName = () => `${user?.FirstName} ${user?.LastName}`;

  const onEditMessageHandler = useCallback(
    (messageId) => {
      const editMessage = comments.find((message) => message?.Id === messageId);
      dispatch(socketSetEditOfferMessageEffect({ editMessage }));
    },
    [dispatch, comments],
  );

  useEffect(() => {
    if (commentViewWrapperRef?.current && currentThreadId) {
      commentViewWrapperRef.current.scrollTop = commentViewWrapperRef?.current?.scrollHeight;
    }
  }, [comments, currentThreadId]);

  const getMessagesContent = () => {
    if (isThreadCreating) {
      return (
        <PendingWrapper
          isPending={isThreadCreating}
          className={styles.pendingWrapper}
          loaderClassName={styles.loader}
        />
      );
    }

    if (comments.length) {
      return (
        <div className={styles.CommentsViewWrapper} ref={commentViewWrapperRef}>
          <CommentsView
            list={comments}
            currentEditMessage={currentEditMessage}
            onEditMessage={onEditMessageHandler}
            className={styles.messages}
            onDeleteMessage={onDeleteMessageHandler}
            messageWrapperClassName={styles.offerCommentsMessage}
            messageHolderClassName={styles.offerCommentsMessageHolder}
            messageSenderClassName={styles.offerCommentMessageSender}
            messageTextClassName={styles.offerMessageText}
            showSenderAsTitle
            useRestApi
          />
        </div>
      );
    }

    return (
      <div className={styles.empty}>
        <span testid="no_comments" className={styles.message}>
          No Comments
        </span>
      </div>
    );
  };

  return (
    <div className={classNames(styles.offerComments, className)}>
      <div className={styles.holder}>
        <SendMessageFormView
          showDropdown
          id="offerComments"
          onSend={currentThreadId ? onSendMessage : onCreateThreadAndSendNewMessage}
          subheader={null}
          content={getMessagesContent()}
          autoFocus={commentAutoFocus}
          footerClassName={styles.footer}
          footerInnerClassName={styles.footerInner}
          inputGroupClassName={styles.inputGroup}
          attachments={false}
          emoji={true}
          autocomplete={true}
          inputPlaceholder="Start typing..."
          sendButtonClassName={styles.sendButton}
          onCancelEdit={onCancelEditHandler}
          currentEditMessage={currentEditMessage}
          placeholder="Type to search users"
          placeholderClassName={styles.commentPlaceholder}
          editableContentWrapperClassName={styles.editableContentWrapper}
          isTaskComments
          className={styles.commentsForm}
          avatar={true}
          avatarPlaceHolder={
            <div className={styles.avatarPlaceholder}>
              {convertNameToAvatarPlaceholder(getUserName())}
            </div>
          }
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

Comments.propTypes = {
  className: PropTypes.string,
  commentAutoFocus: PropTypes.bool,
  offerData: PropTypes.any,
  readOnly: PropTypes.bool,
};

Comments.defaultProps = {
  className: '',
  commentAutoFocus: false,
  readOnly: false,
};

export default Comments;
