import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { milestoneStatus } from 'settings/constants/milestones';
import { Tooltip } from 'components-antd';

import {
  updateMilestoneStatusEffect,
  updateMilestoneStatusEffectAggregated,
} from 'store/effects/transactions';
import { updatedMilestoneStatusEffect } from 'store/effects/milestoneAggregate';
import { Checkbox } from 'components';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { getUserId } from 'store/selectors/user';

import styles from './styles.module.scss';

const Point = (props) => {
  const {
    className,
    milestoneId,
    status,
    isAggregated,
    isCreator,
    transactionId,
    participants,
    IsKeyDate = false,
    editMode = false,
    isProject = false,
    isEditable = false,
  } = props;
  const [isDisable, setIsDisable] = useState(false);
  const dispatch = useDispatch();
  const { fullAccess } =
    isAggregated && isCreator ? { fullAccess: true } : useSelector(getTransactionAccessSelector);
  const [isPendingStatus, setIsPendingStatus] = useState(false);
  const userId = useSelector(getUserId);

  useEffect(() => {
    if (participants) {
      const participant = participants.find((participant) => participant.ParticipantId === userId);
      if (!participant) {
        setIsDisable(true);
      } else setIsDisable(!participant?.TransactionAccess);
      if (IsKeyDate && !isEditable) {
        setIsDisable(true);
      }
    }
  }, [participants]);

  const onChangeStatus = (checked, id) => {
    if (!fullAccess) return null;

    setIsPendingStatus(true);
    const config = {
      milestoneId: id,
      status: !checked ? milestoneStatus.INCOMPLETE : milestoneStatus.COMPLETE,
      ...(isAggregated && isCreator && { transactionId: transactionId }),
    };

    dispatch(
      isAggregated && isCreator
        ? updateMilestoneStatusEffectAggregated(config, { silent: true }, () => {
            dispatch(updatedMilestoneStatusEffect(config));
            setIsPendingStatus(false);
          })
        : updateMilestoneStatusEffect(config, { silent: true }, () => {
            setIsPendingStatus(false);
          }),
    );
  };

  const getTooltipTitle = () => {
    if (IsKeyDate && !isEditable) {
      const type = isProject ? 'project' : 'listing/transaction';
      return `This milestone can only be completed when the ${type} disposition is updated accordingly.`;
    } else if (isDisable)
      return `You are not authorized to mark it ${
        status === milestoneStatus.COMPLETE ? 'incomplete.' : 'complete.'
      }`;
    return '';
  };

  return (
    <div className={classNames(styles.pointMark, className)}>
      <div className={styles.holder}>
        <Tooltip
          title={getTooltipTitle()}
          overlayClassName={classNames('mosaikTooltip', styles.milestonePointTooltip)}
          getPopupContainer={(trigger) => trigger}
        >
          <div>
            <Checkbox
              checked={status === milestoneStatus.COMPLETE}
              variant={Checkbox.DARK}
              className={styles.inner}
              labelClassName={classNames(styles.innerCheckbox, {
                [styles.notClickable]: !fullAccess,
                [styles.disabled]: editMode,
              })}
              onChange={(e, val, checked) => onChangeStatus(checked, milestoneId)}
              checkboxClassName={styles.checkbox}
              checkboxWrapperClassName={styles.checkboxWrapper}
              checkmarkClassName={styles.checkmark}
              isPending={isPendingStatus}
              disabled={isDisable || editMode}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

Point.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  milestoneId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isAggregated: PropTypes.bool,
  isCreator: PropTypes.bool,
  transactionId: PropTypes.number,
  participants: PropTypes.any,
  IsKeyDate: PropTypes.bool,
  editMode: PropTypes.bool,
  isProject: PropTypes.bool,
  isEditable: PropTypes.bool,
};

Point.defaultProps = {
  className: '',
  status: '',
  isAggregated: false,
  isCreator: false,
  transactionId: 0,
  IsKeyDate: false,
  editMode: false,
  isProject: false,
  isEditable: false,
};

export default Point;
