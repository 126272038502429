import { BUNDLE_FILES_LIMIT, SINGLE_FILE_LIMIT } from '../../../helper';
import { Icons } from '../Icons';
import styles from './style.module.scss';

export const FileSizeError = ({ files }) => {
  return (
    <div className={styles.fileSizeError}>
      <Icons variant={'warning'} />
      <div>
        Error Message:{' '}
        {files.length === 1
          ? `File size exceeds ${SINGLE_FILE_LIMIT} MB`
          : `Total file size exceeds ${BUNDLE_FILES_LIMIT} MB`}
      </div>
    </div>
  );
};
