import styles from './styles.module.scss';
import { Map } from 'components';

const MapModal = ({ options }) => {
  const { zoom, center, markers } = options;
  return (
    <div className={styles.mapModal}>
      <div className={styles.bottomBar}>
        <Map
          markersMap={markers}
          className={styles.map}
          controlsClassName={styles.mapControls}
          zoom={zoom}
          center={center}
          controls={{
            zoom: true,
          }}
        />
      </div>
    </div>
  );
};

export default MapModal;
