import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

const Icon = memo(({ variant }) => {
  const getIcon = useCallback(() => {
    switch (variant) {
      case Icon.CENTER:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M2 12H5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 12H22"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 2V5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 19V22"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
              fill="#515151"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.REQUEST_FULLSCREEN:
        return (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 17H1L1 13M13 17H17V13M13 1L17 1V5M5 1L1 1L1 5"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.EXIT_FULLSCREEN:
        return (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 13H5V17M17 13H13V17M17 5L13 5V1M1 5L5 5L5 1"
              stroke="#303030"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.ARROW_RIGHT:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.ARROW_LEFT:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 18L9 12L15 6"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icon.ZOOM_IN:
        return (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 0C6.4477 0 6 0.44772 6 1V6H1C0.44772 6 0 6.4477 0 7C0 7.5523 0.44772 8 1 8H6V13C6 13.5523 6.4477 14 7 14C7.5523 14 8 13.5523 8 13V8H13C13.5523 8 14 7.5523 14 7C14 6.4477 13.5523 6 13 6H8V1C8 0.44772 7.5523 0 7 0Z"
              fill="#303030"
            />
          </svg>
        );
      case Icon.ZOOM_OUT:
        return (
          <svg
            width="14"
            height="2"
            viewBox="0 0 14 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="14" height="2" rx="1" fill="#303030" />
          </svg>
        );
      default:
        return null;
    }
  }, [variant]);

  return getIcon();
});

Icon.REQUEST_FULLSCREEN = 'RequestFullscreen';
Icon.EXIT_FULLSCREEN = 'ExitFullscreen';
Icon.ZOOM_IN = 'ZoomIn';
Icon.ZOOM_OUT = 'ZoomOut';
Icon.CENTER = 'Center';
Icon.ARROW_RIGHT = 'ArrowRight';
Icon.ARROW_LEFT = 'ArrowLeft';

Icon.propTypes = {
  variant: PropTypes.string,
};

Icon.defaultProps = {
  variant: undefined,
};

export default Icon;
