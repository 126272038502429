import { useEffect, useMemo, useState } from 'react';
import { Header as AntdHeader } from 'antd/lib/layout/layout';
import { Route, Switch, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { HeaderActions, HeaderLogo } from 'components-antd';
import { getHeaderConfig } from 'settings/navigation/config/components/header';
import { headerRoutes } from 'settings/navigation/config/components/header/routes';
import { ContextSelector } from 'pages/Properties/LocalHeader/ContextSelector';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getUserFirstName, getUserRolesMapSelector } from 'store/selectors/user';

export const Header = () => {
  const { pathname } = useLocation();

  const [showHeader, setShowHeader] = useState(false);
  const [showContextSelector, setShowContextSelector] = useState(false);
  const [showChildren, setShowChildren] = useState(true);
  const [absoluteHeader, setAbsoluteHeader] = useState(true);
  const { isClient } = useSelector(getUserRolesMapSelector);
  const name = useSelector(getUserFirstName);
  const isUnknownUser = name === 'Unknown';
  const config = getHeaderConfig(pathname);

  useEffect(() => {
    if (config) {
      setShowHeader(config.visible);
      setShowChildren(config.showChildren);
      setAbsoluteHeader(!!config.absoluteHeader);
    } else {
      if (!showHeader) {
        setShowHeader(true);
      }
      if (!showChildren) {
        setShowChildren(true);
      }
      if (!absoluteHeader) {
        setAbsoluteHeader(true);
      }
    }
    if (isClient) {
      setShowContextSelector(
        pathname.includes(routes.properties) ||
          pathname.includes(routes.clientDashboard) ||
          pathname.includes(routes.vault) ||
          pathname === '/',
      );
    }
  }, [pathname, isClient]);

  return useMemo(
    () =>
      showHeader ? (
        <AntdHeader className={classNames(styles.pageHeader, 'prevent-user-select')}>
          <div className={styles.headerContainer}>
            <HeaderLogo absoluteHeader={absoluteHeader} />
            {showContextSelector && <ContextSelector />}
            {showChildren && <HeaderRouter />}
            <HeaderActions absoluteHeader={absoluteHeader} isUnknownUser={isUnknownUser} />
          </div>
        </AntdHeader>
      ) : null,
    [showHeader, showChildren, showContextSelector],
  );
};

const HeaderRouter = () => (
  <Switch>
    {headerRoutes.map(({ path, component, exact }) => (
      <Route key={path} path={path} component={component} exact={exact} />
    ))}
  </Switch>
);
