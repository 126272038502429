import classNames from 'classnames';
import { Fragment, useEffect } from 'react';

import styles from './style.module.scss';
import { UploadDragger } from 'components-antd';
import { Icons } from '../Icons';
import { UPLOAD_FILE } from 'app-constants';
import { Typography } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { cloneDeep } from 'lodash-es';
import { FileSizeInformation } from '../FileSizeInformation';
import {
  BUNDLE_FILES_LIMIT,
  SINGLE_FILE_LIMIT,
  calculateFilesSize,
  validateFileSize,
} from '../../../helper';
import { FileNameBox } from '../FileNameBox';

export const FormFileUploader = (props) => {
  const {
    files,
    updateFiles,
    multiple = true,
    setFilesChangeCounter,
    setFileSizeError,
    fileSizeError,
  } = props;
  let { filesChangeCounter } = props;

  const onChange = ({ fileList }: UploadChangeParam<any>) => {
    if (fileList?.length) {
      filesChangeCounter++;

      if (filesChangeCounter === fileList.length) {
        const isValidSize = validateFileSize([...fileList]);

        if (!multiple) {
          const files = fileList.slice(-1).pop();

          updateFiles([files]);
          setFilesChangeCounter(filesChangeCounter);
        } else {
          updateFiles([...fileList]);
          setFilesChangeCounter(filesChangeCounter);
          !isValidSize && setFileSizeError(true);
          isValidSize && fileSizeError && setFileSizeError(false);
        }
      } else {
        setFilesChangeCounter(filesChangeCounter);
      }
    }
  };

  const deleteFile = (index) => {
    let newFiles = cloneDeep(files);

    newFiles = newFiles.filter((_, i) => i !== index);
    updateFiles(newFiles);
    setFilesChangeCounter(newFiles.length);

    if (!newFiles.length) {
      fileSizeError && setFileSizeError(false);
    } else if (fileSizeError) {
      const totalSize = calculateFilesSize(newFiles);
      const maxSize = newFiles.length === 1 ? SINGLE_FILE_LIMIT : BUNDLE_FILES_LIMIT;

      if (totalSize <= maxSize) {
        setFileSizeError(false);
      }
    }
  };

  return (
    <Fragment>
      <div
        className={classNames(styles.uploadContainer, {
          [styles.uploadContainerNoFiles]: !files?.length,
          [styles.uploadedFilesContainer]: !!files?.length,
        })}
      >
        <UploadDragger
          action={UPLOAD_FILE}
          onChange={onChange}
          multiple={multiple}
          showUploadList={false}
          beforeUpload={() => false}
          accept="application/pdf"
          className={styles.uploadDragger}
          fileList={files}
        >
          <Icons variant={'uploadFile'} className={styles.uploadFileIcon} />

          <p className={styles.uploadHint}>Drag and drop files here</p>
          <p className={styles.uploadSecondHint}>or</p>
          <button className={styles.uploadButton}>
            <Typography className={styles.uploadText}>Choose File</Typography>
          </button>
        </UploadDragger>
      </div>

      <FileSizeInformation />

      {files?.map((file, index) => (
        <FileNameBox
          key={`file-${file.name}-${index}`}
          file={file}
          onDelete={() => deleteFile(index)}
        />
      ))}
    </Fragment>
  );
};
