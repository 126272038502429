import { createSelector } from 'reselect';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { formatOpenHouses, openHouseSorter, preparePriceForMarkup } from 'store/selectors/feed';
import { getMatchedCriteriasList, getMatchedCriteriasFilteredList } from 'settings/constants/match';
import { filterProperties } from 'pages/Properties/ClientFeed/components/ClientProperties/helpers';
import { softCriteria } from 'pages/Properties/ListingDetail/components/Match/MatchItem/Insight/constants';

const localState = ({ feedv3 }) => feedv3;

const CommutePrefs = () => {
  const searchCriteria = localStorage.getItem('searchCriteria');
  return searchCriteria ? JSON.parse(searchCriteria)?.CommutePrefs || [] : [];
};

export const getFeedMetaSelectorV3 = createSelector(localState, (feed) => feed?.meta);

export const getFeedCriteriaSelectorV3 = createSelector(localState, (feed) => feed?.criteria);

export const getSavedSearchesFilteredPropertiesList = createSelector(
  localState,
  ({ savedSearchesProperties, criteria: { filter } }) => {
    return {
      isIdle: savedSearchesProperties?.state === IDLE,
      isPending: savedSearchesProperties?.state === PENDING,
      properties: filterProperties(savedSearchesProperties?.data?.items || [], filter)?.map(
        (prop) => ({
          ...prop,
          MatchedCriterias: getMatchedCriteriasFilteredList({
            ...prop,
            MatchedCriterias: {
              ...prop?.MatchedCriterias,
              ...(prop?.MatchedCriterias?.Commutes
                ? {
                    Commutes: prop?.MatchedCriterias?.Commutes.map((commute) => {
                      const isProximity = CommutePrefs()?.find(
                        (c) => c.Name === commute.Name,
                      )?.IsImportantLocation;
                      return {
                        Feature: `${
                          isProximity ? softCriteria.ProximityTo : softCriteria.CommuteTo
                        }${commute.Name}`,
                        ...commute,
                      };
                    }),
                  }
                : []),
              Commuting: [],
            },
          }),
          OpenHouses:
            prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
              .filter((openHouse) => openHouse.isActual)
              .sort(function (a, b) {
                return openHouseSorter(a, b);
              }) || [],
        }),
      ),
      totalCount: savedSearchesProperties?.data?.totalCount,
    };
  },
);

export const getSavedSearchesPropertiesList = createSelector(
  localState,
  ({ savedSearchesProperties, criteria: { filter } }) => {
    return {
      isIdle: savedSearchesProperties?.state === IDLE,
      isPending: savedSearchesProperties?.state === PENDING,
      properties: filterProperties(savedSearchesProperties?.data?.items || [], filter)?.map(
        (prop) => ({
          ...prop,
          MatchedCriterias: getMatchedCriteriasList({
            ...prop,
            MatchedCriterias: {
              ...prop?.MatchedCriterias,
              ...(prop?.MatchedCriterias?.Commutes
                ? {
                    Commutes: prop?.MatchedCriterias?.Commutes.map((commute) => {
                      const isProximity = CommutePrefs()?.find(
                        (c) => c.Name === commute.Name,
                      )?.IsImportantLocation;
                      return {
                        Feature: `${
                          isProximity ? softCriteria.ProximityTo : softCriteria.CommuteTo
                        }${commute.Name}`,
                        ...commute,
                      };
                    }),
                  }
                : []),
              Commuting: [],
            },
          }),
          OpenHouses:
            prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
              .filter((openHouse) => openHouse.isActual)
              .sort(function (a, b) {
                return openHouseSorter(a, b);
              }) || [],
        }),
      ),
      totalCount: savedSearchesProperties?.data?.totalCount,
    };
  },
);

export const getSavedSearchesPropertiesMarkersSelector = createSelector(
  localState,
  ({ savedSearchesProperties, criteria: { filter } }) =>
    filterProperties(savedSearchesProperties?.data?.items || [], filter)
      .map((property) => {
        const { Id, Geolocation, SellingPrice } = property;

        return {
          id: Id,
          price: SellingPrice,
          priceToDisplay: preparePriceForMarkup(SellingPrice),
          geometry: {
            coordinates: { lng: Geolocation?.Long, lat: Geolocation?.Lat },
          },
          property: {
            ...property,
            MatchedCriterias: getMatchedCriteriasFilteredList({
              ...property,
              MatchedCriterias: {
                ...property?.MatchedCriterias,
                ...(property?.MatchedCriterias?.Commutes
                  ? {
                      Commutes: property?.MatchedCriterias?.Commutes.map((commute) => {
                        const isProximity = CommutePrefs()?.find(
                          (c) => c.Name === commute.Name,
                        )?.IsImportantLocation;
                        return {
                          Feature: `${
                            isProximity ? softCriteria.ProximityTo : softCriteria.CommuteTo
                          }${commute.Name}`,
                          ...commute,
                        };
                      }),
                    }
                  : []),
                Commuting: [],
              },
            }),
            OpenHouses:
              property?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
                .filter((openHouse) => openHouse.isActual)
                .sort(function (a, b) {
                  return openHouseSorter(a, b);
                }) || [],
          },
        };
      })
      .reduce((markersMap, marker) => {
        const {
          geometry: {
            coordinates: { lng, lat },
          },
        } = marker;

        if (typeof lng !== 'number' || typeof lat !== 'number') {
          return markersMap;
        }

        const key = `${lng} ${lat}`;

        return markersMap[key]?.length
          ? { ...markersMap, [key]: [...markersMap[key], marker] }
          : { ...markersMap, [key]: [marker] };
      }, {}),
);

export const getSavedSearchesPropertiesMarkersDrawSelector = createSelector(
  localState,
  ({ savedSearchesPropertiesDraw: savedSearchesProperties, criteria: { filter } }) => ({
    isPending: savedSearchesProperties?.state === PENDING,
    propertiesMarkers: filterProperties(savedSearchesProperties?.data?.items || [], filter)
      .map((property) => {
        const { Id, Geolocation, SellingPrice } = property;

        return {
          id: Id,
          price: SellingPrice,
          priceToDisplay: preparePriceForMarkup(SellingPrice),
          geometry: {
            coordinates: { lng: Geolocation?.Long, lat: Geolocation?.Lat },
          },
        };
      })
      .reduce((markersMap, marker) => {
        const {
          geometry: {
            coordinates: { lng, lat },
          },
        } = marker;

        if (typeof lng !== 'number' || typeof lat !== 'number') {
          return markersMap;
        }

        const key = `${lng} ${lat}`;

        return markersMap[key]?.length
          ? { ...markersMap, [key]: [...markersMap[key], marker] }
          : { ...markersMap, [key]: [marker] };
      }, {}),
  }),
);

export const getFavoritesPropertiesList = createSelector(
  localState,
  ({ favorites, criteria: { filter } }) => {
    return {
      isIdle: favorites?.state === IDLE,
      isPending: favorites?.state === PENDING,
      properties: filterProperties(favorites?.data?.items || [], filter)?.map((prop) => ({
        ...prop,
        MatchedCriterias: getMatchedCriteriasList(prop),
        OpenHouses:
          prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
            .filter((openHouse) => openHouse.isActual)
            .sort(function (a, b) {
              return openHouseSorter(a, b);
            }) || [],
        IsFavorite: true,
      })),
      totalCount: favorites?.data?.totalCount,
    };
  },
);

export const getFavoritesPropertiesMarkersSelector = createSelector(
  localState,
  ({ favorites, criteria: { filter } }) =>
    filterProperties(favorites?.data?.items || [], filter)
      .map((property) => {
        const { Id, Geolocation, SellingPrice } = property;

        return {
          id: Id,
          price: SellingPrice,
          priceToDisplay: preparePriceForMarkup(SellingPrice),
          property: {
            ...property,
            MatchedCriterias: getMatchedCriteriasFilteredList({
              ...property,
              MatchedCriterias: {
                ...property?.MatchedCriterias,
                ...(property?.MatchedCriterias?.Commutes
                  ? {
                      Commutes: property?.MatchedCriterias?.Commutes.map((commute) => {
                        const isProximity = CommutePrefs()?.find(
                          (c) => c.Name === commute.Name,
                        )?.IsImportantLocation;
                        return {
                          Feature: `${
                            isProximity ? softCriteria.ProximityTo : softCriteria.CommuteTo
                          }${commute.Name}`,
                          ...commute,
                        };
                      }),
                    }
                  : []),
                Commuting: [],
              },
            }),
            OpenHouses:
              property?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
                .filter((openHouse) => openHouse.isActual)
                .sort(function (a, b) {
                  return openHouseSorter(a, b);
                }) || [],
          },
          geometry: {
            coordinates: { lng: Geolocation?.Long, lat: Geolocation?.Lat },
          },
        };
      })
      .reduce((markersMap, marker) => {
        const {
          geometry: {
            coordinates: { lng, lat },
          },
        } = marker;

        if (typeof lng !== 'number' || typeof lat !== 'number') {
          return markersMap;
        }

        const key = `${lng} ${lat}`;

        return markersMap[key]?.length
          ? { ...markersMap, [key]: [...markersMap[key], marker] }
          : { ...markersMap, [key]: [marker] };
      }, {}),
);

export const getFeedPageInfoSelector = createSelector(localState, (feed) => {
  return feed?.pageInfo;
});

// AGENT SIDE

export const getAgentPropertiesList = createSelector(
  localState,
  ({ agentProperties, criteria: { filter } }) => {
    return {
      isIdle: agentProperties?.state === IDLE,
      isPending: agentProperties?.state === PENDING,
      properties: filterProperties(agentProperties?.data?.items || [], filter)?.map((prop) => ({
        ...prop,
        MatchedCriterias: getMatchedCriteriasFilteredList({
          ...prop,
          MatchedCriterias: {
            ...prop?.MatchedCriterias,
            ...(prop?.MatchedCriterias?.Commutes
              ? {
                  Commutes: prop?.MatchedCriterias?.Commutes.map((commute) => {
                    const isProximity = CommutePrefs()?.find(
                      (c) => c.Name === commute.Name,
                    )?.IsImportantLocation;
                    return {
                      Feature: `${isProximity ? softCriteria.ProximityTo : softCriteria.CommuteTo}${
                        commute.Name
                      }`,
                      ...commute,
                    };
                  }),
                }
              : []),
            Commuting: [],
          },
        }),
        OpenHouses:
          prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
            .filter((openHouse) => openHouse.isActual)
            .sort(function (a, b) {
              return openHouseSorter(a, b);
            }) || [],
      })),
      totalCount: agentProperties?.data?.totalCount,
    };
  },
);

export const getAgentPropertiesMarkersDrawSelector = createSelector(
  localState,
  ({ agentPropertiesDraw: agentProperties, criteria: { filter } }) => ({
    agentPropertiesMarkers: filterProperties(agentProperties?.data?.items || [], filter)
      .map((property) => {
        const { Id, Geolocation, SellingPrice } = property;

        return {
          id: Id,
          price: SellingPrice,
          priceToDisplay: preparePriceForMarkup(SellingPrice),
          geometry: {
            coordinates: { lng: Geolocation?.Long, lat: Geolocation?.Lat },
          },
        };
      })
      .reduce((markersMap, marker) => {
        const {
          geometry: {
            coordinates: { lng, lat },
          },
        } = marker;

        if (typeof lng !== 'number' || typeof lat !== 'number') {
          return markersMap;
        }

        const key = `${lng} ${lat}`;

        return markersMap[key]?.length
          ? { ...markersMap, [key]: [...markersMap[key], marker] }
          : { ...markersMap, [key]: [marker] };
      }, {}),
    isPending: agentProperties?.state === PENDING,
  }),
);

export const getAgentPropertiesMarkersSelector = createSelector(
  localState,
  ({ agentProperties, criteria: { filter } }) =>
    filterProperties(agentProperties?.data?.items || [], filter)
      .map((property) => {
        const { Id, Geolocation, SellingPrice } = property;

        return {
          id: Id,
          price: SellingPrice,
          priceToDisplay: preparePriceForMarkup(SellingPrice),
          property: {
            ...property,
            MatchedCriterias: getMatchedCriteriasFilteredList({
              ...property,
              MatchedCriterias: {
                ...property?.MatchedCriterias,
                ...(property?.MatchedCriterias?.Commutes
                  ? {
                      Commutes: property?.MatchedCriterias?.Commutes.map((commute) => {
                        const isProximity = CommutePrefs()?.find(
                          (c) => c.Name === commute.Name,
                        )?.IsImportantLocation;
                        return {
                          Feature: `${
                            isProximity ? softCriteria.ProximityTo : softCriteria.CommuteTo
                          }${commute.Name}`,
                          ...commute,
                        };
                      }),
                    }
                  : []),
                Commuting: [],
              },
            }),
            OpenHouses:
              property?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
                .filter((openHouse) => openHouse.isActual)
                .sort(function (a, b) {
                  return openHouseSorter(a, b);
                }) || [],
          },
          geometry: {
            coordinates: { lng: Geolocation?.Long, lat: Geolocation?.Lat },
          },
        };
      })
      .reduce((markersMap, marker) => {
        const {
          geometry: {
            coordinates: { lng, lat },
          },
        } = marker;

        if (typeof lng !== 'number' || typeof lat !== 'number') {
          return markersMap;
        }

        const key = `${lng} ${lat}`;

        return markersMap[key]?.length
          ? { ...markersMap, [key]: [...markersMap[key], marker] }
          : { ...markersMap, [key]: [marker] };
      }, {}),
);

export const getMatchedCriteriasAndScoreByPropertyId = createSelector(
  localState,
  ({ savedSearchesProperties }) =>
    savedSearchesProperties?.data?.items?.reduce(
      (acc, item) => ({
        ...acc,
        [item.Id]: { MatchedCriterias: item.MatchedCriterias, MatchScore: item.MatchScore },
      }),
      {},
    ),
);
