import { handleActions } from 'redux-actions';

import {
  selectedSearchAction,
  setAgentClientModeAction,
  setCriteriaModeAction,
  setSavedSearchTabAction,
  setSelectedClientSavedSearchAction,
  setIsClientMatchesAction,
  setIsSimpleHeaderTitleVisibleAction,
} from 'store/actions/feedv3';
import { appLogoutAction } from 'store/actions/app';
import { cloneDeep } from 'lodash-es';
import { EDIT } from 'settings/constants/mode';

const hasClientId = () => {
  const url = window.location.href;
  const params = new URLSearchParams(new URL(url).search);
  return params.has('clientId');
};

const initialData = {
  savedSearchTab: 1,
  selectedSearch: null,
  isClientMatches: false,
  selectedClientSavedSearch: null,
  agentClientMode: { value: false, user: null },
  mode: EDIT,
  isSimpleHeaderTitleVisible: {
    value: hasClientId(),
    text: hasClientId() ? 'Favorites' : '',
  },
};

export default handleActions(
  {
    [setIsSimpleHeaderTitleVisibleAction as any]: (state, { payload }) => ({
      ...state,
      isSimpleHeaderTitleVisible: payload.isSimpleHeaderTitleVisible,
    }),
    [selectedSearchAction as any]: (state, { payload }) => ({
      ...state,
      selectedSearch: payload.selectedSearch,
    }),
    [setCriteriaModeAction as any]: (state, { payload }) => {
      return {
        ...state,
        mode: payload.mode,
      };
    },
    [setSavedSearchTabAction as any]: (state, { payload }) => {
      return {
        ...state,
        savedSearchTab: payload.savedSearchTab,
      };
    },
    [setSelectedClientSavedSearchAction as any]: (state, { payload }) => {
      return {
        ...state,
        selectedClientSavedSearch: payload.selectedClientSavedSearch,
      };
    },
    [setIsClientMatchesAction as any]: (state, { payload }) => {
      return {
        ...state,
        isClientMatches: payload.isClientMatches,
      };
    },
    [setAgentClientModeAction as any]: (state, { payload }) => {
      return {
        ...state,
        agentClientMode: payload.agentClientMode,
      };
    },
    [appLogoutAction as any]: () => cloneDeep(initialData),
  },
  initialData,
);
