import { useState } from 'react';
import styles from './styles.module.scss';
import WishListRow from './WishListRow';

type WishListPropsType = {
  needs: any[];
  wants: any[];
  hideFields: boolean;
  countStarredFields: number;
  starredFieldsCountHandler: Function;
};

function WishList(props: WishListPropsType) {
  const [needsStarred, setNeedsStarred] = useState<boolean>(false);
  const [wantsStarred, setWantsStarred] = useState<boolean>(false);

  return (
    <div className={styles.wishListContainer}>
      {/* Hide Title if there are no wants and needs in the table */}
      {(needsStarred || wantsStarred || !props?.hideFields) && (
        <h2 className={styles.tableHeading}>Wish List</h2>
      )}
      <table className={styles.wishListTable}>
        {props.needs && props.needs.length && (
          <WishListRow
            sectionName={'Needs'}
            hideFields={props.hideFields}
            needs={props.needs}
            wants={props.wants}
            countStarredFields={props.countStarredFields}
            starredFieldsCountHandler={props.starredFieldsCountHandler}
            setStarredWishListRow={(value: boolean) => setNeedsStarred(value)}
          />
        )}
        {props.wants && props.wants.length && (
          <WishListRow
            sectionName={'Wants'}
            hideFields={props.hideFields}
            needs={props.needs}
            wants={props.wants}
            countStarredFields={props.countStarredFields}
            starredFieldsCountHandler={props.starredFieldsCountHandler}
            setStarredWishListRow={(value: boolean) => setWantsStarred(value)}
          />
        )}
      </table>
    </div>
  );
}

export default WishList;
