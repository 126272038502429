import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { dropConfetti } from 'utils';

import { routes } from 'settings/navigation/routes';
import { PENDING } from 'settings/constants/apiState';
import { AGENT, THIRD_PARTY, CLIENT } from 'settings/constants/roles';

import { dispatch } from 'store';
import { getUserDataSelector } from 'store/selectors/user';
import { getClientInstancesSelector } from 'store/selectors/clientInstances';
import { setMlsAccessModalAction } from 'store/actions/mlsAccess';
import { getSignUpRoleSelector } from 'store/selectors/loginGroup';

import { Wrapper, Button } from 'components';
import { getOnBoardingData } from 'pages/OnBoarding/selectors';
import { Question } from 'pages/RequestQuote/components';
import { ButtonsContainer } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/ButtonsContainer';
import { OnboardingContinueButton } from '../agent/components/OnboardingContinueButton';
import { OnboardingSkipButton } from '../agent/components/OnboardingSkipButton';
import PartyImage from 'images/party-popper.png';

import styles from './styles.module.scss';
import ArrowRightFull from 'components/Icons/ArrowRightFull';

const AccountCompleteScreen = (props) => {
  const { className, onNext, Controls } = props;
  const user = useSelector(getUserDataSelector);
  const { onBoarding } = useSelector(getOnBoardingData);
  const signUpRole = useSelector(getSignUpRoleSelector);
  const { searchInstances, transactionInstances, isLoading } = useSelector(
    getClientInstancesSelector,
  );
  const history = useHistory();

  const isPending = onBoarding.state === PENDING;
  let buyer = false;

  if ([AGENT, CLIENT, THIRD_PARTY].includes(signUpRole)) {
    const hasSearchInstance = user.Client?.SearchInstances?.[0];
    if (hasSearchInstance) {
      buyer = true;
    }
  }

  useEffect(() => {
    dropConfetti();
  }, []);

  const handleGoToTheAppClick = () => {
    const isRetentionClient =
      signUpRole === CLIENT && !(searchInstances?.[0] || transactionInstances?.[0]);
    if (signUpRole === AGENT && !user?.Agent?.SelectedMlsServices?.length) {
      dispatch(
        setMlsAccessModalAction({
          isModalOpened: true,
          showOnboardingText: true,
        }),
      );
    }
    if (isRetentionClient) {
      history.push(routes.clientDashboard);
    } else {
      history.push(routes.feed);
    }
  };

  const [count, setCount] = useState(5);

  useEffect(() => {
    if (count > 0) {
      const timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      handleGoToTheAppClick();
    }
  }, [count]);

  return (
    <Wrapper isPending={isPending} className={classNames(styles.stageWrap, className)}>
      <Controls
        onNext={() => {
          if (buyer) {
            onNext();
          } else {
            handleGoToTheAppClick();
          }
        }}
        isNextDisabled={false}
        className={styles.controls}
        variant="lightFull"
      />

      <div className={styles.main}>
        <div className={styles.celebrate}>
          <img src={PartyImage} />
        </div>
        <Question className={classNames(styles.title, styles.celebrateTitle)}>
          {buyer ? 'You’re all set!' : 'Awesome! You’re all set.'}
        </Question>
        {/* {buyer && (
          <div className={styles.subtitleContainer}>
            <p className={styles.subtitle}>
              You can check out the homes that meet your criteria anytime in your Feed (or create
              new searches anytime on the Search page).
            </p>
          </div>
        )} */}
        <ButtonsContainer>
          <OnboardingContinueButton
            testid="feed"
            type="button"
            onClick={handleGoToTheAppClick}
            title={buyer ? 'Take me to see properties' : 'Take Me To The App'}
            isPending={isPending}
            hideArrow={false}
          />
          <p className={styles.redirect}>Redirecting in {count} seconds..</p>
          {/* {buyer && (
            <OnboardingSkipButton type="button" title="Pre-Approval" isPending={isPending} />
          )} */}
        </ButtonsContainer>
      </div>
    </Wrapper>
  );
};

AccountCompleteScreen.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  stageIndex: PropTypes.number,
  Controls: PropTypes.elementType,
};

AccountCompleteScreen.defaultProps = {
  className: '',
  onNext: () => {},
  stageIndex: undefined,
  Controls: () => null,
};

export default AccountCompleteScreen;
