import { BUNDLE_FILES_LIMIT, SINGLE_FILE_LIMIT } from '../../../helper';
import { Icons } from '../Icons';
import styles from './style.module.scss';

export const FileSizeInformation = () => {
  return (
    <div className={styles.fileSizeInformation}>
      <Icons variant={'info'} />
      Maximum upload file size: <strong>{SINGLE_FILE_LIMIT} MB</strong> for individual files and{' '}
      <strong>{BUNDLE_FILES_LIMIT} MB</strong> for bundles
    </div>
  );
};
