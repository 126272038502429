import { apiServer } from 'settings/web-services/api';
import { GET_ACTIVITY_LOGS, GET_ACTIVITY_LOG_BY_APPOINTMENT_ID } from 'app-constants/endpoints';
import { omit } from 'lodash';
import { CancelToken } from 'axios';

export const getActivityLogByAppointmentId = (cfg) =>
  apiServer.post(GET_ACTIVITY_LOG_BY_APPOINTMENT_ID, cfg);

export const getActivityLogs = (
  cfg,
  queryParams = '',
  cancelToken: CancelToken | undefined = undefined,
) => {
  return apiServer.post(GET_ACTIVITY_LOGS(queryParams), cfg, { cancelToken });
};

export const getWorkshopActivityLogs = (cfg) => {
  const body = omit(cfg, ['queryParams']);
  return apiServer.post('/activities' + cfg?.queryParams, body);
};
