import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Input, Modal } from 'components';
import { LocationService } from 'services';
import { appOpenModalEffect } from 'store/effects';
import { getModalSelector } from 'store/selectors/app';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import { schema } from './validation';
import {
  requestAddValuationHomeValueEffect,
  requestAddValuationRentalValueEffect,
} from 'store/effects/intel';
import { useState } from 'react';
import moment from 'moment';

const locationSrv = new LocationService();

const ValuesModal = ({ rental, params }) => {
  locationSrv.setLocation(location);
  const modal = useSelector(getModalSelector);
  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();
  const label = rental ? 'Add Rental Value' : 'Add Home Value';

  const onClose = () => {
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  const onCallback = () => {
    setIsPending(false);
    onClose();
  };

  const formik = useFormik({
    initialValues: { value: undefined, month: undefined, year: undefined, notes: '' },
    validateOnMount: true,
    validationSchema: schema,
    onSubmit: ({ value, month, year, notes }) => {
      setIsPending(true);
      const cfg = {
        body: { value: Number(value), month, year, notes },
        params: new URLSearchParams(params).toString(),
      };
      if (rental) {
        dispatch(requestAddValuationRentalValueEffect(cfg, {}, onCallback));
      } else {
        dispatch(requestAddValuationHomeValueEffect(cfg, {}, onCallback));
      }
    },
  });

  const { values, isValid, setFieldValue, handleSubmit, handleChange, handleBlur } = formik;

  if (modal?.id !== (rental ? 'valuesModalRental' : 'valuesModalHome')) return null;

  return (
    <Modal
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      innerHolderClassName={styles.innerHolder}
    >
      <div className={styles.drawerInner}>
        <div className={styles.header}>
          <p className={styles.line1}>{label}</p>
          <p className={styles.line2}>{params?.address1}</p>
          <form className={styles.content} onSubmit={handleSubmit}>
            <Input
              label={'Value'}
              variant={Input.LIGHT_ROUND}
              name="value"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.value}
              placeholder="$0"
              isNumberFormat
              prefix={'$'}
            />
            <DatePicker
              onChange={(e) => {
                const date = new Date(e.target.value[0]);
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                setFieldValue('month', month);
                setFieldValue('year', year);
              }}
              placeholder="MM/YY"
              variant={DatePicker.ROUNDED}
              label="Month"
              className={styles.datePicker}
              options={{
                enableTime: false,
                dateFormat: 'Y-m',
                mode: 'single',
                monthSelectorType: 'static',
              }}
              monthOnly={true}
              closeOnOutsideClick={true}
            />

            <Input
              variant={Input.LIGHT_ROUND}
              name="notes"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.notes}
              label="Notes"
              placeholder="Add note..."
              textArea={true}
            />
            <Button
              title="Done"
              type="submit"
              className={styles.button}
              disabled={!isValid}
              isPending={isPending}
            />
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ValuesModal;
