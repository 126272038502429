import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Separator } from './..';
import { Row, Col } from 'components-antd';
import styles from './styles.module.scss';
import { CommissionUnit } from './components';
import { LedgerTransactionSideId } from 'settings/constants/ledger';
import { getCommissionSide, getLedgerId, isFeeTypePercentage } from '../../helpers';
import { getKeyDatesSelector } from 'store/selectors/transaction';

const HeaderText = ({ text }) => <span className={styles.headerText}>{text}</span>;

export const Commission = ({
  fetchFinancials,
  isDualTransaction,
  isBuyerTransaction,
  isSellerTransaction,
  ledgers,
  ledgerLineItems,
  transactionPrice,
  transactionDate,
}) => {
  const [editFieldKey, setEditFieldKey] = useState(null);
  const keyDates = useSelector(getKeyDatesSelector);

  const isDisableField = (editKey) => editFieldKey !== null && editFieldKey !== editKey;

  const buySideLedgerId = getLedgerId(ledgers, LedgerTransactionSideId.Purchase);
  const listSideLedgerId = getLedgerId(ledgers, LedgerTransactionSideId.Listing);

  let _buyerSideComission = getCommissionSide(ledgerLineItems, buySideLedgerId);
  let _sellerSideComission = getCommissionSide(ledgerLineItems, listSideLedgerId);

  const calculateGCI = (rate, comparePrice = null) => {
    return (rate / 100) * (comparePrice || transactionPrice);
  };

  const calculateRate = (GCI, comparePrice) => {
    return (GCI * 100) / (comparePrice || transactionPrice);
  };

  const getTotal = (buyerValue, sellerValue) => {
    if (buyerValue === null && sellerValue === null) {
      return null;
    } else if (buyerValue === null && sellerValue !== null) {
      return sellerValue;
    } else if (buyerValue !== null && sellerValue === null) {
      return buyerValue;
    } else {
      return sellerValue + buyerValue;
    }
  };

  let _rateBuyer = _buyerSideComission?.Percent || null;
  let _gciBuyer = _buyerSideComission?.Amount || null;
  let _rateSeller = _sellerSideComission?.Percent || null;
  let _gciSeller = _sellerSideComission?.Amount || null;
  let _gciTotal = getTotal(_gciBuyer, _gciSeller);

  return (
    <Card>
      <div className={styles.commissionContainer}>
        <p className={styles.title}>Commission</p>
        <Row gutter={8}>
          <Col lg={14}>
            <HeaderText text="Commission" />
          </Col>
          <Col lg={4}>
            <HeaderText text="Rate" />
          </Col>
          <Col lg={6}>
            <HeaderText text="GCI" />
          </Col>
        </Row>
        <Separator className={styles.seperatorMargin} />
        {(isSellerTransaction || isDualTransaction) && (
          <>
            <CommissionUnit
              label="List Side"
              rate={_rateSeller}
              gci={_gciSeller}
              gciOther={_gciBuyer}
              fieldKey="listSide"
              transactionPrice={transactionPrice}
              transactionDate={transactionDate}
              setEditField={setEditFieldKey}
              isDisableField={isDisableField}
              calculateGCI={calculateGCI}
              calculateRate={calculateRate}
              fetchFinancials={fetchFinancials}
              isFeeTypePercentage={isFeeTypePercentage(_sellerSideComission)}
              PercentBasis={_sellerSideComission?.PercentBasis}
              LedgerId={_sellerSideComission?.LedgerId || null}
              LedgerLineItemId={_sellerSideComission?.Id || null}
              isForecast={!keyDates?.ClosePrice}
              isBuyerTransaction={isBuyerTransaction}
              isSellerTransaction={isSellerTransaction}
            />
            <Separator />
          </>
        )}
        <CommissionUnit
          label="Buy Side"
          rate={_rateBuyer}
          gci={_gciBuyer}
          gciOther={_gciSeller}
          transactionPrice={transactionPrice}
          transactionDate={transactionDate}
          fieldKey="buySide"
          setEditField={setEditFieldKey}
          isDisableField={isDisableField}
          calculateGCI={calculateGCI}
          calculateRate={calculateRate}
          fetchFinancials={fetchFinancials}
          isFeeTypePercentage={isFeeTypePercentage(_buyerSideComission)}
          PercentBasis={_buyerSideComission?.PercentBasis}
          LedgerId={_buyerSideComission?.LedgerId || null}
          LedgerLineItemId={_buyerSideComission?.Id || null}
          isForecast={!keyDates?.ClosePrice}
          isBuyerTransaction={isBuyerTransaction}
          isSellerTransaction={isSellerTransaction}
        />
        <Separator />
        {(isSellerTransaction || isDualTransaction) && (
          <CommissionUnit
            label="Total"
            rate={getTotal(_rateBuyer, _rateSeller)}
            gci={_gciTotal}
            notEditable
            isDisableField={isDisableField}
            hidePriceHighlighter
          />
        )}
      </div>
    </Card>
  );
};
