import { TableProps } from 'antd';
import moment from 'moment';

import styles from '../styles.module.scss';

export enum modalTableColumns {
  TeamGrossNet = 'Team Gross/Net',
  AgentGrossNet = 'Agent Gross/Net',
  CustomSplits = 'Custom Splits',
  Fees = 'Fees',
}
interface TeamGrossNetDataType {
  Date: string;
  Transaction: string;
  TotalValue: number;
  RepresentingSide: string;
  PropertyTransactionId: number;
  Agent: string;
}

interface AgentGrossNetDataType {
  Date: string;
  Transaction: string;
  TotalValue: number;
  PropertyTransactionId: number;
  Agent: string;
  RepresentingSide: string;
}

export interface FeesDataType {
  Date: string;
  Transaction: string;
  DeductFrom: string;
  ApplyTo: string;
  TotalValue: number;
  PropertyTransactionId: number;
  Agent: string;
  RepresentingSide: string;
}

export interface CommissiontDataType {
  Date: string;
  Transaction: string;
  TotalAverage: number;
  RepresentingSide: string;
  PropertyTransactionId: number;
  Agent: string;
}

export const getModalTableColumns = (type, isCommission = false) => {
  const TeamGrossColumns: TableProps<TeamGrossNetDataType>['columns'] = [
    {
      title: 'Closing Date',
      dataIndex: 'Date',
      key: 'Date',
      sorter: {
        compare: (record1, record2) => {
          const a = moment(record1.Date);
          const b = moment(record2.Date);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value) {
        return <span className={styles.dateColumn}>{value}</span>;
      },
    },
    {
      title: 'Transaction',
      dataIndex: 'Transaction',
      key: 'Transaction',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Transaction;
          const b = record2.Transaction;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value, record) {
        return (
          <a
            target="_blank"
            className={styles.linkToTransaction}
            href={`/workshop/transactions/${record.PropertyTransactionId}/financials`}
            rel="noreferrer"
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'TotalValue',
      key: 'TotalValue',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.TotalValue;
          const b = record2.TotalValue;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (TotalValue) => {
        return TotalValue > 0
          ? `$${TotalValue.toLocaleString()}`
          : `-$${Math.abs(TotalValue).toLocaleString()}`;
      },
    },
    {
      title: 'Representing',
      dataIndex: 'RepresentingSide',
      key: 'RepresentingSide',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.RepresentingSide;
          const b = record2.RepresentingSide;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Agent',
      dataIndex: 'Agent',
      key: 'Agent',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Agent;
          const b = record2.Agent;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (Agent) => {
        return Agent.join(', ');
      },
    },
  ];

  const AgentGrossNetColumns: TableProps<AgentGrossNetDataType>['columns'] = [
    {
      title: 'Closing Date',
      dataIndex: 'Date',
      key: 'Date',
      sorter: {
        compare: (record1, record2) => {
          const a = moment(record1.Date);
          const b = moment(record2.Date);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value) {
        return <span className={styles.dateColumn}>{value}</span>;
      },
    },
    {
      title: 'Transaction',
      dataIndex: 'Transaction',
      key: 'Transaction',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Transaction;
          const b = record2.Transaction;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value, record) {
        return (
          <a
            target="_blank"
            className={styles.linkToTransaction}
            href={`/workshop/transactions/${record.PropertyTransactionId}/financials`}
            rel="noreferrer"
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'TotalValue',
      key: 'TotalValue',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.TotalValue;
          const b = record2.TotalValue;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (TotalValue) => {
        return TotalValue > 0
          ? `$${TotalValue.toLocaleString()}`
          : `-$${Math.abs(TotalValue).toLocaleString()}`;
      },
    },
    {
      title: 'Representing',
      dataIndex: 'RepresentingSide',
      key: 'RepresentingSide',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.RepresentingSide;
          const b = record2.RepresentingSide;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Agent',
      dataIndex: 'Agent',
      key: 'Agent',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Agent;
          const b = record2.Agent;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (Agent) => {
        return Agent.join(', ');
      },
    },
  ];

  const FeesColumns: TableProps<FeesDataType>['columns'] = [
    {
      title: 'Closing Date',
      dataIndex: 'Date',
      key: 'Date',
      sorter: {
        compare: (record1, record2) => {
          const a = moment(record1.Date);
          const b = moment(record2.Date);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value) {
        return <span className={styles.dateColumn}>{value}</span>;
      },
    },
    {
      title: 'Transaction',
      dataIndex: 'Transaction',
      key: 'Transaction',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Transaction;
          const b = record2.Transaction;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value, record) {
        return (
          <a
            target="_blank"
            className={styles.linkToTransaction}
            href={`/workshop/transactions/${record.PropertyTransactionId}/financials`}
            rel="noreferrer"
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Deduct From',
      dataIndex: 'DeductFrom',
      key: 'DeductFrom',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.DeductFrom;
          const b = record2.DeductFrom;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Apply To',
      dataIndex: 'ApplyTo',
      key: 'ApplyTo',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.ApplyTo;
          const b = record2.ApplyTo;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Amount',
      dataIndex: 'TotalValue',
      key: 'TotalValue',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.TotalValue;
          const b = record2.TotalValue;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (TotalValue) => {
        return TotalValue > 0
          ? `$${TotalValue.toLocaleString()}`
          : `-$${Math.abs(TotalValue).toLocaleString()}`;
      },
    },
    {
      title: 'Representing',
      dataIndex: 'RepresentingSide',
      key: 'RepresentingSide',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.RepresentingSide;
          const b = record2.RepresentingSide;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Agent',
      dataIndex: 'Agent',
      key: 'Agent',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Agent;
          const b = record2.Agent;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (Agent) => {
        return Agent.join(', ');
      },
    },
  ];

  const CommissionColumns: TableProps<CommissiontDataType>['columns'] = [
    {
      title: 'Closing Date',
      dataIndex: 'Date',
      key: 'Date',
      sorter: {
        compare: (record1, record2) => {
          const a = moment(record1.Date);
          const b = moment(record2.Date);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value) {
        return <span className={styles.dateColumn}>{value}</span>;
      },
    },
    {
      title: 'Transaction',
      dataIndex: 'Transaction',
      key: 'Transaction',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Transaction;
          const b = record2.Transaction;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value, record) {
        return (
          <a
            target="_blank"
            className={styles.linkToTransaction}
            href={`/workshop/transactions/${record.PropertyTransactionId}/financials`}
            rel="noreferrer"
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Rate',
      dataIndex: 'TotalAverage',
      key: 'TotalAverage',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.TotalAverage;
          const b = record2.TotalAverage;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (TotalAverage) => {
        return `${TotalAverage.toLocaleString()}%`;
      },
    },
    {
      title: 'Representing',
      dataIndex: 'RepresentingSide',
      key: 'RepresentingSide',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.RepresentingSide;
          const b = record2.RepresentingSide;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Agent',
      dataIndex: 'Agent',
      key: 'Agent',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Agent;
          const b = record2.Agent;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (Agent) => {
        return Agent.join(', ');
      },
    },
  ];

  if (isCommission) return CommissionColumns;
  switch (type) {
    case modalTableColumns.TeamGrossNet:
      return TeamGrossColumns;
    case modalTableColumns.AgentGrossNet:
      return AgentGrossNetColumns;
    case modalTableColumns.CustomSplits:
      return AgentGrossNetColumns;
    case modalTableColumns.Fees:
      return FeesColumns;
    default:
      return TeamGrossColumns;
  }
};
