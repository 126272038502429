import { createAction } from 'redux-actions';

export const requestGetTeamListAction = createAction('REQUEST/GET_TEAM_LIST');
export const requestGetTransactionTeamListAction = createAction(
  'REQUEST/GET_TRANSACTION_TEAM_LIST',
);
export const requestGetTeamPermittedListAction = createAction('REQUEST/GET_TEAM_PERMITTED_LIST');
export const requestGetTeamTitlesAction = createAction('REQUEST/GET_TEAM_TITLES');
export const requestPostAssignClientsToMemberAction = createAction(
  'REQUEST/POST_ASSIGN_CLIENT_TO_MEMBER',
);
export const requestPostConfigureAdminAccessAction = createAction(
  'REQUEST/POST_CONFIGURE_ADMIN_ACCESS',
);
export const requestGetAllTeamsAction = createAction('REQUEST/GET_ALL_TEAMS');
export const requestTeamTransactionPermissionsAction = createAction(
  'REQUEST/GET_TEAM_TRANSACTION_PERMISSIONS',
);
export const requestUpdateTransactionPermissions = createAction(
  'REQUEST/PATCH_CONFIGURE_TEAM_TRANSACTION_PERMISSIONS',
);
