import { useSelector } from 'react-redux';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { AgentView } from './AgentView';
import { TEAM_BASIC } from 'settings/constants/roles';
import { OwnerAdminView } from './OwnerAdminView';

export const Financials = ({
  grossSummary,
  grossData,
  netSummary,
  netData,
  feesSummary,
  feesData,
  grossListSummary,
  grossListData,
  grossBuySummary,
  grossBuyData,
  isPending,
}) => {
  const agentRole = useSelector(getAgentTeamRoleSelector);

  return (
    <>
      {agentRole === TEAM_BASIC ? (
        <AgentView
          grossSummary={grossSummary}
          grossData={grossData}
          netSummary={netSummary}
          netData={netData}
          feesSummary={feesSummary}
          feesData={feesData}
          isPending={isPending}
        />
      ) : (
        <OwnerAdminView
          grossSummary={grossSummary}
          grossData={grossData}
          netSummary={netSummary}
          netData={netData}
          feesSummary={feesSummary}
          feesData={feesData}
          grossListSummary={grossListSummary}
          grossListData={grossListData}
          grossBuySummary={grossBuySummary}
          grossBuyData={grossBuyData}
          isPending={isPending}
        />
      )}
    </>
  );
};
