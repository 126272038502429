import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { requestGetSavedSearchesPropertiesDrawAction } from 'store/actions/feedv3';
import { appLogoutAction } from 'store/actions/app';

const initialData = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [requestGetSavedSearchesPropertiesDrawAction as any]: (state, { payload }) => {
      return {
        ...state,
        state: payload.state,
        data: get(payload, 'data', initialData.data),
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [appLogoutAction as any]: () => initialData,
  },
  initialData,
);
