import * as Yup from 'yup';

export const schema = Yup.object()
  .shape({
    bedsMin: Yup.number().min(0).nullable(),
    bedsMax: Yup.number().min(0).nullable(),
    bathsMin: Yup.number().min(0).nullable(),
    bathsMax: Yup.number().min(0).nullable(),
    squareFeetMin: Yup.number().min(0).nullable(),
    squareFeetMax: Yup.number().min(0).nullable(),
    priceMin: Yup.number().min(0).nullable(),
    priceMax: Yup.number().min(0).nullable(),
    hoaMin: Yup.number().min(0).nullable(),
    hoaMax: Yup.number().min(0).nullable(),
  })
  .test('is-valid-range-beds', 'Invalid range for beds', function (value) {
    const { bedsMin: Min, bedsMax: Max } = value;
    if ((Min || Min === 0) && (Max || Max === 0) ? (Min < Max && Max > Min) || Min === Max : true) {
      return true;
    } else {
      return this.createError({
        path: 'bedsMax',
        message: 'Invalid range for beds',
      });
    }
  })
  .test('is-valid-range-baths', 'Invalid range for baths', function (value) {
    const { bathsMin: Min, bathsMax: Max } = value;
    if ((Min || Min === 0) && (Max || Max === 0) ? (Min < Max && Max > Min) || Min === Max : true) {
      return true;
    } else {
      return this.createError({
        path: 'bathsMax',
        message: 'Invalid range for baths',
      });
    }
  })
  .test('is-valid-range-squareFeet', 'Invalid range for square feet', function (value) {
    const { squareFeetMin: Min, squareFeetMax: Max } = value;
    if ((Min || Min === 0) && (Max || Max === 0) ? (Min < Max && Max > Min) || Min === Max : true) {
      return true;
    } else {
      return this.createError({
        path: 'squareFeetMax',
        message: 'Invalid range for square feet',
      });
    }
  })
  .test('is-valid-range-price', 'Invalid range for price', function (value) {
    const { priceMin: Min, priceMax: Max } = value;
    if ((Min || Min === 0) && (Max || Max === 0) ? (Min < Max && Max > Min) || Min === Max : true) {
      return true;
    } else {
      return this.createError({
        path: 'priceMax',
        message: 'Invalid range for price',
      });
    }
  })
  .test('is-valid-range-hoa', 'Invalid range for HOA', function (value) {
    const { hoaMin: Min, hoaMax: Max } = value;
    if ((Min || Min === 0) && (Max || Max === 0) ? (Min < Max && Max > Min) || Min === Max : true) {
      return true;
    } else {
      return this.createError({
        path: 'hoaMax',
        message: 'Invalid range for HOA',
      });
    }
  });
