import { isSafari } from '../helper';
import styles from './style.module.scss';

export const CheckboxField = (props) => {
  const { itemID, onClick, fieldProps, inputFieldProps, fieldStyle } = props;

  const { onChange } = inputFieldProps;

  const { value, defaultChecked } = fieldProps;

  if (isSafari()) {
    return (
      <label className={styles['custom-checkbox']} onClick={onClick} style={fieldStyle}>
        <input
          type="checkbox"
          className={styles['hidden-checkbox']}
          value={value}
          onChange={onChange}
          defaultChecked={defaultChecked}
        />

        <span
          className={styles['styled-checkbox']}
          style={{ '--tick-width': `${fieldStyle.width * 0.15}px` } as any}
          itemID={itemID}
        ></span>
      </label>
    );
  }

  return <input onClick={onClick} itemID={itemID} {...fieldProps} />;
};
