import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  getAggregatedDate,
  getDateOnly,
  getDateForDatePicker,
  getSimpleDate,
  isAfterToday,
  isBeforeToday,
} from 'helpers';
import { milestoneStatus } from 'settings/constants/milestones';
import { DatePicker, Input, LimitedLinesText } from 'components';
import ItemWrapper from '../ItemWrapper';
import Point from '../Point';
import { Lock } from 'components/Icons';
import Options from 'components/Transactions/EditTimeline/Options';

import styles from './styles.module.scss';
import DueDate from 'pages/Workshop/Transactions/TransactionOverview/components/Timeline/DueDate';
import { useState } from 'react';
import EditDueDate from 'pages/Workshop/Transactions/TransactionOverview/components/Timeline/EditDueDate';
import { TransactionMileStoneTitle } from 'app-constants/enums/transactionMilestoneTitles';

const View = (props) => {
  const { className, listItems, addMode, editMode, startDate, endDate, isAggregated } = props;
  const { onFieldsChange, onRemove } = props;

  const [dueDate, setDueDate] = useState('');
  const [controlOperatorOffset, setControlOperatorOffset] = useState('');
  const lengthItems = startDate ? listItems?.length + 1 : listItems?.length;

  const isMilestoneOverdue = (milestone) =>
    !milestone?.ViewOnly &&
    milestone?.Status === milestoneStatus.INCOMPLETE &&
    isBeforeToday(milestone?.DueDate) &&
    !editMode &&
    !milestone?.IsKeyDate;

  return (
    <div className={classNames(styles.view, className)}>
      <div
        testid="timeline_dates"
        className={classNames(styles.deadlines, {
          [styles.editModeWidth]: editMode,
          [styles.addModeWidth]: addMode,
        })}
      >
        {(listItems || []).map((milestone, index) => (
          <ItemWrapper
            key={milestone?.Id || `${milestone?.Id}-${index}`}
            deadline={milestone?.DueDate}
            listItemsLength={lengthItems}
            timelineItemClassName={classNames(styles.timelineItem, styles.left, {
              [styles.overdue]: isMilestoneOverdue(milestone),
            })}
            activeClassName={classNames({ [styles.active]: !editMode && !milestone?.ViewOnly })}
          >
            <div testid="date" className={styles.deadline}>
              {editMode && (!milestone?.ViewOnly || milestone?.IsTransactionDate) ? (
                <EditDueDate
                  milestone={milestone}
                  setDueDate={setDueDate}
                  setControlOperatorOffset={setControlOperatorOffset}
                  onFieldsChange={onFieldsChange}
                  index={index}
                />
              ) : isAggregated ? (
                getDateOnly(milestone?.DueDate, 'M/DD')
              ) : (
                getDateOnly(milestone?.DueDate)
              )}
            </div>
          </ItemWrapper>
        ))}
      </div>
      <div testid="timeline_points" className={styles.timelinePointsWrapper}>
        {(listItems || []).map((milestone, index) => (
          <ItemWrapper
            key={milestone?.Id || `${milestone?.Id}-${index}`}
            deadline={milestone?.DueDate}
            listItemsLength={lengthItems}
            timelineItemClassName={classNames(styles.timelineItem, {
              [styles.overdue]: isMilestoneOverdue(milestone),
            })}
            activeClassName={classNames({ [styles.active]: !editMode && !milestone?.ViewOnly })}
          >
            <div testid="point" className={classNames(styles.point, className)}>
              <div className={classNames(styles.innerWrapper)}>
                {(milestone?.ViewOnly || milestone?.IsKeyDate) && !milestone?.IsEditable ? (
                  <div className={styles.startPoint} />
                ) : (
                  <Point
                    key={milestone?.Id || `${milestone?.Id}-${index}`}
                    milestoneId={milestone?.Id}
                    status={milestone?.Status}
                    isAggregated={isAggregated}
                    isCreator={milestone?.isTransactionCreator}
                    transactionId={milestone?.TransactionId}
                    editMode={editMode}
                  />
                )}
              </div>
              <div
                className={classNames(
                  styles.line,
                  { [styles.hidden]: !listItems?.length },
                  { [styles.addMode]: addMode && !isAggregated },
                  { [styles.startDate]: index === 0 },
                  { [styles.aggregatedMode]: index === 0 && isAggregated },
                )}
              />
            </div>
          </ItemWrapper>
        ))}
      </div>
      <div testid="timeline_names" className={styles.milestoneNames}>
        {(listItems || []).map((milestone, index) => (
          <ItemWrapper
            key={milestone?.Id || `${milestone?.Id}-${index}`}
            deadline={milestone?.DueDate}
            listItemsLength={lengthItems}
            timelineItemClassName={classNames(styles.timelineItem, styles.right, {
              [styles.overdue]: isMilestoneOverdue(milestone),
            })}
            activeClassName={classNames({ [styles.active]: !editMode && !milestone?.ViewOnly })}
          >
            {milestone?.ViewOnly || milestone?.IsKeyDate ? (
              <div
                className={classNames(styles.name, {
                  [styles.isAfterToday]: milestone?.IsTransactionDate || milestone?.IsKeyDate,
                })}
              >
                {milestone?.Title}
              </div>
            ) : (
              <div
                className={classNames(styles.name, {
                  [styles.isAfterToday]:
                    (isAfterToday(milestone?.DueDate) || isBeforeToday(milestone?.DueDate)) &&
                    milestone?.Status === milestoneStatus.INCOMPLETE,
                })}
              >
                {editMode ? (
                  <Input
                    variant={Input.LIGHT}
                    placeholder="Name"
                    onChange={(e, val) =>
                      onFieldsChange(
                        {
                          Title: val,
                        },
                        index,
                      )
                    }
                    value={milestone?.Title}
                    className={styles.milestoneName}
                    iconClassName={styles.iconClassName}
                    testid="title_input"
                    maxLength={255}
                    icon={
                      milestone?.IsPrivate ? (
                        <Lock
                          className={classNames(styles.private, {
                            [styles.isAfterToday]:
                              (isAfterToday(milestone?.DueDate) ||
                                isBeforeToday(milestone?.DueDate)) &&
                              milestone?.Status === milestoneStatus.INCOMPLETE,
                          })}
                        />
                      ) : null
                    }
                  />
                ) : (
                  <>
                    <LimitedLinesText text={milestone?.Title} className={styles.twoLines} />
                    {isAggregated ? (
                      <div testid="title">{milestone?.Property?.Address?.PlaceName}</div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            )}

            {!editMode && milestone?.IsPrivate ? (
              <Lock
                className={classNames(styles.private, {
                  [styles.isAfterToday]:
                    (isAfterToday(milestone?.DueDate) || isBeforeToday(milestone?.DueDate)) &&
                    milestone?.Status === milestoneStatus.INCOMPLETE,
                })}
              />
            ) : null}
          </ItemWrapper>
        ))}
      </div>
      {editMode && (
        <div testid="timeline_removes" className={classNames(styles.removePart)}>
          {(listItems || []).map((milestone, index) => (
            <ItemWrapper
              key={milestone?.Id || `${milestone?.Id}-${index}`}
              deadline={milestone?.DueDate}
              listItemsLength={lengthItems}
              timelineItemClassName={classNames(styles.timelineItem, styles.right)}
            >
              {(milestone?.ViewOnly || milestone?.IsKeyDate) &&
              milestone?.Title !== TransactionMileStoneTitle.ListingExpire ? (
                <>&nbsp;</>
              ) : (
                <div>
                  <Options
                    index={index}
                    onPrivate={() => onFieldsChange({ IsPrivate: !milestone?.IsPrivate }, index)}
                    onDelete={() => onRemove(index)}
                    allowFirstDelete
                    isPrivate={milestone?.IsPrivate}
                    isKeyDate={milestone?.IsKeyDate}
                  />
                </div>
              )}
            </ItemWrapper>
          ))}
        </div>
      )}
    </div>
  );
};

View.propTypes = {
  className: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.shape({})),
  addMode: PropTypes.bool,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  editMode: PropTypes.bool,
  onFieldsChange: PropTypes.func,
  onRemove: PropTypes.func,
};

View.defaultProps = {
  className: '',
  listItems: [],
  addMode: false,
  startDate: null,
  endDate: null,
  editMode: false,
  onFieldsChange: () => {},
  onRemove: () => {},
};

export default View;
