import { useSelector } from 'react-redux';
import {
  getTimePeriodSelector,
  getTimePeriodDueFromSelector,
  getTimePeriodDueToSelector,
} from 'store/selectors/clarity';

export interface DataType {
  key: number;
  timePeriod: string;
  value: string;
  trend: string;
  index: number | string;
  numberTransactions: number;
  year: number;
  month?: number;
  isoWeek?: number;
  date?: string;
}

export interface LeadSourcesDataType {
  key: number;
  timePeriod: string;
  value: string;
  trend: string;
  index: number | string;
  listings: number;
  purchases: number;
  dual: number;
  year: number;
  month?: number;
  isoWeek?: number;
  date?: string;
}

export enum ClarityTimePeriod {
  YTD = 'Month',
  QTD = 'Week',
  MTD = 'Date',
}

export const generateCSVFileName = (chartName) => {
  const title = chartName.replace(' ', '_');
  const currentTimePeriod = useSelector(getTimePeriodSelector);
  const dueFrom = useSelector(getTimePeriodDueFromSelector);
  const dueTo = useSelector(getTimePeriodDueToSelector);
  if (dueFrom) {
    return `${title}_${dueFrom.format('MM_DD_YYYY')}__${dueTo.format('MM_DD_YYYY')}`;
  } else {
    return `${title}_${currentTimePeriod}`;
  }
};
