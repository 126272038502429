import { apiServer } from 'settings/web-services/api';

export function getAgentDashboardGraphs(cfg) {
  return apiServer.get(`/dashboard/agentDashboardGraphs`, { params: cfg });
}

export function getNeedsAttention(cfg) {
  return apiServer.get(`/dashboard/needsAttention`, { params: cfg });
}

export function getScheduleWidgetStats(cfg) {
  return apiServer.get(`/dashboard/scheduleWidget`, { params: cfg });
}
