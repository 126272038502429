import { Fragment, useState, useRef } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import Icons from 'pages/Workshop/Transactions/TransactionOverview/Icons';
import { Avatar, TransactionInviteModal } from 'components';
import { LoadingOutlined } from '@ant-design/icons';
import { ConfirmationModal } from 'components-antd';
import { Spin } from 'antd';

import { convertNameToAvatarPlaceholder, splitName } from 'helpers';
import { TransactionUserRoleMap } from 'settings/constants/transaction';
import { useOutsideClick } from 'hooks';

import styles from './styles.module.scss';
import { Button } from './../../Button';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTransactionContactEffect,
  getTransactionContactsEffect,
  inviteTransactionParticipantEffect,
  updateTransactionContactEffect,
} from 'store/effects';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { requestGetUserParticipantsListEffect } from 'store/effects/drawers/addParticipants';

export const Contact = ({ contact, openEditModal }) => {
  const params: { id?: string } = useParams();
  const dispatch = useDispatch();
  const [openOptions, setOpenOptions] = useState(false);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const buttonRef = useRef(null);
  const optionsRef = useRef(null);
  const [isPending, setIsPending] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<any>({});
  const [inviteModal, setInviteModal] = useState(false);

  const isDeleteConfirmation = confirmationModal.delete;
  const isInviteConfirmation = confirmationModal.invite;

  const menuClick = (e) => {
    e.stopPropagation();
    setOpenOptions(!openOptions);
  };

  useOutsideClick([buttonRef, optionsRef], () => setOpenOptions(false));

  const toggleConfirmModal = (modalType?) => {
    setOpenOptions(false);
    !isPending && setConfirmationModal(() => (modalType ? { [modalType]: true } : {}));
  };

  const inviteToTransaction = () => {
    // If email doesn't exist
    if (!contact?.Email) {
      setInviteModal(true);
      return;
    }

    const transactionId = params?.id;
    onDeleteContact();
    setIsPending(true);

    const formattedName = splitName(contact?.Name);
    const config = {
      transactionId,
      email: contact?.Email,
      firstName: formattedName?.FirstName,
      lastName: formattedName?.LastName,
      roleId: contact?.RoleId,
    };

    dispatch(
      inviteTransactionParticipantEffect(config, { silent: true }, (err) => {
        if (!err) {
          dispatch(
            requestGetUserParticipantsListEffect(
              {
                includeCollaborators: true,
              },
              { silent: true },
            ),
          );
        }
        setConfirmationModal({});
        setOpenOptions(false);
        setIsPending(false);
      }),
    );
  };

  const onDeleteContact = () => {
    setIsPending(true);
    dispatch(
      deleteTransactionContactEffect(
        {
          id: params?.id,
          contactId: contact?.Id,
        },
        (err) => {
          if (!err) {
            dispatch(getTransactionContactsEffect({ id: params?.id }));
            setConfirmationModal({});
          }
          setIsPending(false);
        },
      ),
    );
  };

  const onConfirm = () => {
    confirmationModal.delete && onDeleteContact();
    confirmationModal.invite && inviteToTransaction();
  };

  const updateContactPrivacy = () => {
    setIsPending(true);
    setOpenOptions(false);

    const updatedContact = _.omitBy(contact, (value) => _.isNull(value) || value === '');
    dispatch(
      updateTransactionContactEffect(
        {
          id: params?.id,
          contactId: contact?.Id,
          contact: {
            ...updatedContact,
            IsPrivate: !contact?.IsPrivate,
          },
        },
        (err) => {
          if (!err) {
            dispatch(getTransactionContactsEffect({ id: params?.id }));
          }
          setIsPending(false);
        },
      ),
    );
  };

  return (
    <Fragment>
      <div className={classNames(styles.cardItem, { [styles.hoverEffect]: openOptions })}>
        <div>
          <div className={styles.participant}>
            <Avatar
              avatarClassName={styles.avatar}
              src={null}
              placeholder={convertNameToAvatarPlaceholder(contact.Name)}
            />
            <div className={styles.names}>
              <span className={styles.name}>{contact.Name}</span>
              <span className={classNames(styles.subtitle, styles.spaceBottom)}>
                {TransactionUserRoleMap[contact.Role] || contact.Role}
              </span>
              <span>
                {contact.CompanyName && (
                  <span className={styles.subtitle}>{contact.CompanyName}</span>
                )}
              </span>
              {contact.Email && <span className={styles.subtitle}>{contact.Email}</span>}
              {contact.Phone && <span className={styles.subtitle}>{contact.Phone}</span>}
            </div>
          </div>
        </div>

        {fullAccess && (
          <div className={styles.options}>
            {isPending ? (
              <Spin
                className={styles.loader}
                indicator={<LoadingOutlined className={styles.spinner} spin />}
              />
            ) : (
              <>
                {contact?.IsPrivate && (
                  <Icons variant={Icons.LOCK} className={styles.privateIcon} />
                )}
                <div className={styles.optionBtn}>
                  <Button
                    className={classNames(styles.btn, { [styles.hoverEffect]: openOptions })}
                    variant="link"
                    icon={<Icons variant={Icons.OPTIONS} />}
                    onClick={menuClick}
                    ref={buttonRef}
                  />

                  {openOptions && (
                    <div ref={optionsRef} className={styles.menuOptions}>
                      <ul>
                        <li className={styles.item} onClick={updateContactPrivacy}>
                          <div className={styles.icon}>
                            <Icons
                              variant={contact?.IsPrivate ? Icons.SHARED : Icons.LOCK}
                              color="#FF576D"
                            />
                          </div>
                          Mark {contact?.IsPrivate ? 'Shared' : 'Private'}
                        </li>

                        {/* inviteToTransaction */}
                        <li className={styles.item} onClick={() => toggleConfirmModal('invite')}>
                          <div className={styles.icon}>
                            <Icons variant={Icons.PLUS} color="#FF576D" />
                          </div>
                          Invite to Transaction
                        </li>

                        <li className={styles.item} onClick={() => openEditModal(contact)}>
                          <div className={styles.icon}>
                            <Icons variant={Icons.EDIT} color="#FF576D" />
                          </div>
                          Edit
                        </li>

                        <li className={styles.item} onClick={() => toggleConfirmModal('delete')}>
                          <div className={styles.icon}>
                            <Icons variant={Icons.DELETE} />
                          </div>
                          Delete
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <div className={styles.seperator} />

      {/* Delete Confirmation Modal */}
      <ConfirmationModal
        className={styles.contactConfirmationModal}
        open={isDeleteConfirmation || isInviteConfirmation}
        confirmText={
          <span>
            Are you sure you want to <br /> {isDeleteConfirmation ? 'delete' : 'invite'} this
            contact{isInviteConfirmation ? ' to transaction?' : '?'}
          </span>
        }
        onOk={onConfirm}
        onCancel={() => toggleConfirmModal()}
        okText={isDeleteConfirmation ? 'Delete' : 'Invite'}
        okButtonProps={{ className: styles.confirmButton }}
        cancelText={'Cancel'}
        cancelButtonStyles={styles.cancelButton}
        confirmLoading={isPending}
        variant={isDeleteConfirmation ? 'Confirm' : 'Primary'}
        closable={false}
        title={
          <Icons
            variant={isInviteConfirmation ? 'inviteToTransaction' : 'deleteCircle'}
            className={styles.modalTitleIcon}
          />
        }
      />

      {/* Transaction Invite Modal */}
      <TransactionInviteModal
        open={inviteModal}
        transactionId={params?.id}
        onClose={() => setInviteModal(false)}
        data={{
          ...contact,
          customName: contact?.Name,
          ...splitName(contact?.Name),
          tagRole: TransactionUserRoleMap?.[contact?.Role],
        }}
        callbackContactDelete={() => {
          onDeleteContact();
          setInviteModal(false);
        }}
      />
    </Fragment>
  );
};
