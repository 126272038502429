import { getState } from 'store';
import {
  setSearchCriteriaAction,
  resetSearchCriteriaAction,
  resetEditedSearchAction,
  setSearchRawPrefsAction,
} from 'store/actions/search';
import Api from 'store/effects/core/api';
import { getSavedSearches } from 'api/user';
import { getSavedSearchById, updateSavedSearch } from 'api/search';
import { cleanSearchQueryObj } from './helpers';
import {
  requestGetSavedSearchByIdAction,
  requestUpdateSavedSearchAction,
  setEditedSearchCriteriaAction,
  requestSavedSearchesThunk,
} from 'store/actions/mySearches';
import { EDIT } from 'settings/constants/mode';
import { LocalStorage } from 'services';

export const setSearchCriteriaEffect = (cfg) => (dispatch) => {
  const { search, searchResults } = getState();
  const mode = searchResults?.filterDrawer?.mode;
  if (mode === EDIT) {
    const data = search?.edited?.data;
    if (!cfg.Locations?.length) {
      cfg.Locations = data?.criterias?.Locations?.filter((loc) => !loc.ProviderPlaceId);
    }
    const newConf = { ...data?.criterias, ...cfg };
    const config = cleanSearchQueryObj(newConf);
    LocalStorage.setSearchCriteria(config);
    dispatch(
      setEditedSearchCriteriaAction({
        ...data,
        criterias: config,
      }),
    );
  } else {
    if (!cfg.Locations?.length) {
      cfg.Locations = search?.search?.Locations?.filter((loc) => !loc.ProviderPlaceId);
    }
    const newConf = { ...search?.search, ...cfg };
    const config = cleanSearchQueryObj(newConf);
    LocalStorage.setSearchCriteria(config);
    dispatch(setSearchCriteriaAction(config));
  }
};

export const resetSearchCriteriaEffect = () => (dispatch) => {
  LocalStorage.setSearchCriteria('');
  dispatch(resetSearchCriteriaAction());
};

export const getSavedSearchesEffect = (cfg, options, cb) => {
  const requestParams = { action: requestSavedSearchesThunk, method: getSavedSearches };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const getSavedSearchByIdEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestGetSavedSearchByIdAction,
    method: getSavedSearchById,
  });

  return sendRequest(cfg, options, cb);
};

export const resetEditedSearchEffect = () => (dispatch) => {
  dispatch(resetEditedSearchAction());
};
export const setRawPrefDataEffect = (data) => (dispatch) => {
  dispatch(setSearchRawPrefsAction(data));
};
export const updateSavedSearchEffect = (cfg, options, cb) => {
  const sendRequest = Api.execBase({
    action: requestUpdateSavedSearchAction,
    method: updateSavedSearch,
  });

  const { id, Name, ...otherConfig } = cfg;
  const config = {
    id,
    Name: cfg.Name,
    SearchQuery: cleanSearchQueryObj(otherConfig),
  };

  return sendRequest(config, options, cb);
};
