import React, { useEffect, useMemo, useState } from 'react';
import { isNumber } from 'lodash-es';

import { InputLabel } from 'components';
import { Option } from 'components-antd';
import { AutoComplete } from './AutoComplete';

import styles from './styles.module.scss';
import classNames from 'classnames';

interface NameAutocompleteProps {
  label?: string;
  allNames: any[];
  value: any;
  onChange?: (...args: any[]) => void;
  onSelect?: (...args: any[]) => void;
  disabled?: boolean;
  placeholder?: string;
  isNoOptionFoundEnabled?: boolean;
  cbNoMemberOption?: Function;
  className?: string;
  isHidePopupContainerParent?: boolean;
}

export const NameAutocomplete = ({
  label,
  allNames = [],
  value,
  onChange,
  onSelect,
  disabled = false,
  placeholder,
  isNoOptionFoundEnabled = false,
  cbNoMemberOption,
  className = '',
  isHidePopupContainerParent = false,
}: NameAutocompleteProps) => {
  const [isSearch, setIsSearch] = useState(false);
  const [name, setName] = useState(value || '');
  const [filteredOptions, setFilteredOptions] = useState(allNames);
  const [isExternalMember, setIsExternalMember] = useState(false);

  useEffect(() => {
    if (value) {
      setName(value?.label ? value?.label : value);
    }
  }, [value]);

  const getSelectedName = (value?: string) => allNames.find((item) => item.value === value);

  const handleSelect = (value: any) => {
    const selectedName = getSelectedName(value);
    onSelect && onSelect(selectedName);
    setIsSearch(false);
    setIsExternalMember(false);
  };

  const handleChange = (value: string, e) => {
    if (isNumber(value)) {
      const optionSelected = allNames?.find((item) => item?.value === value);
      setName(optionSelected?.label);
      onChange && onChange(optionSelected);
    } else {
      setName(value);
      onChange && onChange({ label: value });
      // Filter the options based on input
      const filtered = allNames.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredOptions(filtered);

      setIsExternalMember(filtered.length === 0 && isNoOptionFoundEnabled);
    }
  };

  const handleBlur = () => {
    setIsSearch(false);
  };

  const hasParticipant = (searchTerm: string) => {
    if (searchTerm) {
      return Boolean(
        allNames.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()))
          .length,
      );
    }

    return false;
  };

  const getPopupContainer = (triggerNode) => {
    const parentElement = triggerNode.parentElement;
    // Ensure it returns an HTMLElement, otherwise fallback to document.body or a specific element
    return parentElement instanceof HTMLElement ? parentElement : document.body;
  };

  const popUpProperty = useMemo(() => {
    if (isHidePopupContainerParent) return {};
    return { getPopupContainer: getPopupContainer };
  }, [isHidePopupContainerParent]);

  return (
    <div className={classNames(styles.autoCompleteDropdown, className)}>
      <div className={styles.fieldWrapper}>
        {label && <InputLabel label={label} className={styles.label} />}
      </div>
      <AutoComplete
        disabled={disabled}
        value={name}
        onChange={handleChange}
        open={isSearch}
        popupClassName={classNames(styles.autoCompleteDropdown, {
          [styles.noMemberAutoComplete]: isExternalMember && isNoOptionFoundEnabled,
        })}
        placeholder={placeholder}
        filterOption={false} // Disable the built-in filtering, we'll handle it manually
        onSearch={(val: string) => {
          setIsSearch(!!val.trim());
          if (val.trim().length && !hasParticipant(val)) {
            !isExternalMember && setIsExternalMember(true);
          } else if (isExternalMember) {
            setIsExternalMember(false);
          }
        }}
        onSelect={handleSelect}
        onBlur={handleBlur}
        className={styles.nameAutoComplete}
        {...popUpProperty}
      >
        {isExternalMember && isNoOptionFoundEnabled ? (
          <Option label={'No Member Found'} value={''}>
            <div className={classNames(styles.autoCompleteOption)}>
              <div className={styles.noResultsText}>No results found.</div>
              <div
                className={styles.addMemberText}
                onClick={() => cbNoMemberOption && cbNoMemberOption(name)}
              >
                Add &quot;{name}&quot;{' '}
              </div>
            </div>
          </Option>
        ) : (
          filteredOptions.map((item, idx) => (
            <Option key={idx} label={item.label} value={item.value}>
              <div className={styles.autoCompleteOption}>{item.label}</div>
            </Option>
          ))
        )}
      </AutoComplete>
    </div>
  );
};
