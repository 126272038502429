import { MessageAccessType } from 'app-constants/enums/message';
import { apiServer } from 'settings/web-services/api';

export enum CommentsEntityType {
  taskComment = 'taskComment',
  offerComment = 'offerComment',
  transactionNote = 'transactionNote',
  formComment = 'formComment',
  clientNote = 'clientNote',
}

export const createCommentThread = async (cfg: {
  ParticipantIds: number[];
  EntityId: number;
  EntityType: CommentsEntityType;
  FirstComment: {
    Text: string;
    AccessType: MessageAccessType;
    CommentMeta?: {};
    Attachments?: any;
  };
}) => {
  return apiServer.post(`/comments/thread`, cfg);
};

export const markThreadAsRead = async ({ threadId }: { threadId: number }) =>
  apiServer.put(`/comments/thread/${threadId}/read-all`);

export const deleteComment = ({ CommentId }: { CommentId: number }) =>
  apiServer.delete(`/comments/${CommentId}`);

export const updateComment = (
  cfg: Partial<{
    Id: number;
    Text: string;
    Attachments: File[];
    CommentMeta: { Tags: [] };
    AccessType: MessageAccessType;
  }>,
) => {
  const { Id, ...rest } = cfg;
  return apiServer.put(`/comments/${Id}`, {
    ...rest,
  });
};

export const getTransactionNotes = async (cfg: { transactionId: number }) =>
  apiServer.get(`/comments/notes/${cfg.transactionId}`);

export function addComment(cfg: {
  ThreadId: number;
  Text: string;
  Attachments: File[];
  CommentMeta: { Tags: [] };
  AccessType: MessageAccessType;
}) {
  const { ThreadId, ...rest } = cfg;
  return apiServer.post(`/comments/${ThreadId}`, {
    ...rest,
  });
}

export const getTaskComments = async (cfg: { taskId: number }) =>
  apiServer.get(`/comments/task/${cfg.taskId}`);

export const getClientNotes = async (cfg: { clientId: number }) =>
  apiServer.get(`/comments/client-notes/${cfg.clientId}`);
