import classNames from 'classnames';
import { Dropdown, Menu, MenuItem, Radio } from 'components-antd';
import { ICON_VARIANT_TYPE, Icons } from 'pages/FormProcess/Icons';

import styles from './styles.module.scss';

import { useState } from 'react';

import { Check } from 'components/Icons';
import { MemberTypes } from 'app-constants/enums/permissionSettings';

type MembersTypePopoverTyes = {
  selectedType: string;
  setSelectedType: (type) => void;
};

export const MembersTypePopover = ({ selectedType, setSelectedType }: MembersTypePopoverTyes) => {
  const [open, setOpen] = useState(false);

  const onSelectDocument = (type) => {
    setOpen(false);
    if (MemberTypes[type] === selectedType) return;

    setSelectedType(type);
  };

  const menu = (
    <Radio.Group className={styles.optionsItems}>
      <Menu>
        {Object.keys(MemberTypes).map((typeItem, idx) => (
          <MenuItem key={idx}>
            <Radio
              value={MemberTypes[typeItem]}
              key={idx}
              onClick={() => onSelectDocument(MemberTypes[typeItem])}
            >
              <div className={styles.option}>
                <div className={styles.optionValue}>{MemberTypes[typeItem]}</div>
              </div>

              {selectedType === MemberTypes[typeItem] ? (
                <Check className={styles.checkIcon} />
              ) : (
                <></>
              )}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.membersTypeDropdownWrapper)}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        trigger={['click']}
        overlayClassName={styles.popoverOverlay}
        onOpenChange={(visible) => setOpen(visible)}
      >
        <div
          className={classNames(styles.membersTypePopoverContainer, {
            [styles.openedPopover]: open,
          })}
        >
          <div className={styles.title}>{selectedType}</div>
          <div className={styles.arrowIcon}>
            <Icons variant={ICON_VARIANT_TYPE.DownArrow} />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
