import { useCallback, useState } from 'react';
import classNames from 'classnames';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { Check } from 'components/Icons';
import { DotsIcon } from '../../Icons';

import styles from './styles.module.scss';

export const TableViewFilter = ({ setIsGraphView, openCompareModal, setIsPending }) => {
  const options = ['Switch to Table', 'Switch to Graph'];

  const [dropdownFields, setDropdownFields] = useState([options[0]]);
  const [value, setValue] = useState(options[1]);

  const onChange = (e) => {
    setValue(e.target.value);
    // if (e.target.value === options[2]) openCompareModal();
    const updatedDropdown = e.target.value === 'Switch to Graph' ? options[0] : options[1];
    setDropdownFields([updatedDropdown]);
    setIsGraphView(e.target.value === 'Switch to Graph');
    setIsPending(true);
  };

  const menu = useCallback(() => {
    return (
      <Radio.Group
        onChange={onChange}
        value={value}
        className={styles.optionsItems}
        defaultValue={value}
      >
        <Menu>
          {dropdownFields.map((item, idx) => {
            return (
              <MenuItem key={idx}>
                <Radio value={item} key={idx}>
                  <div className={styles.option}>
                    <span className={styles.optionValue}>{item}</span>
                  </div>
                  {value === item && <Check className={styles.checkIcon} />}
                </Radio>
              </MenuItem>
            );
          })}
        </Menu>
      </Radio.Group>
    );
  }, [dropdownFields]);

  return (
    <div className={classNames(styles.clarityFilterWrap)}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, styles.dotsBtn, 'mosaikDropdownButton')}
        >
          <DotsIcon />
        </a>
      </Dropdown>
    </div>
  );
};
