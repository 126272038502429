import { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { link } from 'settings/navigation/link';
import { getTransactionTaskParamsSelector } from 'store/selectors/transactionTask';

import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { Modal, Row, Col, Button } from 'components-antd';
import { isBeforeToday, subtractTimeZone } from 'helpers';
import { Wrapper as PendingWrapper, InputLabel } from 'components';
import { getUserId } from 'store/selectors/user';
import { getTransactionTaskSelector } from 'store/selectors/transactionTask';
import { getTransactionTaskMessagesSelector } from 'store/selectors/transactionTaskMessages';
import {
  Actions,
  Comments,
  UploadDocument,
  DueDate,
  AssignTo,
  AssignedBy,
  CC,
  OverdueLabel,
} from '../View/components';
import { Documents } from '../..';
import { Plus, Minus } from 'components/Icons';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import {
  socketsGetTransactionTaskMessagesByThreadIdEffect,
  resetTransactionTaskMessagesEffect,
} from 'store/effects/sockets/transactionTaskMessages';
import { updateUnReadCommentsEffect } from 'store/effects/taskAggregate';
import { updateUnReadCommentsForTransactionEffect } from 'store/effects/transactions';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';
import {
  getIsBundleFormTaskSelector,
  getFormTaskDetailsSelector,
  getFormDocumentsSelector,
  getFormTaskLinkedFormsSelector,
  getFormTaskPermissions,
} from 'store/selectors/transactionTask';
import { useLocation } from 'react-router-dom';
import { getTransactionTaskAccess } from 'store/selectors/transactionTasks';
import { LinkFormModal } from 'pages/Workshop/Forms/components/LinkFormModal';

import Icons from 'pages/Main/Pulse/components/Icons';
import { AGENT } from 'settings/constants/roles';
import { routes } from 'settings/navigation/routes';
import { updatePreFormResponseEffect } from 'store/effects/formProcess';
import { FormProcessTypes } from 'types/formProcess/requestFormProcess';
import { LinkedForms } from '../../LinkedForms';
import { getTransactionTaskEffect } from 'store/effects';
import { getAdminOwnerAccessSelector } from 'store/selectors/transaction';

import styles from './styles.module.scss';
import { tasksStatusesIds } from 'settings/constants/transactionTasks';
import { CommentsEntityType } from 'api/comments';

const Form = (props) => {
  const { className, isOpen, onCloseModal } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { transaction } = useSelector(getTransactionSelector);

  const userId = useSelector(getUserId);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const { isPending, task, isError, status } = useSelector(getTransactionTaskSelector);
  const { threadsList, messages } = useSelector(getTransactionTaskMessagesSelector);
  const { hasAccess } = useSelector(getTransactionTaskAccess);

  const [addFiles, setAddFiles] = useState(false);
  const [addComments, setAddComments] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [addLinkedForms, setAddLinkedForms] = useState(false);
  const [openLinkFormsModal, setOpenLinkFormsModal] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [bundleFormNames, setBundleFormNames] = useState([]);
  const [linkedFormsList, setLinkedFormsList] = useState([]);
  const { taskId, transactionId } = useSelector(getTransactionTaskParamsSelector);
  const { IsBundle } = useSelector(getIsBundleFormTaskSelector);
  const { formTask } = useSelector(getFormTaskDetailsSelector);
  const { formDocuments } = useSelector(getFormDocumentsSelector);
  const { linkedForms } = useSelector(getFormTaskLinkedFormsSelector);
  const { isAdminOrOwner } = useSelector(getAdminOwnerAccessSelector);
  const { canBeginSigning, canResumeSigning, canSignForm, canViewForm } =
    useSelector(getFormTaskPermissions);
  const isNotFound = isError && status && status === 404;

  const handleCommentsToggle = () => {
    setAddComments(true);
    setShowComments((val) => !val);
  };

  const { CcList } = task || {};

  const containerRef = useRef();

  const checkAndGetThread = () =>
    threadsList.find(
      (thread) =>
        thread?.EntityId === `${task?.Id}` && thread?.Type === SOCKET_THREAD_TYPES.TRANSACTION_TASK,
    );

  useEffect(() => {
    const showCommentsFromState = location.state?.showComments;
    if (showCommentsFromState) {
      setShowComments(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (isOpen) {
      const existThread = checkAndGetThread();
      if (existThread) {
        dispatch(socketsGetTransactionTaskMessagesByThreadIdEffect({ Id: existThread?.Id }));
      } else {
        dispatch(resetTransactionTaskMessagesEffect());
      }
      setAddComments(!!existThread);

      task?.Comments?.forEach((comment) => {
        if (comment.SenderUserId !== userId) {
          comment.MessageReceipts.some((receipt) => {
            if (receipt.UserId === userId && !receipt.IsRead) {
              setUnreadMessagesCount((unreadMessagesCount) => unreadMessagesCount + 1);
              return true;
            }
          });
        }
      });
    } else {
      setUnreadMessagesCount(0);
    }
  }, [task?.Id, isOpen]);

  useEffect(() => {
    if (showComments) {
      if (task?.Id) {
        dispatch(updateUnReadCommentsEffect({ taskId: task?.Id, userId: userId }));
      }
      if (task?.Id && task?.TransactionId) {
        dispatch(updateUnReadCommentsForTransactionEffect({ taskId: task?.Id, userId: userId }));
      }
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    setUnreadMessagesCount(0);
  }, [showComments]);

  useEffect(() => {
    if (IsBundle) {
      let formNames;
      if (formTask) {
        formNames = formTask?.FormBundleTemplate?.FormBundleTemplateRelation.map(
          (bundle) => bundle.FormTemplate.Name,
        );
      } else {
        formNames = task?.formDocuments?.Documents?.map(({ FormName }) => FormName);
      }
      setBundleFormNames(formNames);
    }
  }, []);

  const beginSigning = () => {
    const dataToSend = {
      type: formTask.FormType,
      isTemplate: formTask.FormType === AGENT,
      isLibraryTemplate: true,
      propertyTransactionId: transactionId,
      taskId,
      ...(IsBundle && {
        bundleId: formTask.FormBundleTemplate.Id,
        formBundle: true,
        bundleTemplateName: formTask.FormBundleTemplate.Name,
      }),
      ...(!IsBundle && {
        formId: formTask?.FormTemplate ? formTask.FormTemplate.FormId : formTask.Form.Id,
        versionId: formTask?.FormTemplate
          ? formTask.FormTemplate.FormVersionId
          : formTask.Form.CurrentFormVersion,
        templateId: formTask.FormTemplate?.Id || undefined,
      }),
    };

    history.push({ pathname: routes.workshopFormProcessRequest, state: { ...dataToSend } });

    if (transactionId) {
      dispatch(
        updatePreFormResponseEffect({
          transactionId,
          isProject: transaction.isProject,
          formName:
            'FormBundleTemplate' in formTask
              ? formTask.FormBundleTemplate.Name
              : formTask?.FormTemplate
              ? formTask.FormTemplate.Name
              : formTask.Form.CurrentFormVersion.Name,
        }),
      );
    }
  };

  const gotToForm = () => {
    if (IsBundle)
      history.push({
        pathname: link.toWorkshopDynamicFormBundle({
          formProcessPublicId: task?.EntityId,
        }),
        state: {
          formBundle: true,
          allDocuments: formDocuments.Documents,
          bundleId: formDocuments.BundleId,
          bundleName: formDocuments.BundleName || '',
          redirectionLink: location.pathname,
        },
      });
    else {
      formDocuments.Type === FormProcessTypes.DynamicForm
        ? history.push({
            pathname: link.toWorkshopDynamicFormDocument({
              formProcessPublicId: task?.EntityId,
              formDocumentPublicId: formDocuments.FormDocuments[0].PublicId,
            }),
            state: { redirectionLink: location.pathname },
          })
        : history.push({
            pathname: link.toWorkshopFormProcessDocument({
              formProcessPublicId: task?.EntityId,
              formDocumentPublicId: formDocuments.FormDocuments[0].PublicId,
            }),
            state: { redirectionLink: location.pathname },
          });
    }
  };

  const formatLinkedForms = (linkedForms) => {
    const {
      recentlyCompletedForms,
      finalizingForms,
      waitingOnYou,
      waitingOnOthers,
      canceledForms,
      draftForms,
      voidedForms,
    } = linkedForms;

    const recentlyCompletedFormsList = recentlyCompletedForms.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#04A451',
        bgColor: '#04A45114',
        status: 'Completed',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });
    const finalizingFormsList = finalizingForms.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#4673D1',
        bgColor: '#4673D114',
        status: 'Finalizing',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });
    const waitingOnYouList = waitingOnYou.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#EC455E',
        bgColor: '#EC455E14',
        status: 'Waiting On You',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });
    const waitingOnOthersList = waitingOnOthers.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#FB913A',
        bgColor: '#FB913A14',
        status: 'Waiting On Others',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });
    const canceledFormsList = canceledForms.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#AAABAB',
        bgColor: '#AAABAB14',
        status: 'Cancelled',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });
    const draftFormsList = draftForms.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#515151',
        bgColor: '#51515114',
        status: 'Draft',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });
    const voidedFormsList = voidedForms.map((form) => {
      return {
        formName: form.formName,
        clientName: form.client,
        color: '#AAABAB',
        bgColor: '#AAABAB14',
        status: 'Voided',
        formProcessId: form.formProcessId,
        creatorId: form.creator.Id,
      };
    });

    return [
      ...recentlyCompletedFormsList,
      ...finalizingFormsList,
      ...waitingOnYouList,
      ...waitingOnOthersList,
      ...canceledFormsList,
      ...draftFormsList,
      ...voidedFormsList,
    ];
  };

  useEffect(() => {
    if (linkedForms?.draftForms) setLinkedFormsList(formatLinkedForms(linkedForms));
  }, [linkedForms]);

  const getSignButton = () => {
    if (canBeginSigning) {
      return (
        <Row>
          <Button className={styles.actionBtn} variant="secondary" onClick={beginSigning}>
            {'Begin Signing'}
          </Button>
        </Row>
      );
    } else if (canResumeSigning) {
      return (
        <Row>
          <Button className={styles.actionBtn} variant="secondary" onClick={gotToForm}>
            {'Resume'}
          </Button>
        </Row>
      );
    } else if (canSignForm) {
      return (
        <Row>
          <Button className={styles.actionBtn} variant="secondary" onClick={gotToForm}>
            {'Sign Form'}
          </Button>
        </Row>
      );
    } else if (canViewForm) {
      return (
        <Row>
          <Button className={styles.actionBtn} variant="secondary" onClick={gotToForm}>
            {'View Form'}
          </Button>
        </Row>
      );
    }

    return <div className={styles.customLine} />;
  };

  const hasLinkedForms = () => linkedFormsList.length || addLinkedForms;
  const hasUploadedFiles = () => task?.Documents?.length || addFiles;
  const hasComments = () => task?.Comments?.length || addFiles;
  const isLimitedAccessParticipant = () => !fullAccess && hasAccess(task?.Id);
  const showFormSigningBtn = () =>
    canBeginSigning || canResumeSigning || canSignForm || canViewForm;

  const getLinkedFormsContent = () => {
    if (fullAccess) {
      return hasLinkedForms() ? (
        <>
          <Row align="middle" className={styles.files}>
            <Col xs={20} sm={20} md={23} lg={23} xl={23}>
              <InputLabel label="Linked Forms" className={styles.subHeading} />
            </Col>
            <Col xs={4} sm={4} md={1} lg={1} xl={1}>
              <div onClick={() => setOpenLinkFormsModal(true)}>
                <Plus className={styles.plusIcon} />
              </div>
            </Col>
          </Row>
          <LinkedForms forms={linkedFormsList} taskId={task?.Id} transactionId={transactionId} />
          <div className={styles.customLine} />
        </>
      ) : (
        <div
          className={styles.addFilesOption}
          onClick={() =>
            isAdminOrOwner || fullAccess || hasAccess(task?.Id) ? setOpenLinkFormsModal(true) : null
          }
        >
          <Icon variant={Icon.ADD} />
          &nbsp;&nbsp;&nbsp;
          <span className={styles.addLabel}>{`Link an Existing Form`}</span>
        </div>
      );
    }

    if (isAdminOrOwner && hasLinkedForms()) {
      return (
        <>
          <Row align="middle" className={styles.files}>
            <Col xs={20} sm={20} md={23} lg={23} xl={23}>
              <InputLabel label="Linked Forms" className={styles.subHeading} />
            </Col>
          </Row>
          <LinkedForms forms={linkedFormsList} taskId={task?.Id} transactionId={transactionId} />
          <div className={styles.customLine} />
        </>
      );
    }
  };

  const getUploadDocumentsContent = () => {
    if (fullAccess || hasAccess(task?.Id)) {
      return hasUploadedFiles() ? (
        <>
          {!isLimitedAccessParticipant() &&
            (showFormSigningBtn() || fullAccess) &&
            !hasLinkedForms() && <div className={styles.customLine} />}
          <Row align="middle" className={styles.files}>
            <Col xs={20} sm={20} md={23} lg={23} xl={23}>
              <InputLabel label="Files" className={styles.subHeading} />
            </Col>
            <Col xs={4} sm={4} md={1} lg={1} xl={1}>
              <UploadDocument
                afterUploadCallback={() =>
                  dispatch(getTransactionTaskEffect({ transactionId, taskId }))
                }
              />
            </Col>
          </Row>
          <Documents value={task?.Documents} taskId={task?.Id} />
          <div className={styles.customLine} />
        </>
      ) : (
        <div
          className={styles.addFilesOption}
          onClick={() => (fullAccess || hasAccess(task?.Id) ? setAddFiles(true) : null)}
        >
          <Icon variant={Icon.ADD} />
          &nbsp;&nbsp;&nbsp;
          <span className={styles.addLabel}>{`Upload File(s)`}</span>
        </div>
      );
    }

    if (isAdminOrOwner && hasUploadedFiles()) {
      return (
        <>
          {showFormSigningBtn() && !hasLinkedForms() && <div className={styles.customLine} />}
          <Row align="middle" className={styles.files}>
            <Col xs={20} sm={20} md={23} lg={23} xl={23}>
              <InputLabel label="Files" className={styles.subHeading} />
            </Col>
          </Row>
          <Documents value={task?.Documents} taskId={task?.Id} />
          <div className={styles.customLine} />
        </>
      );
    }
  };

  const getCommentsContent = () => {
    if (task?.canComment) {
      return addComments ? (
        <>
          {!hasUploadedFiles() && <div className={styles.customLine} />}
          <Row className={styles.comments}>
            <Col xs={20} sm={20} md={23} lg={23} xl={23}>
              <div className={styles.subHeading}>
                <span>Comments</span>
                {!showComments ? (
                  <>
                    {' '}
                    <span
                      className={unreadMessagesCount ? styles.unreadCount : styles.regularCount}
                    >
                      ({unreadMessagesCount ? `${unreadMessagesCount} New` : messages?.length || 0})
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Col>
            <Col xs={4} sm={4} md={1} lg={1} xl={1}>
              <div className={styles.iconContainer}>
                <button className={styles.collapseBtn} onClick={handleCommentsToggle}>
                  {showComments ? <Minus /> : <Plus />}
                </button>
              </div>
            </Col>
          </Row>
          {showComments ? (
            <Comments
              commentAutoFocus={true}
              isModal={false}
              entityId={task?.Id}
              entityType={CommentsEntityType.taskComment}
              threadId={task.CommentsThreadId}
            />
          ) : null}
        </>
      ) : (
        <>
          <div className={styles.addCommentsOption} onClick={() => handleCommentsToggle()}>
            <Icon variant={Icon.ADD} />
            &nbsp;&nbsp;&nbsp;
            <span className={styles.addLabel}>Add Comments</span>
          </div>
        </>
      );
    }

    if (isAdminOrOwner && hasComments()) {
      return (
        <>
          {!hasUploadedFiles() && <div className={styles.customLine} />}
          <Row className={styles.comments}>
            <Col xs={20} sm={20} md={23} lg={23} xl={23}>
              <div className={styles.subHeading}>
                <span>Comments</span>
                {!showComments ? (
                  <>
                    {' '}
                    <span
                      className={unreadMessagesCount ? styles.unreadCount : styles.regularCount}
                    >
                      ({unreadMessagesCount ? `${unreadMessagesCount} New` : messages?.length || 0})
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
          {showComments ? (
            <Comments
              commentAutoFocus={true}
              isModal={false}
              entityId={task?.Id}
              entityType={CommentsEntityType.taskComment}
            />
          ) : null}
        </>
      );
    }
  };

  const showOverDueLabel = () =>
    ![tasksStatusesIds.done, tasksStatusesIds.na].includes(task?.Status);

  return (
    <Modal
      open={isOpen}
      width={675}
      footer={null}
      onCancel={() => {
        setAddFiles(false);
        setAddComments(false);
        setShowComments(false);
        setAddLinkedForms(false);
        dispatch(resetTransactionTaskMessagesEffect());
        onCloseModal();
      }}
      maskClosable={false}
      destroyOnClose
      className={styles.taskViewModal}
    >
      <div testid="task_view" className={classNames(styles.view, className)}>
        <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
          {isNotFound ? (
            <div className={styles.notFoundMessage}>The task has already been deleted</div>
          ) : (
            <Fragment>
              <p className={styles.heading}>
                <span>{task?.Title}</span>
              </p>
              {(isAdminOrOwner || fullAccess) && (
                <div className={styles.subtitle}>
                  {task?.IsRequired ? (
                    <span className={styles.required}>Required</span>
                  ) : (
                    <span className={styles.notRequired}>If Applicable</span>
                  )}
                </div>
              )}
              <div ref={containerRef} className={styles.content}>
                <Row>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Icon variant={Icon.CALENDER} />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={(styles.headerInfo, { [styles.flexHeaderInfo]: !task?.Category })}
                  >
                    <DueDate />
                  </Col>
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <Actions
                      assignor={task?.Assignor}
                      transactionId={task?.TransactionId}
                      dueDate={task?.DueDate}
                    />
                  </Col>
                </Row>

                {showOverDueLabel() &&
                  isBeforeToday(subtractTimeZone(task?.DueDate, 'M/DD/YYYY')) && <OverdueLabel />}

                <div
                  className={classNames(styles.assign, {
                    [styles.alignStart]: task?.AssigneeList?.length > 1,
                  })}
                >
                  <AssignTo className={styles.to} />
                  <AssignedBy className={styles.by} />
                </div>
                {CcList && CcList?.length ? (
                  <div className={styles.cc}>
                    <InputLabel label="CC:" className={styles.subHeading} />
                    <CC />
                  </div>
                ) : (
                  <></>
                )}
                {IsBundle && bundleFormNames?.length ? (
                  <div className={styles.bundleDescription}>
                    <p>This includes the following forms</p>
                    {bundleFormNames.map((formName, index) => (
                      <div className={styles.formNameContainer} key={index}>
                        <Icons className={styles.docIcon} variant="document" />
                        <span className={styles.formName}>{formName}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
                {task?.Description ? (
                  <div testid="description" className={styles.description}>
                    <InputLabel label="Description" className={styles.subHeading} />
                    <p className={styles.descriptionText}>{task?.Description}</p>
                  </div>
                ) : (
                  <></>
                )}
                {getSignButton()}
                {getLinkedFormsContent()}
                {getUploadDocumentsContent()}
                {getCommentsContent()}
              </div>
            </Fragment>
          )}
        </PendingWrapper>
        <LinkFormModal
          open={openLinkFormsModal}
          onClose={() => {
            setOpenLinkFormsModal(false);
          }}
          taskId={taskId}
          cb={() => dispatch(getTransactionTaskEffect({ transactionId, taskId }))}
        />
      </div>
    </Modal>
  );
};

Form.propTypes = {
  className: PropTypes.string,
};

Form.defaultProps = {
  className: '',
};

export default Form;
