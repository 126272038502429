import ThirdPartyIntegrations from './ThirdPartyIntegrations';
import MlsIntegration from './MlsIntegration';

import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { Wrapper } from 'components';
import { getListOfAgentMlsServices, IMlsService } from 'api/agent/mls';
import { setAgentSelectedMlsServiceAction } from 'store/actions/user';
import { showErrorMessage } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { isMlsAccessModalOpenedSelector } from 'store/selectors/mlsAccess';

const Integrations = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [AgentMlsServices, setAgentMlsServices] = useState<IMlsService[]>([]);
  const isMlsAccessModalOpen = useSelector(isMlsAccessModalOpenedSelector);

  const handleError = () => {
    showErrorMessage("Cannot load list of available agent MLS'. Try to reload the page");
  };

  const fetchListing = async () => {
    try {
      setLoading(true);
      const response = await getListOfAgentMlsServices();
      if (response.data?.success) {
        setAgentMlsServices(response.data.result);
        dispatch(setAgentSelectedMlsServiceAction(response.data.result));
      } else {
        handleError();
      }
      setLoading(false);
    } catch (err) {
      handleError();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isMlsAccessModalOpen) {
      fetchListing();
    }
  }, [isMlsAccessModalOpen]);

  return (
    <div>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>Integrations</h2>
      </div>
      <Wrapper isPending={loading}>
        <div>
          <MlsIntegration AgentMlsServices={AgentMlsServices} fetchListing={fetchListing} />
        </div>
        <div>
          <ThirdPartyIntegrations />
        </div>
      </Wrapper>
    </div>
  );
};

export default Integrations;
