import { useSelector } from 'react-redux';
import moment from 'moment';

import { postClarityFinancialData } from 'api/clarity';
import { getAgentTeamIdSelector, getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import {
  getAgentTeamStatsSelector,
  getTimePeriodDueFromSelector,
  getTimePeriodDueToSelector,
  getTimePeriodSelector,
  getTransactionFilter,
} from 'store/selectors/clarity';
import { FeesDataType } from '../Components/Stats/Content/Components/TableView/TableModal/Financial/ModalTableColumns';
import { TEAM_BASIC } from 'settings/constants/roles';
import { getUserId } from 'store/selectors/user';
import { formatAgentUserContact, isValidAgentName } from './helper';

type FinancialStatsProps = {
  setSummary: (val) => void;
  setData: (val) => void;
  setIsPending: (val) => void;
  key: string;
  aggregationType: string;
  summaryType: string;
  aggregateDataFormat: string;
  memo?: string | null;
  ledgerAccountId?: number;
  year?: number | null;
  quarter?: number | null;
  month?: number | null;
  isoWeek?: number | null;
  date?: string | null;
  agentSummary?: boolean;
  transactionAgentUserId?: boolean;
  transactionAgentContactId?: boolean;
  isTeam?: boolean;
  representingSide?: number | null;
  isCommission?: boolean;
};

const formatDescrition = (desc, type) => {
  if (type === 'DeductFrom') {
    if (desc.includes('Custom Account Payment'))
      return desc.replace('Account Payment', 'Split').replace('Deduct from ', '');
    else if (desc.includes('GCI')) return 'GCI';
    else if (desc.includes('Client Fee Balance')) return 'Client';
    else if (desc.includes('Agent Payment')) return 'Agent Split';
    else if (desc.includes('Team Payment')) return 'Team Split';
    else if (desc.includes('Brokerage Payment')) return 'Brokerage Split';
    else return desc;
  } else {
    if (desc.includes('Custom Account Payment'))
      return desc.replace('Account Payment', 'Split').replace('Add to ', '');
    else if (desc.includes('Outbound Payment')) return 'Outbound';
    else if (desc.includes('Client Fee Balance')) return 'Client';
    else if (desc.includes('Agent Payment')) return 'Agent Net';
    else if (desc.includes('Team Payment')) return 'Team Net';
    else if (desc.includes('Brokerage Payment')) return 'Brokerage Net';
    else return desc;
  }
};

const formatFees = (data) => {
  const result = <FeesDataType[]>[];
  for (let i = 0; i < data.length; i += 2) {
    if (!data[i + 1]) break;
    result.push({
      Date: data[i].Date,
      Transaction: data[i].Transaction,
      DeductFrom: formatDescrition(data[i].Description, 'DeductFrom'),
      ApplyTo: formatDescrition(data[i + 1].Description, 'ApplyTo'),
      TotalValue: data[i + 1].TotalValue,
      Agent: data[i].AgentUser,
      PropertyTransactionId: data[i].PropertyTransactionId,
      RepresentingSide: data[i].RepresentingSide,
    });
  }
  return result;
};

export const useFetchFinancialStats = () => {
  const teamId = useSelector(getAgentTeamIdSelector);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const timePeriod = useSelector(getTimePeriodSelector);
  const transactionFilter = useSelector(getTransactionFilter);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const userId = useSelector(getUserId);
  const dueFrom = useSelector(getTimePeriodDueFromSelector);
  const dueTo = useSelector(getTimePeriodDueToSelector);

  const fetchFinancialStats = async ({
    setSummary,
    setData,
    setIsPending,
    key,
    aggregationType,
    summaryType,
    aggregateDataFormat,
    memo,
    ledgerAccountId,
    year,
    quarter,
    month,
    isoWeek,
    date,
    agentSummary = false,
    transactionAgentUserId = false,
    transactionAgentContactId = false,
    isTeam = false,
    representingSide = null,
    isCommission = false,
  }: FinancialStatsProps) => {
    setIsPending(true);
    const previousYear = moment().subtract(1, 'year').year();
    const excludeTimePeriod = !!(quarter || month || isoWeek || dueFrom || dueTo);
    if (timePeriod === previousYear) year = previousYear;
    const sendValues = {
      teamId: teamId,
      ...(!excludeTimePeriod && { timePeriod: timePeriod === previousYear ? 'YTD' : timePeriod }),
      aggregationType,
      summaryType,
      financialOptions: ['ActualOnly'],
      optionalColumns: [],
      ...(agentRole === TEAM_BASIC && { transactionAgentUserId: userId }),
      ...(teamStats.itemValue !== 'Team' && { transactionAgentUserId: teamStats.userId }),
      aggregateDataFormat,
      ...(year && { year: year }),
      ...(quarter && { quarter }),
      ...(month && { month }),
      ...(isoWeek && { isoWeek }),
      ...(date && { date }),
      ...(memo && { filterByMemo: memo }),
      ...(ledgerAccountId && { filterByLedgerAccountId: ledgerAccountId }),
      ...(agentSummary && { agentSummary: agentSummary }),
      ...((transactionFilter || representingSide) && {
        filterByLedgerRepresentingSideId: representingSide ?? transactionFilter,
      }),
      ...(!isTeam && transactionAgentUserId && { transactionAgentUserId: transactionAgentUserId }),
      ...(!isTeam &&
        !transactionAgentUserId &&
        transactionAgentContactId && { transactionAgentContactId: transactionAgentContactId }),
      ...(isCommission && { isCommission: isCommission }),
      ...(dueFrom && { dueFrom: dueFrom.format('MM-DD-YY') }),
      ...(dueTo && { dueTo: dueTo.format('MM-DD-YY') }),
    };
    const { data, status } = await postClarityFinancialData(sendValues);
    if (status === 200) {
      if (agentSummary) {
        setSummary(data.Summary?.[key] || []);
      } else if (summaryType.includes('Expand')) {
        setSummary(data.Summary.filter((s) => s.SummaryColumn === key)?.[0] || {});
        summaryType === 'FeesExpanded'
          ? setData(formatFees(data.Data[key]) || [])
          : setData(formatAgentUserContact(data.Data[key]) || []);
      } else {
        setSummary(data.Summary.filter((s) => s.SummaryColumn === key)?.[0] || {});
        setData(data.Data[key] || []);
      }
    }
    setIsPending(false);
  };
  return fetchFinancialStats;
};
