import {
  createCampaignTaskReq,
  createTestClockReq,
  deleteCampaignTaskDocumentReq,
  deleteCampaignTaskReq,
  fastForwardTestClockReq,
  getCampaignById,
  getCampaignMasterSwitch,
  getCampaignMembersByCampaignId,
  getCampaignPublishedKitsWithStats,
  getCampaigns,
  getCampaignTaskMembersReq,
  getCampaignTaskReq,
  getPastMemberCampaignInfo,
  getPublishedKits,
  getTestClockReq,
  getUpcomingMemberCampaignInfo,
  postCampaign,
  putCampaignMasterSwitch,
  resetTestClockReq,
  updateCampaign,
  UpdateCampaignPayload,
  updateCampaignTaskReq,
  uploadCampaignTaskDocumentsReq,
  postCampaignTaskTemplateReq,
  putCampaignTaskTemplateReq,
  deleteCampainTaskTemplateReq,
  getFailedMemberCampaignInfo,
  cancelCampaignExecEventReq,
  retryCampaignExecEventReq,
  getCanceledMemberCampaignInfo,
  getOverdueMemberCampaignInfo,
  addClientToCampaignsReq,
} from 'api/radarKits';
import { GetMemberCampaignInfoPayload } from 'api/radarKits/types';
import {
  addClientToCampaignsAction,
  cancelCampaignExecEventAction,
  createCampaignAction,
  createCampaignTaskAction,
  createTestClockAction,
  deleteCampaignTaskAction,
  deleteCampaignTaskDocumentAction,
  fastForwardTestClockAction,
  getCampaignByIdAction,
  getCampaignMasterSwitchAction,
  getCampaignMembersByCampaignIdAction,
  getCampaignPublishedKitsWithStatsAction,
  getCampaignsAction,
  getCampaignTaskAction,
  getCampaignTaskMembersAction,
  getCanceledMemberCampaignInfoAction,
  getFailedMemberCampaignInfoAction,
  getOverdueMemberCampaignInfoAction,
  getPastMemberCampaignInfoAction,
  getPublishedKitsAction,
  getTestClockAction,
  getUpcomingMemberCampaignInfoAction,
  putCampaignMasterSwitchAction,
  requestDeleteCampaingTaskTemplateAction,
  requestPostCampaignTaskTemplateAction,
  requestPutCampaignTaskTemplateAction,
  resetTestClockAction,
  retryCampaignExecEventAction,
  updateCampaignAction,
  updateCampaignTaskAction,
  uploadCampaignTaskDocumentsAction,
} from 'store/actions/radarKits';
import Api from 'store/effects/core/api';
import { getTaskTemplateSendData } from 'store/effects/template';
import { handleData, handlePostData } from 'store/effects/transactions/tasks/helpers';
import { getParticipantsData } from 'utils/workshopHelper';

export const requestGetPublishedKitsEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getPublishedKitsAction,
    method: getPublishedKits,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestPostCampaignEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: createCampaignAction,
    method: postCampaign,
  };
  let sendRequest = Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const requestUpdateCampaignEffect =
  (cfg: UpdateCampaignPayload, options?, cb?) => (dispatch) => {
    const requestParams = {
      action: updateCampaignAction,
      method: updateCampaign,
    };
    let sendRequest = Api.execBase(requestParams);
    if (options?.silent) {
      sendRequest = Api.execResult(requestParams);
    }

    return dispatch(
      sendRequest(cfg, options, (err, res) => {
        options?.refresh &&
          cfg?.Id &&
          dispatch(requestGetCampaignByIdEffect(cfg.Id.toString(), { silent: true }));
        cb && cb(err, res);
      }),
    );
  };

export const requestGetCampaignsEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getCampaignsAction,
    method: getCampaigns,
  };
  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetCampaignByIdEffect = (id: string, options?, cb?) => {
  const requestParams = {
    action: getCampaignByIdAction,
    method: getCampaignById,
  };
  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(id, options, cb);
};

export const requestGetCampaignMembersByCampaignIdEffect = (id: string, options?, cb?) => {
  const requestParams = {
    action: getCampaignMembersByCampaignIdAction,
    method: getCampaignMembersByCampaignId,
  };
  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(id, options, cb);
};

export const requestGetCampaignMasterSwitchEffect = (id: string, options?, cb?) => {
  const requestParams = {
    action: getCampaignMasterSwitchAction,
    method: getCampaignMasterSwitch,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(id, options, cb);
};

export const requestPutCampaignMasterSwitchEffect = (
  cfg: { Active: boolean; CampaignId: number },
  options?,
  cb?,
) => {
  const requestParams = {
    action: putCampaignMasterSwitchAction,
    method: putCampaignMasterSwitch,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const getCampaignPublishedKitsWithStatsEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getCampaignPublishedKitsWithStatsAction,
    method: getCampaignPublishedKitsWithStats,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const getUpcomingMemberCampaignInfoEffect = (
  cfg: GetMemberCampaignInfoPayload,
  options?,
  cb?,
) => {
  const requestParams = {
    action: getUpcomingMemberCampaignInfoAction,
    method: getUpcomingMemberCampaignInfo,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const getFailedMemberCampaignInfoEffect = (
  cfg: GetMemberCampaignInfoPayload,
  options?,
  cb?,
) => {
  const requestParams = {
    action: getFailedMemberCampaignInfoAction,
    method: getFailedMemberCampaignInfo,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const getCanceledMemberCampaignInfoEffect = (
  cfg: GetMemberCampaignInfoPayload,
  options?,
  cb?,
) => {
  const requestParams = {
    action: getCanceledMemberCampaignInfoAction,
    method: getCanceledMemberCampaignInfo,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const getOverDueMemberCampaignInfoEffect = (
  cfg: GetMemberCampaignInfoPayload,
  options?,
  cb?,
) => {
  const requestParams = {
    action: getOverdueMemberCampaignInfoAction,
    method: getOverdueMemberCampaignInfo,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const getPastMemberCampaignInfoEffect = (
  cfg: GetMemberCampaignInfoPayload,
  options?,
  cb?,
) => {
  const requestParams = {
    action: getPastMemberCampaignInfoAction,
    method: getPastMemberCampaignInfo,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const createTestClock = (cfg: GetMemberCampaignInfoPayload, options?, cb?) => {
  const requestParams = {
    action: createTestClockAction,
    method: createTestClockReq,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const getTestClock = (cfg: GetMemberCampaignInfoPayload, options?, cb?) => {
  const requestParams = {
    action: getTestClockAction,
    method: getTestClockReq,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const resetTestClock = (cfg: GetMemberCampaignInfoPayload, options?, cb?) => {
  const requestParams = {
    action: resetTestClockAction,
    method: resetTestClockReq,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const fastForwardTestClock = (
  cfg: GetMemberCampaignInfoPayload & { tillDate: Date },
  options?,
  cb?,
) => {
  const requestParams = {
    action: fastForwardTestClockAction,
    method: fastForwardTestClockReq,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const createCampaignTaskEffect = (cfg = {}, options?, cb?) => {
  const requestParams = {
    action: createCampaignTaskAction,
    method: createCampaignTaskReq,
  };

  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const config = handlePostData(undefined, cfg);
  return sendRequest(config, options, cb);
};

export const updateCampaignTaskEffect = (cfg = {}, options?, cb?) => {
  const requestParams = {
    action: updateCampaignTaskAction,
    method: updateCampaignTaskReq,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  const config = handleData(undefined, cfg);
  return sendRequest(config, options, cb);
};

export const getCampaignTaskEffect = (cfg = {}, options?, cb?) => {
  const requestParams = {
    action: getCampaignTaskAction,
    method: getCampaignTaskReq,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const deleteCampaignTaskEffect = (cfg = {}, options?, cb?) => {
  const requestParams = {
    action: deleteCampaignTaskAction,
    method: deleteCampaignTaskReq,
  };
  let sendRequest = Api.execBase(requestParams);

  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const uploadCampaignTaskDocumentsEffect = (cfg, options, cb) => {
  const requestParams = {
    action: uploadCampaignTaskDocumentsAction,
    method: uploadCampaignTaskDocumentsReq,
  };

  const sendRequest: any = Api.execResult(requestParams);

  const config = {
    taskId: cfg?.taskId,
    CampaignId: cfg?.campaignId,
    Documents: (cfg?.documents || []).map((document) => {
      const participants = getParticipantsData(document);
      return {
        Title: document?.documentName,
        Category: cfg?.category?.Name,
        File: {
          ContentType: document?.contentType,
          Filename: document?.filename,
          Size: document?.size,
          Data: btoa(document?.data),
        },
        UsersWithAccess: participants.UsersWithAccess,
        UsersEmailWithAccess: participants.UsersEmailWithAccess,
        AccessToEveryone: participants.AccessToEveryone,
      };
    }),
  };

  return sendRequest(config, options, cb);
};

export const deleteCampaignTaskDocumentEffect = (cfg, options, cb) => {
  const requestParams = {
    action: deleteCampaignTaskDocumentAction,
    method: deleteCampaignTaskDocumentReq,
  };
  const sendRequest: any = Api.execResult(requestParams);
  return sendRequest(cfg, options, cb);
};

export const getCampaignTaskMembersEffect = (cfg?, options?, cb?) => {
  const requestParams = {
    action: getCampaignTaskMembersAction,
    method: getCampaignTaskMembersReq,
  };
  const sendRequest: any = Api.execResult(requestParams);
  return sendRequest(cfg, options, cb);
};

export const postCampaignTaskTemplateEffect = (data, options, cb) => {
  const requestParams = {
    action: requestPostCampaignTaskTemplateAction,
    method: postCampaignTaskTemplateReq,
  };
  const sendRequest = Api.execBase(requestParams);

  const sendData = getTaskTemplateSendData(data);
  return sendRequest(sendData, options, (err, response, dispatch) => {
    if (cb) cb(err, response, dispatch);
  });
};

export const putCampaignTaskTemplateEffect = (data, options, cb) => {
  const requestParams = {
    action: requestPutCampaignTaskTemplateAction,
    method: putCampaignTaskTemplateReq,
  };
  const sendRequest = Api.execBase(requestParams);

  const sendData = getTaskTemplateSendData(data);

  return sendRequest(sendData, options, (err, response, dispatch) => {
    if (cb) cb(err, response, dispatch);
  });
};

export const deleteCampaignTaskTemplateEffect = ({ id }, options, cb) => {
  const requestParams = {
    action: requestDeleteCampaingTaskTemplateAction,
    method: deleteCampainTaskTemplateReq,
  };
  const sendRequest = Api.execBase(requestParams);

  return sendRequest(id, options, (err, response, dispatch) => {
    if (cb) cb(err, response, dispatch);
  });
};

export const cancelCampaignExecEventEffect = (cfg, options, cb) => {
  const requestParams = {
    action: cancelCampaignExecEventAction,
    method: cancelCampaignExecEventReq,
  };
  const sendRequest = Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const retryCampaignExecEventEffect = (cfg, options, cb) => {
  const requestParams = {
    action: retryCampaignExecEventAction,
    method: retryCampaignExecEventReq,
  };
  const sendRequest = Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};

export const addClientToCampaignsEffect = (cfg, options, cb) => {
  const requestParams = {
    action: addClientToCampaignsAction,
    method: addClientToCampaignsReq,
  };
  const sendRequest = Api.execBase(requestParams);

  return sendRequest(cfg, options, cb);
};
