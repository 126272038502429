import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  addNewFormInDynamicViewEffect,
  addNewTemplateInDynamicViewEffect,
  updateDynamicFormEffect,
} from 'store/effects/formProcess';
import { getDynamicFormSelector } from 'store/selectors/requestFormProcess';
import { FormProcessDataType } from 'types';
import { getMergedDetails, prepareFileFormData } from '../../helper';
import { sortDocuments } from 'pages/FormProcess/DynamicForm';

export const useAddFormsInBundle = (props) => {
  const [adding, setAdding] = useState(false);
  const { formProcessPublicId } = useParams<FormProcessDataType>();
  const {
    bundleDocumentsDetails = [],
    dynamicFormDocuments = [],
    ...rest
  } = useSelector(getDynamicFormSelector);

  const isTemplate = dynamicManager.isTemplateForm();

  const { rowKeyRecords, onClose } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const updateLocationState = (updatedState = {}) => {
    history.replace(location.pathname, {
      ...(history.location.state || {}),
      ...updatedState,
    });
  };

  const getDynamicTemplateId = () => {
    const templateId = dynamicManager.getTemplateId();
    const templateBundleId = dynamicManager.getTemplateBundleId()
      ? bundleDocumentsDetails[0].templateId || bundleDocumentsDetails[0].Id
      : '';

    return templateId?.toString() || templateBundleId?.toString();
  };

  const mangeLibraryFormsForDynamicView = (libraryForm?) => {
    let documents: string[] = [];

    let formName = libraryForm?.name || rowKeyRecords[0].name;

    if (libraryForm) {
      documents = isTemplate
        ? [...(libraryForm.templateIds || [libraryForm.templateId])]
        : [...(libraryForm.templateLinks || (libraryForm.link ? [libraryForm.link] : []))];
    } else {
      rowKeyRecords.forEach((item) => {
        documents = [
          ...documents,
          ...(isTemplate
            ? [...(item.templateIds || [item.templateId])]
            : item.link
            ? [item.link]
            : []),
        ];
      });
    }

    addFormsInDynamicBundle({ formName, documents });
  };

  const prepareNewFormRequest = (formDetails) => {
    let requestBody: any = {};

    if (formDetails.files) {
      let formData = new FormData();

      if (!isTemplate) {
        formData.append('formProcessPublicId', formProcessPublicId || '');
      }

      formData = prepareFileFormData(formDetails.files, formData);
      requestBody.formData = formData;
    } else {
      const { documents, formName } = formDetails;
      requestBody.formDetails = {
        ...(isTemplate ? { templateIds: documents } : { formName, documents, formProcessPublicId }),
      };
    }

    return requestBody;
  };

  const saveDocumentsInDynamicForm = () => {
    let oldBundleDetails: any = [...bundleDocumentsDetails];
    const newBundleDetails = dynamicManager.getBundleDocumentsDetails();

    let oldDynamicDocuments: any = [...dynamicFormDocuments];
    const newDynamicFormDocuments = dynamicManager.getDynamicDocuments();

    if (!oldBundleDetails.length) {
      const { editorConfig, meta, screen, strikeThrough } = rest;

      oldBundleDetails = [
        {
          ...(isTemplate
            ? {
                ...newBundleDetails[0],
              }
            : {
                documents: newBundleDetails[0].documents,
                formRoles: newBundleDetails[0].formRoles,
                isDynamicForm: true,
                screen,
                PublicId: dynamicManager.getAuthDocumentPublicId(),
              }),
          meta,
          strikeThrough,
          questions: editorConfig?.questions || {},
        },
      ];
    }

    const preparedBundles = getMergedDetails(
      oldBundleDetails,
      newBundleDetails,
      isTemplate ? 'Id' : 'PublicId',
    );

    const preparedDynamicFormDocuments = getMergedDetails(
      oldDynamicDocuments,
      newDynamicFormDocuments,
      isTemplate ? 'Id' : 'PublicId',
    );

    dispatch(
      updateDynamicFormEffect({
        bundleDocumentsDetails: isTemplate
          ? sortDocuments(preparedBundles, 'Order')
          : preparedBundles,
        dynamicFormDocuments: isTemplate
          ? sortDocuments(preparedDynamicFormDocuments, 'Order')
          : preparedDynamicFormDocuments,
      }),
    );

    updateLocationState({
      ...(isTemplate
        ? { allTemplates: sortDocuments(newBundleDetails[0].bundleTemplates, 'Order') }
        : { allDocuments: sortDocuments(newBundleDetails[0].documents, 'Order') }),
    });

    onClose?.();
    setAdding(false);
  };

  const addNewFormDocumentCB = () => {
    if (isTemplate) {
      dynamicManager.getTemplateDocument(null, saveDocumentsInDynamicForm);
    } else {
      dynamicManager.getDocument(null, saveDocumentsInDynamicForm);
    }
  };

  const addFormsInDynamicBundle = async (formDetails) => {
    setAdding(true);

    try {
      const requestBody = prepareNewFormRequest(formDetails);

      const addFormEffect = isTemplate
        ? addNewTemplateInDynamicViewEffect({
            ...requestBody,
            templateId: getDynamicTemplateId(),
          })
        : addNewFormInDynamicViewEffect(requestBody);

      const response: any = await dispatch(addFormEffect);

      if (dynamicManager.hasBundleData() || dynamicManager.isTemplateBundle()) {
        const { value: documents } = response.data;

        dispatch(
          updateDynamicFormEffect({ dynamicFormDocuments: sortDocuments(documents, 'Order') }),
        );

        setTimeout(() => addNewFormDocumentCB(), 1000);
      } else {
        if (isTemplate && !dynamicManager.getTemplateBundleId()) {
          const { BundleId } = response.data.value[0];
          dynamicManager.setTemplateBundleId(BundleId);
          updateLocationState({ templateBundleId: BundleId });
        }

        addNewFormDocumentCB();
      }
    } catch (error) {
      setAdding(false);
    }
  };

  return { addFormsInDynamicBundle, adding, mangeLibraryFormsForDynamicView };
};
