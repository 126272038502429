import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, TableProps } from 'antd';
import classnames from 'classnames';

import { getTimePeriodSelector } from 'store/selectors/clarity';
import { ClarityTimePeriod } from '../../../../../../helper';
import { generateCSVFileName, LeadSourcesDataType } from '../helper';
import { LeadSourceTableModal } from '../TableModal/Volume/LeadSourceTableModal';
import { ExportCSV } from 'pages/ClarityRevamped/Components/ExportCSV';

import styles from '../styles.module.scss';

enum ClarityTimePeriodIndex {
  YTD = 'MonthNumber',
  QTD = 'ISOWeek',
  MTD = 'Date',
}

enum timePeriodCompare {
  YTD = 'M',
  QTD = 'weeks',
  MTD = 'DD-MM-YY',
}

type TableViewProps = {
  open: boolean;
  data: any[];
  selectedAgent: any;
  sourceList: string[];
};

export const LeadSourcesTableView: React.FC<TableViewProps> = ({
  open,
  data,
  selectedAgent,
  sourceList,
}: TableViewProps) => {
  let timePeriod = useSelector(getTimePeriodSelector);
  const [rowValue, setRowValue] = useState<LeadSourcesDataType>();
  const [openModal, setOpenModal] = useState(false);

  if (timePeriod === moment().subtract(1, 'year').year()) timePeriod = 'YTD';

  const formatTableData = (data): LeadSourcesDataType[] =>
    data?.map((row, index) => {
      return {
        key: index,
        [ClarityTimePeriod[timePeriod]]: row[ClarityTimePeriod[timePeriod]],
        value: `$${row.TotalVolume.toLocaleString()}`,
        index: row[ClarityTimePeriodIndex[timePeriod]],
        trend: row?.Trend.toLocaleString(),
        listings: row.Listings || 0,
        purchases: row.Purchases || 0,
        dual: row.Dual || 0,
        year: row.Year,
        month: row?.MonthNumber,
        isoWeek: row?.ISOWeek,
        date: row?.Date,
      };
    }) || [];

  const columns: TableProps<LeadSourcesDataType>['columns'] = [
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.year;
          const b = record2.year;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: ClarityTimePeriod[timePeriod],
      dataIndex: ClarityTimePeriod[timePeriod],
      key: ClarityTimePeriod[timePeriod],
      sorter: {
        compare: (record1, record2) => {
          const a = moment(record1.index, timePeriodCompare[timePeriod]).format('DD MMM YYYY');
          const b = moment(record2.index, timePeriodCompare[timePeriod]).format('DD MMM YYYY');
          return moment(a).isAfter(b) ? -1 : moment(b).isAfter(a) ? 1 : 0;
        },
      },
    },
    {
      title: 'Listings',
      dataIndex: 'listings',
      key: 'listings',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.listings || 0;
          const b = record2.listings || 0;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (listings) => listings || '-',
    },
    {
      title: 'Purchases',
      dataIndex: 'purchases',
      key: 'purchases',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.purchases || 0;
          const b = record2.purchases || 0;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (purchases) => purchases || '-',
    },
    {
      title: 'Dual',
      dataIndex: 'dual',
      key: 'dual',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.dual || 0;
          const b = record2.dual || 0;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (dual) => dual || '-',
    },
    {
      title: 'Amount',
      dataIndex: 'value',
      key: 'value',
      sorter: {
        compare: (record1, record2) => {
          const a = parseInt(record1.value.replace(/[^0-9.]/g, ''));
          const b = parseInt(record2.value.replace(/[^0-9.]/g, ''));
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Trend',
      dataIndex: 'trend',
      key: 'trend',
      render: (trend) => {
        return trend.includes('-') ? (
          <span className={styles.negative}>{`${trend}%`}</span>
        ) : (
          <span className={styles.positive}>{trend === '0' ? trend : `+${trend}%`}</span>
        );
      },
    },
  ];

  const formatCSVData = (data) => {
    const formattedData = data?.map((row) => {
      return {
        Amount: `"$${row.TotalVolume.toLocaleString()}"`,
        Trend: `"${row?.Trend.toLocaleString()}%"`,
        Year: row.Year,
        Month: row?.Month,
        Week: row?.ISOWeek,
        Date: row?.Date,
        Listings: row?.Listings,
        Purchases: row?.Purchases,
        Dual: row?.Dual,
      };
    });
    if (timePeriod === 'YTD') {
      return formattedData.map(({ Year, Month, Listings, Purchases, Dual, Amount, Trend }) => ({
        Year,
        Month,
        Listings,
        Purchases,
        Dual,
        Amount,
        Trend,
      }));
    } else if (timePeriod === 'QTD') {
      return formattedData.map(({ Year, Week, Listings, Purchases, Dual, Amount, Trend }) => ({
        Year,
        Week,
        Listings,
        Purchases,
        Dual,
        Amount,
        Trend,
      }));
    } else if (timePeriod === 'MTD' || timePeriod === 'WEEK') {
      return formattedData.map(({ Year, Date, Listings, Purchases, Dual, Amount, Trend }) => ({
        Year,
        Date,
        Listings,
        Purchases,
        Dual,
        Amount,
        Trend,
      }));
    }
  };

  return (
    <div className={classnames(styles.tableContainer, { [styles.openMenu]: open })}>
      <div className={styles.btnRow}>
        <ExportCSV data={formatCSVData(data)} fileName={generateCSVFileName('Lead Sources')} />
      </div>
      <Table
        className={styles.statsTable}
        columns={columns}
        dataSource={formatTableData(data)}
        pagination={false}
        sticky={true}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              setRowValue(record);
              setOpenModal(true);
            },
          };
        }}
      />
      {openModal && (
        <LeadSourceTableModal
          year={rowValue?.year}
          month={rowValue?.month}
          isoWeek={rowValue?.isoWeek}
          date={rowValue?.date}
          open={openModal}
          onCancel={() => setOpenModal(false)}
          selectedAgent={selectedAgent}
          sourceList={sourceList}
          modalHeading={null}
        />
      )}
    </div>
  );
};
