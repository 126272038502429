import { createSelector } from 'reselect';

import { PENDING, IDLE } from 'settings/constants/apiState';

const localState = ({ clarity }) => clarity;

export const getClarityLoaderSelector = createSelector(localState, ({ state }) => ({
  state,
  isIdle: state === IDLE,
  isPending: state === PENDING,
}));

export const getAgentTeamStatsSelector = createSelector(localState, ({ teamStats }) => teamStats);

export const getIsGraphSelected = createSelector(
  localState,
  ({ isGraphSelected }) => isGraphSelected,
);

export const getGraphNameSelector = createSelector(localState, ({ graphName }) => graphName);
export const getGraphColorSelector = createSelector(localState, ({ graphColor }) => graphColor);

export const getTimePeriodSelector = createSelector(localState, ({ timePeriod }) => timePeriod);

export const getExpandChart = createSelector(localState, ({ expandChart }) => expandChart);

export const getShowSubCharts = createSelector(localState, ({ showSubCharts }) => showSubCharts);

export const getTransactionFilter = createSelector(
  localState,
  ({ transactionFilter }) => transactionFilter,
);

export const getTimePeriodDueFromSelector = createSelector(localState, ({ dueFrom }) => dueFrom);
export const getTimePeriodDueToSelector = createSelector(localState, ({ dueTo }) => dueTo);
