import _ from 'lodash';
import {
  FormHeader,
  FormSearchFilter,
} from 'pages/Workshop/Forms/components/NewFormModal/FormStages/components';
import styles from './style.module.scss';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { FormFilesTable } from 'pages/Workshop/Forms/components/NewFormModal/FormStages/components/FormFilesTable';
import { inProgressColumns } from './tableColumns';
import { useSelector } from 'react-redux';

import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

import { InProgressFormDetails, InProgressFormFilter } from './components';
import { isExternalMember } from 'pages/Workshop/Forms/components/FormsTable/helper';
import { onHandleViewFormType } from 'pages/Workshop/Forms';
import { handleViewForm } from 'utils';
import { useFetchForms } from './hooks/useFetchForms';
import { useHistory } from 'react-router-dom';

type SelectedFormType = {
  formProcessId: number;
};

export const InProgressForms = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSelection, setShowSelection] = useState(false);

  const [selectedForm, setSelectedForm] = useState<SelectedFormType>();
  const [filterPayload, setFilterPayload] = useState<any>({
    statuses: null,
  });

  const history = useHistory();

  const teamRole = useSelector(getAgentTeamRoleSelector);

  const { formsDataSource, fetching, getForms } = useFetchForms({
    filterPayload,
  });

  const searchedDataSource = useMemo(() => {
    if (searchTerm) {
      return formsDataSource?.filter((form) =>
        form.formName?.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    return formsDataSource;
  }, [searchTerm, formsDataSource]);

  const onHandleViewForm: onHandleViewFormType = (url, type) => {
    handleViewForm(history, url, type);
  };

  const openFormDetailModal = (form) => {
    if (form.isOwner || isExternalMember(form)) setSelectedForm(form);
    else if (form.url) {
      onHandleViewForm(form.url, form.type);
    }
  };

  const onCloseFormDetailModal = () => {
    getForms({ silent: true });
    setSelectedForm(undefined);
  };

  const onApplyFilter = (filterValues) => {
    setFilterPayload((prev) => ({ ...prev, ...filterValues }));
  };

  return (
    <Fragment>
      {selectedForm ? (
        <InProgressFormDetails
          selectedForm={selectedForm}
          onCloseDetail={onCloseFormDetailModal}
          onHandleViewForm={onHandleViewForm}
          refetchForms={() => getForms({ silent: true })}
        />
      ) : (
        <></>
      )}

      <div className={styles.inProgressFormsWrapper}>
        <FormHeader heading={'In Progress'} />

        <div className={styles.inProgressFormsContent}>
          <div className={styles.inProgressFormsContentWrapper}>
            <FormSearchFilter
              searchText={searchTerm}
              onSearch={setSearchTerm}
              showSelection={showSelection}
              onShowSelection={(selection) => {
                setShowSelection(selection);
              }}
              customFilter={<InProgressFormFilter applyFilter={onApplyFilter} />}
              hideSelection={true}
            />

            <FormFilesTable
              className={styles.inProgressFormsTable}
              loading={fetching}
              columns={inProgressColumns({
                isOwnerOrAdmin: [TEAM_OWNER, TEAM_ADMIN].includes(teamRole),
              })}
              dataSource={searchedDataSource}
              total={searchedDataSource.length}
              rowKeyId={'formProcessId'}
              showSelection={showSelection}
              hidePagination={true}
              onRowClick={openFormDetailModal}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
