import { useState } from 'react';

import { ArrowDown, ArrowUp } from 'components/Icons';

import styles from './styles.module.scss';

export const CollapseRow = ({ title, isDefaultCollapsed = false, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(isDefaultCollapsed);

  const handleClick = () => setIsCollapsed(!isCollapsed);

  return (
    <div className={styles.collapsibleRow}>
      <header className={styles.rowHeader}>
        <span className={styles.title} onClick={handleClick}>
          {title}
          {isCollapsed ? (
            <ArrowDown className={styles.leftMargin} color={'#515151'} />
          ) : (
            <ArrowUp className={styles.leftMargin} color={'#515151'} />
          )}
        </span>
      </header>
      {isCollapsed ? <></> : children}
    </div>
  );
};
