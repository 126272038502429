import { createSelector } from 'reselect';
import { filter, get, groupBy, includes, upperCase } from 'lodash-es';

import { formatNumber, formatUsd } from 'helpers/formatters';
import { propertiesTabsIds } from 'settings/constants/properties';
import { getMatchedCriteriasFilteredList, getMatchedCriteriasList } from 'settings/constants/match';
import { IDLE, READY } from 'settings/constants/apiState';
import moment from 'moment';
import { convertTime, getLocalDateTimeFromString, getFirstImage } from 'helpers';
import { LocationType } from 'types';
import { getFeedCriteriaSelectorV3 } from './feedv3';
import { filterProperties } from 'pages/Properties/ClientFeed/components/ClientProperties/helpers';
import { softCriteria } from 'pages/Properties/ListingDetail/components/Match/MatchItem/Insight/constants';

const localState = ({ feed }) => feed;

export const getFeedPrefsFilterSelector = createSelector(localState, ({ feedPrefs }) => {
  return feedPrefs.filters;
});

export const getFeedPrefsFilterStateSelector = createSelector(localState, ({ feedPrefs }) => {
  return feedPrefs.filters && typeof feedPrefs.filters !== 'undefined' ? READY : IDLE;
});

export const getRecommendedPropsSelector = createSelector(localState, (feed) => {
  const recommendedProperties = feed?.[propertiesTabsIds.recommended];

  return {
    ...recommendedProperties,
    data: recommendedProperties?.data?.map((prop) => ({
      ...prop,
      Property: {
        ...prop?.Property,
        MatchedCriterias: getMatchedCriteriasList(prop?.Property),
        OpenHouses:
          prop?.Property?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
            .filter((openHouse) => openHouse.isActual)
            .sort(function (a, b) {
              return openHouseSorter(a, b);
            }) || [],
      },
    })),
  };
});

export const getNewPropsSelector = createSelector(localState, (feed) => {
  const newProperties = feed?.[propertiesTabsIds.new];

  return {
    ...newProperties,
    data: newProperties?.data?.map((prop) => ({
      ...prop,
      MatchedCriterias: getMatchedCriteriasList(prop),
      OpenHouses:
        prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
          .filter((openHouse) => openHouse.isActual)
          .sort(function (a, b) {
            return openHouseSorter(a, b);
          }) || [],
    })),
  };
});

export const getPriceReductionsPropsSelector = createSelector(localState, (feed) => {
  const priceReductionsProperties = feed?.[propertiesTabsIds.priceReductions];

  return {
    ...priceReductionsProperties,
    data: priceReductionsProperties?.data?.map((prop) => ({
      ...prop,
      MatchedCriterias: getMatchedCriteriasList(prop),
      OpenHouses:
        prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
          .filter((openHouse) => openHouse.isActual)
          .sort(function (a, b) {
            return openHouseSorter(a, b);
          }) || [],
    })),
  };
});

export const getClientFavoriteMarkersSelector = createSelector(
  localState,
  getFeedCriteriaSelectorV3,
  (feed, { filter }) => {
    const favorites = feed?.[propertiesTabsIds.clientFavorites];
    const searchCriteria = localStorage.getItem('searchCriteria');
    const CommutePrefs = searchCriteria ? JSON.parse(searchCriteria)?.CommutePrefs || [] : [];
    return filterProperties(favorites?.data || [], filter)
      ?.map((property) => {
        const { Id, Geolocation, SellingPrice } = property;
        return {
          id: Id,
          price: SellingPrice,
          priceToDisplay: preparePriceForMarkup(SellingPrice),
          property: {
            ...property,
            MatchedCriterias: getMatchedCriteriasFilteredList({
              ...property,
              MatchedCriterias: {
                ...property?.MatchedCriterias,
                ...(property?.MatchedCriterias?.Commutes
                  ? {
                      Commutes: property?.MatchedCriterias?.Commutes.map((commute) => {
                        const isProximity = CommutePrefs?.find(
                          (c) => c.Name === commute.Name,
                        )?.IsImportantLocation;
                        return {
                          Feature: `${
                            isProximity ? softCriteria.ProximityTo : softCriteria.CommuteTo
                          }${commute.Name}`,
                          ...commute,
                        };
                      }),
                    }
                  : []),
                Commuting: [],
              },
            }),
            OpenHouses:
              property?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
                .filter((openHouse) => openHouse.isActual)
                .sort(function (a, b) {
                  return openHouseSorter(a, b);
                }) || [],
          },
          geometry: {
            coordinates: { lng: Geolocation?.Long, lat: Geolocation?.Lat },
          },
        };
      })
      .reduce((markersMap, marker) => {
        const {
          geometry: {
            coordinates: { lng, lat },
          },
        } = marker;

        if (typeof lng !== 'number' || typeof lat !== 'number') {
          return markersMap;
        }

        const key = `${lng} ${lat}`;

        return markersMap[key]?.length
          ? { ...markersMap, [key]: [...markersMap[key], marker] }
          : { ...markersMap, [key]: [marker] };
      }, {});
  },
);

export const getClientFavoritePropsSelector = createSelector(
  localState,
  getFeedCriteriaSelectorV3,
  (feed, { filter }) => {
    const favorites = feed?.[propertiesTabsIds.clientFavorites];
    return {
      ...favorites,
      data: filterProperties(favorites?.data || [], filter)?.map((prop) => ({
        ...prop,
        MatchedCriterias: getMatchedCriteriasList(prop),
        OpenHouses:
          prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
            .filter((openHouse) => openHouse.isActual)
            .sort(function (a, b) {
              return openHouseSorter(a, b);
            }) || [],
      })),
    };
  },
);

export const getAllPropsSelector = createSelector(localState, (feed) => {
  const allProperties = feed?.[propertiesTabsIds.all];
  return {
    ...allProperties,
    data: allProperties?.data?.map((prop) => ({
      ...prop,
      MatchedCriterias: getMatchedCriteriasList(prop),
      OpenHouses:
        prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
          .filter((openHouse) => openHouse.isActual)
          .sort(function (a, b) {
            return openHouseSorter(a, b);
          }) || [],
    })),
  };
});

export const getAllProperyImageProcessesSelector = createSelector(localState, (feed) => {
  return feed.imageProcess.propertyImageProcesses;
});

export const getAllSeenOnMapSelector = createSelector(localState, (feed) => {
  return feed.seenOnMapProperties;
});

export const getPropertyToScrollToSelector = createSelector(localState, (feed) => {
  return feed.propertyToScrollInList;
});

export const getSavedSearchesPropsSelector = createSelector(localState, (feed) => {
  const savedSearchesProperties = feed?.[propertiesTabsIds.savedSearches];

  return {
    ...savedSearchesProperties,
    data: savedSearchesProperties?.data?.map((prop) => ({
      ...prop,
      MatchedCriterias: getMatchedCriteriasList(prop),
      OpenHouses:
        prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
          .filter((openHouse) => openHouse.isActual)
          .sort(function (a, b) {
            return openHouseSorter(a, b);
          }) || [],
    })),
  };
});

export const getStatusChangesPropsSelector = createSelector(localState, (feed) => {
  const statusChangesProperties = feed?.[propertiesTabsIds.statusChanges];

  return {
    ...statusChangesProperties,
    data: statusChangesProperties?.data?.map((prop) => ({
      ...prop,
      MatchedCriterias: getMatchedCriteriasList(prop),
      OpenHouses:
        prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
          .filter((openHouse) => openHouse.isActual)
          .sort(function (a, b) {
            return openHouseSorter(a, b);
          }) || [],
    })),
  };
});

export const getOpenHousesPropsSelector = createSelector(localState, (feed) => {
  const openHousesProperties = feed?.[propertiesTabsIds.openHouses];
  return {
    ...openHousesProperties,
    data: openHousesProperties?.data?.map((prop) => ({
      ...prop,
      MatchedCriterias: getMatchedCriteriasList(prop),
      OpenHouses:
        prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
          .filter((openHouse) => openHouse.isActual)
          .sort(function (a, b) {
            return openHouseSorter(a, b);
          }) || [],
    })),
  };
});

export const formatOpenHouses = (openHouse, market = null) => {
  let date = get(openHouse, 'Date');
  let startTime = get(openHouse, 'StartTime');
  let endTime = get(openHouse, 'EndTime');
  let startTimeStamp = get(openHouse, 'StartTimeStamp');
  let endTimeStamp = get(openHouse, 'EndTimeStamp');
  const parsedMarket = openHouse?.Market ? openHouse?.Market : market;
  const keepLocalTime =
    parsedMarket === 'crmls' || parsedMarket === 'bright' || parsedMarket === 'smartmls';
  let isActual = false;
  let formattedDate = null;
  let dateToSort = null;
  const now = moment();
  if (startTimeStamp && endTimeStamp) {
    const {
      localDate: startDate,
      localTime: startTime,
      localDateTime: startDateTime,
    } = getLocalDateTimeFromString(startTimeStamp, false);
    const { localDate: endDate, localTime: endTime } = getLocalDateTimeFromString(endTimeStamp);
    isActual = now.isBefore(endTimeStamp);
    let formattedStartDate = moment.utc(startDate).format('ddd[,] MMMM D');
    dateToSort = moment.utc(startDate).format('ddd[,] MMMM D');
    if (isActual && now.format('DD-MM-YYYY') === startDateTime.format('DD-MM-YYYY')) {
      formattedStartDate = 'Today';
    }
    return {
      isActual,
      date: formattedStartDate,
      dateToSort,
      time: `${startTime} to ${endTime}`,
    };
  } else if (date) {
    date = moment
      .utc(date)
      .startOf('day')
      .add(endTime.split(':')[0], 'hours')
      .add(endTime.split(':')[1], 'minutes');
    const timezone = get(openHouse, 'Timezone') || 'America/Chicago';
    const startDate = startTime ? `${moment.utc(date).format('DD-MM-YYYY')} ${startTime}` : '';
    const endDate = endTime ? `${moment.utc(date).format('DD-MM-YYYY')} ${endTime}` : '';
    dateToSort = moment.utc(date).format('ddd[,] MMMM D');
    startTime = startDate ? convertTime(startDate, timezone, keepLocalTime).format('h:mm A') : '-';
    endTime = endDate ? convertTime(endDate, timezone, keepLocalTime).format('h:mm A z') : '-';

    formattedDate = moment.utc(date).format('ddd[,] MMMM D');
    isActual = moment.utc(date).isSameOrAfter(now);
    if (isActual && now.format('DD-MM-YYYY') === date.format('DD-MM-YYYY')) {
      formattedDate = 'Today';
    }
    return {
      isActual,
      date: formattedDate,
      dateToSort,
      time: `${startTime} to ${endTime}`,
    };
  }
};

export const getWatchlistPropsSelector = createSelector(localState, (feed) => {
  const watchlistProperties = feed?.[propertiesTabsIds.watchlist];

  return {
    ...watchlistProperties,
    data: watchlistProperties?.data?.map((prop) => ({
      ...prop,
      MatchedCriterias: getMatchedCriteriasList(prop),
      OpenHouses:
        prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
          .filter((openHouse) => openHouse.isActual)
          .sort(function (a, b) {
            return openHouseSorter(a, b);
          }) || [],
    })),
  };
});

export const getBackOnMarketPropsSelector = createSelector(localState, (feed) => {
  const backOnMarketProperties = feed?.[propertiesTabsIds.backOnMarket];

  return {
    ...backOnMarketProperties,
    data: backOnMarketProperties?.data?.map((prop) => ({
      ...prop,
      MatchedCriterias: getMatchedCriteriasList(prop),
      OpenHouses:
        prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
          .filter((openHouse) => openHouse.isActual)
          .sort(function (a, b) {
            return openHouseSorter(a, b);
          }) || [],
    })),
  };
});

export const getSuggestionsPropsSelector = createSelector(localState, (feed) => {
  const suggestionsProperties = feed?.[propertiesTabsIds.suggestions];

  return {
    ...suggestionsProperties,
    data: suggestionsProperties?.data?.map((prop) => ({
      ...prop,
      MatchedCriterias: getMatchedCriteriasList(prop),
      OpenHouses:
        prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
          .filter((openHouse) => openHouse.isActual)
          .sort(function (a, b) {
            return openHouseSorter(a, b);
          }) || [],
    })),
  };
});

export const getSearchInstancePropsSelector = createSelector(localState, (feed) => {
  const searchInstanceProperties = feed?.[propertiesTabsIds.searchInstance];

  return {
    ...searchInstanceProperties,
    data: searchInstanceProperties?.data?.map((prop) => ({
      ...prop,
      MatchedCriterias: getMatchedCriteriasList(prop),
      OpenHouses:
        prop?.OpenHouses?.map((openHouse) => formatOpenHouses(openHouse))
          .filter((openHouse) => openHouse.isActual)
          .sort(function (a, b) {
            return openHouseSorter(a, b);
          }) || [],
    })),
  };
});

export const getAgentClientsSelector = createSelector(
  localState,
  (feed) => feed?.[propertiesTabsIds.agentClients],
);

export const getShareDrawerSelector = createSelector(localState, (feed) => feed?.shareDrawer);

export const getShareDrawerClientsSelector = createSelector(
  getShareDrawerSelector,
  (shareDrawer) => shareDrawer?.clients,
);

export const getClientsListSelector = createSelector(
  getShareDrawerSelector,
  (shareDrawer) => shareDrawer?.clients?.data,
);

export const getFeedSortSelector = createSelector(localState, (feed) => feed?.sort);

export const getFeedPageInfoSelector = createSelector(localState, (feed) => feed?.pageInfo);

export const getMultipleModSelector = createSelector(localState, (feed) => feed?.multiple);

export const getComparesSelector = createSelector(localState, (feed) => feed?.compares);

export const getSharePropertySelector = createSelector(localState, (feed) => feed?.shareProperty);

export const getIsFeedFilterDrawerOpenSelector = createSelector(
  localState,
  ({ filterDrawer }) => filterDrawer?.open,
);

export const getIsShareDrawerOpenSelector = createSelector(
  localState,
  ({ shareDrawer }) => shareDrawer?.open,
);

export const getFeedDrawerCommuteIdSelector = createSelector(
  localState,
  ({ filterDrawer }) => filterDrawer?.commuteId,
);

export const getAgentClientsGroupByNameSelector = createSelector(
  getAgentClientsSelector,
  ({ data: list }) => groupBy(list, ({ FirstName }) => upperCase(FirstName?.[0])),
);

export const getShareDrawerSearchClientsValueSelector = createSelector(
  getShareDrawerSelector,
  (shareDrawer) => shareDrawer?.search,
);

export const getRecipientsSelector = createSelector(
  getShareDrawerSelector,
  (shareDrawer) => shareDrawer?.recipients,
);

export const getShareDrawerClientsGroupByNameSelector = createSelector(
  getShareDrawerClientsSelector,
  getShareDrawerSearchClientsValueSelector,
  ({ data: list }, search) => {
    const filteredList = filter(list, ({ FirstName, LastName }) =>
      includes(upperCase(`${FirstName} ${LastName}`), upperCase(search)),
    );
    return groupBy(filteredList, ({ FirstName }) => upperCase(FirstName?.[0]));
  },
);

export const getIsOpenCompareModalSelector = createSelector(
  localState,
  ({ compareModal }) => compareModal?.open,
);

export const getActiveTabSelector = createSelector(localState, ({ activeTab }) => activeTab);

export const getActivePropertiesSelector = createSelector(
  localState,
  getActiveTabSelector,
  (feed, activeTab) =>
    typeof activeTab === 'number' && !isNaN(activeTab)
      ? feed?.[propertiesTabsIds.searchInstance]
      : feed?.[activeTab],
);

export const getActivePropertiesMapMarkers = createSelector(
  getActivePropertiesSelector,
  (activeProperties) =>
    (activeProperties?.data || [])
      .map((property) => {
        const {
          ActivityStatus,
          AddedToFavoritesBy,
          Address,
          AssociationInfo,
          BuildingInfo,
          CustomKitchenFeatures,
          ExteriorAndLotDetails,
          Geolocation,
          HomeType,
          Id,
          IsFavorite,
          ListingDate,
          ListingInfo,
          MatchedClientsCount,
          MatchedCriterias,
          MatchScore,
          Neighborhood,
          NumBathsTotal,
          NumBeds,
          OpenHouses,
          Parking,
          PhotoUrls,
          Media,
          SellingPrice,
          SquareFeet,
          YearBuilt,
        } = property?.Property || property;
        const beds = NumBeds ? `${NumBeds} bd` : '';
        const baths = NumBathsTotal ? `${NumBathsTotal} ba` : '';
        const feet = SquareFeet ? `${formatNumber(SquareFeet)} ft` : '';
        const firstImage = getFirstImage(Media);
        return {
          ActivityStatus,
          AddedToFavoritesBy,
          address: Address,
          AssociationInfo,
          BuildingInfo,
          CustomKitchenFeatures,
          ExteriorAndLotDetails,
          geometry: {
            coordinates: { lng: Geolocation?.Long, lat: Geolocation?.Lat },
          },
          HomeType,
          id: Id,
          image: firstImage ? firstImage : PhotoUrls?.[0],
          info: [beds, baths, feet].filter((val) => !!val).join(', '),
          IsFavorite,
          ListingDate,
          ListingInfo,
          MatchedClientsCount,
          MatchedCriterias,
          MatchScore,
          Neighborhood,
          NumBathsTotal,
          NumBeds,
          OpenHouses,
          Parking,
          price: SellingPrice,
          priceToDisplay: preparePriceForMarkup(SellingPrice),
          SquareFeet,
          YearBuilt,
        };
      })
      .reduce((markersMap, marker) => {
        const {
          geometry: {
            coordinates: { lng, lat },
          },
        } = marker;

        if (typeof lng !== 'number' || typeof lat !== 'number') {
          return markersMap;
        }

        const key = `${lng} ${lat}`;

        return markersMap[key]?.length
          ? { ...markersMap, [key]: [...markersMap[key], marker] }
          : { ...markersMap, [key]: [marker] };
      }, {}),
);

export const preparePriceForMarkup = (number) => {
  const postfixes = ['', 'K', 'M', 'G', 'T', 'P', 'E'];
  const sign = number < 0 ? '-1' : '';
  const absNumber = Math.abs(number);
  const tier = (Math.log10(absNumber) / 3) | 0;
  if (tier == 0) return `${absNumber}`;
  const postfix = postfixes[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = absNumber / scale;
  const floored = Math.floor(scaled * 10) / 10;
  let str = floored.toFixed(1);
  str = /\.0$/.test(str) ? str.substr(0, str.length - 2) : str;
  return `$${sign}${str}${postfix}`;
};

export const getGroupedComparesSelector = createSelector(getComparesSelector, (compares) => {
  const photos = [];
  const addresses = [];
  const prices = [];
  const beds = [];
  const baths = [];
  const squareFeets = [];
  const hoaFees = [];
  const amenities = [];
  const commutes = [];
  const neighborhoods = [];

  compares.data.forEach((compare) => {
    const { PhotoUrls, Media, Address, SellingPrice, NumBeds, NumBathsTotal, SquareFeet } = compare;
    const firstImage = getFirstImage(Media);
    const { Amenities, HomeFeatures, Commutes, Neighborhood } = compare;
    const fee = get(compare, 'AssociationInfo.Fee');
    const feeFrequency = get(compare, 'AssociationInfo.FeeFrequency');

    photos.push(firstImage ? firstImage : PhotoUrls?.[0] || null);
    addresses.push(Address || null);
    prices.push(Number(SellingPrice) || null);
    beds.push(NumBeds || null);
    baths.push(NumBathsTotal || null);
    squareFeets.push(SquareFeet || null);
    hoaFees.push(
      fee && feeFrequency ? `${formatUsd(fee)}${feeFrequency ? `/${feeFrequency}` : ''}` : null,
    );
    commutes.push(groupBy(Commutes, 'Name') || null);
    neighborhoods.push(Neighborhood || null);

    if (Amenities || HomeFeatures) {
      amenities.push([...(Amenities || []), ...(HomeFeatures || [])]);
    } else {
      amenities.push(null);
    }
  });

  const groupedCommutes = {};
  commutes.forEach((commute) => {
    Object.entries(commute || {}).forEach(([commuteName, commuteValue]) => {
      groupedCommutes[commuteName] = groupedCommutes[commuteName]
        ? [...groupedCommutes[commuteName], commuteValue]
        : [commuteValue];
    });
  });

  return {
    photos,
    addresses,
    prices,
    beds,
    baths,
    squareFeets,
    hoaFees,
    amenities,
    commutes: groupedCommutes,
    neighborhoods,
  };
});

export const getFeedViewTypeSelector = createSelector(localState, ({ viewType }) => viewType);

export const getSubFilterTypeSelector = createSelector(localState, ({ subFilterType }) => {
  return subFilterType.type;
});

export const getFeedIsMapDrawingModeSelector = createSelector(
  localState,
  (state) => state.areaDrawing.isEnabled,
);

export const getFeedDrawnPolygonSelector = createSelector(
  localState,
  (state) =>
    state.feedPrefs?.filters?.Locations?.find((loc) => loc.Type === LocationType.Polygon)
      ?.Polygon ?? null,
);

export const getFeedViewportCoordinatesSelector = createSelector(
  localState,
  (state) =>
    state.feedPrefs?.filters?.Locations?.find(
      (loc) => loc.Type === LocationType.ViewportCoordinates,
    )?.Coordinates ?? null,
);

export const openHouseSorter = (a, b) => {
  const formattedNow = moment.utc(moment()).format('ddd[,] MMMM D');
  const aTime = a.time.split(' to ')[0];
  const bTime = b.time.split(' to ')[0];
  const aDateTime = new Date(
    a.dateToSort === 'Today' ? `${formattedNow} ${aTime}` : `${a.dateToSort} ${aTime}`,
  );
  const bDateTime = new Date(
    b.dateToSort === 'Today' ? `${formattedNow} ${bTime}` : `${b.dateToSort} ${bTime}`,
  );
  return aDateTime - bDateTime;
};
