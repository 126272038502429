import classNames from 'classnames';
import { useOutsideClick } from 'hooks';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocationService } from 'services';
import { useLocation } from 'react-router-dom';

import { Popover } from 'components-antd';
import { Icons } from '../../../../../common/Icons';
import { TabButton } from '../../AgentHeader';
import SearchesModal from '../SearchesModal';
import { appOpenModalEffect } from 'store/effects';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import { useAgentSearchInstance } from 'pages/Properties/AgentFeed/hooks/useAgentSearchInstance';
import {
  selectedSearchAction,
  setCriteriaModeAction,
  setSavedSearchTabAction,
  setIsClientMatchesAction,
  setSelectedClientSavedSearchAction,
} from 'store/actions/feedv3';
import { getSavedSearchesFormattedSelector } from 'store/selectors/mySearches';
import { CREATE, EDIT } from 'settings/constants/mode';
import { setSubFilterTypeAction } from 'store/actions/feed';

import styles from './styles.module.scss';

type Props = {
  setShowBlurBg: Function;
  setIsSimpleHeaderTitleVisible: any;
};

const subFilterTypes = {
  NEW: 'New',
};

export const Dropdown: React.FC<Props> = ({ setShowBlurBg, setIsSimpleHeaderTitleVisible }) => {
  const dispatch = useDispatch();
  const getAgentSearchInstance = useAgentSearchInstance();
  const [openPopover, setOpenPopover] = useState(false);
  const { selectedSearch } = useSelector(getFeedMetaSelectorV3);
  const { agentSavedSearches } = useSelector(getSavedSearchesFormattedSelector);

  const ref = useRef(null);

  const locationSrv = new LocationService();
  const location = useLocation();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  useEffect(() => {
    return () => {
      // Set client matches false when the component is about to unmount
      dispatch(setIsClientMatchesAction({ isClientMatches: false }));
    };
  }, []);

  useEffect(() => {
    if ('option' in query) {
      switch (query.option) {
        case 'newMatches':
          DropdownOptions[0].onClick(true);
          break;
        case 'newSearches':
          DropdownOptions[3].onClick();
          break;
        case 'updatedSearches':
          DropdownOptions[3].onClick();
          break;
        case 'newFavorites':
          DropdownOptions[4].onClick();
          break;
        case 'newComments':
          DropdownOptions[5].onClick();
          break;
      }
    }
  }, []);

  const closePopover = () => {
    setOpenPopover(false);
    setShowBlurBg(false);
  };

  useOutsideClick([ref], () => closePopover());

  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  const DropdownOptions = [
    {
      label: 'Client Matches',
      icon: Icons.MATCHES,
      onClick: (onlyNewMatches = false) => {
        dispatch(setCriteriaModeAction({ mode: CREATE }));
        dispatch(setIsClientMatchesAction({ isClientMatches: true }));

        const criterias = {};
        // If onlyNewMatches is true, we need to search for new client matches
        if (onlyNewMatches) {
          dispatch(setSubFilterTypeAction(subFilterTypes.NEW));
        }
        dispatch(
          selectedSearchAction({
            selectedSearch: {
              id: undefined,
              name: 'Client Matches',
            },
          }),
        );
        getAgentSearchInstance(
          { criterias },
          undefined,
          undefined,
          true,
          undefined,
          onlyNewMatches ? subFilterTypes.NEW : undefined,
        );

        closePopover();
      },
    },
    {
      label: 'My Primary Search',
      icon: Icons.MAP,
      onClick: () => {
        const mySearch = agentSavedSearches?.find((search) => search?.name === 'My Primary Search');
        if (mySearch) {
          dispatch(setCriteriaModeAction({ mode: EDIT }));
          dispatch(
            selectedSearchAction({
              selectedSearch: { id: mySearch.id, name: 'My Primary Search' },
            }),
          );
          dispatch(setIsClientMatchesAction({ isClientMatches: false }));
          getAgentSearchInstance({
            criterias: mySearch?.criterias,
            id: mySearch.id,
          });
          closePopover();
        }
      },
    },
    {
      label: 'My Saved Searches',
      icon: Icons.BOOKMARK,
      onClick: () => {
        dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: null }));
        dispatch(setSavedSearchTabAction({ savedSearchTab: 1 }));
        openModal(SearchesModal.id);
        closePopover();
      },
    },
    {
      label: 'Client Searches',
      icon: Icons.CLIENT_SEARCHES,
      onClick: () => {
        dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: null }));
        dispatch(setSavedSearchTabAction({ savedSearchTab: 2 }));
        openModal(SearchesModal.id);
        closePopover();
      },
    },
    {
      label: 'Client Favorites',
      icon: Icons.FAVOURITES_DROPWDOWN,
      onClick: () => {
        dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: null }));
        dispatch(setSavedSearchTabAction({ savedSearchTab: 3 }));
        openModal(SearchesModal.id);
        closePopover();
      },
    },
    {
      label: 'Unread Comments',
      icon: Icons.COMMENT,
      onClick: () => {
        getAgentSearchInstance({ criterias: {} }, undefined, undefined, undefined, true);
        setIsSimpleHeaderTitleVisible({ value: true, text: 'Unread Comments' });
        closePopover();
      },
    },
  ];

  const PopoverContent = (
    <div className={styles.searchesContainer} ref={ref}>
      {DropdownOptions?.map((item, idx) => (
        <div key={idx} className={styles.searchOptionContainer} onClick={() => item?.onClick()}>
          <div className={styles.searchOption}>
            <Icons variant={item.icon} />
            <span>{item?.label}</span>
          </div>
        </div>
      ))}
    </div>
  );

  const TabButtonText = () => (
    <div className={classNames(styles.tabBtnText, { [styles.rotateIcon]: openPopover })}>
      <span className={styles.text}>{selectedSearch?.name ?? 'My Primary Search'}</span>
      <Icons variant={Icons.CARET_DOWN} className={styles.icon} />
    </div>
  );

  return (
    <Popover
      content={PopoverContent}
      overlayClassName={styles.optionsPopoverContent}
      trigger="click"
      autoAdjustOverflow={false}
      placement="bottomLeft"
      open={openPopover}
    >
      <TabButton
        text={<TabButtonText />}
        icon={Icons.BOOKMARK_DARK}
        onClick={() => {
          setOpenPopover(true);
          setShowBlurBg(true);
        }}
        className={styles.savedSearchBtn}
      />
    </Popover>
  );
};
