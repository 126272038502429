import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Close } from 'components/Icons';
import { LeadSourcesDropdown } from './LeadSourcesDropdown';
import { SourceBreakdown } from './Sources/SourceBreakdown';
import { AgentBreakdown } from './Agents';
import { Wrapper as PendingWrapper } from 'components';
import { SortMenu } from './SortMenu';
import { SORT_ASC, SORT_DESC } from 'settings/constants/sort';

import styles from './styles.module.scss';

type BreakdownMenuProps = {
  open: boolean;
  list: any[];
  handleSourceClick: (source, rowIndex) => void;
  handleAgentClick: (agent, rowIndex) => void;
  color: string;
  onClose: (agent, index) => void;
  selectedAgent?: any | null;
  setSelectedAgent: (agent) => void;
  breakdownTotal: any;
  dropDownSelectedOption: string;
  setOption: (option) => void;
  isPending: boolean;
  resetSourceList: () => void;
};

export enum SORTING_OPTIONS {
  ASC_ALPAHBETICALLY = 'ASC_ALPAHBETICALLY',
  DESC_ALPAHBETICALLY = 'DESC_ALPAHBETICALLY',
  ASC_TOTAL_VOLUME = 'ASC_TOTAL_VOLUME',
  DESC_TOTAL_VOLUME = 'DESC_TOTAL_VOLUME',
}

export const LeadSourcesBreakdownMenu: React.FC<BreakdownMenuProps> = ({
  open,
  list,
  handleSourceClick,
  handleAgentClick,
  color,
  onClose,
  selectedAgent = null,
  setSelectedAgent,
  breakdownTotal,
  dropDownSelectedOption,
  setOption,
  isPending,
  resetSourceList,
}) => {
  const [sortedList, setSortedList] = useState<any[]>([]);
  const [sortOption, setSortOption] = useState(SORTING_OPTIONS.ASC_TOTAL_VOLUME);

  useEffect(() => {
    if (sortOption === SORTING_OPTIONS.ASC_TOTAL_VOLUME) {
      setSortedList(sortLeadSourcesByTotalVolume(SORT_ASC));
    } else if (sortOption === SORTING_OPTIONS.DESC_TOTAL_VOLUME) {
      setSortedList(sortLeadSourcesByTotalVolume(SORT_DESC));
    } else if (sortOption === SORTING_OPTIONS.ASC_ALPAHBETICALLY) {
      setSortedList(sortLeadSourcesAlphabetically(SORT_ASC));
    } else {
      setSortedList(sortLeadSourcesAlphabetically(SORT_DESC));
    }
  }, [sortOption, list]);

  function sortLeadSourcesAlphabetically(order) {
    return [...list]?.sort((a, b) => {
      if (a.LeadSource === 'All Sources') return -1; // "All Sources" stays at the top
      if (b.LeadSource === 'All Sources') return 1; // "All Sources" stays at the top

      const comparison = a.LeadSource.localeCompare(b.LeadSource, undefined);
      return order === SORT_ASC ? comparison : -comparison;
    });
  }

  function sortLeadSourcesByTotalVolume(order) {
    return [...list]?.sort((a, b) => {
      if (a.LeadSource === 'All Sources') return -1; // "All Sources" stays at the top
      if (b.LeadSource === 'All Sources') return 1; // "All Sources" stays at the top

      const comparison = a.TotalVolume - b.TotalVolume;
      return order === SORT_ASC ? comparison : -comparison;
    });
  }

  return (
    <div className={styles.breakdownWrapper}>
      <div className={styles.breakdownHeader}>
        <span>Breakdown</span>
        <div className={styles.rightSide}>
          {dropDownSelectedOption === 'Sources' && (
            <SortMenu setSortOption={(sortOption) => setSortOption(sortOption)} />
          )}
          <LeadSourcesDropdown
            value={dropDownSelectedOption}
            setValue={setOption}
            onSelectSourcesOption={() => setSelectedAgent(null)}
            resetSourceList={resetSourceList}
          />
          <Close onClick={onClose} className={styles.closeBtn} />
        </div>
      </div>
      <div className={styles.customLine} />
      <PendingWrapper
        isPending={isPending}
        className={classNames(styles.breakdownContent, { [styles.openMenuContent]: open })}
      >
        {sortedList.length ? (
          <div>
            {dropDownSelectedOption === 'Sources' ? (
              <SourceBreakdown
                summary={breakdownTotal}
                data={sortedList}
                handleClick={handleSourceClick}
              />
            ) : (
              <AgentBreakdown
                list={list}
                handleClick={handleAgentClick}
                color={color}
                selectedAgent={selectedAgent}
              />
            )}
          </div>
        ) : (
          <div className={styles.placeholder}>No Breakdown Data</div>
        )}
      </PendingWrapper>
    </div>
  );
};
