import { Fragment, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import { PriceHighlighter } from './../../..';
import { Row, Col, Button } from 'components-antd';
import { Input, ConfirmationDialog, Select } from 'components';
import { Icons } from 'pages/Workshop/Icons';
import { ErrorMessage } from '../../.././Error/ErrorMessage';
import { CommissionResponsibility } from '../CommissionResponsibility';
import { CommissionField } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/FinancingDetails/components';

import { convertToDecimalIfNotWhole, showErrorMessage } from 'helpers';
import { TransactionBuySideCommissionResponsibility } from 'app-constants';
import {
  createFinancialCommission,
  updateFinancialCommissionV2,
  deleteFinancialCommission,
} from 'api/financials';
import { updateCommissionResponsibility } from 'api/transactions';

import { getAgentBrokerageId, getAgentId, getUserId } from 'store/selectors/user';
import { getAgentTeamIdSelector } from 'store/selectors/agentTeamDetail';
import { getTransactionAccessSelector, getTransactionSelector } from 'store/selectors/transaction';
import { LedgerTransactionSideId } from 'settings/constants/ledger';
import { getTransactionEffect } from 'store/effects';

import styles from './styles.module.scss';

type CommissionFieldFamily = {
  label: string;
  rate: number;
  gci: number;
  notEditable?: boolean;
  fieldKey?: string;
  setEditField?: Function;
  isDisableField: Function;
  calculateGCI?: Function;
  calculateRate?: Function;
  fetchFinancials?: Function;
  gciOther?: number;
  transactionPrice?: number;
  transactionDate?: string | null;
  isFeeTypePercentage?: boolean;
  hidePriceHighlighter?: boolean;
  PercentBasis?: number;
  LedgerId?: number;
  LedgerLineItemId?: number;
  isForecast?: boolean;
  isBuyerTransaction?: boolean;
  isSellerTransaction?: boolean;
};

const initialStateCommissionContribution = {
  Value: null,
  Percent: null,
  IsFeeTypePercentage: true,
};

const buySideCommissionResponsibilityOptions = Object.values(
  TransactionBuySideCommissionResponsibility,
).map((type) => ({
  value: type,
  name: type,
}));

export const CommissionUnit = ({
  label,
  rate,
  gci,
  notEditable = false,
  fieldKey,
  setEditField,
  isDisableField,
  calculateGCI,
  calculateRate,
  fetchFinancials,
  gciOther,
  transactionPrice = 0,
  transactionDate,
  isFeeTypePercentage = false,
  hidePriceHighlighter = false,
  PercentBasis = 0,
  LedgerId,
  LedgerLineItemId,
  isForecast = true,
  isBuyerTransaction,
  isSellerTransaction,
}: CommissionFieldFamily) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [isValidate, setValidate] = useState(false);
  const [isValidateResponsibility, setValidateResponsibility] = useState(false);
  const [isUpdateButtonDisable, setIsUpdateButtonDisable] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isUpdateCommissionLoading, setUpdateCommissionLoading] = useState(false);
  const [ratePercentage, setRate] = useState<number | null>(null);
  const [gciValue, setGCIValue] = useState<number | null>(null);
  const [focusedField, setFocusedField] = useState<'gci' | 'rate' | null>(null);
  const [commissionField, setCommissionField] = useState<any>({});
  const loggedInUserId = useSelector(getUserId);
  const agentId = useSelector(getAgentId);
  const agentTeamId = useSelector(getAgentTeamIdSelector);
  const agentBrokerageId = useSelector(getAgentBrokerageId);
  const { transaction } = useSelector(getTransactionSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);

  const [buysideCommissionResponsibility, setBuysideCommissionResponsibility] = useState<any>('');
  const [buyerCommissionContribution, setBuyerCommissionContribution] = useState<any>(
    initialStateCommissionContribution,
  );
  const [sellerCommissionContribution, setSellerCommissionContribution] = useState<any>(
    initialStateCommissionContribution,
  );

  const handleRateFocus = () => setFocusedField('rate');
  const handleGCIFocus = () => setFocusedField('gci');
  const handleBlur = () => setFocusedField(null);

  const params: { id?: string } = useParams();

  useEffect(() => {
    setRate(rate);
    setGCIValue(gci);
  }, [rate, gci]);

  useEffect(() => {
    if (transaction?.TransactionCommission) setupDefaultValues();
  }, [transaction]);

  const setupDefaultValues = () => {
    const {
      BuySideCommissionResponsibility,
      BuyerCommissionContribution,
      SellerCommissionContribution,
    } = transaction?.TransactionCommission || {};
    setBuysideCommissionResponsibility(BuySideCommissionResponsibility || '');
    setSellerCommissionContribution(
      {
        ...SellerCommissionContribution,
        ...(SellerCommissionContribution?.IsFeeTypePercentage
          ? {
              Percent: SellerCommissionContribution?.Value,
              Value:
                calculateGCI &&
                convertToDecimalIfNotWhole(
                  calculateGCI(SellerCommissionContribution?.Value, gciValue),
                ),
            }
          : {
              Value: SellerCommissionContribution?.Value,
              Percent:
                calculateRate &&
                convertToDecimalIfNotWhole(
                  calculateRate(SellerCommissionContribution?.Value, gciValue),
                ),
            }),
      } || initialStateCommissionContribution,
    );
    setBuyerCommissionContribution(
      {
        ...BuyerCommissionContribution,
        ...(BuyerCommissionContribution?.IsFeeTypePercentage
          ? {
              Percent: BuyerCommissionContribution?.Value,
              Value: calculateGCI && calculateGCI(BuyerCommissionContribution?.Value, gciValue),
            }
          : {
              Value: BuyerCommissionContribution?.Value,
              Percent: calculateRate && calculateRate(BuyerCommissionContribution?.Value, gciValue),
            }),
      } || initialStateCommissionContribution,
    );
  };

  const onEdit = () => {
    setEditField && setEditField(fieldKey);
    setIsEdit(true);
  };

  const onCancel = () => {
    setEditField && setEditField(null);
    setRate(rate);
    setGCIValue(gci);
    setIsEdit(false);
    setValidate(false);
    if (isBuySideCommissionUnit && fullAccess) {
      setupDefaultValues();
      setValidateResponsibility(false);
    }
  };

  const handleRateChange = (e) => {
    const newRate = e.target.value;
    setRate(newRate);

    // Only calculate GCI if the GCI field is not focused
    if (focusedField !== 'gci') {
      let gciResult = calculateGCI && calculateGCI(newRate);
      setGCIValue(convertToDecimalIfNotWhole(gciResult));
      if (
        isBuySideCommissionUnit &&
        buysideCommissionResponsibility === TransactionBuySideCommissionResponsibility.Both
      )
        recalculateBuyerContributions(gciResult);
      setCommissionField({
        Value: parseFloat(newRate) || 0,
        IsFeeTypePercentage: true,
      });
    }
    isPriceWithinRange(calculateGCI ? calculateGCI(newRate) : gciValue, gciOther, transactionPrice);
  };

  const handleGCIChange = (e) => {
    const newGCI = e.target.value;
    setGCIValue(newGCI);

    // Only calculate the rate if the Rate field is not focused
    if (focusedField !== 'rate') {
      let rateResult = calculateRate && calculateRate(newGCI);
      setRate(rateResult);
      setCommissionField({
        Value: parseFloat(newGCI) || 0,
        IsFeeTypePercentage: false,
      });

      if (
        isBuySideCommissionUnit &&
        buysideCommissionResponsibility === TransactionBuySideCommissionResponsibility.Both
      )
        recalculateBuyerContributions(newGCI);
    }

    isPriceWithinRange(newGCI, gciOther, transactionPrice);
  };

  const recalculateBuyerContributions = (newGCI) => {
    const updatedBuyerContribution = {
      ...buyerCommissionContribution,
      ...(buyerCommissionContribution?.IsFeeTypePercentage
        ? {
            Value:
              calculateGCI &&
              convertToDecimalIfNotWhole(
                calculateGCI(buyerCommissionContribution?.Percent || 0, newGCI),
              ),
          }
        : {
            Percent:
              calculateRate &&
              convertToDecimalIfNotWhole(
                calculateRate(buyerCommissionContribution?.Value || 0, newGCI),
              ),
          }),
    };
    const updatedSellerContribution = {
      ...sellerCommissionContribution,
      ...(sellerCommissionContribution?.IsFeeTypePercentage
        ? {
            Value:
              calculateGCI &&
              convertToDecimalIfNotWhole(
                calculateGCI(sellerCommissionContribution?.Percent || 0, newGCI),
              ),
          }
        : {
            Percent:
              calculateRate &&
              convertToDecimalIfNotWhole(
                calculateRate(sellerCommissionContribution?.Value || 0, newGCI),
              ),
          }),
    };

    setBuyerCommissionContribution(updatedBuyerContribution);
    setSellerCommissionContribution(updatedSellerContribution);
    isPriceWithinRange(
      updatedBuyerContribution?.Value,
      updatedSellerContribution?.Value,
      newGCI,
      true,
    );
  };

  const updateFinancialResponsibilityFields = async () => {
    const formatPayload = (object) => ({
      IsFeeTypePercentage: object?.IsFeeTypePercentage,
      Value: object?.IsFeeTypePercentage ? parseFloat(object?.Percent) : parseFloat(object?.Value),
    });

    const payload = {
      id: transaction?.Id,
      BuySideCommissionResponsibility: buysideCommissionResponsibility,
      ...(buyerCommissionContribution?.Value > 0
        ? { BuyerCommissionContribution: formatPayload(buyerCommissionContribution) }
        : {}),
      ...(sellerCommissionContribution?.Value > 0
        ? { SellerCommissionContribution: formatPayload(sellerCommissionContribution) }
        : {}),
    };
    try {
      await updateCommissionResponsibility(payload);
      dispatch(getTransactionEffect({ id: transaction?.Id }, { silent: true }));
    } catch (e) {
      setupDefaultValues();
      showErrorMessage(e);
      setIsEdit(false);
    }
  };

  const handleUpdate = async () => {
    if (commissionField?.Value == 0 || commissionField?.Value === '') {
      setShowDeleteConfirmation(true);
      return;
    }

    setUpdateCommissionLoading(true);
    try {
      const payload = {
        LedgerId,
        CommissionLedgerLineItemId: LedgerLineItemId,
        ...(commissionField?.IsFeeTypePercentage
          ? { Percent: commissionField?.Value }
          : { Amount: commissionField?.Value }),
        ...(transactionPrice !== PercentBasis ? { PercentBasis: transactionPrice } : {}),
        Date: transactionDate,
        UpdatedBy: loggedInUserId,
        IsForecast: isForecast,
      };
      await updateFinancialCommissionV2(payload);
      if (isBuySideCommissionUnit && buysideCommissionResponsibility && fullAccess)
        await updateFinancialResponsibilityFields();
      if (fetchFinancials) fetchFinancials();
    } catch (err) {
      setUpdateCommissionLoading(false);
    } finally {
      setUpdateCommissionLoading(false);
      setEditField && setEditField(null);
      setIsEdit(false);
    }
  };

  const handleCreate = async () => {
    setUpdateCommissionLoading(true);
    try {
      const payload = {
        AgentId: agentId,
        TeamId: agentTeamId,
        BrokerageId: agentBrokerageId,
        TransactionId: Number(params?.id),
        Date: transactionDate,
        LedgerTransactionSideId:
          label === 'Buy Side' ? LedgerTransactionSideId.Purchase : LedgerTransactionSideId.Listing,
        ...(commissionField?.IsFeeTypePercentage
          ? { Percent: commissionField?.Value }
          : { Amount: commissionField?.Value }),
        PercentBasis: transactionPrice,
        UpdatedBy: loggedInUserId,
        IsForecast: isForecast,
        RepresentingRoleBuyer: isBuyerTransaction,
        RepresentingRoleSeller: isSellerTransaction,
      };

      await createFinancialCommission(payload);
      if (isBuySideCommissionUnit && buysideCommissionResponsibility && fullAccess)
        await updateFinancialResponsibilityFields();
      if (fetchFinancials) fetchFinancials();
    } catch (err) {
      setUpdateCommissionLoading(false);
    } finally {
      setUpdateCommissionLoading(false);
      setEditField && setEditField(null);
      setIsEdit(false);
    }
  };

  const onDelete = async () => {
    setUpdateCommissionLoading(true);
    try {
      await deleteFinancialCommission(LedgerId);
      if (fetchFinancials) fetchFinancials();
    } catch (err) {
      setUpdateCommissionLoading(false);
    } finally {
      setUpdateCommissionLoading(false);
      setEditField && setEditField(null);
      setIsEdit(false);
    }
  };

  const isPriceWithinRange = (
    enteredGCI,
    gciOther,
    compareAmount,
    isResponsibilityValidation = false,
  ) => {
    let _otherGCI = gciOther ?? 0;
    let compareToBeAmount = +enteredGCI + +_otherGCI;

    if (
      compareToBeAmount === parseFloat(compareAmount) ||
      compareToBeAmount < parseFloat(compareAmount)
    ) {
      isResponsibilityValidation ? setValidateResponsibility(false) : setValidate(false);
    } else {
      isResponsibilityValidation ? setValidateResponsibility(true) : setValidate(true);
    }
  };

  const isBuySideCommissionUnit = useMemo(() => fieldKey === 'buySide', []);

  const handleContributionUpdate = (
    object,
    previousContribution,
    setContribution,
    otherContribution,
  ) => {
    const value = object?.Value === '' || object?.Value == null ? null : parseFloat(object?.Value);

    const isToggled =
      previousContribution &&
      object?.IsFeeTypePercentage !== previousContribution?.IsFeeTypePercentage;

    if (
      isToggled ||
      (object?.IsFeeTypePercentage && value === previousContribution?.Percent) ||
      (!object?.IsFeeTypePercentage && value === previousContribution?.Value)
    ) {
      const updatedObject = {
        ...previousContribution,
        IsFeeTypePercentage: object?.IsFeeTypePercentage,
      };
      setContribution(updatedObject);
    } else {
      const updatedObject = {
        IsFeeTypePercentage: object?.IsFeeTypePercentage,
        ...(object?.IsFeeTypePercentage
          ? {
              Percent: object?.Value,
              Value: convertToDecimalIfNotWhole(calculateGCI && calculateGCI(value, gciValue)),
            }
          : {
              Value: object?.Value || '',
              Percent: convertToDecimalIfNotWhole(calculateRate && calculateRate(value, gciValue)),
            }),
      };

      isPriceWithinRange(
        parseFloat(updatedObject?.Value || 0), // entered GCI
        parseFloat(otherContribution?.Value || 0), // other GCI
        gciValue,
        true,
      );
      setContribution(updatedObject);
    }
  };

  return (
    <Fragment>
      <Row
        gutter={12}
        className={classNames(
          styles.commissionRow,
          { [styles.commissionRowEdit]: !isEdit && !notEditable && !isDisableField(fieldKey) },
          { [styles.disabled]: isDisableField(fieldKey) },
        )}
      >
        {!isEdit ? (
          <Fragment>
            <Col lg={14}>
              <span className={styles.value}>{label}</span>
            </Col>
            <Col lg={4}>
              <span className={styles.value}>
                {rate === null || isNaN(rate) ? (
                  '-'
                ) : (
                  <>
                    {convertToDecimalIfNotWhole(ratePercentage)}%
                    <PriceHighlighter isShow={isFeeTypePercentage && !hidePriceHighlighter} />
                  </>
                )}
              </span>
            </Col>
            <Col lg={6}>
              {gci === null || isNaN(gci) ? (
                <span className={styles.value}>{'-'}</span>
              ) : (
                <NumberFormat
                  thousandSeparator
                  displayType="text"
                  value={convertToDecimalIfNotWhole(gciValue)}
                  prefix="$"
                  renderText={(val) => (
                    <span className={styles.value}>
                      {val}
                      <PriceHighlighter isShow={!isFeeTypePercentage && !hidePriceHighlighter} />
                    </span>
                  )}
                />
              )}
            </Col>
            <Icons variant={Icons.EDIT_ROUND} className={styles.editIcon} onClick={onEdit} />
          </Fragment>
        ) : (
          <Fragment>
            <Col lg={24}>
              <p className={styles.boldLabel}>{label}</p>
            </Col>
            <Col lg={12}>
              <Input
                label="Rate"
                isNumberFormat
                value={ratePercentage || ''}
                variant={Input.LIGHT_ROUND}
                maxNumber={100}
                onFocus={handleRateFocus}
                onBlur={handleBlur}
                onChange={handleRateChange}
                suffix="%"
              />
            </Col>
            <Col lg={12}>
              <Input
                label="GCI"
                isNumberFormat
                value={gciValue || ''}
                variant={Input.LIGHT_ROUND}
                onFocus={handleGCIFocus}
                onBlur={handleBlur}
                onChange={handleGCIChange}
                prefix="$"
              />
              {isValidate && (
                <ErrorMessage message={'Total commission can never be greater than price'} />
              )}
            </Col>
            {isBuySideCommissionUnit && fullAccess && (
              <Col lg={24}>
                <div className={styles.responsibilityEditStates}>
                  <Row gutter={12}>
                    <Col span={24}>
                      <Select
                        value={buysideCommissionResponsibility}
                        onSelect={(_, { value }) => {
                          if (value !== TransactionBuySideCommissionResponsibility.Both) {
                            setBuyerCommissionContribution(null);
                            setSellerCommissionContribution(null);
                            setValidateResponsibility(false);
                          }
                          setBuysideCommissionResponsibility(value);
                        }}
                        options={buySideCommissionResponsibilityOptions}
                        placeholder={' '}
                        isArrowIcon={true}
                        arrowIconClassName={styles.arrowdownicon}
                        variant={Select.LIGHT_ROUND}
                        label="Responsible Party"
                      />
                    </Col>
                    {buysideCommissionResponsibility ===
                      TransactionBuySideCommissionResponsibility.Both && (
                      <>
                        <Col span={12}>
                          <CommissionField
                            label="Buyer's Contribution"
                            value={
                              buyerCommissionContribution?.IsFeeTypePercentage
                                ? buyerCommissionContribution?.Percent
                                : buyerCommissionContribution?.Value
                            }
                            IsFeeTypePercentage={buyerCommissionContribution?.IsFeeTypePercentage}
                            update={(object) =>
                              handleContributionUpdate(
                                object,
                                buyerCommissionContribution,
                                setBuyerCommissionContribution,
                                sellerCommissionContribution,
                              )
                            }
                            className={styles.financialInputField}
                          />
                        </Col>
                        <Col span={12}>
                          <CommissionField
                            label="Seller's Contribution"
                            value={
                              sellerCommissionContribution?.IsFeeTypePercentage
                                ? sellerCommissionContribution?.Percent
                                : sellerCommissionContribution?.Value
                            }
                            IsFeeTypePercentage={sellerCommissionContribution?.IsFeeTypePercentage}
                            update={(object) =>
                              handleContributionUpdate(
                                object,
                                sellerCommissionContribution,
                                setSellerCommissionContribution,
                                buyerCommissionContribution,
                              )
                            }
                            className={styles.financialInputField}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                  {isValidateResponsibility && (
                    <ErrorMessage message="Total contribution can never be greater than commission" />
                  )}
                </div>
              </Col>
            )}
            <Col lg={12}>
              <div className={styles.buttonsContainer}>
                <Button
                  variant="secondary"
                  size="large"
                  onClick={LedgerId ? handleUpdate : handleCreate}
                  disabled={isValidateResponsibility || isValidate}
                  loading={isUpdateCommissionLoading}
                >
                  Update
                </Button>
                <Button variant="hollow-bordered" size="large" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </Col>
          </Fragment>
        )}
        <ConfirmationDialog
          onReject={() => setShowDeleteConfirmation(false)}
          onConfirm={onDelete}
          isOpen={showDeleteConfirmation}
          confirmText="Delete"
          isPending={isUpdateCommissionLoading}
          className={styles.deleteCommissionConfirmation}
        >
          <div className={styles.deleteConfirmationContent}>
            <p className={styles.title}>Delete Commission</p>
            Do you want to remove <strong>{label}</strong> commission and all related splits and
            fees?
          </div>
        </ConfirmationDialog>
      </Row>
      {isBuySideCommissionUnit && buysideCommissionResponsibility && gci > 0 && !isEdit && (
        <CommissionResponsibility buysidePrice={gci} />
      )}
    </Fragment>
  );
};
