import React, { useEffect, useMemo, useState } from 'react';
import styles from '../styles.module.scss';
import { Table } from 'pages/Radar/components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataType } from './helpers';
import { Toggle } from 'components';
import { Popover } from 'components-antd';
import { PlansIcon } from '../components';
import { link } from 'settings/navigation/link';
import ActionsIcon from 'pages/Properties/Search/components/Icons/ActionsIcon';
import SmallDeleteIcon from 'pages/Properties/Search/components/Icons/SmallDeleteIcon';
import {
  requestGetCampaignByIdEffect,
  requestGetCampaignsEffect,
  requestPostCampaignEffect,
  requestUpdateCampaignEffect,
} from 'store/effects/radarKits';
import { getCampaignsSelector, getFilteredCampaigns } from 'store/selectors/radarKits';
import { TableColumnsType } from 'antd';
import { Campaign } from 'store/reducers/radarKits/types';
import { getAgentId } from 'store/selectors/user';

interface IPlans {
  clientId?: string;
  handleRowClick?: Function;
  handleNew?: any;
  newTitle?: string;
  tableHeading?: string;
}

export const Plans: React.FC<IPlans> = ({
  clientId,
  handleRowClick,
  handleNew,
  newTitle,
  tableHeading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [campaignsList, setCampaignsList] = useState<Campaign[]>([]);
  const currentAgentId = useSelector(getAgentId);

  const { data } = useSelector(getCampaignsSelector);

  useEffect(() => {
    if (clientId) {
      const { addedKits } = getFilteredCampaigns(data, clientId);
      setCampaignsList(addedKits);
    } else {
      setCampaignsList(data || []);
    }
  }, [data]);

  const campaignListDataSource = useMemo(() => {
    if (!campaignsList) return { active: [], inactive: [] };
    const list = campaignsList?.map<DataType>((campaign) => ({
      key: campaign.Id,
      agentId: campaign.AgentId,
      teamId: campaign.TeamId,
      name: {
        title: campaign.Name,
        info: campaign.Description,
        onClick: (key) =>
          handleRowClick
            ? handleRowClick(campaign.Id)
            : history.push(link.toRadarKitSequence(campaign.Id)),
      },
      clients: {
        count: campaign.CampaignMembers.length,
        onClick: (key) => history.push(link.toRadarKitSequence(campaign.Id)),
      },
      selectedMembers: campaign.CampaignMembers.map(({ ClientId }) => ClientId),
      isActive: campaign.Active,
      onActiveChange(isActive, id) {
        dispatch(
          requestUpdateCampaignEffect(
            {
              Id: id,
              Active: isActive,
            },
            {},
            (err, res) => {
              if (!err && res.data.result.Id) {
                // TODO: should implement optimistic update here
                dispatch(requestGetCampaignsEffect({}, { silent: true }));
              }
            },
          ),
        );
      },
      onRemove(id, memberId, selectedMembers) {
        dispatch(
          requestUpdateCampaignEffect(
            {
              Id: Number(id),
              CampaignMembers: selectedMembers.filter((member) => member !== Number(memberId)),
            },
            {},
            (err, res) => {
              if (!err && res.data.result.Id) {
                setCampaignsList((prev) => prev.filter((campaign) => campaign.Id !== id));
                dispatch(requestGetCampaignsEffect({}, { silent: true }));
              }
            },
          ),
        );
      },
    }));

    return {
      active: list.filter((item) => item.isActive),
      inactive: list.filter((item) => !item.isActive),
    };
  }, [campaignsList]);

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: styles.tableCol,
      sorter: (a, b) => a.name.title.localeCompare(b.name.title),
      render: (group: any, record: DataType) => (
        <div className={styles.tableColNameWrapper} onClick={group?.onClick}>
          <div className={styles.iconWrapper}>
            <PlansIcon />
          </div>
          <div className={styles.detailsWrapper}>
            <div className={styles.title}>{group.title}</div>
            <div className={styles.info}>{group.info}</div>
          </div>
        </div>
      ),
    },
    ...(clientId
      ? [
          // {
          //   title: 'Property',
          //   dataIndex: 'property',
          //   key: 'property',
          //   className: styles.tableCol,
          //   sorter: (a, b) => a.name.title.localeCompare(b.name.title),
          //   render: (group: any, record: DataType) => (
          //     <div className={styles.tableColNameWrapper}>
          //       <div className={styles.detailsWrapper}>
          //         <div className={styles.title}>192 NW 12th Street</div>
          //         <div className={styles.info}>New York, NY, 10007</div>
          //       </div>
          //     </div>
          //   ),
          // },
          {
            title: '',
            dataIndex: 'remove',
            key: 'remove',
            className: styles.tableCol,
            render: (isActive: boolean, record: DataType) => {
              return (
                <div className={styles.actionBtn}>
                  <Popover
                    overlayClassName={styles.popoverOverlay}
                    placement="bottomRight"
                    trigger="hover"
                    content={
                      <div className={styles.actionList}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            record.onRemove(record.key, clientId, record?.selectedMembers);
                          }}
                          className={styles.actionListBtn}
                        >
                          <SmallDeleteIcon className={styles.icon} />
                          <span>Remove</span>
                        </div>
                      </div>
                    }
                  >
                    <div>
                      <ActionsIcon className={styles.actionBtnIcon} color={'#252d44'} />
                    </div>
                  </Popover>
                </div>
              );
            },
          },
        ]
      : [
          {
            title: '# of Clients',
            dataIndex: 'clients',
            key: 'clients',
            className: styles.tableCol,
            sorter: (a, b) => a.clients.count - b.clients.count,
            render: (value) => (
              <div className={styles.tableNormalCell} onClick={value?.onClick}>
                {value.count}
              </div>
            ),
          },
          {
            title: 'Active',
            dataIndex: 'isActive',
            key: 'isActive',
            className: styles.tableCol,
            sorter: (a, b) => (a.isActive ? 1 : -1),
            render: (isActive: boolean, record: DataType) => (
              <div className={styles.toggleWrapper}>
                <Toggle
                  disabled={currentAgentId !== record.agentId}
                  checked={isActive}
                  className={styles.toggle}
                  onChange={(e, value) => {
                    e.stopPropagation();
                    record.onActiveChange(value, record.key);
                  }}
                />
              </div>
            ),
          },
        ]),
  ];

  return (
    <div className={styles.tableContainer}>
      <div>
        <Table
          title={tableHeading || 'Active'}
          count={campaignListDataSource.active.length}
          data={campaignListDataSource.active}
          columns={columns}
          newTitle={newTitle}
          handleNew={() => {
            handleNew
              ? handleNew()
              : dispatch(
                  requestPostCampaignEffect(
                    { Active: true, Name: '', Description: '' },
                    {},
                    (err, res) => {
                      dispatch(requestGetCampaignByIdEffect(res.data.result.Id.toString()));
                      !err && history.push(link.toRadarKitSequence(res.data.result.Id));
                    },
                  ),
                );
          }}
        />
      </div>
      <div>
        {campaignListDataSource?.inactive?.length > 0 ? (
          <Table
            title="Inactive"
            count={campaignListDataSource.inactive.length}
            data={campaignListDataSource.inactive}
            columns={columns}
            defaultCollapse={true}
          />
        ) : null}
      </div>
    </div>
  );
};
