import React from 'react';
import ContentEditable from 'react-contenteditable';
import styles from './style.module.scss';
import classNames from 'classnames';

interface PropTypes {
  className: string;
  message: string;
  onChangeHandler: (e: any) => void;
  inputValue: string;
  contentEditable: React.RefObject<any>;
  handleKeyPress: (e: any) => void;
  placeholder: string;
  disabled: boolean;
  sideBarChatView: boolean;
  placeholderClassName?: string;
  editableContentWrapperClassName?: string;
}
interface StateTypes {
  html: string;
}

export class EditableContent extends React.Component<PropTypes, StateTypes> {
  constructor(props) {
    super(props);
    this.state = { html: '' };
  }
  moveCursorToStart = (editableDiv) => {
    if (editableDiv) {
      const selection: any = window.getSelection();
      const range = document.createRange();
      range.setStart(editableDiv, 0);
      range.setEnd(editableDiv, 0);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };
  handleChange = (evt) => {
    this.setState({ html: evt.target.value });
    this.props.onChangeHandler(evt);
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.inputValue !== this.state.html) {
      this.setState({ html: this.props.inputValue });
    }
  }

  render = () => {
    return (
      <div
        style={{ position: 'relative' }}
        className={
          this.props.sideBarChatView
            ? styles.wrapper_editableDiv
            : this.props.editableContentWrapperClassName || ''
        }
      >
        {!this.state.html ? (
          <div
            className={classNames(styles.placeholder, this.props.placeholderClassName)}
            onClick={() => this.props.contentEditable?.current?.focus()}
          >
            {this.props.placeholder}
          </div>
        ) : null}

        <ContentEditable
          className={classNames(styles.editableDiv)}
          innerRef={this.props.contentEditable}
          onKeyDown={this.props.handleKeyPress}
          html={this.state.html}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          tagName="p"
        />
      </div>
    );
  };
}
