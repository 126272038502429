import { HeaderTop } from './HeaderTop';
import { HeaderStats } from './HeaderStats';

import styles from './styles.module.scss';

export const LocalHeader = (props) => {
  const { onChange, activeValue, isPending } = props;
  return (
    <div className={styles.symphonyHeader}>
      <HeaderTop onChange={onChange} activeValue={activeValue} />
      {!isPending && <HeaderStats />}
    </div>
  );
};
