import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import { PropertyCard } from 'pages/Properties/ClientFeed/components/PropertyCard';
import Icon from '../../Icons';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { propertySeenOnMap, setPropertyToScrollInList } from 'store/actions/feed';

const InfoProperties = ({ properties }) => {
  const dispatch = useDispatch();
  const [selectedProperty, setSelectedProperty] = useState(0);
  const history = useHistory();
  const pathname = history.location.pathname;
  const isFeed = pathname === '/properties/feed';
  const { selectedSearch, agentClientMode, isSimpleHeaderTitleVisible } =
    useSelector(getFeedMetaSelectorV3);
  const { isAgent } = useSelector(getUserRolesMapSelector);

  const isFavorites =
    isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Favorites';
  const isAgentType = isAgent ? (agentClientMode?.value ? true : !isFavorites) : undefined;

  useEffect(() => {
    const id = properties[selectedProperty]?.Id;
    dispatch(propertySeenOnMap({ id }));
    dispatch(setPropertyToScrollInList({ id }));
  }, [selectedProperty]);

  return (
    <div className={styles.InfoProperties}>
      <PropertyCard
        countOnlyUnread={
          isSimpleHeaderTitleVisible?.value &&
          isSimpleHeaderTitleVisible?.text === 'Unread Comments'
        }
        containerClassName={styles.container}
        className={styles.propertyCard}
        photoClassName={styles.photo}
        getPageNumber={() => {}}
        shouldScrollIntoView={() => {}}
        isAgentType={isAgentType}
        data={properties[selectedProperty]}
        renderingIndex={selectedProperty}
        index={selectedProperty}
        isFavProperty={
          isSimpleHeaderTitleVisible?.value && isSimpleHeaderTitleVisible?.text === 'Favorites'
        }
        searchInstanceId={isFeed && selectedSearch?.id ? selectedSearch.id : undefined}
      />
      {properties?.length > 1 && (
        <div className={styles.footer}>
          <button
            className={styles.arrow}
            disabled={selectedProperty === 0}
            onClick={() => {
              setSelectedProperty((prev) => prev - 1);
            }}
          >
            <Icon variant={Icon.ARROW_LEFT} />
          </button>
          <p className={styles.pagination}>
            {selectedProperty + 1} of {properties?.length}
          </p>
          <button
            className={styles.arrow}
            disabled={selectedProperty === properties?.length - 1}
            onClick={() => {
              setSelectedProperty((prev) => prev + 1);
            }}
          >
            <Icon variant={Icon.ARROW_RIGHT} />
          </button>
        </div>
      )}
    </div>
  );
};

export default InfoProperties;
