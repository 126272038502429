import Expand from '../../../../../components/Icons/Expand';
import { Map } from 'components';
import styles from './styles.module.scss';
import ExpandedModal from './ExpandedModal';
import { useState } from 'react';

const ListingDetailMap = ({ options }) => {
  const { zoom, center, markers } = options;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.mapContainer}>
      <Map
        markersMap={markers}
        className={styles.map}
        controlsClassName={styles.mapControls}
        zoom={zoom}
        center={center}
        controls={{
          zoom: false,
        }}
      />
      <Expand className={styles.mapContainerExpand} onClick={() => setIsOpen(true)} />
      <ExpandedModal isOpen={isOpen} setIsOpen={setIsOpen} options={options} />
    </div>
  );
};

export default ListingDetailMap;
