import prettyBytes from 'pretty-bytes';
import { Icons } from '../Icons';
import styles from './style.module.scss';

export const FileNameBox = ({ file, onDelete, key }) => {
  return (
    <div className={styles.fileBox} key={key}>
      <div className={styles.fileNameBox}>
        <Icons variant={'file'} className={styles.fileIcon} />
        <div className={styles.fileNameWrapper}>
          <span className={styles.fileName}>{file.name}</span>

          {file.size && (
            <div testid="size" className={styles.fileSize}>
              {prettyBytes(file.size)}
            </div>
          )}
        </div>
      </div>
      <Icons variant={'trash'} onClick={onDelete} className={styles.deleteIcon} />
    </div>
  );
};
