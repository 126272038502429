import { createSelector } from 'reselect';
import { map, omit } from 'lodash-es';
import { IDLE, PENDING, READY } from 'settings/constants/apiState';
import { getSearchCriteriaFormattedString } from 'helpers';

const localState = ({ mySearches }) => mySearches;

export const getSearchInstancesSelector = createSelector(localState, ({ savedInstances }) => {
  const formatted = { ...(savedInstances || {}) };
  if (formatted?.data?.length) {
    formatted.data = formatted.data.map((item) => {
      const formattedSearch = { ...(item || {}) };
      if (formattedSearch.SearchQuery?.Locations?.length) {
        formattedSearch.SearchQuery.Locations = item.SearchQuery.Locations.map((location) =>
          omit(location, 'LocationId'),
        );
      }
      return formattedSearch;
    });
  }
  return formatted;
});

export const getEditedSearchInstanceSelector = createSelector(
  localState,
  ({ savedInstances }) => savedInstances,
);

export const getSearchInstancesFormattedSelector = createSelector(
  getSearchInstancesSelector,
  ({ data, state }) => ({
    data: map(
      data,
      ({
        Id,
        ClientId,
        AgentId,
        Name,
        UpdatedDate,
        Status,
        DefaultPropertySearchPreferences,
        AgentName,
        PreviewPhotoUrls,
        NumOfResults,
      }) => ({
        id: Id,
        clientId: ClientId,
        agentId: AgentId,
        name: Name,
        UpdatedDate,
        status: Status,
        info: getSearchCriteriaFormattedString(DefaultPropertySearchPreferences)?.info,
        fullInfo: getSearchCriteriaFormattedString(DefaultPropertySearchPreferences)?.fullInfo,
        criterias: DefaultPropertySearchPreferences,
        agentName: AgentName,
        photos: PreviewPhotoUrls || [],
        total: NumOfResults,
      }),
    ),
    isLoading: state === PENDING && !data,
    loaded: state === READY,
    isData: !!data?.length,
    hasSoftCriterias: data?.some((instance) =>
      hasSoftCriteria(instance.DefaultPropertySearchPreferences),
    ),
    isIdle: state === IDLE,
  }),
);

const hasSoftCriteria = (searchPrefs) => {
  return Object.keys(searchPrefs).some((key) =>
    [
      'HomePrefs',
      'OutdoorPrefs',
      'AmenitiesPrefs',
      'Parking',
      'KitchenPreferences',
      'NeighborhoodPrefs',
      'CommutePrefs',
      'Stories',
      'InteriorStylesPrefs',
      'Schools',
    ].includes(key),
  );
};

export const getSavedSearchesSelector = createSelector(localState, ({ savedSearches }) => {
  const formatted = { ...(savedSearches || {}) };
  if (formatted?.data?.length) {
    formatted.data = formatted.data.map((search) => {
      const formattedSearch = { ...(search || {}) };
      if (formattedSearch.SearchQuery?.Locations?.length) {
        formattedSearch.SearchQuery.Locations = search.SearchQuery.Locations.map((location) =>
          omit(location, 'LocationId'),
        );
      }
      return formattedSearch;
    });
  }
  return formatted;
});

export const getSavedSearchesFormattedSelector = createSelector(
  getSavedSearchesSelector,
  ({ data, state }) => ({
    agentSavedSearches: map(
      data?.filter(({ ClientId }) => !ClientId),
      ({
        Id,
        Name,
        Status,
        DefaultPropertySearchPreferences: SearchQuery,
        PhotoUrls,
        NumOfResults,
        DeactivatedOn,
        UpdatedDate,
      }) => ({
        id: Id,
        name: Name,
        UpdatedDate: UpdatedDate,
        status: Status,
        info: getSearchCriteriaFormattedString(SearchQuery)?.info,
        fullInfo: getSearchCriteriaFormattedString(SearchQuery)?.fullInfo,
        criterias: SearchQuery,
        photos: PhotoUrls || [],
        total: NumOfResults,
        deactivatedOn: DeactivatedOn,
      }),
    ),
    formattedData: map(
      data,
      ({
        Id,
        Name,
        Status,
        DefaultPropertySearchPreferences: SearchQuery,
        PhotoUrls,
        NumOfResults,
        DeactivatedOn,
        UpdatedDate,
      }) => ({
        id: Id,
        name: Name,
        UpdatedDate: UpdatedDate,
        status: Status,
        info: getSearchCriteriaFormattedString(SearchQuery)?.info,
        fullInfo: getSearchCriteriaFormattedString(SearchQuery)?.fullInfo,
        criterias: SearchQuery,
        photos: PhotoUrls || [],
        total: NumOfResults,
        deactivatedOn: DeactivatedOn,
      }),
    ),
    isLoading: state === PENDING && !data,
    isPending: state === PENDING,
    isIdle: state === IDLE,
    loaded: state === READY,
  }),
);

export const isPreApprovalModalOpenSelector = createSelector(
  localState,
  ({ preApproval }) => preApproval.isModalOpen,
);

export const getPreApprovalDocumentsSelector = createSelector(
  localState,
  ({ preApproval }) => preApproval,
);

export const getPreApprovalRequestsSelector = createSelector(
  localState,
  ({ preApprovalRequests }) => preApprovalRequests,
);
