import { Row, Col } from 'antd';
import { AreaGraphTemplate } from '../../Graphs/AreaGraphTemplate';
import { GeneralLedgerKeys } from '../../Stats/Content/ContentTypes/helper';

export const AgentView = ({
  grossSummary,
  grossData,
  netSummary,
  netData,
  feesSummary,
  feesData,
  isPending,
}) => {
  return (
    <Row gutter={[24, 24]}>
      <Col
        span={8}
        xs={{ span: 24, order: 1 }}
        sm={{ span: 12, order: 1 }}
        lg={{ span: 8, order: 1 }}
        className="gutter-row"
      >
        <AreaGraphTemplate
          title={'GCI'}
          color={'#FB913A'}
          hoverColor={'#E28234'}
          summary={grossSummary.filter((s) => s.SummaryColumn === GeneralLedgerKeys.GCI)?.[0] || {}}
          data={grossData?.[GeneralLedgerKeys.GCI] || []}
          isPending={isPending}
          isExpandableChart={false}
        />
      </Col>
      <Col
        span={8}
        xs={{ span: 24, order: 2 }}
        sm={{ span: 12, order: 2 }}
        lg={{ span: 8, order: 2 }}
        className="gutter-row"
      >
        <AreaGraphTemplate
          title={'Fees'}
          color={'#4673D1'}
          hoverColor={'#3F68BC'}
          summary={
            feesSummary.filter((s) => s.SummaryColumn === GeneralLedgerKeys.FeeSummary)?.[0] || {}
          }
          data={feesData?.[GeneralLedgerKeys.FeeSummary] || []}
          isPending={isPending}
          isExpandableChart={true}
        />
      </Col>
      <Col
        span={8}
        xs={{ span: 24, order: 3 }}
        sm={{ span: 12, order: 3 }}
        lg={{ span: 8, order: 3 }}
        className="gutter-row"
      >
        <AreaGraphTemplate
          title={'Split'}
          color={'#8882D9'}
          hoverColor={'#7570B9'}
          summary={
            grossSummary.filter((s) => s.SummaryColumn === GeneralLedgerKeys.AgentPayment)?.[0] ||
            {}
          }
          data={grossData?.[GeneralLedgerKeys.AgentPayment] || []}
          isPending={isPending}
          isExpandableChart={false}
        />
      </Col>
      {grossData?.[GeneralLedgerKeys.CustomAccountPayment]?.length && (
        <Col
          span={8}
          xs={{ span: 24, order: 6 }}
          sm={{ span: 12, order: 6 }}
          lg={{ span: 8, order: 6 }}
          className="gutter-row"
        >
          <AreaGraphTemplate
            title={'Other Splits'}
            color={'#4673D1'}
            hoverColor={'#3F68BC'}
            summary={
              grossSummary.filter(
                (s) => s.SummaryColumn === GeneralLedgerKeys.CustomAccountPayment,
              )?.[0] || {}
            }
            data={grossData?.[GeneralLedgerKeys.CustomAccountPayment] || []}
            isPending={isPending}
            isExpandableChart={true}
          />
        </Col>
      )}
      <Col
        span={8}
        xs={{ span: 24, order: 7 }}
        sm={{ span: 12, order: 7 }}
        lg={{ span: 8, order: 7 }}
        className="gutter-row"
      >
        <AreaGraphTemplate
          title={'Net Income'}
          color={'#4673D1'}
          hoverColor={'#3F68BC'}
          summary={
            netSummary.filter((s) => s.SummaryColumn === GeneralLedgerKeys.AgentPayment)?.[0] || {}
          }
          data={netData?.[GeneralLedgerKeys.AgentPayment] || []}
          isPending={isPending}
          isExpandableChart={false}
        />
      </Col>
      {netData?.[GeneralLedgerKeys.CustomAccountPayment]?.length && (
        <Col
          span={8}
          xs={{ span: 24, order: 10 }}
          sm={{ span: 12, order: 10 }}
          lg={{ span: 8, order: 10 }}
          className="gutter-row"
        >
          <AreaGraphTemplate
            title={'Other Net Income'}
            color={'#4673D1'}
            hoverColor={'#3F68BC'}
            summary={
              netSummary.filter(
                (s) => s.SummaryColumn === GeneralLedgerKeys.CustomAccountPayment,
              )?.[0] || {}
            }
            data={netData?.[GeneralLedgerKeys.CustomAccountPayment] || []}
            isPending={isPending}
            isExpandableChart={true}
          />
        </Col>
      )}
    </Row>
  );
};
